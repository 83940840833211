import React, { useState, useEffect } from "react";
import { MdLock } from "react-icons/md";
import * as R from "ramda";
import { ClientInfo } from "@blisspointmedia/bpm-types/dist/Onboarding";
import useLocation from "../utils/hooks/useLocation";
import { Spinner } from "../Components/Spinner";
import { Page } from "../Components";
import CircularProgressBar from "../Components/CircularProgressBar/CircularProgressBar";
import { useTabbedNav } from "../utils/hooks/useNav";
import { RouteComponentProps } from "@reach/router";
import BudgetsBilling from "./BudgetsBilling/BudgetsBilling";
import CoreReporting from "./CoreReporting/CoreReporting";
import Data from "./Data/Data";
import PlatformAccess from "./PlatformAccess/PlatformAccess";
import PlatformBudgets from "./PlatformBudgets/PlatformBudgets";
import PlatformChecklist from "./PlatformChecklist/PlatformChecklist";
import { getClientInfo, getCompletedPlatformPercentage, getCompletedPercentage } from "./OnboardingUtils";
import { budgetDetails, budgetStrategy, budgets } from "./BudgetsBilling/BudgetsBillingQuestions";
import { primaryContacts, calendar, metrics } from "./CoreReporting/CoreReportingQuestions";
import { questions } from "./Data/DataQuestions";
import { platformAccessOptions } from "./PlatformAccess/PlatformAccessOptions";
import { platformBudgetsOptions } from "./PlatformBudgets/PlatformBudgetsOptions";
import { PLATFORM_OPTIONS } from "./PlatformChecklist/PlatformChecklistOptions";
import "./Onboarding.scss";

const enum TabKey {
  PLATFORM_CHECKLIST = "platform-checklist",
  PLATFORM_ACCESS = "platform-access",
  CORE_REPORTING = "core-reporting",
  BUDGETS_BILLING = "budgets-billing",
  PLATFORM_BUDGETS = "platform-budgets",
  DATA = "data",
}

const Onboarding: React.FC = ({ navigate }: RouteComponentProps) => {
  const { company } = useLocation();
  const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);
  const [dataPercentage, setDataPercentage] = useState<number>(0);
  const [budgetPercentage, setBudgetPercentage] = useState<number>(0);
  const [coreReportingPercentage, setCoreReportingPercentage] = useState<number>(0);
  const [platformChecklistPercentage, setPlatformChecklistPercentage] = useState<number>(0);
  const [platformAccessPercentage, setPlatformAccessPercentage] = useState<number>(0);
  const [platformBudgetsPercentage, setPlatformBudgetsPercentage] = useState<number>(0);

  useEffect(() => {
  const fetchClientInfo = async () => {
    const info = await getClientInfo(company);
    const updatedInfo = R.isEmpty(info) ? { company, responses: [], changeLog: [] } : info;
    setClientInfo(updatedInfo);

    const corePrimaryCount = (!updatedInfo.responses || updatedInfo.responses.length === 0)
      ? primaryContacts.rows.length
      : updatedInfo.responses.filter(response =>
          response.questionNumber.startsWith("3A")
        ).length;

    const initialPlatformChecklistPercentage = getCompletedPlatformPercentage(updatedInfo.responses, PLATFORM_OPTIONS);
    setPlatformChecklistPercentage(initialPlatformChecklistPercentage);

    const initialPlatformAccessPercentage = getCompletedPlatformPercentage(updatedInfo.responses, platformAccessOptions(updatedInfo.responses));
    setPlatformAccessPercentage(initialPlatformAccessPercentage);

    const initialCoreReportingPercentage = getCompletedPercentage(updatedInfo.responses, corePrimaryCount + calendar.length + metrics.length, "3");
    setCoreReportingPercentage(initialCoreReportingPercentage);

    const initialBudgetsPercentage = getCompletedPercentage(updatedInfo.responses, budgetStrategy.length + budgetDetails.length + budgets.length, "4");
    setBudgetPercentage(initialBudgetsPercentage);

    const initialPlatformBudgetsPercentage = getCompletedPlatformPercentage(updatedInfo.responses, platformBudgetsOptions(updatedInfo.responses));
    setPlatformBudgetsPercentage(initialPlatformBudgetsPercentage);

    const initialDataPercentage = getCompletedPercentage(updatedInfo.responses, questions.length, "6");
    setDataPercentage(initialDataPercentage);
  };

  fetchClientInfo();
}, [company]);


  const platformLocked = platformChecklistPercentage === 0;

  const NAVS = [
    {
      label: "1. Platform Checklist",
      key: TabKey.PLATFORM_CHECKLIST,
      percentage: platformChecklistPercentage,
    },
    {
      label: "2. Platform Access",
      key: TabKey.PLATFORM_ACCESS,
      percentage: platformAccessPercentage,
      isLocked: platformLocked,
    },
    { label: "3. Core Reporting", key: TabKey.CORE_REPORTING, percentage: coreReportingPercentage },
    { label: "4. Budgets/Billing", key: TabKey.BUDGETS_BILLING, percentage: budgetPercentage },
    {
      label: "5. Platform Budgets",
      key: TabKey.PLATFORM_BUDGETS,
      percentage: platformBudgetsPercentage,
      isLocked: platformLocked,
    },
    { label: "6. Data", key: TabKey.DATA, percentage: dataPercentage },
  ];

  useEffect(() => {
    const fetchClientInfo = async () => {
      const info = await getClientInfo(company);
      if (R.isEmpty(info)) {
        setClientInfo({ company, responses: [], changeLog: [] });
      }
      setClientInfo(info);
    };

    fetchClientInfo();
  }, [company]);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "client-info",
    defaultKey: TabKey.PLATFORM_CHECKLIST,
  });

  if (!clientInfo) {
    return <Spinner size={2} />;
  }

  const handleNavClick = (navKey: string) => {
    const selectedNav = NAVS.find(nav => nav.key === navKey);

    if (selectedNav?.isLocked) {
      console.warn(`Cannot navigate to ${selectedNav.label}. The tab is locked.`);
      return;
    }

    goToTab(navKey);
  };

  const handlePlatformChecklistPercentageChange = (percentage: number) => {
    setPlatformChecklistPercentage(percentage);
  };

  const handlePlatformAccessPercentageChange = (percentage: number) => {
    setPlatformAccessPercentage(percentage);
  };

  const handlePlatformBudgetsPercentageChange = (percentage: number) => {
    setPlatformBudgetsPercentage(percentage);
  };

  const handleDataPercentageChange = (percentage: number) => {
    setDataPercentage(percentage);
  };

  const handleBudgetsPercentageChange = (percentage: number) => {
    setBudgetPercentage(percentage);
  };

  const handleCoreReportingPercentageChange = (percentage: number) => {
    setCoreReportingPercentage(percentage);
  };

  const handleClientInfoChange = (updatedClientInfo: ClientInfo) => {
    setClientInfo(updatedClientInfo);
  };

  return (
    <span className="onboardingPageContainer">
      <Page
        title="Onboarding"
        pageType="Onboarding"
        app2Redesign
        navs={NAVS}
        onNav={handleNavClick}
        selectedNav={tab}
        navRenderer={label => {
          const navItem = NAVS.find(nav => nav.label === label);
          if (!navItem) {
            return <span>{label}</span>;
          }

          return (
            <span key={navItem.key} className="onboardingProgress">
              <CircularProgressBar
                className="onboardingProgressBar"
                percentage={navItem.percentage}
                size={60}
                icon={navItem.isLocked ? <MdLock size={30} /> : undefined}
              />
              {label}
            </span>
          );
        }}
      >
        <div>
          {tab === TabKey.PLATFORM_CHECKLIST && (
            <PlatformChecklist
              clientInfo={clientInfo}
              onClientInfoChange={handleClientInfoChange}
              onPercentageChange={handlePlatformChecklistPercentageChange}
            />
          )}
          {tab === TabKey.PLATFORM_ACCESS && (
            <PlatformAccess
              clientInfo={clientInfo}
              onClientInfoChange={handleClientInfoChange}
              onPercentageChange={handlePlatformAccessPercentageChange}
            />
          )}
          {tab === TabKey.CORE_REPORTING && (
            <CoreReporting
              clientInfo={clientInfo}
              onPercentageChange={handleCoreReportingPercentageChange}
            />
          )}
          {tab === TabKey.BUDGETS_BILLING && (
            <BudgetsBilling
              clientInfo={clientInfo}
              onPercentageChange={handleBudgetsPercentageChange}
            />
          )}
          {tab === TabKey.PLATFORM_BUDGETS && (
            <PlatformBudgets
              clientInfo={clientInfo}
              onPercentageChange={handlePlatformBudgetsPercentageChange}
            />
          )}
          {tab === TabKey.DATA && (
            <Data clientInfo={clientInfo} onPercentageChange={handleDataPercentageChange} />
          )}
        </div>
      </Page>
    </span>
  );
};

export default Onboarding;

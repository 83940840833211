import React, { useMemo } from "react";
import { Button, ButtonType } from "../../Button";
import { DateRange, StateSetter } from "../../../utils/types";
import { CustomDatePresets } from "../CustomDatePresets";
import {
  CustomPresetState,
  COMP_PER_TYPES,
  DATE_PRESETS,
  RangeErrorPreset,
  defaultIsDayOrRangeInvalid,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePresets";
import cn from "classnames";
import "./DPPresets.scss";
import type { Day } from "date-fns";

interface DPPresetsProps {
  selectedPreset:
    | (typeof DATE_PRESETS[number][number] | typeof COMP_PER_TYPES[number][number])
    | null;
  setSelectedPreset: (string) => void;
  setDateInputs: StateSetter<Partial<DateRange>>;
  resolvedIsOutsideValidRange?: (date: string) => boolean;
  startCustomPreset: CustomPresetState;
  setStartCustomPreset: StateSetter<CustomPresetState>;
  endCustomPreset: CustomPresetState;
  setEndCustomPreset: StateSetter<CustomPresetState>;
  rangeErrorPreset: RangeErrorPreset;
  setRangeErrorPreset: StateSetter<RangeErrorPreset>;
  weekDefinition?: Day;
  comparison?: boolean;
}

export const DPPresets: React.FC<DPPresetsProps> = ({
  selectedPreset,
  setSelectedPreset,
  setDateInputs,
  resolvedIsOutsideValidRange = defaultIsDayOrRangeInvalid,
  weekDefinition = 1,
  startCustomPreset,
  setStartCustomPreset,
  endCustomPreset,
  setEndCustomPreset,
  rangeErrorPreset,
  setRangeErrorPreset,
  comparison = false,
}) => {
  const buttonOptions = useMemo(() => (comparison ? COMP_PER_TYPES : DATE_PRESETS), [comparison]);

  return (
    <div className="DPPresets">
      <div className="standardPresets">
        {buttonOptions.map((group, i) => (
          <div className="datePresetOptionsContainer" key={`DPPresetOptionContainer${i}`}>
            {group.map((preset, j) => (
              <Button
                key={`DPPresetOptionButton${i}${j}`}
                type={ButtonType.EMPTY}
                design="secondary"
                size="sm"
                onClick={() => {
                  setSelectedPreset(preset);
                }}
                className={cn("controlButton", {
                  selected: selectedPreset === preset,
                  datePresetButton: !comparison,
                })}
              >
                {preset}
              </Button>
            ))}
          </div>
        ))}
      </div>
      <div className="customPresets">
        <Button
          key={"DPPresetOptionButtonPrimaryCustom"}
          type={ButtonType.EMPTY}
          design="secondary"
          size="sm"
          onClick={() => {
            setSelectedPreset("Custom");
          }}
          className={cn("controlButton", "datePresetButton", {
            selected: selectedPreset === "Custom",
          })}
        >
          {"Custom"}
        </Button>
        <CustomDatePresets
          setRangeError={setRangeErrorPreset}
          setDateInputs={setDateInputs}
          invalidOrOutOfRange={resolvedIsOutsideValidRange}
          weekDefinition={weekDefinition}
          startCustomPreset={startCustomPreset}
          setStartCustomPreset={setStartCustomPreset}
          endCustomPreset={endCustomPreset}
          setEndCustomPreset={setEndCustomPreset}
          disabled={selectedPreset !== "Custom"}
          rangeError={rangeErrorPreset}
        />
      </div>
    </div>
  );
};

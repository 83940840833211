import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { DateRange } from "../utils/types";

export const SHORT_DATE_FORMAT = "MM/dd";
const DATE_FORMAT = "yyyy-MM-dd";

/**
 * Splits a given date range into an array of formatted date strings representing the start of each week within the range.
 * The range is inclusive of both the start and end dates.
 *
 * @param {DateRange} dateRange - The range of dates to be split into weeks.
 * @returns {string[]} An array of formatted date strings, each representing the start of a week within the date range.
 */
export const getWeeksFromRange = (dateRange: DateRange): string[] => {
  let weeks: string[] = [];
  let currentWeek = dateRange.start;
  while (currentWeek <= dateRange.end) {
    weeks.push(currentWeek);
    currentWeek = R.pipe(Dfns.parseISO, Dfns.addWeeks(1), Dfns.format(DATE_FORMAT))(currentWeek);
  }

  return weeks;
};

/**
 * Checks if the provided date is a valid Date object.
 *
 * @param {Date} date - The date to validate.
 * @returns {boolean} If it's a valid date or not
 */
export const validDate = (date: Date): boolean => {
  if (R.isNil(date)) {
    return false;
  }
  const timeNow = new Date(date);
  return !R.isNil(timeNow) && !R.isNil(timeNow.getDate()) && !isNaN(timeNow.getDate());
};

/**
 * Returns a formatted date string representing the date exactly N days prior to today.
 *
 * @param {number} [daysAgo=1] - The number of days ago to subtract from the current date (optional, defaults to 1).
 * @param {string} [format=DATE_FORMAT] - The format in which to return the date (optional, defaults to the predefined DATE_FORMAT).
 * @returns {string} The formatted date string of the specified days ago.
 */
export const getFormattedDaysAgo = (daysAgo = 1, format = DATE_FORMAT): string =>
  R.pipe(Dfns.subDays(daysAgo), Dfns.format(format))(new Date());

/**
 * Returns a formatted date string representing the date exactly N months prior to today.
 *
 * @param {number} [monthsAgo=1] - The number of months ago to subtract from the current date (optional, defaults to 1).
 * @param {string} [format=DATE_FORMAT] - The format in which to return the date (optional, defaults to the predefined DATE_FORMAT).
 * @returns {string} The formatted date string of the specified months ago.
 */
export const getFormattedMonthsAgo = (monthsAgo = 1, format = DATE_FORMAT): string =>
  R.pipe(Dfns.subMonths(monthsAgo), Dfns.format(format))(new Date());

import "./IncrementalRoas.scss";
import React, { useCallback } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import ComposedChart from "../../Components/Charts/ComposedChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import * as Dfns from "date-fns/fp";
import { DownloadDropdown } from "../../Components";
import { currFormat } from "../BrandImpact/BrandImpactUtils";
import { MdArrowForward } from "react-icons/md";

interface IncrementalRoasProps {
  company: string;
  weeklyIncrementalRoas: WeeklyIncrementalRoas[];
  incrementalRoasTakeaways: IncrementalRoasTakeaways;
}

interface IncrementalRoasTakeaways {
  latestDate: string;
  PaidMedia: number;
  IncrementalRevenue: number;
  IncrementalRoas: number;
  Iroas_ttm: number;
}

interface WeeklyIncrementalRoas {
  date: string;
  RevenueFromMediaDrivenBrandEquity: number;
  Spend: number;
  IncrementalRoas: number;
}

export const IncrementalRoas: React.FC<IncrementalRoasProps> = ({
  weeklyIncrementalRoas,
  incrementalRoasTakeaways,
}) => {
  const excelDownloadWeeklyAdAwarenessAreaChart = useCallback(() => {
    exportToExcel(weeklyIncrementalRoas, "weekly_ad_awareness_area_chart");
  }, [weeklyIncrementalRoas]);

  const pngDownloadWeeklyAdAwarenessAreaGraph = useCallback(async () => {
    await downloadPNG(".leftLeft .chartContainer .contents", "weekly_ad_awareness_area_graph");
  }, []);

  return (
    <WidgetContainer
      collapsible
      header="What is our incremental ROAS from media-driven brand equity?"
      subHeader="Brand media investments' contribution to the bottom line."
    >
      <div className="left">
        <div className="leftLeft">
          {!R.isEmpty(weeklyIncrementalRoas) && (
            <ChartContainer
              enableHoverDesign
              rightActions={
                <div className="incrementalRoasRightActions">
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[
                      excelDownloadWeeklyAdAwarenessAreaChart,
                      pngDownloadWeeklyAdAwarenessAreaGraph,
                    ]}
                  />
                </div>
              }
              title={
                <>
                  <div>
                    Weekly Incremental ROAS
                    <span style={{ color: "#1F003F", fontWeight: 500, fontSize: "1rem" }}>
                      {" (Revenue From Media-Driven Brand Equity / Spend)"}
                    </span>
                  </div>
                </>
              }
              titleAfterDashText={`${Dfns.format(
                "M/dd/yy",
                new Date(weeklyIncrementalRoas[0].date)
              )} – ${Dfns.format(
                "M/dd/yy",
                new Date(weeklyIncrementalRoas[weeklyIncrementalRoas.length - 1].date)
              )}`}
              beforeTooltipText="Incremental ROAS"
              rightSideText="Revenue"
            >
              <ComposedChart
                data={weeklyIncrementalRoas}
                xAxisDataKey="date"
                xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}`))}
                dateGrouping="Week"
                areaYAxisTickFormatter={value => `${currFormat(value, 0)}`}
                yAxisWidth={150}
                colorOverrides={{
                  "Revenue From Media-Driven Brand Equity": "#6B2DEF",
                  IncrementalRoas: "#79DFF5",
                }}
                areas={[
                  {
                    name: "Revenue From Media-Driven Brand Equity",
                    dataKey: "RevenueFromMediaDrivenBrandEquity",
                  },
                  {
                    name: "Incremental ROAS",
                    dataKey: "IncrementalRoas",
                    line: true,
                  },
                ]}
                tooltipFormatter={val => {
                  if (!val) {
                    return val;
                  }
                  return `${currFormat(val, 0)}`;
                }}
                reverseToolTipItems={true}
                reverseLegend={true}
                areaYAxisOrientation="right"
              ></ComposedChart>
            </ChartContainer>
          )}
        </div>
        <div className="leftRight">
          <ChartContainer title="Takeaways" titleAfterDashText="TTM" enableHoverDesign>
            <div className="parentIncrementalTakeaways">
              <div className="topIncremental">
                <div className="topTextBox">
                  <div></div>
                  <div className="topText">Spend </div>
                  <div className="topText">
                    {`$${(incrementalRoasTakeaways.PaidMedia / 1000000).toFixed(0)}M`}
                  </div>
                </div>
                <div className="iconHolder">
                  <MdArrowForward className="icon"></MdArrowForward>
                </div>
                <div className="topTextBox">
                  <div></div>
                  <div className="topText">Brand</div>
                  <div className="topText">Equity</div>
                </div>
                <div className="iconHolder">
                  <MdArrowForward className="icon"></MdArrowForward>
                </div>
                <div className="topTextBox">
                  <div>Incremental</div>
                  <div className="topText">Revenue</div>
                  <div className="topText">{`~$${(
                    incrementalRoasTakeaways.IncrementalRevenue / 1000000
                  ).toFixed(0)}M`}</div>
                </div>
              </div>
              <div className="middleIncremental">
                <div className="middleText">{`Incremental ROAS = ${incrementalRoasTakeaways.IncrementalRoas.toFixed(
                  2
                )}`}</div>
              </div>
              <div className="bottomIncremental">
                <div className="bottomText">
                  {`We estimate paid media-driven brand equity led to an incremental ROAS of ${incrementalRoasTakeaways.IncrementalRoas.toFixed(
                    2
                  )} over
                  the trailing twelve months.`}
                </div>
              </div>
            </div>
          </ChartContainer>
        </div>
      </div>
    </WidgetContainer>
  );
};

export default IncrementalRoas;

import React, { useContext } from "react";
import { FormContext, metaAdSetFormDefault } from "../MetaBuyingContext";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MetaBuyingNamePreview } from "./MetaBuyingNamePreview";
import { MetaBuyingOptionsNoteCard } from "./MetaBuyingOptionsNoteCard";
import { MetaBuyingFooterControls } from "./MetaBuyingFooterControls";
import { RouteComponentProps } from "@reach/router";
import * as R from "ramda";

const BUDGET_TYPES = [
  {
    label: "Ad Set Daily Budget",
    value: "AD_SET_DAILY_BUDGET",
  },
  {
    label: "Campaign Lifetime Budget",
    value: "CAMPAIGN_LIFETIME_BUDGET",
  },
];

const BID_STRATEGIES = [
  {
    label: "Lowest Cost w/out Cap",
    value: "LOWEST_COST_WITHOUT_CAP",
  },
  {
    label: "Lowest Cost w/ Bid Cap",
    value: "LOWEST_COST_WITH_BID_CAP",
  },
  {
    label: "Cost Cap",
    value: "COST_CAP",
  },
  {
    label: "Lowest Cost w/ Min. ROAS",
    value: "LOWEST_COST_WITH_MIN_ROAS",
  },
];

const ATTRIBUTION_WINDOW_OPTIONS = [
  {
    label: "0 Days",
    value: 0,
  },
  {
    label: "1 Day",
    value: 1,
  },
  {
    label: "7 Days",
    value: 7,
  },
];

// for testing only
const DROPDOWN_TEST_PLACEHOLDER = [
  {
    label: "Option A",
    value: "test_a",
  },
  {
    label: "Option B",
    value: "test_b",
  },
  {
    label: "Option C",
    value: "test_c",
  },
];

const OPTIONS_NOTE_CARD_HEADER = "Ad Set Options to Populate in Ads Manager";
const OPTIONS_NOTE_CARD_TEXT =
  "If you need to adjust these, you’ll need to build your campaign here and adjust it in meta once you’ve published as paused in Bliss Point.";
const OPTIONS_NOTE_CARD_OPTIONS = [
  "Catalog Ads",
  "Additional Text Options",
  "Promo Codes",
  "Advantage+ Creative",
  "Instant Experiences",
  "Facebook Events",
];

interface MetaBuyingAdSetProps {
  namingConvention: string[];
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
}

export const MetaBuyingAdSet = ({
  path,
  namingConvention,
  tabIndex,
  setTabIndex,
  showReviewModal,
}: MetaBuyingAdSetProps & RouteComponentProps): JSX.Element => {
  const { metaAdSetForm, setMetaAdSetForm } = useContext(FormContext);

  const updateFormField = (key: string, value: string | number) =>
    setMetaAdSetForm((current: any): typeof metaAdSetFormDefault => {
      return { ...current, [key]: value };
    });

  return (
    <div className="metaBuyingCreate">
      <div className="metaBuyingContainer">
        <div className="metaBuyingCard">
          <div className="metaBuyingHeader">
            <h2>Ad&nbsp;Set</h2>
            <div className="metaBuyingNameWrapper">
              Name:
              <MetaBuyingNamePreview data={metaAdSetForm} namingConvention={namingConvention} />
            </div>
          </div>

          <div className="metaBuyingFormBody">
            <div className="metaBuyingFormSection">
              <h3>Ad Set Name Construction</h3>
              <Form.Group>
                <Form.Label>Audience Type</Form.Label>
                <Form.Control
                  value={metaAdSetForm.audience_type}
                  onChange={e => updateFormField("audience_type", e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Audience</Form.Label>
                <Form.Control
                  value={metaAdSetForm.audience}
                  onChange={e => updateFormField("audience", e.target.value)}
                />
              </Form.Group>
              <h3>Ad Set Options</h3>
              <Form.Group>
                <Form.Label>Campaign</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.campaign}
                  onSelect={value => updateFormField("campaign", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Pixel</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.pixel}
                  onSelect={value => updateFormField("pixel", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Conversion Event</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.conversion_event}
                  onSelect={value => updateFormField("conversion_event", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Optimization Goal</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.optimization_goal}
                  onSelect={value => updateFormField("optimization_goal", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Bid Strategy</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.bid_strategy || "Select"}
                  onSelect={value => updateFormField("bid_strategy", value || "")}
                >
                  {BID_STRATEGIES.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Bid Amount</Form.Label>
                <Form.Control
                  value={metaAdSetForm.bid_amount}
                  onChange={e => updateFormField("bid_amount", e.target.value)}
                />
              </Form.Group>
              <h3>Ad Set Targeting</h3>
              <Form.Group>
                <Form.Label>Custom Audiences</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.custom_audiences}
                  onSelect={value => updateFormField("custom_audiences", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Geo</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.geo}
                  onSelect={value => updateFormField("geo", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Likes & Interests</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.interests}
                  onSelect={value => updateFormField("interests", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.gender}
                  onSelect={value => updateFormField("gender", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Placements</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.placements}
                  onSelect={value => updateFormField("placements", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Age</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.custom_audiences}
                  onSelect={value => updateFormField("age", value || 18)}
                >
                  {R.range(18, 65).map(age => (
                    <Dropdown.Item key={age} eventKey={age.toString()}>
                      {age}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <h3>Ad Set Budget</h3>
              <Form.Group>
                <Form.Label>Budget Type</Form.Label>
                {BUDGET_TYPES.map(({ label, value }) => (
                  <Form.Check
                    type="radio"
                    label={label}
                    checked={metaAdSetForm.budget_type === value}
                    id={value}
                    onChange={() => updateFormField("budget_type", value)}
                  />
                ))}
              </Form.Group>
              <div className="metaBuyingFormSubsection">
                <Form.Group>
                  <Form.Label>Budget</Form.Label>
                  <Form.Control
                    value={metaAdSetForm.budget}
                    onChange={e => updateFormField("budget", e.target.value)}
                  />
                </Form.Group>
              </div>
              <h3>Ad Set Attribution Specs</h3>
              <Form.Group>
                <Form.Label>Click Through Attribution Window</Form.Label>
                <DropdownButton
                  title={metaAdSetForm.attribution_window || "Select"}
                  onSelect={value => updateFormField("attribution_window", value || "")}
                >
                  {ATTRIBUTION_WINDOW_OPTIONS.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value.toString()}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
            </div>
            <MetaBuyingFooterControls
              tabName={"Ad Set"}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              showReviewModal={showReviewModal}
            />
          </div>
        </div>

        <MetaBuyingOptionsNoteCard
          header={OPTIONS_NOTE_CARD_HEADER}
          text={OPTIONS_NOTE_CARD_TEXT}
          options={OPTIONS_NOTE_CARD_OPTIONS}
        />
      </div>
    </div>
  );
};

export default MetaBuyingAdSet;

import "./NewToBrand.scss";
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { useQuery } from "@apollo/client";
import { useCompanyInfo } from "../../../redux/company";
import { BPMTable, FullPageSpinner, Page } from "../../../Components";
import { amcClient } from "../../Cache";
import {
  formatDate,
  MonthPicker,
  startDateRange,
} from "../../../Components/MonthPicker/MonthPicker";
import { GET_NTB_REPORT } from "../../Queries";
import { ntbTableHeaders } from "./NewToBrandConfig";
import { GET_MAX_NTB, maxNtbRate, maxNtbRepeatRate } from "../../ReactiveVariables";

import DataCardRow from "./DataCardRow";
import LineChart from "../../AMCLineChart";
import BarChart from "../../AMCStackedBarChart";
import { RouteComponentProps } from "@reach/router";
import { AMC_TEST_INSTANCE_ID } from "../../Insights";

/*eslint-disable no-empty-pattern*/
const NewToBrand = ({}: RouteComponentProps): JSX.Element => {
  let { cid, amc_instance_id: amcInstanceId } = useCompanyInfo();
  if (cid === "tinuititest") {
    amcInstanceId = AMC_TEST_INSTANCE_ID;
  }
  // Previous month reports not prepared until the 15th of current month
  const monthsBack = new Date().getDate() < 15 ? 2 : 1;
  const [startDateSelection, updateStartDateSelection] = useState(startDateRange(monthsBack));
  const [endDateSelection, updateEndDateSelection] = useState(startDateRange(monthsBack));
  const [dateOptions, updateDateOptions] = useState([{ value: "" }]);
  const [dateRange, updateDateRange] = useState({
    endDate: formatDate(endDateSelection, "end"),
    startDate: formatDate(startDateSelection, "start"),
  });

  useQuery(GET_MAX_NTB, { client: amcClient });

  let { data } = useQuery(GET_NTB_REPORT, {
    client: amcClient,
    variables: {
      amcInstanceId,
      dateRange,
    },
  });

  const tableData = data?.amc.newToBrandReport.behaviorByGroup;

  useEffect(() => {
    const filterData = (data, dataType) => {
      return data.amc.newToBrandReport.behaviorByGroup.map(row => row[dataType]);
    };
    if (data) {
      const ntbRate = filterData(data, "ntbRate");
      const ntbRepeatRate = filterData(data, "ntbRepeatRate");

      maxNtbRate(Math.max(...ntbRate));
      maxNtbRepeatRate(Math.max(...ntbRepeatRate));
    }
  }, [data]);

  const costCardData = data?.amc?.newToBrandReport.overview.cost;
  const revenueCardData = data?.amc?.newToBrandReport.overview.revenue;

  useEffect(() => {
    if (data) {
      const months = data?.amc.newToBrandReport.availableFilters.months;
      const options = months?.map(m => {
        return { value: m };
      });

      updateDateOptions(options);
    }
  }, [data]);

  // To display graph in % we have to clone data and add percent attr for each data point displayed.
  const purchasesGraphData = R.clone(data?.amc.newToBrandReport.ntbVsRepeatBehavior.purchases);
  purchasesGraphData?.forEach((d, index) => {
    const total = d.ntbCustomers + d.repeatCustomers;
    const ntbPercent = (d.ntbCustomers / total) * 100;
    const repeatPercent = (d.repeatCustomers / total) * 100;
    purchasesGraphData[index].ntbCustomersPercent = ntbPercent;
    purchasesGraphData[index].repeatCustomersPercent = repeatPercent;
  });

  const customersGraphData = R.clone(data?.amc.newToBrandReport.ntbVsRepeatBehavior.customers);
  customersGraphData?.forEach((d, index) => {
    const total = d.ntbCustomers + d.ntbRepeatCustomers;
    const ntbPercent = (d.ntbCustomers / total) * 100;
    const repeatPercent = (d.ntbRepeatCustomers / total) * 100;
    customersGraphData[index].ntbCustomersPercent = ntbPercent;
    customersGraphData[index].ntbRepeatCustomersPercent = repeatPercent;
  });

  return (
    <Page minHeight="600px" pageType="AMC New To Brand" title="AMC New To Brand">
      {!data && <FullPageSpinner />}
      <div className="ntb-page">
        <div className="ntb-page-header">
          <div className="back-arrow"></div>
          <MonthPicker
            dateOptions={dateOptions}
            endDateSelection={endDateSelection}
            startDateSelection={startDateSelection}
            updateDateRange={updateDateRange}
            updateEndDateSelection={updateEndDateSelection}
            updateStartDateSelection={updateStartDateSelection}
          />
        </div>
        <div className="row-header">
          <div className="row-header-text">NTB Cost</div>
          <div className="row-header-text">NTB Revenue</div>
        </div>
        <DataCardRow costCardData={costCardData} revenueCardData={revenueCardData} />

        <div className="ntb-row">
          <div className="basic-card">
            <div className="graph-wrapper">
              <LineChart
                data={data?.amc.newToBrandReport.overviewGraph}
                dataKey="ntbCpa"
                lineColor="#63da66"
                tooltipKey="NTB CPA"
              />
            </div>
          </div>
          <div className="basic-card">
            <div className="graph-wrapper">
              <LineChart
                data={data?.amc.newToBrandReport.overviewGraph}
                dataKey="ntbRoas"
                lineColor="#FF6C95"
                tooltipKey="NTB ROAS"
              />
            </div>
          </div>
        </div>

        <div className="row-header">NTB vs Repeat Customer Behavior</div>
        <div className="ntb-row">
          <div className="basic-card bar-chart">
            <BarChart
              data={purchasesGraphData}
              dataKeys={["ntbCustomers", "repeatCustomers"]}
              percentKeys={["ntbCustomersPercent", "repeatCustomersPercent"]}
              fill={{ ntbCustomers: "#6070FF", repeatCustomers: "#01b6cb" }}
              dataLabel={{
                ntbCustomersPercent: "NTB Customers",
                repeatCustomersPercent: "Repeat Customers",
              }}
            />
          </div>
          <div className="basic-card bar-chart">
            <BarChart
              data={customersGraphData}
              dataKeys={["ntbCustomers", "ntbRepeatCustomers"]}
              percentKeys={["ntbCustomersPercent", "ntbRepeatCustomersPercent"]}
              fill={{ ntbCustomers: "#ffa800", ntbRepeatCustomers: "#f66e03" }}
              dataLabel={{
                ntbCustomersPercent: "NTB Customers",
                ntbRepeatCustomersPercent: "NTB Repeat Customers",
              }}
            />
          </div>
        </div>
        <div className="row-header">NTB Behavior by Group</div>
        <div className="ntb-row">
          <div className="basic-card">
            <div className="table-wrapper">
              <BPMTable
                data={tableData}
                filterBar={false}
                headers={ntbTableHeaders}
                noRowsRenderer={() => <div className="noFailures">No data to show</div>}
                pagination={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default NewToBrand;

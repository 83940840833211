import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MdSave } from "react-icons/md";
import { Response, ClientInfo } from "@blisspointmedia/bpm-types/dist/Onboarding";
import useLocation from "../../utils/hooks/useLocation";
import { useSetError } from "../../redux/modals";
import * as UserRedux from "../../redux/user";
import WidgetContainer from "../../Components/WidgetContainer";
import { Button, ButtonType } from "../../Components";
import { PLATFORM_OPTIONS, PlatformOptions } from "./PlatformChecklistOptions";
import OnboardingFreeForm from "../OnboardingFreeForm";
import { saveClientInfo, getCompletedPlatformPercentage } from "../OnboardingUtils";
import { indexOf } from "ramda";

interface PlatformChecklistProps {
  clientInfo: ClientInfo;
  onPercentageChange: (percentage: number) => void;
  onClientInfoChange: (clientInfo: ClientInfo) => void;
}

const PlatformChecklist: React.FC<PlatformChecklistProps> = ({
  clientInfo,
  onClientInfoChange,
  onPercentageChange,
}) => {
  const { company } = useLocation();
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);
  const [tables, setTables] = useState<PlatformOptions | null>(null);
  const setError = useSetError();
  const user = useSelector(UserRedux.fullNameSelector);

  useEffect(() => {
    if (tables === null) {
      const newTables: PlatformOptions = {};
      Object.keys(PLATFORM_OPTIONS).forEach(key => {
        const values = PLATFORM_OPTIONS[key];
        const responses = allResponses.responses.filter(response =>
          response.questionNumber.startsWith(values.simpleId)
        );
        if (responses.length === 0) {
          newTables[key] = {
            ...values,
          };
          return;
        }
        const table = responses.map(response => {
          return {
            id: (indexOf(response, responses) + 1).toString(),
            questionNumber: response.questionNumber,
            title: response.additionalInfo || "",
          };
        });
        newTables[key] = {
          ...values,
          table: {
            columns: values.table.columns,
            rows: table,
          },
        };
      });
      setTables(newTables);
    }
  }, [allResponses.responses, tables]);

  useEffect(() => {
    const totalPercentage = getCompletedPlatformPercentage(
      allResponses.responses,
      PLATFORM_OPTIONS
    );
    onPercentageChange(totalPercentage);
  }, [allResponses.responses, onPercentageChange]);

  const handlePlatformChecklistChange = (updatedResponses: Response[]) => {
    const updatedClientInfo = {
      ...allResponses,
      company,
      responses: updatedResponses,
    };
    setAllResponses(updatedClientInfo);
    onClientInfoChange(updatedClientInfo);
  };

  const platformForm = (key: string, option) => {
    return (
      <div>
        <OnboardingFreeForm
          questions={option.table}
          responses={allResponses.responses}
          sectionKey={key}
          sectionId={option.simpleId}
          renderAddButton
          onDataChange={handlePlatformChecklistChange}
        />
      </div>
    );
  };

  const handleSave = async () => {
    try {
      await saveClientInfo({
        company: allResponses.company,
        responses: allResponses.responses,
        user,
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>
        This is the platform / source checklist so we know which platforms and sources to populate
        later on in these pages.
      </h2>
      {tables &&
        Object.keys(PLATFORM_OPTIONS).map(key => {
          return (
            <WidgetContainer
              key={key}
              backgroundColor="white"
              header={PLATFORM_OPTIONS[key].title}
              design="secondary"
              collapsible
              startOpen={false}
              rightActions={
                <Button type={ButtonType.FILLED} icon={<MdSave />} onClick={handleSave} />
              }
            >
              {platformForm(key, tables[key])}
            </WidgetContainer>
          );
        })}
    </div>
  );
};

export default PlatformChecklist;

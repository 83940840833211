import { makeReducer } from "./utils";
import { StreamingV2LambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useSetError } from "./modals";

const ROOT = "networks";

const SET_NETWORK_MAP = `${ROOT}/SET_NETWORK_MAP`;
const SET_V1_NETWORK_MAP = `${ROOT}/SET_V1_NETWORK_MAP`;
const SET_DERIVED_NETWORK_MAP = `${ROOT}/SET_DERIVED_NETWORK_MAP`;

interface Network {
  shortCode: string;
  networkGroup: string;
  name: string;
  color: string;
  pdfText: string;
  defaultIOPdfText: string;
  requiresDescriptions: boolean;
  parent?: string;
  buyable: boolean;
  children?: string[];
  platforms: string[];
}
type NetworkMap = Record<string, Network>;
interface V1Network {
  cpm: string;
  name: string;
  networkGroup: string;
  shortCode: string;
  color: string;
  networkGroupColor: string;
  platforms: string[];
  hidden: boolean;
  specialType: string;
  durationTags: boolean;
  dsp: string | null;
}
type V1NetworkMap = Record<string, V1Network>;

export interface DerivedNetwork {
  network: string;
  description: string;
  platform: string;
  company: string;
  networkGroup: string;
  dsp?: string;
}

export type DerivedNetworkMap = Record<string, DerivedNetwork | undefined>;

interface NetworkReduxState {
  networkMap?: NetworkMap;
  v1NetworkMap?: V1NetworkMap;
  derivedNetworkMap?: DerivedNetworkMap;
}
interface NetworkReduxRootState {
  [ROOT]: NetworkReduxState;
}

export const networkMapSelector = (state: NetworkReduxRootState): NetworkMap | undefined =>
  state[ROOT].networkMap;
export const v1NetworkMapSelector = (state: NetworkReduxRootState): V1NetworkMap | undefined =>
  state[ROOT].v1NetworkMap;

export const derivedNetworkMapSelector = (
  state: NetworkReduxRootState
): DerivedNetworkMap | undefined => state[ROOT].derivedNetworkMap;

export const useNetworkMap = (): NetworkMap | undefined => {
  const dispatch = useDispatch();
  const setError = useSetError();
  const networkMap = useSelector(networkMapSelector);
  useEffect(() => {
    if (!networkMap) {
      let bail = false;
      (async () => {
        try {
          let res = await StreamingV2LambdaFetch("/networks");
          let networks = await awaitJSON(res);
          if (bail) {
            return;
          }
          dispatch({
            type: SET_NETWORK_MAP,
            networkMap: networks,
          });
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Couldn't fetch network map. Error: ${reportError.message}`,
            reportError,
          });
        }
      })();
      return () => {
        bail = true;
      };
    }
  }, [networkMap, dispatch, setError]);
  return networkMap;
};

export const useDerivedNetworkMap = (company: string): DerivedNetworkMap | undefined => {
  const dispatch = useDispatch();
  const setError = useSetError();
  const derivedNetworkMap = useSelector(derivedNetworkMapSelector);
  useEffect(() => {
    if (!derivedNetworkMap) {
      let bail = false;
      (async () => {
        try {
          const params = company !== "tinuititest" ? { company } : {};
          let res = await StreamingV2LambdaFetch("/derived", { params });
          let derivedNetworks = await awaitJSON<Record<string, DerivedNetwork>>(res);
          if (bail) {
            return;
          }
          dispatch({
            type: SET_DERIVED_NETWORK_MAP,
            derivedNetworkMap: derivedNetworks,
          });
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Couldn't fetch derived network map. Error: ${reportError.message}`,
            reportError,
          });
        }
      })();
      return () => {
        bail = true;
      };
    }
  }, [derivedNetworkMap, dispatch, setError, company]);
  return derivedNetworkMap;
};

const setNetworkMap = (
  state: NetworkReduxState,
  { networkMap }: { networkMap: NetworkMap }
): NetworkReduxState => ({
  ...state,
  networkMap,
});

const setV1NetworkMap = (
  state: NetworkReduxState,
  { networkMap }: { networkMap: V1NetworkMap }
): NetworkReduxState => ({
  ...state,
  v1NetworkMap: networkMap,
});

const setDerivedNetworkMap = (
  state: NetworkReduxState,
  { derivedNetworkMap }: { derivedNetworkMap: DerivedNetworkMap }
): NetworkReduxState => ({
  ...state,
  derivedNetworkMap,
});

export default makeReducer<NetworkReduxState>(
  {},
  {
    [SET_NETWORK_MAP]: setNetworkMap,
    [SET_V1_NETWORK_MAP]: setV1NetworkMap,
    [SET_DERIVED_NETWORK_MAP]: setDerivedNetworkMap,
  }
);

import * as R from "ramda";

import { download } from "../utils/download-utils";

import { reduxStore } from "../redux";
import * as UserRedux from "../redux/user";

import { getAccessToken } from "./auth";
import { artificialWait } from "./async";

const { REACT_APP_LAMBDA_HOST, REACT_APP_DOCKER_LAMBDA_HOST } = process.env;

const S3_POLLING_PERIOD = 1000;
const S3_POLLING_TIMEOUT = 5 * 60;

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
export class FetchError extends Error {
  constructor(message: string, public status = 500) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = "FetchError";
  }
}

export const getCleanEmail = (email: string): string | null => {
  // remove all whitespace
  email = email.replace(/\s/g, "");
  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  let match = email.match(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  );
  return match && match[0];
};

export const awaitText = (response: Response): Promise<string> => response.text();

export const awaitJSON = async <T = any>(response: Response): Promise<T> => {
  let text = await response.text();
  try {
    let json = JSON.parse(text);
    return json;
  } catch (e) {
    let msg = `Failed to parse response as JSON. Parsed as text:\n${text}`;
    console.error(msg);
    throw new Error(msg);
  }
};

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};

// TODO: We should figure out a way to pass along the status code. If the error is a 409 or something, for instance, I
// might want to do something special.
export const awaitStatus = async (response: Response): Promise<boolean> => {
  // If there is no status, that means the fetch was canceled, and we shouldn't do anything
  if (!response.status) {
    return true;
  } else if (response.status >= 200 && response.status < 300) {
    // 300s might be needed as well (304 not modified)
    return true;
  } else if (`${response.status}` === "404") {
    throw new FetchError("404", response.status);
  } else if (`${response.status}` === "503") {
    throw new FetchError("Service Unavailable", response.status);
  } else {
    try {
      let res = await response.json();
      let msg = R.prop("message", res);
      if (msg) {
        throw new Error(msg);
      }
      throw new Error();
    } catch (e) {
      console.error(e);
      throw new FetchError(getErrorMessage(e), response.status);
    }
  }
};

type ValidParam = string | number | boolean;

interface LambdaFetchOptions<T = Record<string, ValidParam | ValidParam[]>> {
  method: "GET" | "get" | "POST" | "post" | "PUT" | "put" | "DELETE" | "delete" | "PATCH" | "patch";
  authenticate?: boolean;
  retries?: number;
  headers: Record<string, any>;
  params?: T;
  body?: T;
  mode?: "cors" | "no-cors" | "same-origin";
}

const DEFAULT_RETRIES = 2;

export const BpmLambdaFetch = async <T>(
  service: string,
  useDockerLambdas = false,
  path = "/",
  passedOptions: Partial<LambdaFetchOptions<T>> = {}
): Promise<Response> => {
  let options = R.mergeDeepRight<LambdaFetchOptions<T>, Partial<LambdaFetchOptions<T>>>(
    {
      method: "GET",
      authenticate: true,
      retries: DEFAULT_RETRIES,
      headers: {},
    },
    passedOptions
  ) as LambdaFetchOptions;

  let headers = {
    ...(R.prop("headers", options) || {}),
  };

  if (options.authenticate) {
    try {
      let accessToken = await getAccessToken();
      headers.Authorization = `bearer ${accessToken}`;
    } catch (e) {
      console.log("You've been logged out error", e);
      throw new Error("You've been logged out. Please sign out and log back in.");
    }
  }
  options.headers = headers;
  delete options.authenticate;

  let paramStr = "";

  if (options.params) {
    paramStr = "?";
    let keys = R.keys(options.params);
    for (let key of keys) {
      // If we pass anything - false, null, 0 - APIGateway / Lambda will always interpret it as truthy smh. Only do nils
      // or false, though, since an empty string or a 0 might actually be valid.
      if (R.isNil(options.params[key]) || options.params[key] === false) {
        continue;
      }
      if (paramStr !== "?") {
        paramStr += "&";
      }

      // if we are sending multiple QS-params of the same name, it's passed in as an array
      if (Array.isArray(options.params[key])) {
        for (let val of options.params[key] as ValidParam[]) {
          if (paramStr !== "?") {
            paramStr += "&";
          }

          paramStr = `${paramStr}${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
        }
      } else {
        paramStr = `${paramStr}${encodeURIComponent(key)}=${encodeURIComponent(
          options.params[key] as ValidParam
        )}`;
      }
    }
  }
  let body: string | undefined;
  if (options.body) {
    if (typeof options.body == "object") {
      body = JSON.stringify(options.body);
    } else {
      ({ body } = options);
    }
  }

  let initTries = options.retries || DEFAULT_RETRIES;
  let tries = initTries + 1;
  delete options.retries;
  let res: Response | undefined;

  if (window.location.hostname === "localhost") {
    useDockerLambdas = false;
  }
  let url = `${
    useDockerLambdas ? REACT_APP_DOCKER_LAMBDA_HOST : REACT_APP_LAMBDA_HOST
  }/${service}${path}${paramStr}`;

  do {
    try {
      res = await fetch(url, {
        ...options,
        body,
      });
      break;
    } catch (e) {
      console.error(`Failed to fetch. Error: ${getErrorMessage(e)}`);
      console.error("Tries: ", --tries);
      await artificialWait(5000);
    }
  } while (tries > 0);
  if (tries && res) {
    await awaitStatus(res);
    return res;
  }
  let millisAlive = Date.now() - (UserRedux.startTimeSelector(reduxStore.getState()) as number);
  let hoursAlive = millisAlive / 3600000;
  console.error(
    `Could not complete request after ${initTries} retries. Request: ${options.method} ${url}. Hours Alive: ${hoursAlive}`
  );
  throw new Error("Could not complete request.");
};

export const GetS3SignedUrl = async (path: string): Promise<string> => {
  let res = await AdminLambdaFetch(`/signedUrl?path=${path}`);
  res = await awaitJSON(res);
  return R.prop("url", res);
};

// Follows url redirect through JSON payload `url` property
export const S3SignedUrlFetch = async (path: string, options: object = {}): Promise<any> => {
  const signedURL = await GetS3SignedUrl(path);
  let res = await fetch(signedURL, options);
  return res;
};

export const GetS3SignedUrl2 = async (
  bucket: string,
  key: string,
  contentType?: string
): Promise<string> => {
  let params: Record<string, string> = {
    bucket,
    key,
  };

  if (contentType) {
    params.contentType = contentType;
  }
  let res = await ToolsLambdaFetch("/s3_signed_url", {
    params,
  });
  res = await awaitJSON(res);
  return R.prop("url", res);
};

export const S3SignedUrlFetch2 = async (
  bucket: string,
  key: string,
  contentType?: string
): Promise<Response> => {
  const signedURL = await GetS3SignedUrl2(bucket, key, contentType);
  let res = await fetch(signedURL);
  return res;
};

interface PotentialUnfoundCheckMaker<T> {
  (): Promise<T | false>;
}

export const makePotentiallyUnfoundCheck = async (
  fetcher: () => Promise<Response>
): Promise<Response | false> => {
  try {
    let res = await fetcher();
    if (res.status === 200) {
      return res;
    }
    if (res.status !== 404) {
      let errorText: string;
      try {
        errorText = await awaitText(res);
      } catch (e) {
        errorText = res.statusText;
      }
      throw new Error(errorText);
    }
    return false;
  } catch (e) {
    return false;
  }
};

type ErrorCheckMaker = PotentialUnfoundCheckMaker<string>;

export const makePollingErrorChecker = async (
  fetcher: () => Promise<Response>
): Promise<string | false> => {
  let res = await makePotentiallyUnfoundCheck(fetcher);
  if (res !== false) {
    return await res.text();
  }
  return false;
};

interface PollOptions<T> {
  fetchMaker: PotentialUnfoundCheckMaker<T>;
  errorCheckMaker?: ErrorCheckMaker;
  period?: number; // Milliseconds
  timeout?: number; // Seconds. If -1, then no timeout
  maxAttempts?: number; // Maximum tries to fetch the resource
}

export const poll = async <T>({
  fetchMaker,
  errorCheckMaker,
  period = S3_POLLING_PERIOD,
  timeout = S3_POLLING_TIMEOUT,
  maxAttempts,
}: PollOptions<T>): Promise<T> => {
  let start = Date.now();
  let latest = Date.now();
  let attemptNumber = 0;
  while (timeout < 0 || (latest - start) / 1000 < timeout) {
    if (attemptNumber === maxAttempts) {
      throw new Error(`Attempted to fetch the maximum number of times: ${maxAttempts}`);
    }
    await artificialWait(period);
    try {
      let response = await fetchMaker();
      if (response !== false) {
        return response;
      }

      if (errorCheckMaker) {
        let errorMessage = await errorCheckMaker();
        if (errorMessage !== false) {
          throw Error(errorMessage);
        }
      }
    } catch (e) {
      throw new Error(getErrorMessage(e));
    }
    latest = Date.now();
    attemptNumber++;
  }
  throw new Error("Timeout");
};

interface PollS3Options {
  bucket: string;
  filename: string;
  mimeType: string;
  ext?: string;
  autoDownload?: boolean;
  overloadFilename?: string;
  noTimeout?: boolean;
  withErrors?: boolean;
  timeout?: number;
  maxAttempts?: number;
  period?: number;
}

export const pollS3 = async ({
  bucket,
  filename,
  mimeType,
  ext,
  autoDownload = true,
  maxAttempts = -1, // Default no maximum
  overloadFilename,
  noTimeout = false,
  withErrors = false,
  timeout = S3_POLLING_TIMEOUT,
  period = S3_POLLING_PERIOD,
}: PollS3Options): Promise<Blob> => {
  let errorCheckMaker: undefined | (() => Promise<string | false>);
  if (withErrors) {
    errorCheckMaker = () =>
      makePollingErrorChecker(() =>
        S3SignedUrlFetch2(bucket, `${filename}.error.txt`, "text/plain")
      );
  }
  let response = await poll({
    fetchMaker: () =>
      makePotentiallyUnfoundCheck(() =>
        S3SignedUrlFetch2(bucket, ext ? `${filename}.${ext}` : filename, mimeType)
      ),
    errorCheckMaker,
    period,
    timeout: noTimeout ? -1 : timeout,
    maxAttempts,
  });
  if (!response) {
    throw new Error(`Unable to download s3://${bucket}/${filename}${ext ? ext : ""}`);
  }
  let content = await response.blob();
  if (autoDownload) {
    download(content, overloadFilename ? overloadFilename : filename, mimeType);
  }
  return content;
};

interface S3PutOptions {
  bufferEncoding?: string;
  contentEncoding?: string;
  contentType?: string;
}
// TODO: if I wasn't lazy, I'd make a type for what I know this lambda returns
export const S3Put = (
  bucket: string,
  key: string,
  body: string,
  options?: S3PutOptions
): Promise<any> =>
  ToolsLambdaFetch("/s3", {
    method: "post",
    body: {
      body,
      bucket,
      key,
      ...options,
    },
  });

export const S3PutPng = (bucket: string, key: string, body: string): Promise<any> =>
  S3Put(bucket, key, body.replace(/^data:image\/png;base64,/, ""), {
    bufferEncoding: "base64",
    contentType: "image/png",
  });

const makeFetcher = (service: string, useDockerLambdas = false) => <T>(
  ...args: [path: string, fetchOptions?: Partial<LambdaFetchOptions<T>>]
): Promise<Response> => {
  return BpmLambdaFetch(service, useDockerLambdas, ...args);
};

export const AdminLambdaFetch = makeFetcher("admin", true);
export const ClientLambdaFetch = makeFetcher("client");
export const CreativeLambdaFetch = makeFetcher("creative", true);
export const CroOnboardLambdaFetch = makeFetcher("croonboard", true);
export const CrossChannelLambdaFetch = makeFetcher("crosschannel", true);
export const DagLambdaFetch = makeFetcher("dag", true);
export const EbayLambdaFetch = makeFetcher("ebay", true);
export const ExtremeReachLambdaFetch = makeFetcher("extremereach", true);
export const FlashtalkingLambdaFetch = makeFetcher("flashtalking", true);
export const FiltersLambdaFetch = makeFetcher("filters", true);
export const JavaLambdaFetch = makeFetcher("java", true);
export const JobsLambdaFetch = makeFetcher("jobs", true);
export const LinearBuyingLambdaFetch = makeFetcher("linearbuying", true);
export const LinearLambdaFetch = makeFetcher("linear", true);
export const LinearOptimizationsLambdaFetch = makeFetcher("linearoptimizations", true);
export const LinearPerformanceLambdaFetch = makeFetcher("linearperformance", true);
export const MetaLambdaFetch = makeFetcher("meta", true);
export const MetricsLambdaFetch = makeFetcher("metrics", true);
export const MiscLambdaFetch = makeFetcher("misc", true);
export const MobiusSlidesLambdaFetch = makeFetcher("mobiusslides", true);
export const SegmentationMappingLambdaFetch = makeFetcher("segmentationmapping", true);
export const SheetsLambdaFetch = makeFetcher("sheets", true);
export const SingleChannelLambdaFetch = makeFetcher("singlechannel", true);
export const SiteMonitoringLambdaFetch = makeFetcher("sitemonitoring", true);
export const SlidesLambdaFetch = makeFetcher("slides", true);
export const StreamingBuyingLambdaFetch = makeFetcher("streamingbuying");
export const StreamingLambdaFetch = makeFetcher("streaming", true);
export const StreamingOptimizationsLambdaFetch = makeFetcher("streamingoptimizations", true);
export const StreamingPerformanceLambdaFetch = makeFetcher("streamingperformance", true);
export const StreamingUtilsLambdaFetch = makeFetcher("streamingutils", true);
export const StreamingV2LambdaFetch = makeFetcher("streamingv2", true);
export const ToolsLambdaFetch = makeFetcher("tools", true);
export const UsersLambdaFetch = makeFetcher("users", true);
export const WtoLambdaFetch = makeFetcher("wto", true);
export const YoutubePerformanceLambdaFetch = makeFetcher("youtubeperformance", true);

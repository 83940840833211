import mixpanel from "mixpanel-browser";

const ENV_CHECK = process.env.NODE_ENV === "production" && process.env.REACT_APP_MIXPANEL_TOKEN;

if (ENV_CHECK) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}

// this keeps us from accidentally creating similar events
export const enum MxE {
  PAGE_KPI_CHANGE = "page_kpi_change",
  EXCEL_DOWNLOAD = "excel_download",
  PNG_DOWNLOAD = "png_download",
  DATE_GROUPING_CHANGE = "date_grouping_change",
  METRIC_OPTION_CHANGE = "metric_option_change",
  LOG_SCALE_CHANGE = "log_scale_change",
  WIDGET_PRESET_CHANGE = "widget_preset_change",
  GRAPH_OR_TABLE_CHANGE = "graph_or_table_change",
  SOT_VS_PLATFORM_CHANGE = "sot_vs_platform_change",
  PAGE_PRESET_CHANGE = "page_preset_chage",
  DATE_CHANGE = "date_change",
  CSV_DOWNLOAD = "csv_download",
  REFRESH_CC_VIEWS = "refresh_cross_channel_views",
  // add new events types here
}

interface MixpanelEventProps {
  widget?: string;
  option_selected?: string | number | boolean;
  primary_or_comparison?: "primary" | "comparison" | "both";
  fileName?: string;
  label?: string;
  // add new event property types here
}

interface MixpanelRegisterProps {
  company?: any | null;
  active_page?: string | null;
  page_group?: string | null;
  // add new register properties types here
}

export const Mixpanel = {
  identify: (id: string): void => {
    if (ENV_CHECK) {
      mixpanel.identify(id);
    }
  },
  track: (name: MxE, props?: MixpanelEventProps): void => {
    if (ENV_CHECK) {
      mixpanel.track(name, props);
    }
  },
  track_pageview: (props: Record<string, any>): void => {
    if (ENV_CHECK) {
      mixpanel.track_pageview(props);
    }
  },
  people: {
    set: (props: Record<string, any>): void => {
      if (ENV_CHECK) {
        mixpanel.people.set(props);
      }
    },
    set_once: (props: Record<string, any>): void => {
      if (ENV_CHECK) {
        mixpanel.people.set_once(props);
      }
    },
  },
  register: (props: MixpanelRegisterProps): void => {
    if (ENV_CHECK) {
      mixpanel.register(props);
    }
  },
  unregister: (prop: string): void => {
    if (ENV_CHECK) {
      mixpanel.unregister(prop);
    }
  },
  register_once: (props: Record<string, any>): void => {
    if (ENV_CHECK) {
      mixpanel.register_once(props);
    }
  },
};

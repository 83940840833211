import React from "react";
import { StateSetter } from "../../utils/types";
import "./ToggleSwitch.scss";
import { Button, ButtonType } from "../Button";
import { ButtonFrameworkVariant } from "../ButtonFramework";

interface ToggleSwitchProps {
  label: string;
  checked: boolean;
  onChange: StateSetter<boolean>;
  [passthroughProp: string]: any;
  labelPosition?: "leading" | "trailing" | "none";
  designType?: ToggleSwitchType;
  design?: "primary" | "secondary";
  background?: "light" | "dark";
  size?: "sm" | "lg";
  id?: string;
}

export enum ToggleSwitchType {
  FILLED = "filled",
  OUTLINED = "outlined",
  EMPTY = "empty",
}

export const toggleToButtonTypeMap: { [key in ToggleSwitchType]: ButtonType } = {
  [ToggleSwitchType.FILLED]: ButtonType.FILLED,
  [ToggleSwitchType.OUTLINED]: ButtonType.OUTLINED,
  [ToggleSwitchType.EMPTY]: ButtonType.EMPTY,
};

export const ToggleSwitch: React.FC<ToggleSwitchProps> = React.memo(
  ({
    label,
    checked,
    onChange,
    labelPosition = "leading",
    // TODO enable of design styles
    designType = ToggleSwitchType.FILLED,
    size = "sm",
    design = "secondary",
    background = "light",
  }) => {
    return (
      <Button
        className={`BPMToggleSwitch ${checked ? "checked" : ""}`}
        size={size}
        design={design}
        onClick={() => onChange(current => !current)}
        value={label}
        type={toggleToButtonTypeMap[ToggleSwitchType.FILLED]}
        background={background}
        variant={
          labelPosition === "leading"
            ? ButtonFrameworkVariant.TRAILING_ICON
            : labelPosition === "trailing"
            ? ButtonFrameworkVariant.LEADING_ICON
            : ButtonFrameworkVariant.NO_ICON
        }
        icon={
          <div className={`toggleRedesignWrapper ${checked ? "on" : "off"}`}>
            <div className="toggleRedesignBall"></div>
          </div>
        }
      >
        {label}
      </Button>
    );
  }
);

import {
  BrandImpactData,
  BusinessImpactData,
  GroupByOptionsBrandEquityMetric,
} from "@blisspointmedia/bpm-types/dist/BrandEquity";

import Papa from "papaparse";
import { S3SignedUrlFetch } from "../utils/fetch-utils";

export const fetchAndParseData = async (
  url: string,
  transformFunction: (data: any) => any,
  metricValue: string
): Promise<any> => {
  try {
    const response = await S3SignedUrlFetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch data from URL: ${url} - Status: ${response.status}`);
    }
    const text = await response.text();
    const { data: parsedData } = Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
    });
    return transformFunction(parsedData);
  } catch (error) {
    console.error(`Error processing ${metricValue}:, error`);
    throw new Error(
      `Unable to process data for ${metricValue}. Check if the URL ${url} is accessible.`
    );
  }
};

export const removeDashPFromEndOfMetric = (metric: string): string => {
  const cleanedMetric = metric.endsWith("_p")
    ? metric
        .slice(0, -2)
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : metric
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
  return cleanedMetric;
};

export const hasClientFacingOptions = (
  data: Record<string, GroupByOptionsBrandEquityMetric[]>
): boolean => {
  return Object.values(data).some(array => array.some(item => item.client_facing === true));
};

export const hasClientFacingItem = (data: GroupByOptionsBrandEquityMetric[]): boolean => {
  return Array.isArray(data) && data.some(item => item.client_facing === true);
};

export const getMostRecentDateAndKPI = (
  data: Record<
    string,
    { value: string; client_facing: boolean; branch: string; stage_one: boolean }[]
  >
): { date: string; kpi: string } => {
  let defaultDate = "latest";
  let { value } = data.latest[0];

  if (data.hasOwnProperty("latest") && data.latest.some(item => item.client_facing === true)) {
    defaultDate = "latest";
    value = data.latest.find(item => item.client_facing === true)?.value || "";
  } else {
    const dates = Object.keys(data).filter(key => !isNaN(Date.parse(key)));
    const dateList = dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    for (let date of dateList) {
      const clientFacingKPI = data[date].find(item => item.client_facing === true);
      if (clientFacingKPI) {
        defaultDate = date;
        ({ value } = clientFacingKPI);
        break;
      }
    }
  }

  return { date: defaultDate, kpi: value };
};

export const returnModelRunDates = (
  modelOptions: Record<string, GroupByOptionsBrandEquityMetric[]>
): any => {
  return Object.entries(modelOptions)
    .map(([dateKey, items], index) => {
      const formattedDate =
        dateKey === "latest"
          ? "latest"
          : new Date(dateKey.slice(0, 10)).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            });
      const clientFacing = hasClientFacingItem(items as GroupByOptionsBrandEquityMetric[]);
      const options = Array.from(
        new Map(
          (items as {
            label: string;
            value: string;
            s3Path: string;
            client_facing: boolean;
            branch: string;
            stage_one: boolean;
          }[])
            .map(item => ({
              label: `${clientFacing ? "" : `[Branch: ${item.branch}] `}${formattedDate}`,
              value: dateKey,
              section: clientFacing ? "Client-Facing" : "Admin-Only",
              statusPill: index === 0 ? "Newest" : "Archived",
              className: "archiveOptions",
              clientFacing: clientFacing,
            }))
            .map(option => [option.value, option])
        ).values()
      );

      return options;
    })
    .flat();
};

export const isBrandImpactDataComplete = (data: BrandImpactData): boolean => {
  return (
    data.weeklyBrandHealthTimeSeries.length > 0 &&
    Object.keys(data.modelOverview).length > 0 &&
    Object.keys(data.weeklyBrandHealthAreaChartSpend).length > 0 &&
    Object.keys(data.implicationsData).length > 0 &&
    Object.keys(data.paidMediaAreaChart).length > 0 &&
    Object.keys(data.paidMediaBarChart).length > 0 &&
    Object.keys(data.spendAndEffectBarChart).length > 0 &&
    Object.keys(data.nextBestDollarBarChart).length > 0 &&
    Object.keys(data.decayData).length > 0 &&
    Object.keys(data.saturationData).length > 0
  );
};

export const isBusinessImpactDataComplete = (data: BusinessImpactData): boolean => {
  return (
    data.weeklyBrandHealthTimeSeries.length > 0 &&
    Object.values(data.modelOverview).every(
      value => value !== null && value !== undefined && value !== ""
    ) &&
    data.weeklyRevenueTimeSeries.length > 0 &&
    data.totalRevenueDecomposition.length > 0 &&
    data.weeklyRevenueDecomposition.length > 0 &&
    data.revenueImpactTakeaways.length > 0 &&
    data.weeklyIncrementalRoas.length > 0 &&
    data.incrementalRoasTakeaways !== undefined &&
    data.incrementalRoasTakeaways !== null &&
    Object.keys(data.saturationData).length > 0 &&
    Object.keys(data.decayData).length > 0
  );
};

import "./EditKpiMapping.scss";
import React, { useState, useCallback, useContext, useMemo } from "react";
import { useSetAreYouSure, useSetError } from "../redux/modals";
import * as R from "ramda";
import * as uuid from "uuid";
import { MdDelete, MdSave, MdAdd, MdEdit, MdSend } from "react-icons/md";
import { Button, ButtonType, OverlayTrigger, Spinner, Tooltip } from "../Components";
import { KpiMappingsContext } from "./KpiMapping";
import { awaitJSON, CrossChannelLambdaFetch } from "../utils/fetch-utils";
import SourceColumn from "./SourceColumn";
import CrossChannelKpiColumn from "./CrossChannelKpiColumn";
import { KpiMappings, KpiEdits, SourceMapping } from "@blisspointmedia/bpm-types/dist/KpiMapping";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { Mixpanel, MxE } from "../utils/mixpanelWrapper";

export interface HandleCrossChannelKpiNameEdit {
  id: number;
  name: string;
}

export interface HandleLagEditInput {
  source: string;
  lag: string;
}

export interface HandleKpiEditInput {
  key: string;
  mappings: SourceMapping[];
}

export interface HandleDeleteMappingInput {
  key: string;
  source: string;
  crossChannelKpiId: string;
  accountId: string;
}

export interface HandleNewSourceKpiInput {
  crossChannelKpiId: number;
  source: string;
  sourceId: number;
  kpiLabel: string;
  accountId: string;
  isRemoveKpi?: boolean;
}

export interface HandleNewRowInput {
  crossChannelKpiId: string;
  kpiLabel: string;
  sourceLabel: string;
  isCrossChannelKpiLabel: boolean;
  sourceMappings?: SourceMapping[];
  accountId?: string;
}

interface CombinedCrossChannelKpi {
  crossChannelKpiLabel: string;
  crossChannelKpiId: string | number;
  isNewRow?: boolean;
}

export type CombinedCrossChannelKpis = CombinedCrossChannelKpi[];

interface EditKpiMappingProps {
  company: string;
  data: KpiMappings;
  setEditMode: (editMode: boolean) => void;
  setKpiMappings: (kpiMappings: KpiMappings | undefined) => void;
}

/**
 * Concatenation of the Cross Channel KPI ID, source, and account ID
 */
export const makeEditKey = ({
  crossChannelKpiId,
  source,
  accountId,
}: {
  crossChannelKpiId: number | string;
  source: string;
  accountId: string;
}): string => `${crossChannelKpiId}_${source}_${accountId}`;

const EditKpiMapping: React.FC<EditKpiMappingProps> = ({
  company,
  data,
  setEditMode,
  setKpiMappings,
}) => {
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);

  const { sourcesWithKpis, accountInfoBySource, sourceIdMap, editMode } = useContext(
    KpiMappingsContext
  );

  const [kpiEdits, setKpiEdits] = useState<KpiEdits>({}); // Edit existing source KPIs
  const [crossChannelKpiNameEdits, setCrossChannelKpiNameEdits] = useState<Record<string, string>>(
    {}
  ); // Edit existing cross channel KPI names
  const [newRows, setNewRows] = useState<KpiMappings>({}); // Add new cross channel KPIs and source KPIs
  const [lagEdits, setLagEdits] = useState<Record<string, string>>({}); // Edit lags for source KPIs
  const [deleteMapping, setDeleteMapping] = useState<Record<string, any>>({}); // Clear source KPIs
  const [saving, setSaving] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  // Edit a Source KPI for the existing Cross Channel KPI
  const handleLagEdit = useCallback(({ source, lag }: HandleLagEditInput) => {
    setLagEdits(prevEdits => ({
      ...prevEdits,
      [source]: lag,
    }));
  }, []);

  // Edit the name of an existing Cross Channel KPI
  const handleCrossChannelKpiNameEdit = useCallback(({ id, name }) => {
    setCrossChannelKpiNameEdits(prevEdits => ({
      ...prevEdits,
      [id]: name,
    }));
  }, []);

  // Edit a Source KPI for the existing Cross Channel KPI
  const handleKpiEdit = useCallback(({ key, mappings }: HandleKpiEditInput) => {
    setKpiEdits(prevEdits => ({
      ...prevEdits,
      [key]: mappings,
    }));
  }, []);

  // Clear a Source KPI for the existing Cross Channel KPI so that nothing is mapped for the Source/Cross Channel KPI. We will delete these rows in DB.
  const handleDeleteMapping = useCallback(
    ({ key, source, crossChannelKpiId, accountId }: HandleDeleteMappingInput) => {
      setDeleteMapping(prev => ({
        ...prev,
        [key]: { sourceId: sourceIdMap[source], crossChannelKpiId, accountId },
      }));
    },
    [sourceIdMap]
  );

  // Edits a pending new row
  const handleNewRow = useCallback(
    ({
      crossChannelKpiId,
      kpiLabel,
      sourceLabel,
      isCrossChannelKpiLabel,
      sourceMappings,
      accountId,
    }: HandleNewRowInput) => {
      if (isCrossChannelKpiLabel) {
        setNewRows(prevNewRows => ({
          ...prevNewRows,
          [crossChannelKpiId]: {
            ...prevNewRows[crossChannelKpiId],
            crossChannelKpiLabel: kpiLabel,
          },
        }));
      } else {
        setNewRows(prevNewRows => {
          // Keep the mappings for other account IDs for this source. Then overwrite the account ID we're editing now.
          const sourceMappingsToKeep: SourceMapping[] = R.pipe(
            R.pathOr([], [crossChannelKpiId, "mappings", sourceLabel]),
            R.filter((item: SourceMapping) => item.accountId !== accountId)
          )(prevNewRows);

          return {
            ...prevNewRows,
            [crossChannelKpiId]: {
              ...prevNewRows[crossChannelKpiId],
              mappings: {
                ...R.path([crossChannelKpiId, "mappings"], prevNewRows),
                [sourceLabel]: [...sourceMappingsToKeep, ...(sourceMappings || [])],
              },
            },
          };
        });
      }
    },
    []
  );

  // Adds a pending new row
  const addNewRow = useCallback(() => {
    const newRowKey = uuid.v4();

    setNewRows(prevNewRows => ({
      ...prevNewRows,
      [`${newRowKey}`]: {
        isNewRow: true,
        crossChannelKpiId: newRowKey,
        crossChannelKpiLabel: "",
        mappings: {},
      },
    }));
  }, []);

  // Deletes a pending new row
  const deleteNewRow = useCallback(id => {
    setNewRows(prevNewRows => {
      const newRowsCopy = { ...prevNewRows };
      delete newRowsCopy[id];
      return newRowsCopy;
    });
  }, []);

  const saveChanges = useCallback(async () => {
    try {
      setSaving(true);
      try {
        await setAreYouSure({
          title: "Are you sure?",
          message: "Are you sure you want to save these changes?",
          okayText: "Save",
        });
      } catch (e) {
        setSaving(false);
        return;
      }

      const flattenedKpiEdits = R.pipe(R.values, R.flatten)(kpiEdits);

      await CrossChannelLambdaFetch("/setKpiMappings", {
        method: "POST",
        body: {
          kpiEdits: flattenedKpiEdits,
          crossChannelKpiNameEdits: crossChannelKpiNameEdits,
          newRows: R.values(newRows),
          lagEdits: lagEdits,
          deleteMapping: R.values(deleteMapping),
          company,
        },
      });
      setSaving(false);
      setEditMode(false);
      setKpiEdits({});
      setNewRows({});
      setCrossChannelKpiNameEdits({});
      setLagEdits({});
      setKpiMappings(undefined);
    } catch (e) {
      setSaving(false);
      setError({
        message: `Failed to save KPI mapping edits. Error: ${e.message}`,
        reportError: e,
      });
    }
  }, [
    kpiEdits,
    crossChannelKpiNameEdits,
    newRows,
    lagEdits,
    deleteMapping,
    company,
    setEditMode,
    setKpiMappings,
    setAreYouSure,
    setError,
  ]);

  const clearEdits = useCallback(() => {
    setKpiEdits({});
    setNewRows({});
    setCrossChannelKpiNameEdits({});
    setLagEdits({});
    setDeleteMapping({});
    setEditMode(false);
  }, [setEditMode]);

  const combinedCrossChannelKpis: CombinedCrossChannelKpis = useMemo(() => {
    const existingCrossChannelKpis = R.values(data)
      .map(kpiObj => R.pick(["crossChannelKpiLabel", "crossChannelKpiId", "isNewRow"], kpiObj))
      //@ts-ignore
      .sort((a, b) => a.crossChannelKpiId - b.crossChannelKpiId); // ID is a number for existing

    const newCrossChannelKpis = R.values(newRows)
      .map(kpiObj => R.pick(["crossChannelKpiLabel", "crossChannelKpiId", "isNewRow"], kpiObj))
      //@ts-ignore
      .sort((a, b) => a.crossChannelKpiId.localeCompare(b.crossChannelKpiId)); // ID is a UUID for new

    return [...existingCrossChannelKpis, ...newCrossChannelKpis];
  }, [data, newRows]);

  const combinedKpiMappings = useMemo(() => ({ ...data, ...newRows }), [data, newRows]);

  const refreshCrossChannel = useCallback(() => {
    (async () => {
      try {
        setRefreshing(true);
        Mixpanel.track(MxE.REFRESH_CC_VIEWS);
        const res = await CrossChannelLambdaFetch("/refreshCrossChannelViews", {
          method: "POST",
          body: { company },
        });
        const resJson = await awaitJSON(res);
        const { message, status } = resJson;
        setRefreshing(false);
        setError({
          variant: status === "IN_PROGRESS" ? "warning" : "success",
          title: status === "IN_PROGRESS" ? "Publish already in progress" : "Publish started",
          message: <div>{message}</div>,
        });
      } catch (e) {
        setRefreshing(false);
        setError({
          message: `Failed to publish to dashboards for ${company}. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [company, setError]);

  return (
    <>
      <div className="editKpiMappingContainer">
        <div className="kpiMappingEditControls">
          {editMode ? (
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdAdd />}
              onClick={addNewRow}
              disabled={saving}
            >
              Add KPI
            </Button>
          ) : (
            <Button
              className="editModeButton"
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdEdit />}
              onClick={() => setEditMode(!editMode)}
            >
              Edit
            </Button>
          )}
          <OverlayTrigger
            placement={OverlayTrigger.PLACEMENTS.TOP.RIGHT}
            delay={500}
            overlay={
              <Tooltip>
                {
                  "Publish the latest mapping changes to dashboards now.\nChanges will auto-update by the next day if not manually published"
                }
              </Tooltip>
            }
          >
            <Button
              type={ButtonType.OUTLINED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdSend color="#6B2DEF" />}
              onClick={() => refreshCrossChannel()}
            >
              {refreshing ? <Spinner /> : "Publish to dashboards"}
            </Button>
          </OverlayTrigger>
        </div>
        <div className="editSection">
          <div className="crossChannelKpisColumn">
            <CrossChannelKpiColumn
              crossChannelKpis={combinedCrossChannelKpis}
              crossChannelKpiNameEdits={crossChannelKpiNameEdits}
              handleCrossChannelKpiNameEdit={handleCrossChannelKpiNameEdit}
              handleNewRow={handleNewRow}
              deleteNewRow={deleteNewRow}
            />
          </div>
          <div className="scrollableSection">
            {sourcesWithKpis.map(source => {
              return (
                <SourceColumn
                  key={source}
                  combinedKpiMappings={combinedKpiMappings}
                  source={source}
                  crossChannelKpis={combinedCrossChannelKpis}
                  editsMap={kpiEdits}
                  newRows={newRows}
                  accounts={accountInfoBySource[source] || []}
                  lagEdits={lagEdits}
                  handleKpiEdit={handleKpiEdit}
                  handleNewRow={handleNewRow}
                  handleLagEdit={handleLagEdit}
                  handleDeleteMapping={handleDeleteMapping}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="footerControls">
        {editMode && (
          <div className="rightControls">
            {saving ? (
              <Spinner size={40} color="#8254FF" />
            ) : (
              <>
                <Button
                  type={ButtonType.OUTLINED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  icon={<MdDelete style={{ color: "#8254FF" }} />}
                  onClick={clearEdits}
                >
                  Discard Edits
                </Button>
                <Button
                  type={ButtonType.FILLED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  icon={<MdSave />}
                  onClick={saveChanges}
                  disabled={
                    R.isEmpty(kpiEdits) &&
                    R.isEmpty(crossChannelKpiNameEdits) &&
                    R.isEmpty(newRows) &&
                    R.isEmpty(lagEdits)
                  }
                >
                  Commit
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EditKpiMapping;

import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import "./DateInputField.scss";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import { MAX_DATE, PRETTY_DATE_FORMAT } from "../DatePickerButton/DatePickerConstants";
import { defaultIsDayOrRangeInvalid } from "@blisspointmedia/bpm-types/dist/RelativeDatePresets";

const makePrettyDate = (date?: string) =>
  date ? R.pipe(Dfns.parseISO, Dfns.format(PRETTY_DATE_FORMAT))(date) : "";

const checkMonth = (month: string) => {
  const parsedMonth = +month;
  if (month.length === 1) {
    return parsedMonth <= 1;
  }
  return parsedMonth > 0 && parsedMonth <= 12;
};

const checkDay = (month: string, day: string) => {
  const parsedMonth = +month;
  const parsedDay = +day;
  if (day.length === 1) {
    return parsedDay <= MAX_DATE[parsedMonth] / 10;
  }
  return parsedDay <= MAX_DATE[parsedMonth];
};

const validDate = value => {
  const parseDate = Dfns.parse(new Date(), PRETTY_DATE_FORMAT);
  const result = parseDate(value);
  return Dfns.isValid(result);
};

interface DateInputFieldProps {
  label: React.ReactNode;
  value: string;
  setValue?: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void);
  customValidation?: {
    validate: (value: string) => boolean;
    errorMessage: string;
  }[];
  infoMessage?: string;
  className?: string;
  invalidateDateOrRange?: (date: string) => boolean;
  valueDateFormat?: string;
  dashBoarder?: boolean;
  clearPreset?: () => void;
}

export const DateInputField: React.FC<DateInputFieldProps> = React.memo(
  ({
    label,
    value,
    setValue,
    customValidation,
    infoMessage,
    className = "",
    invalidateDateOrRange = defaultIsDayOrRangeInvalid,
    valueDateFormat = "yyyy-MM-dd",
    dashBoarder = false,
    clearPreset,
  }) => {
    const [customErrorMessage, setCustomErrorMessage] = useState("");
    const [tempDateStr, setTempDateStr] = useState(makePrettyDate(value));
    const [isValid, setIsValid] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
      // case where value is reassigned by parent
      const prettyDate = makePrettyDate(value);
      setTempDateStr(t => (t !== prettyDate ? prettyDate : t));
    }, [value]);

    const checkIsValid = useCallback(value => {
      if (validDate(value)) {
        return true;
      } else if (value.length === 1) {
        return checkMonth(value) && value.match(/^[0-9]+$/) !== null;
      } else if (value.length === 2) {
        return checkMonth(value) && value.match(/^[0-9][0-9]+$/) !== null;
      } else if (value.length === 3) {
        return checkMonth(value.substring(0, 2)) && value.match(/^[0-9][0-9][/]+$/) !== null;
      } else if (value.length === 4) {
        const month = value.substring(0, 2);
        const day = value.substring(3);
        return (
          checkMonth(month) && checkDay(month, day) && value.match(/^[0-9][0-9][/][0-9]+$/) !== null
        );
      } else if (value.length === 5) {
        const month = value.substring(0, 2);
        const day = value.substring(3);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9]+$/) !== null
        );
      } else if (value.length === 6) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/]+$/) !== null
        );
      } else if (value.length === 7) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/][0-9]+$/) !== null
        );
      } else if (value.length === 8) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/][0-9][0-9]+$/) !== null
        );
      } else if (value.length === 9) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/][0-9][0-9][0-9]+$/) !== null
        );
      } else if (value.length === 10) {
        const month = value.substring(0, 2);
        const day = value.substring(3, 5);
        return (
          checkMonth(month) &&
          checkDay(month, day) &&
          value.match(/^[0-9][0-9][/][0-9][0-9][/][0-9][0-9][0-9][0-9]+$/) !== null
        );
      } else if (value.length > 10) {
        return false;
      } else {
        return true;
      }
    }, []);

    useEffect(() => {
      if (customValidation && value.length > 0 && isValid) {
        let newErrorMessage = "";
        customValidation.forEach(({ validate, errorMessage }) => {
          if (!newErrorMessage && !validate(value)) {
            newErrorMessage = errorMessage;
          }
        });

        if (newErrorMessage !== customErrorMessage) {
          setCustomErrorMessage(newErrorMessage);
        }
      }
    }, [customErrorMessage, customValidation, isValid, value]);

    const onChange = useCallback(
      event => {
        let newValue = event.target.value;
        if (newValue.length > 10) {
          return;
        }
        const isAdding = newValue.length > tempDateStr.length;
        if (
          isAdding &&
          ((newValue.length === 2 && newValue.match(/^[0-9][0-9]$/) !== null) ||
            (newValue.length === 5 && newValue.match(/^[0-9][0-9][/][0-9][0-9]$/) !== null))
        ) {
          newValue += "/";
        }
        setTempDateStr(newValue);
        let valid = checkIsValid(newValue);
        if (!valid) {
          setErrorMessage("Invalid date format");
          setIsValid(false);
          return;
        }

        if (setValue && newValue.length === 10) {
          const parseDate = Dfns.parse(new Date(), "MM/dd/yyyy");
          const result = parseDate(newValue);

          if (!Dfns.isValid(result)) {
            setErrorMessage("Invalid date format");
            setIsValid(false);
            return;
          }
          const formattedDate = Dfns.format(valueDateFormat, result);
          if (invalidateDateOrRange(formattedDate)) {
            setErrorMessage("Outside date range");
            setIsValid(false);
          } else {
            setValue(formattedDate);
            setIsValid(true);
          }
        } else {
          setIsValid(true);
        }
      },
      [setValue, tempDateStr, invalidateDateOrRange, valueDateFormat, checkIsValid]
    );

    return (
      <div
        className={cn("dateInputFieldContainer", {
          [className]: className,
        })}
      >
        <div>
          <div className="dateInputFieldLabel">{label}</div>
          <input
            className={cn("dateInputFieldValue", {
              invalid: !isValid,
              dashed: dashBoarder,
            })}
            type="text"
            placeholder="mm/dd/yyyy"
            value={tempDateStr}
            onChange={onChange}
            onFocus={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              if (clearPreset) {
                clearPreset();
              }
              setTempDateStr("");
            }}
            onBlur={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              const prettyDate = makePrettyDate(value);
              const valid = checkIsValid(prettyDate);
              setIsValid(valid);
              setErrorMessage("");
              setTempDateStr(prettyDate);
            }}
          ></input>
        </div>
        {infoMessage && <div className="dateInputFieldInfo">{infoMessage}</div>}
        {!isValid && (
          <div
            className={cn("dateInputFieldError", {
              [label as string]: typeof label === "string",
            })}
          >
            {errorMessage}
          </div>
        )}
        {customErrorMessage && (
          <div
            className={cn("dateInputFieldError", {
              [label as string]: typeof label === "string",
            })}
          >
            {customErrorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default DateInputField;

import React from "react";
import * as R from "ramda";

export const NAME_PREVIEW_LABELS = {
  agency: "Agency",
  client: "Client",
  funnel: "Funnel",
  tactic: "Tactic",
  geo: "Geo",
  initiative: "Initiative",
  segmentation_id: "SegmentID",
  campaign_type: "Type",
};

interface MetaBuyingNamePreviewProps {
  data: Record<string, any>;
  namingConvention: string[];
}

export const MetaBuyingNamePreview = ({
  data,
  namingConvention,
}: MetaBuyingNamePreviewProps): JSX.Element => {
  return (
    <span className="metaBuyingNamePreview">
      {namingConvention.map((dimension, index) =>
        R.isNil(data[dimension]) || R.isEmpty(data[dimension]) ? (
          <span key={index} className="placeholder">
            {NAME_PREVIEW_LABELS[dimension] || dimension}
            {index < namingConvention.length - 1 ? "_" : ""}
          </span>
        ) : (
          <span key={index}>
            {data[dimension]}
            {index < namingConvention.length - 1 ? "_" : ""}
          </span>
        )
      )}
    </span>
  );
};

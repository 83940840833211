// Linear Buying experiments.

// If opted into this experiment, you'll see the "Campaign" column on the Buying and Orders pages.
const campaignCompanies: string[] = [
  "care",
  "casper",
  "hers",
  "hims",
  "homechef",
  "instacart",
  "julie_scratchpad",
  "oura",
  "vinnie_scratchpad",
  "warbyparker",
  // Add companies here!
];

export const shouldEnableLinearCampaigns = (company: string): boolean => {
  return campaignCompanies.includes(company);
};

// This is for the companies that need access to upfront buy items including impresssions tables, excel downloads, UI changes

const allChangesLinearBuyingUsers: string[] = [
  "brenna.huggins@tinuiti.com",
  "carling.sugarman@tinuiti.com",
  "vinnie.jaidar@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "justin.manus@tinuiti.com",
  "berto.garciacarrillo@tinuiti.com",
  // Add users here!
];

export const shouldEnableAllLinearChangesUsers = (userEmail: string): boolean => {
  return allChangesLinearBuyingUsers.includes(userEmail);
};

const allChangesLinearBuyingCompany: string[] = [
  // "michelle_scratchpad",
  "rachelcostanzo_scratchpad",
  "instacart",
  "hims",
  "poppi",
  // Add users here!
];

export const shouldEnableAllLinearChangesCompanies = (company: string): boolean => {
  return allChangesLinearBuyingCompany.includes(company);
};

import { useState, createContext, ReactNode } from "react";

export const metaCampaignFormDefault = {
  campaign_name: "",
  agency: "",
  client: "",
  funnel: "",
  tactic: "",
  campaign_type: "",
  geo: "",
  initiative: "",
  segmentation_id: "",
  objective: "",
  special_ad_categories: [],
  budget_type: "",
  budget: 0,
  bid_strategy: "",
};

export const metaAdSetFormDefault = {
  ad_set_name: "",
  audience_type: "",
  audience: "",
  campaign: "",
  pixel: "",
  conversion_event: "",
  optimization_goal: "",
  bid_strategy: "",
  bid_amount: 0,
  custom_audiences: "",
  geo: "",
  interests: "",
  gender: "",
  placements: "",
  age: 0,
  budget_type: "",
  budget: 0,
  attribution_window: "",
};

export const metaAdFormDefault = {
  ad_name: "",
  platform: "",
  date: "",
  asset_type: "",
  tactic: "",
  creative: "",
  initiative: "",
  ad_set: "",
  facebook_page: "",
  instagram_page: "",
  creative_format: "",
  creative_asset: "",
  headline: "",
  copy: "",
  cta_text: "",
  cta_link: "",
  cta_link_description: "",
};

export const FormContext = createContext<any>([[], () => {}]);

export interface MetaFormContextProps {
  children: ReactNode;
}

export const MetaBuyingProvider = (props: MetaFormContextProps): JSX.Element => {
  const [metaCampaignForm, setMetaCampaignForm] = useState(metaCampaignFormDefault);
  const [metaAdSetForm, setMetaAdSetForm] = useState(metaAdSetFormDefault);
  const [metaAdForm, setMetaAdForm] = useState(metaAdFormDefault);

  return (
    <FormContext.Provider
      value={{
        metaCampaignForm,
        setMetaCampaignForm,
        metaAdSetForm,
        setMetaAdSetForm,
        metaAdForm,
        setMetaAdForm,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export default MetaBuyingProvider;

import {
  awaitJSON,
  LinearPerformanceLambdaFetch,
  SingleChannelLambdaFetch,
} from "../../utils/fetch-utils";
import { DateRange, typedReactMemo } from "../../utils/types";
import { DimensionMap } from "../MetricsTable/metricsTableUtils";
import { fetchLinearDelivery } from "../../LinearDelivery/LinearDelivery";
import { FullPageSpinner } from "../../Components";
import { getGlobalBrand } from "../../Performance/performanceUtils";
import { overrideDateRange } from "../../utils/test-account-utils";
import { RouteComponentProps } from "@reach/router";
import { useCompanyInfo } from "../../redux/company";
import { useCreativeMap } from "../../redux/creative";
import { useSetError } from "../../redux/modals";
import * as Dfns from "date-fns/fp";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";
import * as R from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SingleChannelPage from "../SingleChannelPage";
import useLocation from "../../utils/hooks/useLocation";
import {
  columnMetaDataMap,
  dimensionColumnMetaDataMap,
  getDimensionCell as LIN_GET_DIMENSION_CELL,
  GLOSSARY,
} from "./linearPerformanceUtils";
import {
  DeletePresetParams as DeletePagePresetParams,
  GetPresetsParams as GetPagePresetsParams,
  MetricsPagePreset,
  SavePresetParams as SavePagePresetParams,
} from "@blisspointmedia/bpm-types/dist/MetricsPage";
import {
  DeletePresetParams as DeleteTablePresetParams,
  DimensionColumn,
  GetPresetParams as GetTablePresetParams,
  MetricsTablePreset,
  SavePresetParams as SaveTablePresetParams,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";

const DATE_FORMAT = "yyyy-MM-dd";

interface LinearSingleChannelProps extends RouteComponentProps {
  urlSettings: string;
}

const LinearSingleChannel = typedReactMemo<React.FC<LinearSingleChannelProps>>(
  ({ location, urlSettings }) => {
    const [dates, setDates] = useState<DateRange>();
    const [fetchingPagePresets, setFetchingPagePresets] = useState(false);
    const [fetchingTablePresets, setFetchingTablePresets] = useState(false);
    const [filterData, setFilterData] = useState<P.GetFilterOptionsResponse>();
    const [kpiMetaData, setKpiMetaData] = useState<P.GetKpiMetaDataResponse>();
    const [pagePresets, setPagePresets] = useState<MetricsPagePreset[]>();
    const [tablePresets, setTablePresets] = useState<MetricsTablePreset[]>();
    const { company } = useLocation();
    const companyInfo = useCompanyInfo();
    const globalBrand = getGlobalBrand(companyInfo, companyInfo.initial_kpi);
    const setError = useSetError();

    const { creativeMap } = useCreativeMap({
      company: globalBrand || company,
      mediaTypes: ["linear"],
    });

    const creativeNameMap = useMemo(() => {
      let res = {};
      if (creativeMap) {
        for (const key of R.keys(creativeMap)) {
          const { name } = creativeMap[key];
          if (R.isNil(res[name]) || !res[name].file) {
            res[name] = creativeMap[key];
          }
        }
      }
      return res;
    }, [creativeMap]);

    const getPagePresets = useCallback(() => {
      (async () => {
        try {
          setFetchingPagePresets(true);
          const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
            "/metrics_page_presets",
            {
              params: {
                company,
                mediatype: "linear",
              },
            }
          );
          const parsedPresets = await awaitJSON(res);
          if (!parsedPresets.errorMessage) {
            setPagePresets(parsedPresets as MetricsPagePreset[]);
          }
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to fetch page presets. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          setFetchingPagePresets(false);
        }
      })();
    }, [company, setError]);

    const getTablePresets = useCallback(() => {
      (async () => {
        try {
          setFetchingTablePresets(true);
          const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
            "/metrics_table_presets",
            {
              params: {
                company,
                mediatype: "linear",
              },
            }
          );
          const parsedPresets = await awaitJSON(res);
          if (!parsedPresets.errorMessage) {
            setTablePresets(parsedPresets as MetricsTablePreset[]);
          }
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to fetch table presets. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          setFetchingTablePresets(false);
        }
      })();
    }, [company, setError]);

    const getTablePreset = useCallback(
      params => {
        return (async () => {
          try {
            const res = await SingleChannelLambdaFetch<GetTablePresetParams>(
              "/metrics_table_preset",
              {
                params,
              }
            );
            return await awaitJSON<MetricsTablePreset>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to fetch table preset. Error: ${error.message}`,
              reportError: error,
            });
          }
        })();
      },
      [setError]
    );

    const savePagePreset = useCallback(
      body => {
        return (async () => {
          let presetResponse;
          try {
            setFetchingPagePresets(true);
            const res = await SingleChannelLambdaFetch<SavePagePresetParams>(
              "/metrics_page_preset",
              {
                method: "POST",
                body,
              }
            );
            presetResponse = await awaitJSON<P.SavePresetResponse>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to save page preset. Error: ${error.message}`,
              reportError: error,
            });
            presetResponse = { message: `Failed to save page preset. Error: ${error.message}` };
          } finally {
            getPagePresets();
            return presetResponse;
          }
        })();
      },
      [getPagePresets, setError]
    );

    const saveTablePreset = useCallback(
      body => {
        return (async () => {
          let presetResponse;
          try {
            setFetchingTablePresets(true);
            const res = await SingleChannelLambdaFetch<SaveTablePresetParams>(
              "/metrics_table_preset",
              {
                method: "POST",
                body,
              }
            );
            presetResponse = await awaitJSON<P.SavePresetResponse>(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to save table preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getTablePresets();
            return presetResponse;
          }
        })();
      },
      [getTablePresets, setError]
    );

    const deletePagePreset = useCallback(
      params => {
        return (async () => {
          try {
            setFetchingPagePresets(true);
            const res = await SingleChannelLambdaFetch<DeletePagePresetParams>(
              "/metrics_page_preset",
              {
                method: "DELETE",
                params,
              }
            );
            await awaitJSON(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to delete page preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getPagePresets();
          }
        })();
      },
      [getPagePresets, setError]
    );

    const deleteTablePreset = useCallback(
      params => {
        return (async () => {
          try {
            setFetchingTablePresets(true);
            const res = await SingleChannelLambdaFetch<DeleteTablePresetParams>(
              "/metrics_table_preset",
              {
                method: "DELETE",
                params,
              }
            );
            await awaitJSON(res);
          } catch (e) {
            const error = e as Error;
            setError({
              message: `Failed to delete table preset. Error: ${error.message}`,
              reportError: error,
            });
          } finally {
            getTablePresets();
          }
        })();
      },
      [getTablePresets, setError]
    );

    const getDeliveryData = useCallback(params => {
      return (async () => {
        return await fetchLinearDelivery(params);
      })();
    }, []);

    const getPerformanceData = useCallback(
      params => {
        return (async () => {
          if (
            params &&
            params.dates &&
            params.dates.end &&
            R.pipe(Dfns.parseISO, Dfns.isMonday)(params.dates.end)
          ) {
            params.dates.end = R.pipe(
              Dfns.parseISO,
              Dfns.addDays(6),
              Dfns.format(DATE_FORMAT)
            )(params.dates.end);
          }
          const dateRangeToUse = overrideDateRange(company, params.dates);
          const res = await LinearPerformanceLambdaFetch<L.GetPageDataParams>("/", {
            params: {
              ...params,
              ...(dateRangeToUse || {}),
            },
          });
          return await awaitJSON<L.GetPageDataResponse>(res);
        })();
      },
      [company]
    );

    const getDimensionCell = useCallback(
      (dimensionData: DimensionMap, dimensionHeader: DimensionColumn) =>
        LIN_GET_DIMENSION_CELL(dimensionData, dimensionHeader, {
          ...creativeNameMap,
          ...creativeMap,
        }),
      [creativeMap, creativeNameMap]
    );

    useEffect(() => {
      if (company && !pagePresets) {
        getPagePresets();
      }
    }, [company, getPagePresets, pagePresets, setError, urlSettings]);

    useEffect(() => {
      if (company && !tablePresets) {
        getTablePresets();
      }
    }, [company, getTablePresets, pagePresets, setError, tablePresets, urlSettings]);

    useEffect(() => {
      if (company && !pagePresets) {
        (async () => {
          try {
            const params = {
              company,
              mediatype: "linear",
            };
            const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
              "/metrics_page_presets",
              {
                params,
              }
            );
            const parsedPresets = await awaitJSON(res);
            if (!parsedPresets.errorMessage) {
              setPagePresets(parsedPresets as MetricsPagePreset[]);
            }
          } catch (e) {
            const error = e as Error;
            setError({
              reportError: error,
              message: `Failed to fetch page presets. Error: ${error.message}`,
            });
          }
        })();
      }
    }, [company, pagePresets, setError, urlSettings]);

    useEffect(() => {
      if (company && !tablePresets) {
        (async () => {
          try {
            let params = {
              company,
              mediatype: "linear",
            };
            const res = await SingleChannelLambdaFetch<GetPagePresetsParams>(
              "/metrics_table_presets",
              {
                params,
              }
            );
            const parsedPresets = await awaitJSON(res);
            if (!parsedPresets.errorMessage) {
              setTablePresets(parsedPresets as MetricsTablePreset[]);
            }
          } catch (e) {
            const error = e as Error;
            setError({
              reportError: error,
              message: `Failed to fetch table presets. Error: ${error.message}`,
            });
          }
        })();
      }
    }, [company, pagePresets, setError, tablePresets, urlSettings]);

    useEffect(() => {
      if (R.isNil(filterData)) {
        (async () => {
          try {
            const res = await LinearPerformanceLambdaFetch<L.GetFilterOptionsParams>(
              "/getFilterOptions",
              {
                params: {
                  company: globalBrand || company,
                },
              }
            );
            const options = await awaitJSON<P.GetFilterOptionsResponse>(res);
            setFilterData(options);
          } catch (e) {
            const error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [company, dates, filterData, globalBrand, setError]);

    useEffect(() => {
      if (company && !kpiMetaData) {
        (async () => {
          try {
            const res = await LinearPerformanceLambdaFetch<P.GetKpiMetaDataParams>(
              "/getKpiMetaData",
              {
                params: {
                  company,
                },
              }
            );
            const kpiMap = await awaitJSON<P.GetKpiMetaDataResponse>(res);
            setKpiMetaData(kpiMap);
          } catch (e) {
            const error = e as Error;
            setError({
              message: error.message,
              reportError: error,
            });
          }
        })();
      }
    }, [company, kpiMetaData, setError]);

    return creativeMap && pagePresets && tablePresets ? (
      <SingleChannelPage
        columnMetaDataMap={columnMetaDataMap}
        dataFetchKey={L.getFetchKey}
        dateIncrementOptions={["weekly", "monthly", "quarterly", "yearly"]}
        dates={dates as any}
        defaultSparkChartMetrics={["spend", "impressions", "CPM", "volume"]}
        deletePagePreset={deletePagePreset}
        deleteTablePreset={deleteTablePreset}
        deliveryDimensionMap={{
          Network: "network",
          Avail: "avail",
          Campaign: "Campaign",
          Creative: "creative",
          Daypart: "daypart",
          Length: "length",
          "Media Classification": "media_classification",
        }}
        deliveryMetricOptions={[
          {
            label: "Spend",
            isSpend: true,
            value: "cost",
          },
          {
            label: "Rated Spend",
            isSpend: true,
            value: "ratedCost",
          },
          {
            label: "Impressions",
            value: "count",
          },
          {
            label: "Spots",
            value: "spots",
          },
        ]}
        dimensionColumnMetaDataMap={dimensionColumnMetaDataMap}
        fetchingPagePresets={fetchingPagePresets}
        fetchingTablePresets={fetchingTablePresets}
        filterData={R.defaultTo([], filterData)}
        getDeliveryData={getDeliveryData}
        getDimensionCell={getDimensionCell}
        getPerformanceData={getPerformanceData}
        getTablePreset={getTablePreset}
        glossary={GLOSSARY}
        kpiMetaData={R.defaultTo({}, kpiMetaData)}
        location={location}
        pagePresets={R.defaultTo([], pagePresets)}
        prefix={"linear"}
        savePagePreset={savePagePreset}
        saveTablePreset={saveTablePreset}
        setDates={setDates as any}
        setPagePresets={setPagePresets}
        sparkChartMetricsMap={{
          cpm: "eCPM",
          cpx: "cpvDirect",
          impressions: "impressions",
          impsConversionRate: "impsConversionRate",
          ratedSpend: "ratedSpend",
          revenue: "revenue",
          roas: "roas",
          spend: "spend",
          spots: "spots",
          volume: "volume",
        }}
        sparkChartMetricPrettyNameMap={{
          cpm: "eCPM",
          cpx: "CPX",
          impressions: "Impressions",
          impsConversionRate: "Imps Conversion Rate",
          ratedSpend: "Rated Spend",
          revenue: "Revenue",
          roas: "ROAS",
          spend: "Spend",
          spots: "Spots",
          volume: "KPI Volume",
        }}
        setTablePresets={setTablePresets}
        tablePresets={R.defaultTo([], tablePresets)}
        urlSettings={urlSettings}
      />
    ) : (
      <FullPageSpinner />
    );
  }
);

export default LinearSingleChannel;

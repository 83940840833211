import React, { useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import "./MetaBuyingPublished.scss";
import MetaTableWidget from "./Components/MetaTableWidget";
import { BPMTable, Button, ButtonType, CheckBox, Header } from "../../Components";
import { useStateFunction } from "../../utils/hooks/useData";
import { MdMoreVert, MdOutlineFileDownload, MdRemoveRedEye } from "react-icons/md";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import {
  AdAccountInfo,
  MetaBuyingTableRow,
  CampaignRow,
  AdSetRow,
  AdRow,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import * as R from "ramda";
import {
  useFilterBar,
  useGetButtonPosition,
  useSelectRow,
  useTableHeadersRenderer,
  useViewableDataMap,
} from "./MetaBuyingUtils";
import MoreActionsMenu from "./Components/MoreActionsMenu";
import { createPortal } from "react-dom";

interface MetaBuyingPublishedProps {
  isInternal: boolean;
  selectedAdAccount: AdAccountInfo;
  setSelectedAdAccount: React.Dispatch<React.SetStateAction<AdAccountInfo>>;
  adAccountOptions: AdAccountInfo[];
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
}

const MetaBuyingPublished = ({
  isInternal,
  selectedAdAccount,
  setSelectedAdAccount,
  adAccountOptions,
  campaignRows,
  adSetRows,
  adRows,
}: MetaBuyingPublishedProps & RouteComponentProps): JSX.Element => {
  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);
  const [selectedLevel, setSelectedLevel] = useState<string>(isInternal ? "campaign" : "ad");
  const [selectedRows, setSelectedRows] = useState<
    Record<string, Record<string, MetaBuyingTableRow>>
  >({
    campaign: {},
    adset: {},
    ad: {},
  });
  const [selectAll, setSelectAll] = useState<Record<string, boolean>>({
    campaign: false,
    adset: false,
    ad: false,
  });

  // Map of each level to its rows. Data is filtered based on selected rows.
  const viewableDataMap: Record<string, MetaBuyingTableRow[]> = useViewableDataMap({
    campaignRows,
    adSetRows,
    adRows,
    selectedRows,
  });

  // Update selectAll state when viewableDataMap changes
  useEffect(() => {
    for (let level of Object.keys(selectedRows)) {
      const currObj = selectedRows[level];
      if (Object.keys(currObj).length === viewableDataMap[level].length) {
        setSelectAll(current => ({ ...current, [level]: true }));
      } else {
        setSelectAll(current => ({ ...current, [level]: false }));
      }
    }
  }, [selectedRows, viewableDataMap]);

  // Rows to display in the table based on selected level
  const publishedTableData: MetaBuyingTableRow[] = useMemo(() => {
    if (selectedLevel === "ad") {
      return viewableDataMap.ad;
    } else if (selectedLevel === "adset") {
      return viewableDataMap.adset;
    } else {
      return viewableDataMap.campaign;
    }
  }, [selectedLevel, viewableDataMap]);

  // Apply filter bar to table data
  const filteredData = useMemo(() => {
    return publishedTableData?.filter(filter) || [];
  }, [publishedTableData, filter]);

  const selectRow = useSelectRow({
    setSelectedRows,
    selectedLevel,
  });

  const buttonRefs = useRef({}); // Object to hold refs for each row
  const getButtonPosition = useGetButtonPosition({ buttonRefs, selectedLevel });
  const menuRef: React.LegacyRef<HTMLDivElement> | undefined = useRef(null);
  const [activeRowId, setActiveRowId] = useState<string>("");
  // Close menu if clicking outside of it
  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        menuRef &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRefs.current[activeRowId]?.contains(event.target)
      ) {
        setActiveRowId("");
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [activeRowId]);

  const publishedTableHeaders: Header[] = useMemo(() => {
    let headers: Header[] = [
      {
        label: "",
        name: "id",
        width: 40,
        nonInteractive: true,
        renderer: (row: MetaBuyingTableRow) => {
          return (
            <CheckBox
              className="checkboxCell"
              checked={R.has(row.id, selectedRows[selectedLevel])}
              onCheck={() => selectRow(row)}
            />
          );
        },
      },
    ];

    if (!isInternal) {
      headers.push(
        ...[
          {
            label: "Ad",
            flex: 3,
            renderer: (row: AdRow) => {
              return <div className="adNameCell highlighted">{row.name}</div>;
            },
          },
          {
            label: "Ad Set",
            flex: 3,
            renderer: (row: AdRow) => {
              return <div className="adSetNameCell">{row.adset_name}</div>;
            },
          },
          {
            label: "Campaign",
            flex: 3,
            renderer: (row: AdRow) => {
              return <div className="campaignNameCell">{row.campaign_name}</div>;
            },
          },
          {
            label: "Created On",
            name: "created",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return <div className="createdCell">{row.created}</div>;
            },
          },
          {
            label: "Status",
            width: 80,
            renderer: (row: MetaBuyingTableRow) => {
              return (
                <div className="statusCell">
                  <Button type={ButtonType.FILLED} size="sm">
                    Live
                  </Button>
                </div>
              );
            },
          },
          {
            width: 239,
            nonInteractive: true,
            renderer: (row: AdRow) => {
              return (
                <div className="actionsCell">
                  <Button
                    variant={ButtonFrameworkVariant.TRAILING_ICON}
                    icon={<MdRemoveRedEye />}
                    type={ButtonType.OUTLINED}
                    size="sm"
                  >
                    View Ad Preview
                  </Button>
                  <Button
                    variant={ButtonFrameworkVariant.ICON_ONLY}
                    icon={<MdOutlineFileDownload />}
                    size="sm"
                    type={ButtonType.FILLED}
                  />
                </div>
              );
            },
          },
        ]
      );
    } else {
      if (selectedLevel === "ad") {
        headers.push(
          ...[
            {
              label: "Ad",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="adNameCell highlighted">{row.name}</div>;
              },
            },
            {
              label: "Ad Set",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="adSetNameCell">{row.adset_name}</div>;
              },
            },
            {
              label: "Campaign",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="campaignNameCell">{row.campaign_name}</div>;
              },
            },
          ]
        );
      } else if (selectedLevel === "adset") {
        headers.push(
          ...[
            {
              label: "Ad Set",
              flex: 4,
              renderer: (row: AdSetRow) => {
                return <div className="adSetNameCell highlighted">{row.name}</div>;
              },
            },
            {
              label: "Campaign",
              flex: 4,
              renderer: (row: AdSetRow) => {
                return <div className="campaignNameCell">{row.campaign_name}</div>;
              },
            },
          ]
        );
      } else {
        headers.push({
          label: "Campaign",
          flex: 4,
          renderer: (row: CampaignRow) => {
            return <div className="campaignNameCell highlighted">{row.name}</div>;
          },
        });
      }

      headers.push(
        ...[
          {
            label: "Created On",
            name: "created",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return <div className="createdCell">{row.created}</div>;
            },
          },
          {
            label: "Creator",
            name: "lastuser",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return <div className="creatorCell">{row.lastuser}</div>;
            },
          },
        ]
      );

      headers.push(
        {
          label: "Status",
          width: 80,
          renderer: (row: MetaBuyingTableRow) => {
            return (
              <div className="statusCell">
                <Button type={ButtonType.FILLED} size="sm">
                  Live
                </Button>
              </div>
            );
          },
        },
        {
          width: 66,
          nonInteractive: true,
          renderer: (row: MetaBuyingTableRow) => {
            const buttonPosition = getButtonPosition(row.id);
            return (
              <div className="actionsCell">
                <Button
                  ref={el => {
                    buttonRefs.current[row.id] = el;
                  }} // Assign ref for each button
                  variant={ButtonFrameworkVariant.ICON_ONLY}
                  type={ButtonType.FILLED}
                  icon={<MdMoreVert />}
                  size="sm"
                  onClick={() => {
                    setActiveRowId(activeRowId === row.id ? "" : row.id);
                  }}
                />
                {activeRowId === row.id &&
                  createPortal(
                    <div
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "white",
                        position: "fixed",
                        top: buttonPosition.top,
                        left: buttonPosition.left,
                        display: "flex",
                        zIndex: 1000,
                      }}
                      ref={menuRef}
                    >
                      <MoreActionsMenu
                        selectedRows={selectedRows}
                        selectedLevel={selectedLevel}
                        tab="published"
                      />
                    </div>,
                    document.body
                  )}
              </div>
            );
          },
        }
      );
    }

    return headers;
  }, [activeRowId, getButtonPosition, isInternal, selectRow, selectedLevel, selectedRows]);

  const publishedTableHeadersRenderer = useTableHeadersRenderer({
    filteredData,
    selectAll,
    setSelectAll,
    selectedRows,
    setSelectedRows,
    selectedLevel,
  });

  const publishedFilterBar = useFilterBar({
    pageTab: "published",
    tableData: publishedTableData,
    isInternal,
    selectedLevel,
    setFilter,
  });

  const publishedTable = useMemo(() => {
    return (
      <BPMTable
        className="publishedTableExternal"
        alternateColors={false}
        data={filteredData}
        headers={publishedTableHeaders}
        headersRenderer={publishedTableHeadersRenderer}
        filterBar={false}
        noRowsRenderer={() => <div>No rows to show.</div>}
      ></BPMTable>
    );
  }, [publishedTableHeaders, publishedTableHeadersRenderer, filteredData]);

  return (
    <div className="metaBuyingPublished">
      {
        <MetaTableWidget
          isInternal={isInternal}
          title="Published"
          selectedAdAccount={selectedAdAccount}
          setSelectedAdAccount={setSelectedAdAccount}
          adAccountOptions={adAccountOptions}
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
          filterBar={publishedFilterBar}
          tableComponent={publishedTable}
          selectedRows={selectedRows}
        />
      }
    </div>
  );
};

export default MetaBuyingPublished;

import React, { useEffect, useCallback } from "react";
import "./CustomDatePresets.scss";
import { DateRange, Setter } from "../../../utils/types";
import { SingleCustomDatePreset } from "../SingleCustomDatePreset";
import type { Day } from "date-fns";
import {
  CustomPresetState,
  INIT_CUSTOM_PRESET_STATE,
  computeCustomRelativeDate,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePresets";

interface CustomDatePresetsProps {
  startCustomPreset: CustomPresetState;
  setStartCustomPreset: Setter<CustomPresetState | ((c: CustomPresetState) => CustomPresetState)>;
  endCustomPreset: CustomPresetState;
  setEndCustomPreset: Setter<CustomPresetState | ((c: CustomPresetState) => CustomPresetState)>;
  rangeError: "start" | "end" | null;
  setRangeError: Setter<"start" | "end" | null>;
  disabled?: boolean;
  weekDefinition: Day;
  invalidOrOutOfRange: (date: string) => boolean;
  setDateInputs?: Setter<Partial<DateRange>>;
}

export const CustomDatePresets: React.FC<CustomDatePresetsProps> = ({
  startCustomPreset,
  setStartCustomPreset,
  endCustomPreset,
  setEndCustomPreset,
  disabled,
  rangeError,
  weekDefinition,
  invalidOrOutOfRange,
  setDateInputs,
  setRangeError,
}) => {
  const onPresetSelect = useCallback(
    (
      state: CustomPresetState,
      setParentDateInput,
      isStartDate: boolean,
      rangerErrorSetter,
      companionSetter
    ) => {
      if (state.number === undefined || state.number < 0) {
        rangerErrorSetter(null);
        return;
      }
      const calculatedDate = computeCustomRelativeDate(state, weekDefinition);

      if (calculatedDate) {
        if (invalidOrOutOfRange(calculatedDate)) {
          rangerErrorSetter(isStartDate ? "start" : "end");
        } else {
          rangerErrorSetter(r => {
            setParentDateInput(p => {
              if (isStartDate) {
                if (calculatedDate > p.end || r === "end") {
                  companionSetter({ ...INIT_CUSTOM_PRESET_STATE });
                  return { start: calculatedDate };
                } else {
                  return { ...p, start: calculatedDate };
                }
              } else if (calculatedDate < p.start || r === "start") {
                companionSetter({ ...INIT_CUSTOM_PRESET_STATE });
                return { end: calculatedDate };
              } else {
                return { ...p, end: calculatedDate };
              }
            });
            return null;
          });
        }
      }
    },
    [weekDefinition, invalidOrOutOfRange]
  );

  useEffect(() => {
    onPresetSelect(startCustomPreset, setDateInputs, true, setRangeError, setEndCustomPreset);
  }, [startCustomPreset, onPresetSelect, setDateInputs, setEndCustomPreset, setRangeError]);

  useEffect(() => {
    onPresetSelect(endCustomPreset, setDateInputs, false, setRangeError, setStartCustomPreset);
  }, [endCustomPreset, setDateInputs, setRangeError, setStartCustomPreset, onPresetSelect]);

  return (
    <div className={`DPCustomDatePresets ${disabled ? "disabled" : ""}`}>
      <SingleCustomDatePreset
        key={"singleDatePresetCustomStart"}
        label="Start"
        customPresetState={startCustomPreset}
        setCustomPresetState={setStartCustomPreset}
        disabled={disabled}
        rangeError={rangeError === "start"}
      />
      <SingleCustomDatePreset
        key={"singleDatePresetCustomEnd"}
        label="End"
        customPresetState={endCustomPreset}
        setCustomPresetState={setEndCustomPreset}
        disabled={disabled}
        rangeError={rangeError === "end"}
      />
    </div>
  );
};

// import React, { useState, useEffect, useContext } from "react";
import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Button, ButtonType } from "../../Components";
// import { awaitJSON, MetaLambdaFetch } from "../../utils/fetch-utils";
// import { useCompanyInfo } from "../../redux/company";
// import { useSetError } from "../../redux/modals";
import MetaBuyingAd from "./MetaBuyingAd";
import MetaBuyingAdSet from "./MetaBuyingAdSet";
import MetaBuyingCampaign from "./MetaBuyingCampaign";
import MetaBuyingCreationPrompt from "./MetaBuyingCreationPrompt";
import MetaBuyingReviewModal from "./MetaBuyingReviewModal";
import * as R from "ramda";
import "../MetaBuying.scss";
import "./MetaBuyingCreate.scss";

interface MetaBuyingCreateProps {
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  setSelectedAdAccount: React.Dispatch<
    React.SetStateAction<{
      account_id: string;
      account_name: string;
      business_manager: string;
    }>
  >;
  adAccountOptions: {
    account_id: string;
    account_name: string;
    business_manager: string;
  }[];
  namingConventions: {
    campaign: string[];
    adset: string[];
    ad: string[];
  };
  client: string;
}

export const MetaBuyingCreate = ({
  selectedAdAccount,
  setSelectedAdAccount,
  adAccountOptions,
  namingConventions,
  client,
}: RouteComponentProps & MetaBuyingCreateProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [creationTabs, setCreationTabs] = useState({
    Campaign: false,
    "Ad Set": false,
    Ad: false,
  });

  const [selectionsSubmitted, setSelectionsSubmitted] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const constructNameUsingConvention = (
    form: Record<string, string | number>,
    namingConvention: string[]
  ): string => namingConvention.map(element => form[element]).join("_");

  const CreationTab = (): JSX.Element => {
    switch (tabIndex) {
      case 1:
        return (
          <MetaBuyingAdSet
            namingConvention={namingConventions.adset}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={() => setShowReviewModal(true)}
          />
        );
      case 2:
        return (
          <MetaBuyingAd
            namingConvention={namingConventions.ad}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={() => setShowReviewModal(true)}
          />
        );
      default:
        return (
          <MetaBuyingCampaign
            client={client}
            namingConvention={namingConventions.campaign}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={() => setShowReviewModal(true)}
          />
        );
    }
  };

  return (
    <div className="metaBuyingCreate">
      {selectionsSubmitted ? (
        <>
          <div className="metaBuyingTabs">
            {R.keys(R.filter(tab => tab, creationTabs)).map((tab, index) => (
              <Button
                key={tab}
                type={ButtonType.EMPTY}
                className={tabIndex === index ? "selected" : ""}
                onClick={() => setTabIndex(index)}
              >
                {index + 1}. {tab}
              </Button>
            ))}
          </div>
          <CreationTab />
        </>
      ) : (
        <MetaBuyingCreationPrompt
          adAccountOptions={adAccountOptions}
          selectedAdAccount={selectedAdAccount}
          setSelectedAdAccount={setSelectedAdAccount}
          creationTabs={creationTabs}
          setCreationTabs={setCreationTabs}
          setSelectionsSubmitted={setSelectionsSubmitted}
        />
      )}
      <MetaBuyingReviewModal
        selectedAdAccount={selectedAdAccount}
        client={client}
        show={showReviewModal}
        closeModal={() => setShowReviewModal(!showReviewModal)}
        creationTabs={Object.keys(creationTabs).filter(tab => creationTabs[tab])}
        namingConventions={namingConventions}
        constructNameUsingConvention={constructNameUsingConvention}
      />
    </div>
  );
};

export default MetaBuyingCreate;

import React, { useState, useEffect } from "react";
import { formatInTimeZone } from "date-fns-tz";
import {
  CURRENT_WEEK_START,
  DATE_FORMAT,
  PRETTY_DEMO_NAMES,
} from "../LinearBuying/linearBuyingConstants";
import * as R from "ramda";
import { useSetError } from "../redux/modals";
import * as Dfns from "date-fns/fp";
import { Page, DateRangePicker, BPMTable, Spinner, BPMToggleButton } from "../Components";
import { useCompanyInfo } from "../redux/company";
import { LinearBuyingLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { Form } from "react-bootstrap";
import { formatMoney, formatNumberAsInt } from "../utils/format-utils";
import { time24hr } from "../LinearBuying/linearBuyingUtils";
import "./LinearUpfrontReconciliation.scss";

const TIMESTAMP_FORMAT = "HH:mm";

export const CUTOFF_DATE_PAST = R.pipe(
  Dfns.parseISO,
  Dfns.subMonths(6),
  Dfns.format(DATE_FORMAT)
)(CURRENT_WEEK_START);
export const CUTOFF_DATE_FUTURE = R.pipe(
  Dfns.parseISO,
  Dfns.subDays(7),
  Dfns.format(DATE_FORMAT)
)(CURRENT_WEEK_START);

const LinearUpfrontReconciliation: React.FC = () => {
  const companyInfo = useCompanyInfo();
  const setError = useSetError();
  const company = companyInfo.cid;
  const [start, setStart] = useState(
    R.pipe(Dfns.parseISO, Dfns.subDays(7), Dfns.format(DATE_FORMAT))(CURRENT_WEEK_START)
  );
  const [end, setEnd] = useState(
    R.pipe(Dfns.parseISO, Dfns.format(DATE_FORMAT))(CURRENT_WEEK_START)
  );

  const [upfrontTableData, setUpfrontTableData] = useState();
  const [isEquivalized, setIsEquivalized] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const res = await LinearBuyingLambdaFetch("/get_linear_upfront_recon", {
          params: {
            company: company,
            weekStart: start,
            weekEnd: end,
          },
        });
        const plansData = await awaitJSON(res);
        setUpfrontTableData(plansData);
      } catch (e) {
        setError({
          message: `Failed to get data for ${start} - ${end}. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [company, start, end, setError]);

  //format post log date to time//
  const formatTimestampToTime = (timestamp: string): string => {
    try {
      let date = new Date(timestamp);
      let formattedDate = formatInTimeZone(date, "America/New_York", TIMESTAMP_FORMAT);
      return formattedDate;
    } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid Date";
    }
  };

  //format post log date to time//
  const formatDemo = (demo: string): string => {
    try {
      const formattedDemo = PRETTY_DEMO_NAMES[demo];
      return formattedDemo;
    } catch (error) {
      console.error("Error parsing demo:", error);
      return "Invalid demo";
    }
  };

  return (
    <Page
      title="Linear Upfront Reconciliation"
      pageType="LinearUpfrontReconciliation"
      minHeight="300"
      actions={
        <div className="linearUpfrontActions">
          <Form.Group>
            <BPMToggleButton
              options={[
                {
                  key: "Equivalized",
                },
                {
                  key: "Unequivalized",
                },
              ]}
              selectedOption={isEquivalized ? "Equivalized" : "Unequivalized"}
              onChange={key => setIsEquivalized(key === "Equivalized")}
            />
            <DateRangePicker
              isOutsideRange={date => date < CUTOFF_DATE_PAST || date > CUTOFF_DATE_FUTURE}
              startDate={start}
              endDate={end}
              startDateId="tableDataStartDate"
              endDateId="tableDataEndDate"
              mondayOnly={true}
              onChange={({ startDate, endDate }) => {
                if (startDate && endDate) {
                  setStart(startDate);
                  setEnd(endDate);
                }
              }}
            />
          </Form.Group>
        </div>
      }
    >
      {upfrontTableData ? (
        <div>
          <div className="newTableView">
            <BPMTable
              noRowsRenderer={() => (
                <div className="noRows">
                  There are no orders for the selected week(s). Start by adding a row or importing a
                  buy.
                </div>
              )}
              data={upfrontTableData}
              alternateColors
              headerHeight={35}
              rowHeight={35}
              filterBar={true}
              headers={[
                {
                  label: "Week",
                  name: "week",
                  sortPriority: 0,
                  sortAscending: true,
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Network",
                  name: "network",
                  sortPriority: 2,
                  sortAscending: true,
                  flex: 1,
                  minFlexWidth: 100,
                },
                {
                  label: "Avail",
                  name: "avail",
                  minFlexWidth: 85,
                },
                {
                  label: "Rotation",
                  name: "rotation",
                  sortPriority: 2,
                  sortAscending: true,
                  flex: 1,
                  minFlexWidth: 300,
                },
                {
                  label: "Length",
                  name: "length",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Program",
                  name: "program",
                  flex: 1,
                  minFlexWidth: 450,
                },
                {
                  label: "Cost",
                  name: "cost",
                  flex: 1,
                  minFlexWidth: 200,
                  renderer: row => formatMoney(row.cost, 0),
                },
                {
                  label: "Start Time",
                  name: "daypart_start",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => time24hr(row.daypart_start),
                },
                {
                  label: "End Time",
                  name: "daypart_end",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => time24hr(row.daypart_end),
                },
                {
                  label: "Post Log Date",
                  name: "air_date",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Post Log Time (EST)",
                  name: "airdate_timestamp",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => formatTimestampToTime(row.airdate_timestamp),
                },
                {
                  label: "Days of Week",
                  name: "dow",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "ISCI",
                  name: "isci",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Media Classification",
                  name: "media_classification",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Ordered Measurement",
                  name: "linear_ordered_measurement",
                  flex: 1,
                  minFlexWidth: 150,
                },
                {
                  label: "Ordered Demo",
                  name: "linear_ordered_demo",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => formatDemo(row.linear_ordered_demo),
                },
                {
                  label: "Ordered Impressions",
                  name: "linear_ordered_impressions",
                  flex: 1,
                  minFlexWidth: 250,
                  renderer: row => formatNumberAsInt(row.linear_ordered_impressions),
                },
                {
                  label: "Ordered Impressions Delivered",
                  name: "ordered_actuals",
                  flex: 1,
                  minFlexWidth: 250,
                  renderer: isEquivalized
                    ? row => formatNumberAsInt(row.ordered_actuals_e)
                    : row => formatNumberAsInt(row.ordered_actuals_non_e),
                },
                {
                  label: "Difference",
                  name: "difference",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => {
                    const difference = isEquivalized
                      ? row.ordered_actuals_e - row.linear_ordered_impressions
                      : row.ordered_actuals_non_e - row.row.linear_ordered_impressions;
                    const formattedDifference = formatNumberAsInt(difference);
                    const color = difference >= 0 ? "green" : "red";
                    return <span style={{ color: color }}>{formattedDifference}</span>;
                  },
                },
                {
                  label: "Secondary Demo",
                  name: "linear_override_demo",
                  flex: 1,
                  minFlexWidth: 200,
                  renderer: row => formatDemo(row.linear_override_demo),
                },
                {
                  label: "Secondary Measurement",
                  name: "linear_override_measurement",
                  flex: 1,
                  minFlexWidth: 200,
                },
                {
                  label: "Secondary Impressions Tracked",
                  name: "linear_override_impressions",
                  flex: 1,
                  minFlexWidth: 250,
                  renderer: row => formatNumberAsInt(row.linear_override_impressions),
                },
                {
                  label: "Secondary Impressions Delivered",
                  name: "override_actuals",
                  flex: 1,
                  minFlexWidth: 250,
                  renderer: isEquivalized
                    ? row => formatNumberAsInt(row.override_actuals_e)
                    : row => formatNumberAsInt(row.override_actuals_non_e),
                },
                {
                  label: "Difference",
                  name: "difference",
                  flex: 1,
                  minFlexWidth: 150,
                  renderer: row => {
                    const difference = isEquivalized
                      ? row.override_actuals_e - row.linear_override_impressions
                      : row.override_actuals_non_e - row.linear_override_impressions;
                    const formattedDifference = formatNumberAsInt(difference);
                    const color = difference >= 0 ? "green" : "red";
                    return <span style={{ color: color }}>{formattedDifference}</span>;
                  },
                },
                {
                  label: "Notes",
                  name: "notes",
                  flex: 1,
                  minFlexWidth: 150,
                },
              ]}
            />
          </div>
        </div>
      ) : (
        <Spinner size={100} />
      )}
    </Page>
  );
};

export default LinearUpfrontReconciliation;

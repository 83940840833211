import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import "./MetaBuyingDrafts.scss";
import MetaTableWidget from "./Components/MetaTableWidget";
import { BPMTable, Button, ButtonType, CheckBox, Header, ToggleSwitch } from "../../Components";
import { useStateFunction } from "../../utils/hooks/useData";
import {
  MdCheck,
  MdFacebook,
  MdMoreVert,
  MdNotificationImportant,
  MdRemoveRedEye,
} from "react-icons/md";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import {
  AdAccountInfo,
  MetaBuyingTableRow,
  CampaignRow,
  AdSetRow,
  AdRow,
  MBApprovalStage,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import * as R from "ramda";
import {
  useFilterBar,
  useGetButtonPosition,
  useSelectRow,
  useTableHeadersRenderer,
  useViewableDataMap,
} from "./MetaBuyingUtils";
import MoreActionsMenu from "./Components/MoreActionsMenu";
import { createPortal } from "react-dom";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { MetaLambdaFetch } from "../../utils/fetch-utils";
import { fullNameSelector, emailSelector } from "../../redux/user";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";

interface MetaBuyingDraftsProps {
  isInternal: boolean;
  selectedAdAccount: AdAccountInfo;
  setSelectedAdAccount: React.Dispatch<React.SetStateAction<AdAccountInfo>>;
  adAccountOptions: AdAccountInfo[];
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
}

const MetaBuyingDrafts: React.FC<MetaBuyingDraftsProps & RouteComponentProps> = React.memo(
  ({
    isInternal,
    selectedAdAccount,
    setSelectedAdAccount,
    adAccountOptions,
    campaignRows,
    adSetRows,
    adRows,
  }: MetaBuyingDraftsProps & RouteComponentProps): JSX.Element => {
    const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);
    const [selectedLevel, setSelectedLevel] = useState<string>(isInternal ? "campaign" : "ad");
    const [selectedRows, setSelectedRows] = useState<
      Record<string, Record<string, MetaBuyingTableRow>>
    >({
      campaign: {},
      adset: {},
      ad: {},
    });
    const [selectAll, setSelectAll] = useState<Record<string, boolean>>({
      campaign: false,
      adset: false,
      ad: false,
    });

    // Map of each level to its rows. Data is filtered based on selected rows.
    const viewableDataMap: Record<string, MetaBuyingTableRow[]> = useViewableDataMap({
      campaignRows,
      adSetRows,
      adRows,
      selectedRows,
    });

    // Update selectAll state when viewableDataMap changes
    useEffect(() => {
      for (let level of Object.keys(selectedRows)) {
        const currObj = selectedRows[level];
        if (Object.keys(currObj).length === viewableDataMap[level].length) {
          setSelectAll(current => ({ ...current, [level]: true }));
        } else {
          setSelectAll(current => ({ ...current, [level]: false }));
        }
      }
    }, [selectedRows, viewableDataMap]);

    // Rows to display in the table based on selected level
    const draftsTableData: MetaBuyingTableRow[] = useMemo(() => {
      if (selectedLevel === "ad") {
        return viewableDataMap.ad;
      } else if (selectedLevel === "adset") {
        return viewableDataMap.adset;
      } else {
        return viewableDataMap.campaign;
      }
    }, [selectedLevel, viewableDataMap]);

    // Apply filter bar to table data
    const filteredData = useMemo(() => {
      return draftsTableData?.filter(filter) || [];
    }, [draftsTableData, filter]);

    const selectRow = useSelectRow({
      setSelectedRows,
      selectedLevel,
    });

    const buttonRefs = useRef({}); // Object to hold refs for each row
    const getButtonPosition = useGetButtonPosition({ buttonRefs, selectedLevel });
    const menuRef: React.LegacyRef<HTMLDivElement> | undefined = useRef(null);
    const [activeActionMenuRowID, setActiveActionMenuRowID] = useState<string>("");
    // Close menu if clicking outside of it
    useEffect(() => {
      const handleOutsideClick = event => {
        if (
          menuRef &&
          menuRef.current &&
          !menuRef.current.contains(event.target) &&
          !buttonRefs.current[activeActionMenuRowID]?.contains(event.target)
        ) {
          setActiveActionMenuRowID("");
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [activeActionMenuRowID, menuRef, buttonRefs, selectedRows, selectedLevel]);

    // State to manage client facing alert dialog when making rows client facing
    const [rowIDClientFacingAlertON, setRowIDClientFacingAlertON] = useState<string>("");
    // State to manage client facing alert dialog when making rows not client facing
    const [rowIDClientFacingAlertOFF, setRowIDClientFacingAlertOFF] = useState<string>("");
    // State to manage published alert dialog when publishing rows
    const [rowIDPublishAlert, setRowIDPublishAlert] = useState<string>("");
    // State to manage request approval alert dialog when requesting approval for rows
    const [rowIDRequestApprovalAlert, setRowIDRequestApprovalAlert] = useState<string>("");

    const fullName = useSelector(fullNameSelector);
    const email = useSelector(emailSelector);
    const updateAdApprovalStage = useCallback(
      async (newStage: MBApprovalStage) => {
        console.log("body:", {
          newStage,
          adRows: Object.values(selectedRows.ad),
          requester: `${fullName} (${email})`,
          notes: "Testing",
        });
        await MetaLambdaFetch("/updateAdApprovalStage", {
          method: "POST",
          body: {
            newStage,
            adRows: Object.values(selectedRows.ad) as AdRow[],
            requester: `${fullName} (${email})`,
            notes: "Testing",
          },
        });
      },
      [email, fullName, selectedRows]
    );

    const [notes, setNotes] = useState<string>("");

    const draftsTableHeaders: Header[] = useMemo(() => {
      let headers: Header[] = [
        {
          label: "",
          name: "id",
          width: 40,
          nonInteractive: true,
          renderer: (row: MetaBuyingTableRow) => {
            return (
              <CheckBox
                className="checkboxCell"
                checked={R.has(row.id, selectedRows[selectedLevel])}
                onCheck={() => selectRow(row)}
              />
            );
          },
        },
      ];

      if (!isInternal) {
        headers.push(
          ...[
            {
              label: "Ad",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="adNameCell highlighted">{row.name}</div>;
              },
            },
            {
              label: "Ad Set",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="adSetNameCell">{row.adset_name}</div>;
              },
            },
            {
              label: "Campaign",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="campaignNameCell">{row.campaign_name}</div>;
              },
            },
            {
              label: "Created On",
              name: "created",
              width: 130,
              renderer: (row: MetaBuyingTableRow) => {
                return <div className="createdCell">{row.created}</div>;
              },
            },
            {
              width: 308,
              nonInteractive: true,
              renderer: (row: AdRow) => {
                // TODO: Update to add approval stage logic
                return (
                  <div className="actionsCell">
                    <Button
                      variant={ButtonFrameworkVariant.TRAILING_ICON}
                      icon={<MdRemoveRedEye />}
                      type={ButtonType.OUTLINED}
                      size="sm"
                    >
                      View Ad Preview
                    </Button>
                    <Button
                      className="approveButton"
                      variant={ButtonFrameworkVariant.TRAILING_ICON}
                      icon={<MdCheck />}
                      type={ButtonType.OUTLINED}
                      size="sm"
                    >
                      Approve
                    </Button>
                  </div>
                );
              },
            },
          ]
        );
      } else {
        if (selectedLevel === "ad") {
          headers.push(
            ...[
              {
                label: "Ad",
                flex: 4,
                renderer: (row: AdRow) => {
                  return <div className="adNameCell highlighted">{row.name}</div>;
                },
              },
              {
                label: "Ad Set",
                flex: 4,
                renderer: (row: AdRow) => {
                  return <div className="adSetNameCell">{row.adset_name}</div>;
                },
              },
              {
                label: "Campaign",
                flex: 4,
                renderer: (row: AdRow) => {
                  return <div className="campaignNameCell">{row.campaign_name}</div>;
                },
              },
            ]
          );
        } else if (selectedLevel === "adset") {
          headers.push(
            ...[
              {
                label: "Ad Set",
                flex: 4,
                renderer: (row: AdSetRow) => {
                  return <div className="adSetNameCell highlighted">{row.name}</div>;
                },
              },
              {
                label: "Campaign",
                flex: 4,
                renderer: (row: AdSetRow) => {
                  return <div className="campaignNameCell">{row.campaign_name}</div>;
                },
              },
            ]
          );
        } else {
          headers.push({
            label: "Campaign",
            flex: 4,
            renderer: (row: CampaignRow) => {
              return <div className="campaignNameCell highlighted">{row.name}</div>;
            },
          });
        }

        headers.push(
          ...[
            {
              label: "Created On",
              name: "created",
              width: 130,
              renderer: (row: MetaBuyingTableRow) => {
                return <div className="createdCell">{row.created}</div>;
              },
            },
            {
              label: "Creator",
              name: "lastuser",
              flex: 4,
              renderer: (row: MetaBuyingTableRow) => {
                return <div className="creatorCell">{row.lastuser}</div>;
              },
            },
          ]
        );
        if (selectedLevel === "ad") {
          headers.push(
            {
              label: "Client-Facing",
              width: 130,
              renderer: (row: AdRow) => {
                // TODO: revisit logic to cover edge cases and add logic for updating client_facing
                const alertDialogBody: string[] = Object.values(selectedRows.ad).map(ad => ad.name);

                let toggleOn;
                if (rowIDClientFacingAlertOFF === row.id) {
                  // If we are turning off the row's toggle
                  toggleOn = false;
                } else if (rowIDClientFacingAlertON === row.id) {
                  // If we are turning on the row's toggle
                  toggleOn = true;
                } else if (rowIDClientFacingAlertOFF.length && selectedRows.ad[row.id]) {
                  // If row is selected and we are turning off another row's toggle
                  toggleOn = false;
                } else if (rowIDClientFacingAlertON.length && selectedRows.ad[row.id]) {
                  // If row is selected and we are turning on another row's toggle
                  toggleOn = true;
                } else if (row.client_facing) {
                  toggleOn = true;
                } else {
                  toggleOn = false;
                }

                return (
                  <div className="clientFacingCell">
                    <AlertDialog
                      show={rowIDClientFacingAlertON === row.id}
                      onHide={() => {
                        setRowIDClientFacingAlertON("");
                      }}
                      icon={<MdRemoveRedEye size={72} />}
                      title={"Your client will be able to see the following ad(s):"}
                      body={alertDialogBody.join(", ")}
                      primaryAction={() => {}}
                      primaryButtonLabel="Got it!"
                      secondaryAction={() => {
                        setRowIDClientFacingAlertON("");
                      }}
                      secondaryButtonLabel="Cancel"
                    ></AlertDialog>
                    <AlertDialog
                      show={rowIDClientFacingAlertOFF === row.id}
                      onHide={() => {
                        setRowIDClientFacingAlertOFF("");
                      }}
                      icon={<MdRemoveRedEye size={72} />}
                      title={"Your client will no longer be able to see the following ad(s):"}
                      body={alertDialogBody.join(", ")}
                      primaryAction={() => {}}
                      primaryButtonLabel="Got it!"
                      secondaryAction={() => {
                        setRowIDClientFacingAlertOFF("");
                      }}
                      secondaryButtonLabel="Cancel"
                    ></AlertDialog>
                    <ToggleSwitch
                      label={""}
                      labelPosition="none"
                      checked={toggleOn}
                      onChange={() => {
                        if (row.client_facing) {
                          setRowIDClientFacingAlertOFF(row.id);
                        } else {
                          setRowIDClientFacingAlertON(row.id);
                        }
                        selectRow(row, false);
                      }}
                    />
                  </div>
                );
              },
            },
            {
              label: "Approval Status",
              width: 175,
              renderer: (row: AdRow) => {
                if (row.approval_stage === "NONE" || row.approval_stage === "CHANGES_REQUESTED") {
                  const alertDialogBody: string[] = Object.values(selectedRows[selectedLevel]).map(
                    obj => obj.name
                  );
                  return (
                    <div className="requestApprovalCell">
                      <AlertDialog
                        show={rowIDRequestApprovalAlert === row.id}
                        onHide={() => setRowIDRequestApprovalAlert("")}
                        icon={<MdNotificationImportant size={72} />}
                        title={"Request approval for the following ad(s):"}
                        body={
                          <div>
                            <div>{alertDialogBody.join(", ")}</div>
                            <Form.Group>
                              <Form.Label>{"Notes (optional):"}</Form.Label>
                              <Form.Control
                                value={notes}
                                placeholder="Anything you'd like to say?"
                                onChange={e => {
                                  console.log("e.target.value", e.target.value);
                                  setNotes(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </div>
                        }
                        primaryAction={() => updateAdApprovalStage(MBApprovalStage.IN_REVIEW)}
                        primaryButtonLabel="Got it!"
                        secondaryAction={() => {
                          setRowIDRequestApprovalAlert("");
                        }}
                      />
                      <Button
                        disabled={!row.client_facing}
                        type={ButtonType.FILLED}
                        design="secondary"
                        size="sm"
                        onClick={() => {
                          setRowIDRequestApprovalAlert(row.id);
                          selectRow(row, false);
                        }}
                      >
                        Request Approval
                      </Button>
                    </div>
                  );
                } else if (row.approval_stage === "IN_REVIEW") {
                  return <div className="pendingCell">Pending</div>;
                } else {
                  return <div className="approvedCell">Approved</div>;
                }
              },
            }
          );
        }

        headers.push({
          width: 247,
          nonInteractive: true,
          renderer: (row: MetaBuyingTableRow) => {
            const buttonPosition = getButtonPosition(row.id);
            // TODO: revisit logic to cover edge cases and add logic for publishing
            const alertDialogBody: string[] = Object.values(selectedRows[selectedLevel]).map(
              obj => obj.name
            );

            return (
              <div className="actionsCell">
                <AlertDialog
                  show={row.id === rowIDPublishAlert}
                  onHide={() => setRowIDPublishAlert("")}
                  icon={<MdFacebook size={72} />}
                  title={"Will publish to Meta as paused!"}
                  body={alertDialogBody.join(", ")}
                  primaryAction={() => {}}
                  primaryButtonLabel="Got it!"
                  secondaryAction={() => {
                    setRowIDPublishAlert("");
                  }}
                  secondaryButtonLabel="Cancel"
                />
                <Button
                  variant={ButtonFrameworkVariant.TRAILING_ICON}
                  icon={<MdFacebook />}
                  size="sm"
                  type={ButtonType.FILLED}
                  onClick={() => {
                    setRowIDPublishAlert(row.id);
                    selectRow(row, false);
                  }}
                >
                  Publish As Paused
                </Button>
                <div className="moreActionsContainer">
                  <Button
                    ref={el => {
                      buttonRefs.current[row.id] = el;
                    }} // Assign ref for each button
                    variant={ButtonFrameworkVariant.ICON_ONLY}
                    type={ButtonType.FILLED}
                    icon={<MdMoreVert />}
                    size="sm"
                    onClick={() => {
                      selectRow(row, false);
                      setActiveActionMenuRowID(activeActionMenuRowID === row.id ? "" : row.id);
                    }}
                  />
                  {activeActionMenuRowID === row.id &&
                    createPortal(
                      <div
                        style={{
                          borderRadius: "6px",
                          backgroundColor: "white",
                          position: "fixed",
                          top: buttonPosition.top,
                          left: buttonPosition.left,
                          display: "flex",
                          zIndex: 1000,
                        }}
                        ref={menuRef}
                      >
                        <MoreActionsMenu
                          selectedRows={selectedRows}
                          selectedLevel={selectedLevel}
                          tab="drafts"
                        />
                      </div>,
                      document.body
                    )}
                </div>
              </div>
            );
          },
        });
      }

      return headers;
    }, [
      activeActionMenuRowID,
      getButtonPosition,
      isInternal,
      notes,
      rowIDClientFacingAlertOFF,
      rowIDClientFacingAlertON,
      rowIDPublishAlert,
      rowIDRequestApprovalAlert,
      selectRow,
      selectedLevel,
      selectedRows,
      updateAdApprovalStage,
    ]);

    const draftsTableHeadersRenderer = useTableHeadersRenderer({
      filteredData,
      selectAll,
      setSelectAll,
      selectedRows,
      setSelectedRows,
      selectedLevel,
    });

    const draftsFilterBar = useFilterBar({
      pageTab: "drafts",
      tableData: draftsTableData,
      isInternal,
      selectedLevel,
      setFilter,
    });

    const draftsTable = useMemo(() => {
      return (
        <BPMTable
          className="draftsTableExternal"
          alternateColors={false}
          data={filteredData}
          headers={draftsTableHeaders}
          headersRenderer={draftsTableHeadersRenderer}
          filterBar={false}
          noRowsRenderer={() => <div>No rows to show.</div>}
        ></BPMTable>
      );
    }, [draftsTableHeaders, draftsTableHeadersRenderer, filteredData]);

    return (
      <div className="metaBuyingDrafts">
        <MetaTableWidget
          isInternal={isInternal}
          title="Drafts"
          selectedAdAccount={selectedAdAccount}
          setSelectedAdAccount={setSelectedAdAccount}
          adAccountOptions={adAccountOptions}
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
          filterBar={draftsFilterBar}
          tableComponent={draftsTable}
          selectedRows={selectedRows}
        />
      </div>
    );
  }
);

export default MetaBuyingDrafts;

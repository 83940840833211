import "./ChannelPlatformTacticBrand.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import { Dropdown, DropdownToggleType } from "../../Components/Dropdown";
import { DownloadDropdown } from "../../Components/DownloadDropdown";
import AreaChart from "../../Components/Charts/AreaChart";
import MoreInfo from "../MoreInfo";
import * as R from "ramda";
import {
  cptbAreaData,
  cptbSnapShotData,
  cptbTwoStageSnapData,
  calculateTTMOverallRoasCpa,
} from "./DataFormatters";
import StackedAreaChart from "./StackedAreaChart";
import LineChart from "../../Components/Charts/LineChart";
import { SnapshotChart } from "../../Components/SnapshotChart";
import { DatePicker } from "../../Components/DatePicker";
import * as Dfns from "date-fns/fp";
import { formatMoney } from "../../utils/format-utils";
import {
  currencyFormatter,
  snapShotDataSort,
  capitalizeWords,
  CONVERSION_FORMATTER_TYPE,
} from "../MMMUtils";
import { downloadPNG, exportToExcel } from "../../utils/download-utils";
import ToggleSwitchWithIcon from "../../Components/ToggleSwitchWithIcon/ToggleSwitchWithIcon";
import { MdOutlineWarningAmber } from "react-icons/md";
import useLocation from "../../utils/hooks/useLocation";
import { TEST_COMPANIES } from "@blisspointmedia/bpm-types/dist/TestCompanies";
import { TwoStageSpendandEffectContainer } from "./TwoStageSpendAndEffectContainer";

const CONVERSION_TYPE_STRING = {
  conversion: "KPI Volume",
  revenue: "Revenue",
};

const DEFAULT_SORT_SELECTIONS = {
  spendAndEffectShare: "Spend: High to low",
  revenue: "Highest to lowest",
  costPerAcquisition: "Highest to lowest",
  roas: "Highest to lowest",
};

const DEFAULT_SORT_OPTIONS = [
  { value: "Alphabetical" },
  { value: "Highest to lowest" },
  { value: "Lowest to highest" },
];

export type DateGroupingKey = "Day" | "Week" | "Month" | "Quarter" | "Year";
export interface DateGroupingOption {
  value: DateGroupingKey;
  label: string;
}
export const DATE_GROUPING_OPTIONS: DateGroupingOption[] = [
  { value: "Week", label: "Weekly" },
  { value: "Month", label: "Monthly" },
  { value: "Quarter", label: "Quarterly" },
];

interface ChannelPlatformTacticBrandProps {
  cptbData: any[];
  disabledPlatform: boolean | undefined;
  groupBy: string;
  kpiType: string;
  setOverallRoasCpa: React.Dispatch<React.SetStateAction<number | undefined>>;
  showActualizedData: boolean;
  twoStageData?: any[];
  kpi: string;
}

const HARD_CODED_COLOR = "#cbd2e1";
const HARD_CODED_COLOR_NAME = "Covariates";

/**
 * Get default date ranges for date picker.
 */
export const getDefaultDates = (
  data: any[]
): {
  start: string;
  end: string;
} => {
  const start = data[0].date;
  const end = data[data.length - 1].date;
  return { start, end };
};

const WIDGET_SUBHEADER = {
  revenue: (
    <>
      Attribution of revenue to each investment based on model parameter estimates.
      <MoreInfo rightLabel="More info" size="sm">
        Calculated for each point in time as (level of media investment at that point in time) x
        (model parameter estimates for that media input).
      </MoreInfo>
    </>
  ),
  costPerAcquisition: (
    <>
      Attribution of cost-pers to each investment based on model parameter estimates.
      <MoreInfo rightLabel="More info" size="sm">
        Calculated for each point in time as (level of media investment at that point in time) /
        ((level of media investment at that point in time) x (model parameter estimates for that
        media input)).
      </MoreInfo>
    </>
  ),
  roas: (
    <>
      Attribution of ROAS to each investment based on model parameter estimates.
      <MoreInfo rightLabel="More info" size="sm">
        Calculated for each point in time as ((level of media investment at that point in time) x
        (model parameter estimates for that media input)) / (level of media investment at that point
        in time).
      </MoreInfo>
    </>
  ),
  spendAndEffectShare: (
    <>
      Attribution of spend and effect share to each investment based on model parameter estimates.
      <MoreInfo rightLabel="More info" size="sm">
        Effect share calculated for each point in time as ((level of media investment at that point
        in time) x (model parameter estimates for that media input)) / (total KPI volume).
      </MoreInfo>
    </>
  ),
};

const ChannelPlatformTacticBrand: React.FC<ChannelPlatformTacticBrandProps> = ({
  cptbData,
  disabledPlatform,
  groupBy,
  kpiType,
  setOverallRoasCpa,
  showActualizedData,
  twoStageData,
  kpi,
}) => {
  const [investment, setInvestment] = useState("spendAndEffectShare");
  const [dateGrouping, setDateGrouping] = useState("Week");
  const [dates, setDates] = useState({ start: "", end: "" });
  const [covariates, setCovariates] = useState(false);
  const [areaChartData, setAreaChartData] = useState({} as any);
  const [areaChart2Data, setAreaChart2Data] = useState({} as any);
  const [snapChartData, setSnapChartData] = useState([] as any);
  const [toggleLabel, setToggleLabel] = useState(false);
  const [snapChartSortValue, setSnapChartSortValue] = useState("Spend: High to low");
  const [absoluteOrRelative, setAbsoluteOrRelative] = useState("Absolute");
  const [twoStageBarData, setTwoStageBarData] = useState<any[]>();
  const { company } = useLocation();
  const companyEqualsTest = TEST_COMPANIES.includes(company);

  const SNAPCHART_TITLE = {
    spendAndEffectShare: "Spend and Effect Share",
    revenue: `${CONVERSION_TYPE_STRING[kpiType]} Decomposition Share`,
    costPerAcquisition: "Average CPA",
    roas: "Average ROAS",
  };

  const INVESTMENT_OPTIONS = useMemo(() => {
    return [
      { label: "Spend and Effect Share", value: "spendAndEffectShare", title: "Spend and Effect" },
      {
        label: CONVERSION_TYPE_STRING[kpiType],
        value: "revenue",
        title: `${CONVERSION_TYPE_STRING[kpiType]} Decomposition`,
      },
      {
        label: "Cost Per Acquisition",
        value: "costPerAcquisition",
        title: "Cost Per Acquisition",
      },
      { label: "ROAS", value: "roas", title: "ROAS" },
    ];
  }, [kpiType]);

  const SPEND_AND_EFFECT_SORT_OPTIONS = [
    { value: "Alphabetical" },
    { value: "Spend to effect ratio" },
    { value: "Spend: High to low" },
    { value: "Spend: Low to high" },
    { value: `${CONVERSION_TYPE_STRING[kpiType]}: High to low` },
    { value: `${CONVERSION_TYPE_STRING[kpiType]}: Low to high` },
  ] as const;

  const exportFormattedData = useMemo(() => {
    if (!showActualizedData && cptbData && !R.isEmpty(snapChartData)) {
      const refreshDate = cptbData.length ? cptbData[0].refresh_date || "" : "";

      return snapChartData.barData.map(obj => {
        const sharedValues = {
          company: company,
          kpi: kpi,
          refresh_date: refreshDate,
          media_name: obj.name,
        };
        switch (investment) {
          case "revenue":
            return {
              ...sharedValues,
              decomposition_percent: obj.value,
              decomposition_value: obj.labelRawNumber,
            };
          case "costPerAcquisition":
            return {
              ...sharedValues,
              average_cpa: obj.value,
              efficiency_ratio_to_overall_cpa: obj.labelRawNumber,
            };
          case "roas":
            return {
              ...sharedValues,
              average_roas: obj.value,
              efficiency_ratio_to_overall_roas: obj.labelRawNumber,
            };
          default:
            return {
              ...sharedValues,
              spend_share_percent: obj.value,
              effect_share_percent: obj.value2,
              spend_volume: obj.spend_volume,
              effect_volume: obj.effect_volume,
            };
        }
      });
    } else if (showActualizedData && twoStageData && twoStageBarData) {
      const refreshDate = twoStageData.length ? twoStageData[0].refresh_date || "" : "";
      // todo: get two stage export plan
      return twoStageBarData.map(obj => ({
        company: company,
        kpi: kpi,
        refresh_date: refreshDate,
        media_name: obj.name,
        spend_share_percent: obj.value,
        effect_share_percent: obj.value2,
        spend_volume: obj.label,
        effect_volume: obj.label2.bottom,
      }));
    } else {
      return [];
    }
  }, [
    showActualizedData,
    snapChartData,
    twoStageBarData,
    company,
    kpi,
    cptbData,
    twoStageData,
    investment,
  ]);

  useEffect(() => {
    if (twoStageData) {
      if (dates.start === "" || dates.end === "") {
        return setDates(getDefaultDates(twoStageData));
      }

      if (dates.start.length && dates.end.length) {
        const dateFilteredData = twoStageData.filter(item => {
          const itemDate = new Date((item as any).date);
          return itemDate >= new Date(dates.start) && itemDate <= new Date(dates.end);
        });

        const formattedData = cptbTwoStageSnapData(
          dateFilteredData,
          disabledPlatform,
          CONVERSION_TYPE_STRING[kpiType],
          kpiType,
          covariates
        );
        setTwoStageBarData(snapShotDataSort(formattedData.barData, snapChartSortValue));
      }
    }
  }, [
    twoStageData,
    disabledPlatform,
    kpiType,
    snapChartSortValue,
    showActualizedData,
    dates,
    covariates,
  ]);

  useEffect(() => {
    if (showActualizedData) {
      setTwoStageBarData(d => snapShotDataSort(d || [], snapChartSortValue));
    }
  }, [snapChartSortValue, showActualizedData]);

  useEffect(() => {
    let chartData;

    if (!R.isEmpty(cptbData) && (dates.start === "" || dates.end === "")) {
      return setDates(getDefaultDates(cptbData));
    }

    if (!R.isEmpty(cptbData) && dates.start !== "" && dates.end !== "") {
      let dateRangeFilteredData = R.filter(item => {
        const itemDate = new Date((item as any).date);
        return itemDate >= new Date(dates.start) && itemDate <= new Date(dates.end);
      })(cptbData);

      dateRangeFilteredData = !covariates
        ? R.filter(R.compose(R.not, R.propEq("name", "Covariates")))(dateRangeFilteredData)
        : dateRangeFilteredData;

      switch (investment) {
        case "revenue":
          chartData = cptbAreaData({
            data: dateRangeFilteredData,
            data1Key: "adjusted_outcome_to_observed",
            groupBy,
            dateGrouping,
            disabledPlatform,
            relative: absoluteOrRelative === "Relative",
            companyEqualsTest,
          });
          break;
        case "costPerAcquisition":
          chartData = cptbAreaData({
            data: dateRangeFilteredData,
            data1Key: "raw_spend",
            groupBy,
            dateGrouping,
            data2Key: "predicted_outcome",
            disabledPlatform,
            companyEqualsTest,
          });
          break;
        case "roas":
          chartData = cptbAreaData({
            data: dateRangeFilteredData,
            data1Key: "predicted_outcome",
            groupBy,
            dateGrouping,
            data2Key: "raw_spend",
            disabledPlatform,
            companyEqualsTest,
          });
          break;
        default:
          // spend and effect
          chartData = cptbAreaData({
            data: dateRangeFilteredData,
            data1Key: "raw_spend",
            groupBy,
            dateGrouping,
            disabledPlatform,
            relative: absoluteOrRelative === "Relative",
            companyEqualsTest,
          });
          const ac2Data = cptbAreaData({
            data: dateRangeFilteredData,
            data1Key: "adjusted_outcome_to_observed",
            groupBy,
            dateGrouping,
            disabledPlatform,
            relative: absoluteOrRelative === "Relative",
            companyEqualsTest,
          });
          setAreaChart2Data(ac2Data);
          break;
      }

      const snapData = cptbSnapShotData({
        data: dateRangeFilteredData,
        groupBy,
        type: investment,
        disabledPlatform,
        companyEqualsTest,
        conversionTypeString: CONVERSION_TYPE_STRING[kpiType],
        kpiType,
      });

      const newestDate = new Date(dates.end);
      const TTMsDate = newestDate.setFullYear(newestDate.getFullYear() - 1);

      const TTMData =
        Date.parse(dates.start) < TTMsDate
          ? dateRangeFilteredData.filter(item => Date.parse(item.date) > TTMsDate)
          : dateRangeFilteredData;
      const TTMOverallRoasCpa = calculateTTMOverallRoasCpa(TTMData, kpiType);
      setOverallRoasCpa(TTMOverallRoasCpa);

      snapData.barData = snapShotDataSort(snapData.barData, snapChartSortValue);
      setSnapChartData(snapData);
      setAreaChartData(chartData);
    }
  }, [
    absoluteOrRelative,
    covariates,
    cptbData,
    dates,
    dateGrouping,
    disabledPlatform,
    investment,
    kpiType,
    groupBy,
    snapChartSortValue,
    company,
    companyEqualsTest,
    setOverallRoasCpa,
  ]);

  useEffect(() => {
    setInvestment("spendAndEffectShare");
    setSnapChartSortValue("Spend: High to low");
  }, [kpiType]);

  // if kpiType is conversion, remove roas
  const buildInvestmentOptions = () => {
    return INVESTMENT_OPTIONS.filter(item => {
      return (
        !(kpiType === "conversion" && item.value === "roas") &&
        !(kpiType === "revenue" && item.value === "costPerAcquisition")
      );
    });
  };

  const revenueAbsoluteRelativeFormatter = useCallback(
    val => {
      if (absoluteOrRelative === "Absolute") {
        return kpiType === "conversion"
          ? CONVERSION_FORMATTER_TYPE[kpiType].format(val, 0)
          : val < 1000
          ? CONVERSION_FORMATTER_TYPE[kpiType].format(val, 0)
          : CONVERSION_FORMATTER_TYPE[kpiType].format(val, 1);
      } else {
        return absoluteOrRelative === "Relative" ? `${val.toFixed(0)}%` : "";
      }
    },
    [absoluteOrRelative, kpiType]
  );
  const spendAbsoluteRelativeFormatter = useCallback(
    val => {
      if (absoluteOrRelative === "Absolute") {
        return val < 1000 ? currencyFormatter.format(val, 0) : currencyFormatter.format(val, 1);
      } else {
        return absoluteOrRelative === "Relative" ? `${val.toFixed(0)}%` : "";
      }
    },
    [absoluteOrRelative]
  );

  const xAxisTickFormatter = useCallback(
    // this isn't doing anything now, we should agree on how we display dates by grouping
    // and then move to a util and change it everywhere
    (val: string) => {
      return dateGrouping === "Week"
        ? Dfns.format("M/dd/yy", new Date(`${val}T00:00:00`))
        : Dfns.format("M/dd/yy", new Date(`${val}T00:00:00`));
    },
    [dateGrouping]
  );

  const buildRightChart = () => {
    switch (investment) {
      case "revenue":
        return (
          <AreaChart
            areas={areaChartData.areas}
            data={areaChartData.newD}
            dateGrouping={dateGrouping}
            hardCodedColorName={HARD_CODED_COLOR_NAME}
            hardCodedColor={HARD_CODED_COLOR}
            incrementality={true}
            groupedAreas={areaChartData.groupedAreas}
            tooltipFormatter={val => revenueAbsoluteRelativeFormatter(val)}
            usePercentageYAxis={absoluteOrRelative === "Relative"}
            xAxisDataKey="date"
            xAxisTickFormatter={val => xAxisTickFormatter(val)}
            yAxisTickFormatter={val => `${CONVERSION_FORMATTER_TYPE[kpiType].format(val, 0)}`}
          />
        );
      case "costPerAcquisition":
        return (
          <LineChart
            data={areaChartData?.newD}
            dateGrouping={dateGrouping}
            colorMappings={[{ name: HARD_CODED_COLOR_NAME, color: HARD_CODED_COLOR }]}
            groupedAreas={areaChartData.groupedAreas}
            lineDataKeys={areaChartData?.areas}
            tooltipLabelFormatter={val => (val ? capitalizeWords(val) : val)}
            tooltipFormatter={val => {
              if (!val) {
                return val;
              }

              return val > 15
                ? `${currencyFormatter.format(val, 0)}`
                : `${currencyFormatter.format(val, 2)}`;
            }}
            useLeftYAxis
            xAxisDataKey="date"
            xAxisTickFormatter={val => xAxisTickFormatter(val)}
            yAxisTickFormatter={val => `${currencyFormatter.format(val, 0)}`}
          />
        );
      case "roas":
        return (
          <LineChart
            data={areaChartData?.newD}
            dateGrouping={dateGrouping}
            colorMappings={[{ name: HARD_CODED_COLOR_NAME, color: HARD_CODED_COLOR }]}
            groupedAreas={areaChartData.groupedAreas}
            lineDataKeys={areaChartData?.areas}
            tooltipLabelFormatter={val => (val ? capitalizeWords(val) : val)}
            tooltipFormatter={val => (val ? `${val.toFixed(2)}` : val)}
            tooltipShape={"line"}
            useLeftYAxis
            xAxisDataKey="date"
            xAxisTickFormatter={val => xAxisTickFormatter(val)}
          />
        );
      default:
        // spend and effect
        return (
          <div style={{ display: "flex" }}>
            <StackedAreaChart
              areas={areaChartData.areas}
              chart1Title="Spend"
              chart2Title={`Effect (${CONVERSION_TYPE_STRING[kpiType]})`}
              data1={areaChartData.newD}
              data2={areaChart2Data.newD}
              dateGrouping={dateGrouping}
              groupedAreas={areaChartData.groupedAreas}
              hardCodedColorName={HARD_CODED_COLOR_NAME}
              hardCodedColor={HARD_CODED_COLOR}
              xAxisDataKey="date"
              tooltipFormatterTop={val => spendAbsoluteRelativeFormatter(val)}
              tooltipFormatterBottom={val => revenueAbsoluteRelativeFormatter(val)}
              usePercentageYAxis={absoluteOrRelative === "Relative"}
              xAxisTickFormatter={val => xAxisTickFormatter(val)}
              yAxisTickFormatter1={val => `${currencyFormatter.format(val, 0)}`}
              yAxisTickFormatter2={val => `${CONVERSION_FORMATTER_TYPE[kpiType].format(val, 0)}`}
            />
          </div>
        );
    }
  };

  const rightChartTitle = useCallback(() => {
    const option = INVESTMENT_OPTIONS.find(item => item.value === investment);
    return option?.title;
  }, [investment, INVESTMENT_OPTIONS]);

  const snapChartFormatter = useCallback(
    value => {
      switch (investment) {
        case "costPerAcquisition":
          return value === null ? "--" : formatMoney(value);
        case "roas":
          return value === null ? "--" : `${value.toFixed(2)}`;
        case "revenue":
        default:
          return value === null ? "--" : `${value.toFixed(0)}%`;
      }
    },
    [investment]
  );

  const buildSnapshotHeader = useCallback(() => {
    switch (investment) {
      case "costPerAcquisition":
      case "roas":
        return `Overall ${investment === "roas" ? "ROAS" : "CPA"} ${snapChartData.titleData}`;
      default:
        return undefined;
    }
  }, [investment, snapChartData.titleData]);

  const excelDownloadCPTB = useCallback(() => {
    exportToExcel(cptbData, "CPTB_Data");
  }, [cptbData]);

  const pngDownloadRight = useCallback(async () => {
    await downloadPNG(".cptb .right .chartContainer", `${rightChartTitle()}`);
  }, [rightChartTitle]);

  return (
    <WidgetContainer
      bottom={
        <div className="mmmWidgetBottomWrapper">
          <MdOutlineWarningAmber style={{ fontSize: 22, marginRight: 14 }} />
          <div>
            Channels marked with an alert icon lack incrementality testing, and results should be
            interpreted cautiously. <br />
            Incrementality testing is strongly recommended, and we encourage you to contact your
            team to review options.
          </div>
        </div>
      }
      collapsible
      header={
        <div
          className="cptbWidgetDropdownWrapper"
          style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}
        >
          <span>How well did our investments do based on </span>
          <Dropdown
            className="largeTitle"
            onChange={option => {
              setSnapChartSortValue(DEFAULT_SORT_SELECTIONS[option]);
              setInvestment(option);
            }}
            options={buildInvestmentOptions()}
            type={DropdownToggleType.WIDGET_TITLE}
            value={investment}
          />
          <span>?</span>
        </div>
      }
      rightActions={
        <>
          <div className="cptbCovariatesToggle">
            <ToggleSwitchWithIcon
              leftLabel="Covariates"
              checked={covariates}
              onChange={() => setCovariates(!covariates)}
              rightLabel={
                <MoreInfo background="light">
                  An independent variable that can influence the outcome of a given statistical
                  trial, but which is not of direct interest.
                </MoreInfo>
              }
              rightIcon
            />
          </div>
          <DatePicker
            range={dates}
            isOutsideRange={date => {
              const range = getDefaultDates(cptbData);
              return new Date(date) < new Date(range.start) || new Date(date) > new Date(range.end);
            }}
            onChange={({ start, end }) => {
              setDates({
                start: start,
                end: end,
              });
            }}
          />
        </>
      }
      subHeader={WIDGET_SUBHEADER[investment]}
    >
      {
        <div className="cptb" style={{ display: "flex" }}>
          <div
            className="left"
            style={{ display: "flex", flexDirection: "column", flex: 1, minWidth: "640px" }}
          >
            {showActualizedData && investment === "spendAndEffectShare"
              ? twoStageBarData && (
                  <TwoStageSpendandEffectContainer
                    colorOverrides={{ Covariates: "#cbd2e1" }}
                    twoStageData={twoStageBarData}
                    dropdownOptions={SPEND_AND_EFFECT_SORT_OPTIONS}
                    dropdownValue={snapChartSortValue}
                    setDropdownValue={setSnapChartSortValue}
                    title={SNAPCHART_TITLE[investment]}
                    valueFormatter={snapChartFormatter}
                    exportFormattedData={exportFormattedData}
                    kpiType={kpiType}
                  />
                )
              : !R.isEmpty(snapChartData) && (
                  <SnapshotChart
                    colorOverrides={{ Covariates: "#cbd2e1" }}
                    data={snapChartData.barData}
                    dropdownOptions={
                      investment === "spendAndEffectShare"
                        ? SPEND_AND_EFFECT_SORT_OPTIONS
                        : DEFAULT_SORT_OPTIONS
                    }
                    dropdownValue={snapChartSortValue}
                    setDropdownValue={setSnapChartSortValue}
                    doubleBarChart={investment === "spendAndEffectShare"}
                    header={buildSnapshotHeader()}
                    setToggleLabel={
                      investment === "revenue" || investment === "spendAndEffectShare"
                        ? setToggleLabel
                        : undefined
                    }
                    title={SNAPCHART_TITLE[investment]}
                    toggleLabel={
                      investment === "revenue" || investment === "spendAndEffectShare"
                        ? toggleLabel
                        : true
                    }
                    toggleSwitchLabel={kpiType === "conversion" ? "Volume" : "$"}
                    valueFormatter={snapChartFormatter}
                    exportFormattedData={exportFormattedData}
                  />
                )}
          </div>
          <div className="right" style={{ flex: 2 }}>
            <ChartContainer
              enableHoverDesign
              height={405}
              title={
                <>
                  <Dropdown
                    type={DropdownToggleType.WIDGET_TITLE}
                    value={dateGrouping}
                    options={DATE_GROUPING_OPTIONS}
                    onChange={option => setDateGrouping(option)}
                  />
                  <div>{rightChartTitle()}</div>
                </>
              }
              rightActions={
                <>
                  {(investment === "revenue" || investment === "spendAndEffectShare") && (
                    <Dropdown
                      type={DropdownToggleType.OUTLINED}
                      design="secondary"
                      size="sm"
                      value={absoluteOrRelative}
                      options={[{ value: "Absolute" }, { value: "Relative" }]}
                      onChange={option => setAbsoluteOrRelative(option)}
                    />
                  )}
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadCPTB, pngDownloadRight]}
                  />
                </>
              }
            >
              {!R.isEmpty(areaChartData) && buildRightChart()}
            </ChartContainer>
          </div>
        </div>
      }
    </WidgetContainer>
  );
};

export default ChannelPlatformTacticBrand;

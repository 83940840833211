import React from "react";
import { Button, ButtonType, DropdownMenu } from "../../Components";
import { RouteComponentProps } from "@reach/router";
import "../MetaBuying.scss";
import * as R from "ramda";

interface MetaBuyingCreationPromptProps {
  adAccountOptions: {
    account_name: string;
    business_manager: string;
    account_id: string;
  }[];
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  setSelectedAdAccount: React.Dispatch<
    React.SetStateAction<{
      account_id: string;
      account_name: string;
      business_manager: string;
    }>
  >;
  creationTabs: {
    Campaign: boolean;
    "Ad Set": boolean;
    Ad: boolean;
  };
  setCreationTabs: React.Dispatch<
    React.SetStateAction<{
      Campaign: boolean;
      "Ad Set": boolean;
      Ad: boolean;
    }>
  >;
  setSelectionsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const CREATION_MENU = ["Campaign", "Ad Set", "Ad"];

export const MetaBuyingCreationPrompt = ({
  path,
  adAccountOptions,
  selectedAdAccount,
  setSelectedAdAccount,
  creationTabs,
  setCreationTabs,
  setSelectionsSubmitted,
}: MetaBuyingCreationPromptProps & RouteComponentProps): JSX.Element => {
  return (
    <div className="metaBuyingWidgetWrapper">
      <div className="metaBuyingWidget">
        <h2>Options</h2>
        <div className="metaBuyingWidgetBody">
          <div className="metaBuyingMenuWrapper">
            <h3>What do you want to create?</h3>
            <DropdownMenu
              applyOnClick={null}
              cancelOnClick={null}
              menuItems={R.map(
                option => ({
                  label: option,
                  selected: creationTabs[option],
                  value: option,
                }),
                CREATION_MENU as any
              )}
              standalone
              itemOnClick={item => {
                let newCreationTabs = R.clone(creationTabs);
                newCreationTabs[item.value] = item.selected;
                setCreationTabs(newCreationTabs);
              }}
              multiSelect
            />
          </div>
          <div className="metaBuyingMenuWrapper">
            <h3>Which ad account?</h3>
            <DropdownMenu
              applyOnClick={null}
              cancelOnClick={null}
              menuItems={R.map(
                account => ({
                  label: `${account.account_name} (${account.account_id})`,
                  selected: account.account_id === selectedAdAccount.account_id,
                  value: account.account_id,
                }),
                adAccountOptions as any
              )}
              standalone
              itemOnClick={item =>
                setSelectedAdAccount(R.indexBy(R.prop("account_id"), adAccountOptions)[item.value])
              }
              hasTicks={true}
            />
          </div>
        </div>
        <Button
          className="submitSelections"
          type={ButtonType.OUTLINED}
          disabled={
            (!creationTabs.Campaign && !creationTabs["Ad Set"] && !creationTabs.Ad) ||
            R.isEmpty(selectedAdAccount.account_id)
          }
          onClick={e => setSelectionsSubmitted(true)}
        >
          Go
        </Button>
      </div>
    </div>
  );
};

export default MetaBuyingCreationPrompt;

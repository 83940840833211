import React, { useState, useCallback } from "react";

import { Card, Spinner } from "../../Components";
import { Button, Form, InputGroup } from "react-bootstrap";
import { DagLambdaFetch } from "../../utils/fetch-utils";
import { AdOpsToolProps } from "../AdOps";

const LinearIngestions: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const { setError } = utils;

  const [linearIngestionCompany, setLinearIngestionCompany] = useState("");
  const [linearIngestionStatus, setLinearIngestionStatus] = useState("");

  const startLinearIngestion = useCallback(async () => {
    try {
      setActiveAPICall(true);
      if (
        linearIngestionCompany.startsWith("warbyparker") ||
        linearIngestionCompany.startsWith("instacart")
      ) {
        setLinearIngestionStatus(`Manual start of linear ingestions for ${linearIngestionCompany} are disabled. \n
        If restart is necessary, contact engineering team.`);
      } else {
        await DagLambdaFetch("/start", {
          method: "POST",
          body: JSON.stringify({
            dag: linearIngestionCompany,
            start_task: "WAITBVS",
            override: true,
            retries: 0,
          }),
        });
        setLinearIngestionStatus(`Started for ${linearIngestionCompany}`);
      }
    } catch (e) {
      setError({
        message: `Failed to start dag ${linearIngestionCompany}`,
        reportError: e,
      });
    }
    setActiveAPICall(false);
  }, [linearIngestionCompany, setLinearIngestionStatus, setError]);

  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">Start Linear Ingestions</div>
      <InputGroup size="sm" className="adOpsElem">
        <InputGroup.Prepend>
          <InputGroup.Text className="inputLabel">Company</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={linearIngestionCompany}
          onChange={e => setLinearIngestionCompany(e.target.value)}
        />
      </InputGroup>
      <div className="adOpsElem">{linearIngestionStatus}</div>
      <Button className="linearAdOpsElem" onClick={startLinearIngestion}>
        {activeAPICall ? <Spinner /> : "Start"}
      </Button>
    </Card>
  );
};

export default LinearIngestions;

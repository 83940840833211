import "./LineChart.scss";
import React, { useCallback, useMemo, useState } from "react";
import { LineChart as LC, CartesianGrid, Line, XAxis, YAxis, Tooltip } from "recharts";
import AutoSizer from "react-virtualized-auto-sizer";

import { customColorMapping, Neutral200 } from "../../../utils/colors";
import { CARTESIAN_GRID_STROKE, CARTESIAN_GRID_STROKE_WIDTH, TICK_STYLE } from "../ChartConstants";
import ChartTooltip from "../ChartTooltip/ChartTooltip";
import * as R from "ramda";
import { headerFormatter, useDynamicYAxisWidth } from "../ChartUtils";
import { MdOutlineWarningAmber } from "react-icons/md";
import MoreInfo from "../../../MMM/MoreInfo";
const GRAY_COLOR = "#94a0b8";

interface CustomColorMapping {
  name: string;
  color: string;
}

interface LineChartProps {
  customTooltipHeader?: (val) => string;
  data: {}[];
  dateGrouping?: string;
  colorMappings?: CustomColorMapping[];
  height?: number;
  groupedAreas?: any[];
  isAnimationActive?: boolean;
  leftYAxisDataKey?: string;
  legendTitle?: string | null;
  lineDataKeys: {
    dataKey: string;
    grouping?: { channel: string; platform: string; tactic: string; brand_nonbrand: string };
    incrementality?: boolean;
    name?: string;
    toolTipText?: string;
  }[];
  percentageMinTickGap?: number;
  percentDomain?: [number, number];
  tooltipLabelFormatter?: (value: any) => string;
  tooltipFormatter?: (value: any) => string;
  tooltipShape?: "circle" | "square" | "line";
  usePercentageYAxis?: boolean; // default to left side; use leftYAxisDataKey and leftYAxisLabel
  width?: number;
  xAxisDataKey: string;
  xAxisLabel?: string;
  xAxisTickFormatter?: (value: any) => string;
  yAxisTickFormatter?: (value: any) => string;
  useLeftYAxis?: boolean;
  usePrimaryColors?: boolean;
}

const INACTIVE_COLOR = Neutral200;

export const LineChart: React.FC<LineChartProps> = ({
  customTooltipHeader,
  data,
  dateGrouping,
  colorMappings,
  groupedAreas,
  isAnimationActive = false,
  leftYAxisDataKey,
  legendTitle,
  lineDataKeys,
  percentageMinTickGap = 20,
  percentDomain = [0, 100],
  tooltipFormatter,
  tooltipLabelFormatter,
  tooltipShape,
  useLeftYAxis,
  usePercentageYAxis = false,
  xAxisDataKey,
  xAxisLabel,
  xAxisTickFormatter,
  yAxisTickFormatter,
  usePrimaryColors = true,
}) => {
  const [activeLine, setActiveLine] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const [hoveredLegend, setHoveredLegend] = useState(null);

  const { xAxisHeight, yAxisWidth, setChartRef } = useDynamicYAxisWidth({
    yAxisWidthModifier: x => x + 7,
    data,
  });

  const xAxis = (
    <XAxis
      axisLine={false}
      dataKey={xAxisDataKey}
      height={xAxisHeight}
      tick={TICK_STYLE}
      tickFormatter={xAxisTickFormatter}
    />
  );

  const leftYAxis = (
    <YAxis
      axisLine={false}
      dataKey={leftYAxisDataKey}
      domain={[0, "auto"]}
      orientation="left"
      tick={TICK_STYLE}
      tickFormatter={tick => `${yAxisTickFormatter ? yAxisTickFormatter(tick) : tick}`}
      tickLine={false}
      type="number"
      width={yAxisWidth}
    />
  );
  const percentageYAxis = (
    <YAxis
      axisLine={false}
      dataKey={leftYAxisDataKey}
      domain={percentDomain}
      minTickGap={percentageMinTickGap}
      orientation="left"
      tick={TICK_STYLE}
      tickLine={false}
      type="number"
      unit="%"
      width={yAxisWidth}
      yAxisId="0"
    />
  );
  const tooltip = (
    <Tooltip
      content={({ active, label, payload }) => {
        if (active && payload && payload.length) {
          const formatDateLabel = headerFormatter(dateGrouping, label);
          const formattedHeaderLabel = customTooltipHeader
            ? customTooltipHeader(label)
            : formatDateLabel;
          let items = R.sortBy(
            (item: any) => -Math.abs(item.value),
            payload?.map(item => ({
              label: tooltipLabelFormatter
                ? tooltipLabelFormatter(item.name)
                : (item.name as string),
              value: tooltipFormatter ? tooltipFormatter(item.value) : (item.value as number),
              color: item.stroke,
            })) || []
          );

          return (
            <ChartTooltip headerLabel={formattedHeaderLabel} items={items} shape={tooltipShape} />
          );
        }
        return null;
      }}
      isAnimationActive={false}
    />
  );

  const colorMap = useMemo(() => {
    if (!R.isEmpty(lineDataKeys)) {
      return customColorMapping(lineDataKeys, colorMappings, usePrimaryColors);
    }
  }, [colorMappings, lineDataKeys, usePrimaryColors]);

  const buildLines = useCallback(
    (lineDataKeys, activeLine, hoveredLine) => {
      const lines = lineDataKeys.map((dataKeyInfo, index: number) => {
        const isActiveLine = activeLine === null ? true : activeLine === dataKeyInfo.name;
        const isHoveredLegend = hoveredLine === null ? true : hoveredLine === dataKeyInfo.name;
        const activeColor = isActiveLine ? colorMap[dataKeyInfo.name] : GRAY_COLOR;
        const hoverColor = isHoveredLegend ? colorMap[dataKeyInfo.name] : GRAY_COLOR;
        if (!isActiveLine && activeLine !== null) {
          return null;
        }
        return (
          <Line
            dataKey={dataKeyInfo.dataKey}
            dot={false}
            isAnimationActive={isAnimationActive}
            key={index}
            stroke={activeLine ? activeColor : hoverColor}
            strokeWidth={3}
            type="monotone"
          />
        );
      });
      return lines;
    },
    [colorMap, isAnimationActive]
  );

  const useLeftAxis = (leftYAxisDataKey || useLeftYAxis) && !usePercentageYAxis;

  const handleLegendClick = useCallback(
    (line, group?) => {
      if (activeLine === line.name) {
        setActiveLine(null);
        setActiveGroup(null);
      } else {
        setActiveLine(line.name);
        if (group) {
          setActiveGroup(group.channel);
        }
      }
    },
    [activeLine]
  );

  const handleLegendEnter = useCallback(
    line => {
      hoveredLegend === line.name ? setHoveredLegend(null) : setHoveredLegend(line.name);
    },
    [hoveredLegend]
  );

  const buildLegend = useCallback(
    areas => {
      return areas.map(area => {
        const isActiveArea = activeLine === null ? true : activeLine === area.name;
        const isHoveredLegend = hoveredLegend === null ? true : hoveredLegend === area.name;
        const activeColor = isActiveArea ? colorMap[area.name] : INACTIVE_COLOR;
        const hoverColor = isHoveredLegend ? colorMap[area.name] : INACTIVE_COLOR;
        return (
          <div
            className="legendItem"
            key={area.name}
            onClick={() => handleLegendClick(area)}
            onMouseEnter={() => handleLegendEnter(area)}
            onMouseLeave={() => setHoveredLegend(null)}
          >
            <div className="legendValue">
              <div className={`${isActiveArea ? "" : "inactiveLegend"}`}>
                {
                  <div className="nonGroupLegendWrap">
                    <div
                      className="line"
                      style={{
                        backgroundColor: `${activeLine ? activeColor : hoverColor}`,
                      }}
                    />
                    <div className="legendHeader">{area.name}</div>
                    {area.toolTipText && (
                      <div className="toolTipBox">
                        <MoreInfo size="sm"> {area.toolTipText} </MoreInfo>
                      </div>
                    )}
                    {area.incrementality && (
                      <MdOutlineWarningAmber
                        style={{
                          color: `${
                            isActiveArea || activeLine === null ? "orange" : INACTIVE_COLOR
                          }`,
                          marginLeft: "5px",
                        }}
                      />
                    )}
                  </div>
                }
                {area.grouping &&
                  (area.grouping.platform ||
                    area.grouping.tactic ||
                    area.grouping.brand_nonbrand) && (
                    <>
                      <div className="legendHeaderWrapper">
                        <div className="legendHeader">{area.grouping.channel}</div>
                        {area.incrementality && (
                          <MdOutlineWarningAmber
                            style={{
                              color: `${
                                isActiveArea || activeLine === null ? "orange" : INACTIVE_COLOR
                              }`,
                              marginLeft: "5px",
                            }}
                          />
                        )}
                      </div>
                      <div className="subHeaderWrapper">
                        <div
                          className="line"
                          style={{
                            backgroundColor: `${activeLine ? activeColor : hoverColor}`,
                          }}
                        />
                        <div className="subHeader1">{area.grouping.platform}</div>
                        {area.grouping.platform &&
                          (area.grouping.tactic || area.grouping.brand_nonbrand) && (
                            <div style={{ whiteSpace: "pre-wrap" }}> – </div>
                          )}
                        <div className="subHeader2">{`${
                          area.grouping.tactic ? area.grouping.tactic : ""
                        }${area.grouping.tactic && area.grouping.brand_nonbrand ? ", " : ""}${
                          area.grouping.brand_nonbrand ? area.grouping.brand_nonbrand : ""
                        }`}</div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        );
      });
    },
    [activeLine, colorMap, handleLegendClick, handleLegendEnter, hoveredLegend]
  );

  const buildLegendByGroupedArea = useCallback(
    groupedAreas => {
      return groupedAreas.map((group, i) => {
        return (
          <div className="legendItem" key={`legenditemgroup${i}`}>
            <div className="legendValue">
              <div>
                <div className="legendHeaderWrapper" style={{ cursor: "default" }}>
                  <div
                    className={`legendHeader ${
                      activeGroup === group.channel || activeGroup === null ? "" : "inactiveLegend"
                    }`}
                  >
                    {group.channel}
                  </div>
                </div>
                {group.areas.map((area, j) => {
                  const isActiveArea = activeLine === null ? true : activeLine === area.name;
                  const isHoveredLegend =
                    hoveredLegend === null ? true : hoveredLegend === area.name;
                  const activeColor = isActiveArea ? colorMap[area.name] : INACTIVE_COLOR;
                  const hoverColor = isHoveredLegend ? colorMap[area.name] : INACTIVE_COLOR;
                  return (
                    <div
                      key={`legendlinechar${j}`}
                      className="subHeaderWrapper"
                      onClick={() => handleLegendClick(area, group)}
                      onMouseEnter={() => handleLegendEnter(area)}
                      onMouseLeave={() => setHoveredLegend(null)}
                    >
                      <div
                        className="line"
                        style={{
                          backgroundColor: `${activeLine ? activeColor : hoverColor}`,
                        }}
                      />
                      <div className="subHeaderTextWrap">
                        <div className={`subHeader1 ${isActiveArea ? "" : "inactiveLegend"}`}>
                          {area.grouping.platform}
                        </div>
                        {area.grouping.platform &&
                          (area.grouping.tactic || area.grouping.brand_nonbrand) && (
                            <div style={{ whiteSpace: "pre-wrap" }}> – </div>
                          )}
                        <div className={`subHeader2 ${isActiveArea ? "" : "inactiveLegend"}`}>{`${
                          area.grouping.tactic ? area.grouping.tactic : ""
                        }${area.grouping.tactic && area.grouping.brand_nonbrand ? ", " : ""}${
                          area.grouping.brand_nonbrand ? area.grouping.brand_nonbrand : ""
                        }`}</div>
                        {area.incrementality && (
                          <MdOutlineWarningAmber
                            style={{
                              color: `${
                                isActiveArea || activeLine === null ? "orange" : INACTIVE_COLOR
                              }`,
                              marginLeft: "5px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      });
    },
    [activeLine, activeGroup, colorMap, handleLegendClick, handleLegendEnter, hoveredLegend]
  );

  return (
    <div className="lineWrapper">
      <div className="lineChart" style={{ display: "flex", flex: 1 }}>
        <div style={{ display: "flex", flex: 1 }}>
          <AutoSizer>
            {({ width, height }) => (
              <LC data={data} height={height} width={width} ref={setChartRef}>
                <CartesianGrid
                  stroke={CARTESIAN_GRID_STROKE}
                  strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                  vertical={false}
                />
                {xAxis}
                {usePercentageYAxis && percentageYAxis}
                {useLeftAxis && leftYAxis}
                {tooltip}

                {buildLines(lineDataKeys, activeLine, hoveredLegend)}
              </LC>
            )}
          </AutoSizer>
        </div>
        <div className="rightSideWrapper">
          <div className="rightOfChart">
            <div className="legendTitle">{legendTitle}</div>
            <div className="legend">
              {(!groupedAreas || R.isEmpty(groupedAreas)) && buildLegend(lineDataKeys)}
              {groupedAreas && buildLegendByGroupedArea(groupedAreas)}
            </div>
          </div>
          {xAxisLabel && <div className="xAxisLabel">{xAxisLabel}</div>}
        </div>
      </div>
    </div>
  );
};

export default LineChart;

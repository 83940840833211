import * as R from "ramda";

// Abbreviates numbers compact version. EX: 1200000 -> $1.2M or 1200 -> $1.2K
// maximumFractionDigits: 2; minimumFractionDigits: 1
export const currencyFormatter = {
  format: (value: number, decimals = 2): string => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
    return formatter.format(value).replace("K", "k");
  },
};

export const numberFormatter = {
  format: (value: number, decimals = 2): string => {
    // Abbreviates numbers compact version. EX: 1200000 -> 1.2M or 1200 -> 1.2K
    // maximumFractionDigits: 2; minimumFractionDigits: 1
    const intlNumberFormatter = new Intl.NumberFormat("en-US", {
      notation: "compact",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
    return intlNumberFormatter.format(value).replace("K", "k");
  },
};

export const snapShotDataSort = (data: any[], snapChartSortValue: string): any[] => {
  let snapData;

  switch (snapChartSortValue) {
    case "Alphabetical":
      snapData = R.sortBy(R.prop("name"), data);
      break;
    case "Highest to lowest":
    case "Spend: High to low":
      snapData = R.sortBy(R.compose(R.negate, R.prop("value")), data);
      break;
    case "Lowest to highest":
    case "Spend: Low to high":
      snapData = R.sortBy(R.prop("value"), data);
      break;
    case "Revenue: High to low":
    case "KPI Volume: High to low":
      snapData = R.sortBy(R.compose(R.negate, R.prop("value2")), data);
      break;
    case "Revenue: Low to high":
    case "KPI Volume: Low to high":
      snapData = R.sortBy(R.prop("value2"), data);
      break;
    case "Spend to effect ratio":
      snapData = R.sortBy(R.compose(R.negate, R.prop("spendOverOutcome")), data);
      break;
    default:
      snapData = data;
  }

  return snapData;
};

export const GROUP_BY_OPTIONS = [
  { label: "Channel", value: "channel" },
  { label: "Channel, Platform", value: "platform" },
  { label: "Channel, Platform, Tactic, Brand/Nonbrand", value: "name" },
];

// Turn display_television__total__none__none_spend into Display Television,  Total
export const capitalizeWords = (
  name: string,
  disabledPlatform?: boolean,
  useEnDash?: boolean
): string => {
  if (!name) {
    return "";
  }

  name = disabledPlatform ? name.replace(", Total", "").replace(", total", "") : name;

  let words = name
    .split(",")
    .map(item => item.replace(/_/g, " "))
    .filter(item => {
      return (
        item !== "none" &&
        item !== "spend" &&
        item !== "impressions" &&
        item !== "null" &&
        item !== " null" &&
        (disabledPlatform ? item.toLowerCase() !== "total" && item !== "" && item !== null : true)
      );
    });

  const capitalizeWord = (word: string): string => {
    const splitWord = word.split(" ");
    const capWords = splitWord.map(item => {
      return item.charAt(0).toUpperCase() + item.slice(1); // Capitalize the first letter of each word
    });

    const nonEmptyArray = capWords.filter(str => str !== "");
    return nonEmptyArray.join(" ");
  };
  const capitalizedWords: string[] = R.map(capitalizeWord, words);

  return useEnDash
    ? capitalizedWords.join(" – ").replace("Television", "TV").replace("Tv", "TV")
    : capitalizedWords.join(", ").replace("Television", "TV").replace("Tv", "TV"); // Join the words back together
};

export const CONVERSION_FORMATTER_TYPE = {
  conversion: numberFormatter,
  revenue: currencyFormatter,
};

export const getBarChartContributersData = (data: any[], activeDependencies: Set<string>): any[] =>
  data.filter(channel => activeDependencies.has(channel.name));

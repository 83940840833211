import React from "react";
import { Button, ButtonType } from "../Button";
import { ButtonFrameworkVariant } from "../ButtonFramework";
import "./VisualLegendHorizontal.scss";
import { ShadedPattern } from "../../MMM/FillPatterns/ShadedPattern";

interface VisualLegendHorizontalProps {
  inModal: boolean;
}

export const VisualLegendHorizontal: React.FC<VisualLegendHorizontalProps> = ({ inModal }) => {
  return (
    <div className={`horizontalLegend ${inModal ? "inModal" : "smallWidget"}`}>
      <Button
        className="first"
        design="secondary"
        size="sm"
        type={ButtonType.EMPTY}
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<div className="emptySquare"></div>}
      >
        Actualized
      </Button>
      <Button
        design="secondary"
        size="sm"
        type={ButtonType.EMPTY}
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<ShadedPattern color="darkgray" />}
      >
        Reduced
      </Button>
      <Button
        design="secondary"
        size="sm"
        type={ButtonType.EMPTY}
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<ShadedPattern color="white" backgroundColor="darkgray" id="Negdarkgray" />}
      >
        Added
      </Button>
    </div>
  );
};

import { Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import { FormLayoutProps } from "../OnboardingFreeForm";
import { PlatformOptions } from "../PlatformChecklist/PlatformChecklistOptions";

const platformBudgetsColumns = [
  {
    title: "Platform",
    type: "header",
    width: 2,
  },
  {
    title: "Billing KPI",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["Spend", "Revenue"],
  },
  {
    title: "Spend Type",
    type: "select",
    width: 2,
    required: true,
    selectOptions: [
      "Campaign",
      "Spend with Agency and Platform Fees",
      "Agency Fees",
      "Platform Fees",
    ],
  },
  {
    title: "Payment Process",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["Tinuiti pays platform (passes through cost)", "Client pays platform"],
  },
  {
    title: "Currency",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["USD", "CA", "Euro", "GBP", "Other"],
  },
  {
    title: "Notes",
    type: "text",
    width: 2,
    required: false,
  },
];

const platformBudgetsForm = (responses: Response[], prevId: string, id: string) => {
  const platforms = responses.filter(response => response.questionNumber.startsWith(prevId));
  const selections = platforms.filter(
    platform => String(platform.selections[1]?.openText) === "true"
  );
  return {
    columns: platformBudgetsColumns,
    rows: selections.map((platform, index) => {
      return {
        id: index.toString(),
        questionNumber: `${id}-${index}`,
        title: `${platform.additionalInfo || ""} - ${platform.selections[3]?.openText || ""}`,
      };
    }),
  } as FormLayoutProps;
};

export const platformBudgetsOptions = (selectedPlatforms: Response[]): PlatformOptions => {
  return {
    sourceOfTruth: {
      simpleId: "5Source",
      title: "Source Of Truth*",
      table: platformBudgetsForm(selectedPlatforms, "2Source", "5Source"),
    },
    affiliateLifecycle: {
      simpleId: "5Affiliate",
      title: "Affiliate, Lifecycle",
      table: platformBudgetsForm(selectedPlatforms, "2Affiliate", "5Affiliate"),
    },
    marketplaces: {
      simpleId: "5Marketplaces",
      title: "Marketplaces",
      table: platformBudgetsForm(selectedPlatforms, "2Marketplace", "5Marketplaces"),
    },
    programmaticDisplay: {
      simpleId: "5Programmatic",
      title: "Programmatic, Display, OTT, Streaming",
      table: platformBudgetsForm(selectedPlatforms, "2Programmatic", "5Programmatic"),
    },
    searchShopping: {
      simpleId: "5Search",
      title: "Search & Shopping",
      table: platformBudgetsForm(selectedPlatforms, "2Search", "5Search"),
    },
    seoCro: {
      simpleId: "5SEO",
      title: "SEO, CRO",
      table: platformBudgetsForm(selectedPlatforms, "2SEO", "5SEO"),
    },
    social: {
      simpleId: "5Social",
      title: "Social",
      table: platformBudgetsForm(selectedPlatforms, "2Social", "5Social"),
    },
    misc: {
      simpleId: "5Misc",
      title: "Misc",
      table: platformBudgetsForm(selectedPlatforms, "2Misc", "5Misc"),
    },
  };
};

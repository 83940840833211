import "./Overview.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import AreaChart from "../../Components/Charts/AreaChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG, downloadJSONToCSV } from "../../utils/download-utils";
import * as Dfns from "date-fns/fp";
import { DownloadDropdown } from "../../Components";
import MoreInfo from "../../MMM/MoreInfo";
import LineChart from "../../Components/Charts/LineChart";
import { Channel1, Channel2, Channel3, Channel4 } from "../../utils/colors";
import { capitalizeWords, currencyFormatter, numberFormatter } from "../../MMM/MMMUtils";

interface ModelOverview {
  client: string;
  kpi: string;
  kpi_type: string;
  date_granularity: string;
  refresh_date: string;
  refresh_timestamp: string;
  train_start_date: string;
  train_end_date: string;
  test_start_date: string;
  test_end_date: string;
  train_size: number;
  test_size: number;
  train_rsquared: number;
  test_rsquared: number;
  test_mape: number;
  test_mae: number;
  n_observations: number;
  mean_spend: number;
  median_spend: number;
}

interface WeeklyBrandHealthAreaChart {
  date: string;
  channel: string;
  spend: number;
}

interface OverviewProps {
  company: string;
  groupByMetric: string;
  groupByLevel: string;
  weeklyBrandHealthTimeSeries: any[];
  weeklyBrandHealthAreaChartSpend: Record<string, WeeklyBrandHealthAreaChart[]>;
  modelOverviewAll: Record<string, {}>;
}

const colors = [Channel1, Channel3, Channel2, Channel4];

export const Overview: React.FC<OverviewProps> = ({
  company,
  groupByMetric,
  groupByLevel,
  weeklyBrandHealthTimeSeries,
  weeklyBrandHealthAreaChartSpend,
  modelOverviewAll,
}) => {
  const modelOverview = modelOverviewAll[groupByMetric] as ModelOverview;
  const [
    finalWeeklyBrandHealthAreaChartSpendData,
    setFinalWeeklyBrandHealthAreaChartSpendData,
  ] = useState<any[]>([]);
  const [areaChartKeys, setAreaChartKeys] = useState<any[]>([]);

  const finalWeeklyBrandHealthAreaChartData = useMemo(() => {
    if (Object.values(weeklyBrandHealthAreaChartSpend).length !== 0) {
      const finalAreaChart = weeklyBrandHealthAreaChartSpend[groupByMetric].reduce(
        (total, current) => {
          const existingRow = total.find(r => r.date === current.date);
          if (existingRow) {
            const levelName = current[groupByLevel];
            if (levelName in existingRow) {
              existingRow[levelName] += current.spend;
            } else {
              existingRow[levelName] = current.spend;
            }
          } else {
            const levelName = current[groupByLevel];
            total.push({ date: current.date, [levelName]: current.spend });
          }
          return total;
        },
        [] as any[]
      );
      return finalAreaChart;
    }
    return [];
  }, [groupByLevel, groupByMetric, weeklyBrandHealthAreaChartSpend]);

  useEffect(() => {
    const areaChartEntry = finalWeeklyBrandHealthAreaChartData[0];
    const filteredAreaChartEntry = Object.fromEntries(
      Object.entries(areaChartEntry).filter(([key]) => key !== "date" && key !== "covariates")
    );
    const finalAreaChartKeys = Object.entries(filteredAreaChartEntry).map(([key, row], index) => ({
      name: key
        .split("_")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      dataKey: key,
    }));

    setAreaChartKeys(finalAreaChartKeys);
    setFinalWeeklyBrandHealthAreaChartSpendData(finalWeeklyBrandHealthAreaChartData);
  }, [finalWeeklyBrandHealthAreaChartData]);

  const excelDownloadWeeklyBrandHealth = useCallback(() => {
    exportToExcel(weeklyBrandHealthTimeSeries, "weekly_brand_health");
  }, [weeklyBrandHealthTimeSeries]);

  const excelDownloadWeeklySpend = useCallback(() => {
    exportToExcel(finalWeeklyBrandHealthAreaChartSpendData, "weekly_spend");
  }, [finalWeeklyBrandHealthAreaChartSpendData]);

  const excelDownloadModelOverview = useCallback(() => {
    exportToExcel([modelOverview], "model_overview");
  }, [modelOverview]);

  const pngDownloadWeeklyBrandHealth = useCallback(async () => {
    await downloadPNG(".brandEquityleft .chartContainer .contents", "weekly_brand_health");
  }, []);

  const pngDownloadWeeklySpend = useCallback(async () => {
    await downloadPNG(".brandEquityleft .chartContainer .contents .areaChart", "weekly_spend");
  }, []);
  const csvDownloadModelOverview = useCallback(() => {
    downloadJSONToCSV([modelOverview], `${company}_model_overview`);
  }, [modelOverview, company]);

  return (
    <WidgetContainer
      collapsible
      header={"Overview"}
      subHeader={
        <>
          Assessing the impact of paid media on discrete brand health outcomes
          <MoreInfo rightLabel="More info" size="sm">
            The goal is to understand and quantify the independent effect of paid media on a set of
            brand health outcomes. We employ a multivariate time series model that regresses each
            brand outcome on the set of paid media activities and a series of control variables. In
            the estimation, the model accounts for non-linearities in effects and the often
            significant timing differences between inputs and outputs.
          </MoreInfo>
        </>
      }
    >
      {!R.isEmpty(modelOverview) &&
        !R.isEmpty(weeklyBrandHealthTimeSeries) &&
        !R.isEmpty(finalWeeklyBrandHealthAreaChartSpendData) &&
        !R.isEmpty(areaChartKeys) && (
          <div className="brandEquityOverview">
            <div className="brandEquityleft">
              <ChartContainer
                enableHoverDesign
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadWeeklyBrandHealth, pngDownloadWeeklyBrandHealth]}
                  />
                }
                title="Weekly Brand Health"
                titleAfterDashText={`${Dfns.format(
                  "M/dd/yy",
                  new Date(weeklyBrandHealthTimeSeries[0].date)
                )} – ${Dfns.format(
                  "M/dd/yy",
                  new Date(weeklyBrandHealthTimeSeries[weeklyBrandHealthTimeSeries.length - 1].date)
                )}`}
                beforeTooltipText="Positive Response"
                tooltipText="Positive response indicates the percentage of survey respondents expressing favorable perceptions or experiences related to each brand health metric."
              >
                <LineChart
                  customTooltipHeader={val => `Week of ${val}`}
                  data={weeklyBrandHealthTimeSeries}
                  lineDataKeys={Object.entries(modelOverviewAll).map(([key, row], index) => ({
                    name: key,
                    dataKey: key,
                    toolTipText: "ToolTipText",
                  }))}
                  colorMappings={Object.entries(modelOverviewAll).map(([key, row], index) => ({
                    name: key,
                    color: colors[index],
                  }))}
                  tooltipLabelFormatter={val => capitalizeWords(val)}
                  tooltipFormatter={val => (val ? `${numberFormatter.format(val, 0)}%` : val)}
                  tooltipShape="line"
                  usePercentageYAxis={true}
                  xAxisDataKey="date"
                  xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}`))}
                />
              </ChartContainer>
              <ChartContainer
                enableHoverDesign
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadWeeklySpend, pngDownloadWeeklySpend]}
                  />
                }
                title="Weekly Spend"
                titleAfterDashText={`${Dfns.format(
                  "M/dd/yy",
                  new Date(finalWeeklyBrandHealthAreaChartSpendData[0].date)
                )} – ${Dfns.format(
                  "M/dd/yy",
                  new Date(
                    finalWeeklyBrandHealthAreaChartSpendData[
                      finalWeeklyBrandHealthAreaChartSpendData.length - 1
                    ].date
                  )
                )}`}
              >
                <AreaChart
                  data={finalWeeklyBrandHealthAreaChartSpendData}
                  xAxisDataKey="date"
                  xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}`))}
                  dateGrouping="Week"
                  yAxisWidth={75}
                  yAxisTickFormatter={val => `${currencyFormatter.format(val, 0)}`}
                  tooltipFormatter={val => {
                    if (!val) {
                      return val;
                    }
                    return val < 1000
                      ? currencyFormatter.format(val, 0)
                      : currencyFormatter.format(val, 1);
                  }}
                  tooltipShape="line"
                  areas={areaChartKeys}
                ></AreaChart>
              </ChartContainer>
            </div>
            <div className="brandEquityRight">
              <ChartContainer
                enableHoverDesign
                title="Model Overview"
                rightActions={
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownloadModelOverview, csvDownloadModelOverview]}
                    menuOptions={["XLSX", "CSV"]}
                  />
                }
              >
                <div
                  className="chartBody"
                  style={{ display: "flex", flexDirection: "column", gap: "32px" }}
                >
                  <div className="brandEquityDataSection">
                    <div className="brandEquityDataSectionTitle">Training Data</div>
                    <div className="dataBody">
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">First model observation:</div>
                        <div className="brandEquityValue">
                          {Dfns.format("M/dd/yy", new Date(`${modelOverview.train_start_date}`))}
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">Last model observation:</div>
                        <div className="brandEquityValue">
                          {Dfns.format("M/dd/yy", new Date(`${modelOverview.test_end_date}`))}
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">Average weekly spend:</div>
                        <div className="brandEquityValue">
                          {currencyFormatter.format(modelOverview.mean_spend, 0)}
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">Outcome variable:</div>
                        <div className="brandEquityValue brandEquityOutcomeVariable">
                          {groupByMetric}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="brandEquityDataSection">
                    <div className="brandEquityDataSectionTitle">Recency</div>
                    <div
                      className="dataBody"
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">Last refresh date:</div>
                        <div className="brandEquityValue">
                          {Dfns.format("M/dd/yy", new Date(`${modelOverview.refresh_date}`))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="brandEquityDataSection">
                    <div className="brandEquityDataSectionTitle">Statistics</div>
                    <div className="dataBody">
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">
                          Out-of-sample R<sup>2</sup>:{" "}
                          <MoreInfo size="reg">
                            The percentage of variation in the unseen test data outcomes that is
                            accounted for by the model's explanatory variables. Formally, this is 1
                            - (residual sum of squares) / (total sum of squares)
                          </MoreInfo>
                        </div>
                        <div className="brandEquityValue">
                          {modelOverview.test_rsquared.toFixed(2)}
                        </div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">
                          Out-of-sample MAPE:
                          <MoreInfo size="reg">
                            The percentage of variation in the unseen test data outcomes that is
                            accounted for by the model's explanatory variables. Formally, this is 1
                            - (residual sum of squares) / (total sum of squares)
                          </MoreInfo>
                        </div>
                        <div className="brandEquityValue">{modelOverview.test_mape.toFixed(2)}</div>
                      </div>
                      <div className="brandEquityDataSectionRow">
                        <div className="brandEquityLabel">
                          Out-of-sample MAE:
                          <MoreInfo size="reg">
                            The percentage of variation in the unseen test data outcomes that is
                            accounted for by the model's explanatory variables. Formally, this is 1
                            - (residual sum of squares) / (total sum of squares)
                          </MoreInfo>
                        </div>
                        <div className="brandEquityValue">{modelOverview.test_mae.toFixed(2)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ChartContainer>
            </div>
          </div>
        )}
    </WidgetContainer>
  );
};

export default Overview;

import React, { useMemo, useState, useRef, useContext, useCallback, useEffect } from "react";

import * as R from "ramda";
import * as Dfns from "date-fns/fp";

import { Button, ButtonGroup, Tooltip } from "react-bootstrap";

import {
  MdEdit,
  MdClose,
  MdCheckBoxOutlineBlank,
  MdCheck,
  MdViewAgenda,
  MdViewHeadline,
  MdDoneAll,
  MdRemoveCircleOutline,
  MdLibraryAdd,
  MdSave,
} from "react-icons/md";

import AutoSizer from "react-virtualized-auto-sizer";

import { useSetError } from "../redux/modals";
import { useCompanyInfo } from "../redux/company";

import { CreativeLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useStateFunction, useMap } from "../utils/hooks/useData";
import { useScrollbarSizes } from "../utils/hooks/useDOMHelpers";
import useLocation from "../utils/hooks/useLocation";
import { downloadJSONToCSV } from "../utils/download-utils";

import {
  FullPageSpinner,
  OldFilterBar,
  Img,
  StickyTable,
  IconButton,
  OverlayTrigger,
} from "../Components";

import { ViewCreativeModal, BulkEditModal } from "./ViewCreativeModal";
import { CreativeMapContext, BULK_ADD_KEY, useReportingCreative } from "./CreativeMap";
import { useExperimentFlag } from "../utils/experiments/experiment-utils";
import { getCreativeThumbnail } from "../SingleChannel/MetricsTable/metricsTableUtils";

export const MENU_KEY = "menu";
export const TIMELINE_KEY = "timeline";

const DATE_FORMAT = "yyyy-MM-dd";
const PRETTY_DATE_FORMAT = "M/d/yyyy";

const TODAY = Dfns.format(DATE_FORMAT, new Date());

const toPrettyDate = R.pipe(Dfns.parse(new Date(), DATE_FORMAT), Dfns.format(PRETTY_DATE_FORMAT));

const MENU_FILTER_OPTIONS = [
  {
    name: "isci",
    label: "ISCI",
  },
  {
    name: "modelEntry",
    label: "Model Entry",
  },
  {
    name: "name",
    label: "Creative Name",
  },
  {
    name: "length",
    label: "Length",
  },
  {
    name: "parent",
    label: "Parent Creative",
  },
  {
    name: "variant",
    label: "Creative Variant",
  },
  {
    name: "startDate",
    label: "Start Date",
  },
  {
    name: "endDate",
    label: "End Date",
  },
  {
    name: "language",
    label: "Language",
  },
  {
    name: "avail",
    label: "Avail",
  },
  {
    name: "liveStreaming",
    label: "Live Streaming",
    boolean: true,
  },
  {
    name: "liveLinear",
    label: "Live Linear",
    boolean: true,
  },
  {
    name: "startingInFuture",
    label: "Starting",
    boolean: true,
  },
  {
    name: "mediaTypes",
    label: "Media Types",
  },
  {
    name: "concept",
    label: "Concept",
  },
];

const useData = () => {
  const { company } = useLocation();
  const setError = useSetError();

  const usesReportingCreative = useReportingCreative();

  const [data, setData] = useState();
  const [preFillEditData, setPreFillEditData] = useState();

  const fetchData = useCallback(async () => {
    setData();
    if (company) {
      try {
        let res = await CreativeLambdaFetch("/", {
          params: {
            company,
            timeline: 1,
            include_reporting: usesReportingCreative,
            include_retired: 1,
          },
        });
        let data = await awaitJSON(res);
        setData(data);
      } catch (e) {
        let message = `Couldn't fetch creative map: ${e.message}`;
        setError({ message, reportError: e });
      }
    }
  }, [setError, company, usesReportingCreative]);

  const fetchPreFillData = useCallback(async () => {
    if (company) {
      try {
        let res = await CreativeLambdaFetch("/bulk_add_prefill", {
          params: { company },
        });

        let preFillData = await awaitJSON(res);
        setPreFillEditData(preFillData);
      } catch (e) {
        let message = `Couldn't fetch creative prefill data: ${e.message}`;
        setError({ message, reportError: e });
      }
    }
  }, [company, setError]);

  const fetchAll = useCallback(async () => {
    await Promise.all([fetchData(), fetchPreFillData()]);
  }, [fetchData, fetchPreFillData]);

  useEffect(() => {
    if (!data) {
      fetchAll();
    }
  }, [fetchAll, data]);

  return {
    data,
    preFillEditData,
    refetchData: fetchAll,
  };
};

const Menu = () => {
  const listRef = useRef();
  const { goToTab } = useContext(CreativeMapContext);
  const { company } = useLocation();
  const { width: scrollbarWidth } = useScrollbarSizes(listRef);

  const { data, preFillEditData, refetchData } = useData();

  const shouldEnableInsightsCategories = useExperimentFlag("enableInsightsCategories");
  const enableDisplayIscis = useExperimentFlag("enableDisplayIscis");

  const { media_types } = useCompanyInfo();

  const [selectedMediaTypes, setSelectedMediaTypesValue] = useMap({
    streaming: media_types.includes("streaming"),
    linear: media_types.includes("tv"),
    audio: media_types.includes("audio"),
    display: media_types.includes("display") && enableDisplayIscis,
  });

  const toggleSelectedMediaTypes = useCallback(
    mediaType => {
      const currentVal = selectedMediaTypes[mediaType];
      setSelectedMediaTypesValue(mediaType, !currentVal);
    },
    [selectedMediaTypes, setSelectedMediaTypesValue]
  );

  const listLines = useMemo(() => {
    let temporaryList = [];
    let currentList = [];
    let futureList = [];
    let pastList = [];
    for (const { isci, avail, timeline, media_types, ...rest } of R.values(data)) {
      let matchesSelectedMediaTypes = false;

      for (const mediaType of Object.keys(selectedMediaTypes)) {
        if (selectedMediaTypes[mediaType] && media_types.includes(mediaType)) {
          matchesSelectedMediaTypes = true;
        }
      }

      // If this creative doesn't match any of the selected media types, skip it.
      if (!matchesSelectedMediaTypes) {
        continue;
      }

      let item = {
        isci,
        live: false,
        startingInFuture: false,
        media_types,
        mediaTypes: media_types.sort().join(", "),
        avail,
        ...rest,
      };

      let streamingFutureStart = null;
      let streamingPastEnd = null;
      if (
        selectedMediaTypes.streaming ||
        selectedMediaTypes.display ||
        (selectedMediaTypes.audio && avail === "N")
      ) {
        let { startDate: streamingStartDate, endDate: streamingEndDate } = R.path(
          ["streaming", "current"],
          timeline
        );
        item = { ...item, streamingStartDate, streamingEndDate };

        for (let { startDate, endDate } of R.path(["streaming", "ranges"], timeline)) {
          if (startDate >= TODAY) {
            streamingFutureStart = streamingFutureStart
              ? R.min(streamingFutureStart, startDate)
              : startDate;
          }
          if (endDate && endDate < TODAY) {
            streamingPastEnd = streamingPastEnd ? R.max(endDate, streamingPastEnd) : endDate;
          }
        }
      }

      let linearFutureStart = null;
      let linearPastEnd = null;
      if (selectedMediaTypes.linear || (selectedMediaTypes.audio && avail === "L")) {
        let { startDate: linearStartDate, endDate: linearEndDate } = R.path(
          ["linear", "current"],
          timeline
        );
        item = { ...item, linearStartDate, linearEndDate };

        for (let { startDate, endDate } of R.path(["linear", "ranges"], timeline)) {
          if (startDate >= TODAY) {
            linearFutureStart = linearFutureStart ? R.min(linearFutureStart, startDate) : startDate;
          }
          if (endDate && endDate < TODAY) {
            linearPastEnd = linearPastEnd ? R.max(endDate, linearPastEnd) : endDate;
          }
        }
      }

      if (streamingFutureStart || linearFutureStart) {
        item.startingInFuture = true;
      }

      if (item.streamingStartDate && item.streamingEndDate) {
        item.liveStreaming = true;
      } else if (item.streamingStartDate) {
        item.liveStreaming = true;
      } else {
        item = { ...item, streamingFutureStart, streamingPastEnd };
      }

      if (item.linearStartDate && item.linearEndDate) {
        item.liveLinear = true;
      } else if (item.linearStartDate) {
        item.liveLinear = true;
      } else {
        item = { ...item, linearFutureStart, linearPastEnd };
      }

      if (
        (item.streamingStartDate && item.streamingEndDate) ||
        (item.linearStartDate && item.linearEndDate)
      ) {
        temporaryList.push(item);
      } else if (item.streamingStartDate || item.linearStartDate) {
        currentList.push(item);
      } else if (streamingFutureStart || linearFutureStart) {
        futureList.push(item);
      } else {
        pastList.push(item);
      }
    }
    return R.pipe(
      R.map(R.sortWith([R.ascend(R.prop("name")), R.ascend(R.prop("isci"))])),
      R.flatten,
      R.uniqBy(R.prop("isci"))
    )([temporaryList, currentList, futureList, pastList]);
  }, [data, selectedMediaTypes]);

  const [liveOnly, setLiveOnly] = useState(false);
  const [futureOnly, setFutureOnly] = useState(false);
  const [showRetired, setShowRetired] = useState(false);
  const [showLengths, setShowLength] = useMap({
    15: false,
    30: false,
    60: false,
  });

  const [showAvails, setShowAvail] = useMap({
    N: true,
    L: true,
  });

  const [filter, setFilter] = useStateFunction(() => true);

  const filteredLines = useMemo(() => {
    let filteredData = R.filter(item => {
      const specifiedLengths = Object.keys(showLengths).filter(length => showLengths[length]);
      const hasSpecifiedLength =
        !specifiedLengths.length || specifiedLengths.includes(`${item.length}`);
      let liveStreamingOnly = liveOnly && selectedMediaTypes.streaming && item.liveStreaming;
      let liveLinearOnly = liveOnly && selectedMediaTypes.linear && item.liveLinear;
      return (
        (!liveOnly || liveStreamingOnly || liveLinearOnly) &&
        (!futureOnly || item.startingInFuture) &&
        hasSpecifiedLength &&
        (selectedMediaTypes.streaming || showAvails[item.avail]) &&
        filter(item)
      );
    }, listLines);

    if (showRetired) {
      return filteredData.filter(item => item.retired);
    } else {
      return filteredData.filter(item => !item.retired);
    }
  }, [
    listLines,
    showRetired,
    showLengths,
    liveOnly,
    selectedMediaTypes,
    futureOnly,
    showAvails,
    filter,
  ]);

  const [selectedISCI, setSelectedISCI] = useState();

  const [tableView, setTableView] = useState(false);

  const [sortData, setSortData] = useState([
    {
      key: "liveStreaming",
      asc: false,
    },
    {
      key: "liveLinear",
      asc: false,
    },
    {
      key: "isci",
      asc: true,
    },
  ]);

  // Necessary to make a one-column sticky table
  const stickyTableData = useMemo(
    () =>
      R.pipe(
        R.sortWith(
          tableView ? sortData.map(({ key, asc }) => (asc ? R.ascend : R.descend)(R.prop(key))) : []
        ),
        R.map(line => [line])
      )(filteredLines),
    [filteredLines, sortData, tableView]
  );

  const [bulkEditMode, setBulkEditMode] = useState(false);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [selectedBulkISCIs, setSelectedBulkISCI, setSelectedBulkISCIs] = useMap({});

  const selectAllISCIsInView = useCallback(() => {
    R.pipe(
      R.pluck("isci"),
      R.map(isci => [isci, true]),
      R.fromPairs,
      setSelectedBulkISCIs
    )(filteredLines);
  }, [filteredLines, setSelectedBulkISCIs]);
  const deselectedAllISCIsInView = useCallback(() => {
    for (let { isci } of filteredLines) {
      setSelectedBulkISCI(isci, false);
    }
  }, [filteredLines, setSelectedBulkISCI]);

  const selectedISCIsInView = useMemo(
    () => R.any(line => selectedBulkISCIs[line.isci], filteredLines),
    [selectedBulkISCIs, filteredLines]
  );

  const sortByColumn = useCallback(
    column => {
      let sortObjects = [];
      let found = false;
      for (let { key, asc } of sortData) {
        if (column === key) {
          found = true;
          if (asc) {
            sortObjects.push({
              key,
              asc: false,
            });
          }
          break;
        } else {
          sortObjects.push({ key, asc });
        }
      }
      if (!found) {
        sortObjects.push({
          key: column,
          asc: true,
        });
      }

      setSortData(sortObjects);
    },
    [sortData]
  );

  const [filterBarState, setFilterBarState] = useState();
  const [creativeOptions, setCreativeOptions] = useState();

  useEffect(() => {
    (async () => {
      try {
        let cid = company;
        let res = await CreativeLambdaFetch("/get_creative_options", {
          params: { cid },
        });

        let formattedRes = await awaitJSON(res);
        let sortByName = R.sortBy(R.compose(R.toLower, R.prop("name")));
        formattedRes.categoryData = sortByName(formattedRes.categoryData);
        setCreativeOptions(formattedRes);
      } catch (e) {
        console.log("Error fetching creative options ", e);
      }
    })();
  }, [company]);

  let columns = useMemo(() => {
    let columnsToReturn = [
      {
        key: "isci",
        label: "ISCI",
        className: "isci",
      },
      {
        key: "modelEntry",
        label: "Model Entry",
        className: "modelEntry",
      },
      {
        key: "name",
        label: "Creative Name",
        className: "name",
      },
      {
        key: "length",
        label: "Length",
        className: "length",
      },
      {
        key: "concept",
        label: "Concept",
        className: "concept",
      },
      ...(!selectedMediaTypes.linear
        ? []
        : [
            {
              key: "avail",
              label: "Avail",
              className: "avail",
            },
          ]),
    ];

    if (shouldEnableInsightsCategories) {
      columnsToReturn.push({
        key: "insightsCategories",
        label: "Insights Categories",
        className: "insightsCategories",
      });
    }
    if (creativeOptions && creativeOptions.categoryData) {
      for (let category of creativeOptions.categoryData) {
        columnsToReturn.push({
          key: category.name,
          label: category.name,
          className: category.name,
          sortable: false,
        });
      }
    }
    if (selectedMediaTypes.streaming) {
      columnsToReturn.push({
        key: "liveStreaming",
        label: "Live Streaming",
        className: "live",
      });
    }
    if (selectedMediaTypes.linear) {
      columnsToReturn.push({
        key: "liveLinear",
        label: "Live Linear",
        className: "live",
      });
    }
    return columnsToReturn;
  }, [creativeOptions, selectedMediaTypes, shouldEnableInsightsCategories]);

  let dynamicTableProps = useMemo(() => {
    if (tableView) {
      let sortMap = R.pipe(R.groupBy(R.prop("key")), R.pluck(0))(sortData);
      return {
        // We have this bootleg table that's only one column. There's no point in passing any data for these table
        // headers, but we need to give it something so that it actually renders the top header.
        topData: [{}],
        topRenderer: ({ style, classes }) => {
          return (
            <div className={[...classes, "tableRowItem", "tableRowHeader"].join(" ")} style={style}>
              {bulkEditMode && <div className="bulkEditCheckbox" />}
              {columns.map(({ label, key, className = "", sortable = true }) => {
                let classes = [className];
                classes.push(sortable ? "headerCaret" : "nonCaret");
                if (sortable && sortMap[key]) {
                  classes.push(R.path([key, "asc"], sortMap) ? "up" : "down");
                }
                return (
                  <div
                    key={key}
                    className={classes.join(" ")}
                    onClick={sortable ? () => sortByColumn(key) : null}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          );
        },
      };
    }
    return {};
  }, [tableView, sortData, columns, bulkEditMode, sortByColumn]);

  const cancelBulkEditMode = useCallback(() => {
    setShowBulkEditModal(false);
    setBulkEditMode(false);
    setSelectedBulkISCIs({});
  }, [setSelectedBulkISCIs]);

  const filterOptions = useMemo(() => {
    let options = [...MENU_FILTER_OPTIONS];
    if (shouldEnableInsightsCategories) {
      options.push({
        name: "insightsCategories",
        label: "Insights Categories",
      });
    }
    if (selectedMediaTypes.streaming) {
      options.push({
        name: "clickthroughUrl",
        label: "Click-through",
      });
    }
    if (creativeOptions && creativeOptions.categoryData) {
      for (let category of creativeOptions.categoryData) {
        options.push({
          name: category.name,
          label: category.name,
          optionsGetter: row => {
            if (row.creativeTags && row.creativeTags[category.name]) {
              return row.creativeTags[category.name];
            } else {
              return [];
            }
          },
        });
      }
    }
    return options;
  }, [creativeOptions, selectedMediaTypes.streaming, shouldEnableInsightsCategories]);

  const downloadCSV = useCallback(() => {
    const mlAttributes = creativeOptions.categoryData.map(attribute => attribute.name);

    let csvData = R.pipe(
      R.values,
      R.reduce((list, { media_types, timeline, ...rest }) => {
        // Make sure a default concept is set on each line so it shows up in the sheet.
        rest.concept = rest.concept || "No Concept Set";

        // Remove parent/variant cols
        delete rest.parent;
        delete rest.variant;

        // Add columns for ML tags.
        let dataWithMLCols = rest;
        for (const attribute of mlAttributes) {
          dataWithMLCols[attribute] = rest.creativeTags
            ? (rest.creativeTags[attribute] || []).join(", ")
            : "";
        }

        let mediaTypeKeys = R.reduce(
          (obj, type) => ({ ...obj, [type]: true }),
          {
            audio: false,
            streaming: false,
            linear: false,
          },
          media_types
        );

        for (let dateType of R.keys(timeline)) {
          // If the ISCI isn't linear, then skip adding linear dates. Same for streaming, but audio dates are
          // "streaming" type, so we need to do a special check.
          if (
            (dateType === "linear" && !mediaTypeKeys.linear) ||
            (dateType === "streaming" && !(mediaTypeKeys.streaming || mediaTypeKeys.audio))
          ) {
            continue;
          }
          let dates = R.path([dateType, "ranges"], timeline);
          for (let dateSet of dates) {
            list.push({
              ...dataWithMLCols,
              ...mediaTypeKeys,
              liveDateType: dateType,
              endDate: "forever",
              ...dateSet,
            });
          }
          if (!R.length(dates)) {
            list.push({
              ...dataWithMLCols,
              ...mediaTypeKeys,
              liveDateType: dateType,
              startDate: "never live",
              endDate: "never live",
            });
          }
        }
        return list;
      }, []),
      R.sortWith([
        R.ascend(R.prop("isci")),
        R.ascend(R.prop("parent")),
        R.ascend(R.prop("variant")),
        R.ascend(R.prop("language")),
        R.ascend(R.prop("avail")),
        R.ascend(R.prop("liveDateType")),
        R.ascend(R.prop("startDate")),
        R.ascend(R.prop("endDate")),
      ]),
      // NOTE: this is probably unnecessary. We were having duplicate issues, but those were actually because we weren't
      // linear vs streaming dates properly (so they looked the same but really they were one or the other). Doesn't
      // hurt to leave it in though (unless there's the unlikely scenario that a creative map is so huge that this
      // uniqueness check is too computationally intensive).
      R.uniq
    )(data);
    downloadJSONToCSV(csvData, `${company}_creative_map_${TODAY}`);
  }, [data, company, creativeOptions]);

  // Row height needs to change depending on how many tags
  // are in a cell and also if the tags have wrapped text,
  // which makes them taller. Estimate a height for each cell
  // within a given row based on number of tags and whether
  // any of those tags will have extra lines of text within them
  // then pick the largest one as the height for the whole
  // row.
  const calculateRowHeight = index => {
    let defaultRowHeight = 40;
    let extraLineHeight = 18;
    let numCharsPerRow = 10;

    if (!stickyTableData || !stickyTableData[index]) {
      return defaultRowHeight;
    }
    let line = stickyTableData[index][0];
    let maxExpectedHeight = defaultRowHeight;
    if (line.creativeTags) {
      for (let value of Object.values(line.creativeTags)) {
        let extraLines = 0;
        let expectedHeight = 0;
        expectedHeight += defaultRowHeight * value.length;
        for (let tag of value) {
          extraLines += Math.floor(tag.length / numCharsPerRow);
        }
        expectedHeight += extraLineHeight * extraLines;
        maxExpectedHeight = Math.max(expectedHeight, maxExpectedHeight);
      }
    }
    return maxExpectedHeight;
  };

  const getDateContent = useCallback((startDate, endDate, futureStart, pastEnd) => {
    let dateContent;
    if (startDate && endDate) {
      dateContent = (
        <div className="currentlyLive">
          <strong>{toPrettyDate(startDate)}</strong> to <strong>{toPrettyDate(endDate)}</strong>
        </div>
      );
    } else if (startDate) {
      dateContent = (
        <div className="currentlyLive">
          Started <strong>{toPrettyDate(startDate)}</strong>
        </div>
      );
    } else if (futureStart && pastEnd) {
      dateContent = (
        <div className="startingLater">
          <div>
            Ended <strong>{toPrettyDate(pastEnd)}</strong>
          </div>
          <div>
            Restarting <strong>{toPrettyDate(futureStart)}</strong>
          </div>
        </div>
      );
    } else if (futureStart) {
      dateContent = (
        <div className="startingLater">
          <div>Never Live</div>
          <div>
            Starting <strong>{toPrettyDate(futureStart)}</strong>
          </div>
        </div>
      );
    } else if (pastEnd) {
      dateContent = (
        <div className="notLive">
          Ended <strong>{toPrettyDate(pastEnd)}</strong>
        </div>
      );
    } else {
      dateContent = <div className="notLive">Never Live</div>;
    }
    return dateContent;
  }, []);

  const LENGTH_OPTIONS = [15, 30, 60];

  return data ? (
    <>
      <div className="controls">
        <div className="subControls">
          <ButtonGroup toggle className="mediaTypePicker">
            <Button
              variant="outline-primary"
              active={selectedMediaTypes.streaming}
              onClick={() => toggleSelectedMediaTypes("streaming")}
            >
              Streaming
            </Button>
            <Button
              variant="outline-primary"
              active={selectedMediaTypes.linear}
              onClick={() => toggleSelectedMediaTypes("linear")}
            >
              Linear
            </Button>
            <Button
              variant="outline-primary"
              active={selectedMediaTypes.audio}
              onClick={() => toggleSelectedMediaTypes("audio")}
            >
              Audio
            </Button>
            {enableDisplayIscis && media_types.includes("display") && (
              <Button
                variant="outline-primary"
                active={selectedMediaTypes.display}
                onClick={() => toggleSelectedMediaTypes("display")}
              >
                Display
              </Button>
            )}
          </ButtonGroup>
          {bulkEditMode ? (
            <div className="buttonGroup">
              <IconButton
                variant="outline-primary"
                onClick={() => {
                  if (selectedISCIsInView) {
                    deselectedAllISCIsInView();
                  } else {
                    selectAllISCIsInView();
                  }
                }}
                icon={selectedISCIsInView ? <MdRemoveCircleOutline /> : <MdDoneAll />}
              >
                Select {selectedISCIsInView ? "None" : "All"}
              </IconButton>
              <IconButton
                variant="primary"
                disabled={!selectedISCIsInView}
                onClick={() => setShowBulkEditModal(true)}
                icon={<MdEdit />}
              >
                Edit
              </IconButton>
              <IconButton variant="outline-danger" onClick={cancelBulkEditMode} icon={<MdClose />}>
                <span className="logoButtonLabel">Cancel</span>
              </IconButton>
            </div>
          ) : (
            <IconButton onClick={() => setBulkEditMode(true)} icon={<MdEdit />}>
              <span className="logoButtonLabel">Bulk Edit</span>
            </IconButton>
          )}
          <IconButton onClick={() => goToTab(BULK_ADD_KEY)} icon={<MdLibraryAdd />}>
            Bulk Add
          </IconButton>
        </div>
        <ButtonGroup className="viewToggle">
          <IconButton
            variant={`${tableView ? "outline-" : ""}primary`}
            icon={<MdViewAgenda />}
            onClick={() => setTableView(false)}
          >
            List View
          </IconButton>
          <IconButton
            variant={`${tableView ? "" : "outline-"}primary`}
            icon={<MdViewHeadline />}
            onClick={() => setTableView(true)}
          >
            Table View
          </IconButton>
        </ButtonGroup>
        <Button variant="outline-secondary" onClick={downloadCSV}>
          <MdSave />
        </Button>
      </div>
      <div className="controls">
        <Button
          variant={`${liveOnly ? "" : "outline-"}secondary`}
          onClick={() => setLiveOnly(val => !val)}
        >
          Live Only
        </Button>
        <Button
          variant={`${futureOnly ? "" : "outline-"}secondary`}
          onClick={() => setFutureOnly(val => !val)}
        >
          Starting Only
        </Button>
        <Button
          variant={`${showRetired ? "" : "outline-"}secondary`}
          onClick={() => setShowRetired(val => !val)}
        >
          Show Retired
        </Button>
        {(selectedMediaTypes.streaming ||
          selectedMediaTypes.linear ||
          selectedMediaTypes.audio) && (
          <ButtonGroup>
            {LENGTH_OPTIONS.map(length => (
              <Button
                variant="secondary"
                key={length}
                active={showLengths[length]}
                onClick={() => setShowLength(length, !showLengths[length])}
              >
                {length}s
              </Button>
            ))}
          </ButtonGroup>
        )}
        {selectedMediaTypes.linear && (
          <ButtonGroup>
            {["N", "L"].map(avail => (
              <Button
                variant="secondary"
                key={avail}
                active={showAvails[avail]}
                onClick={() => setShowAvail(avail, !showAvails[avail])}
              >
                {avail}
              </Button>
            ))}
          </ButtonGroup>
        )}
        <OldFilterBar
          options={filterOptions}
          lines={listLines}
          onFilter={setFilter}
          onChange={setFilterBarState}
          tokens={filterBarState}
        />
      </div>
      <div className="list" ref={listRef}>
        <AutoSizer>
          {({ width, height }) => (
            <StickyTable
              noBorders
              {...dynamicTableProps}
              alternateColors={tableView}
              width={width}
              height={height}
              data={stickyTableData}
              rowHeight={tableView ? calculateRowHeight : 120}
              columnWidth={width - scrollbarWidth}
              cellRenderer={({ style, classes, data }) => {
                const {
                  isci,
                  length,
                  name,
                  language,
                  avail,
                  streamingStartDate,
                  streamingEndDate,
                  linearStartDate,
                  linearEndDate,
                  streamingFutureStart,
                  streamingPastEnd,
                  linearFutureStart,
                  linearPastEnd,
                  liveStreaming,
                  liveLinear,
                  concept,
                  modelEntry,
                  b2bCreatives,
                  creativeTags,
                  media_types,
                  insightsCategories,
                } = data;

                let streamingDateContent = getDateContent(
                  streamingStartDate,
                  streamingEndDate,
                  streamingFutureStart,
                  streamingPastEnd
                );
                let linearDateContent = getDateContent(
                  linearStartDate,
                  linearEndDate,
                  linearFutureStart,
                  linearPastEnd
                );

                let ourClasses = [...classes, tableView ? "tableRowItem" : "listItem"];
                if (bulkEditMode && selectedBulkISCIs[isci]) {
                  ourClasses.push("selected");
                }
                return (
                  <div
                    key={isci}
                    style={style}
                    className={ourClasses.join(" ")}
                    onClick={() => {
                      if (bulkEditMode) {
                        setSelectedBulkISCI(isci, !selectedBulkISCIs[isci]);
                      } else {
                        setSelectedISCI(isci);
                      }
                    }}
                  >
                    {bulkEditMode && (
                      <div className="bulkEditCheckbox">
                        {selectedBulkISCIs[isci] ? <MdCheck /> : <MdCheckBoxOutlineBlank />}
                      </div>
                    )}
                    {tableView ? (
                      <>
                        {[
                          ["isci", isci, false],
                          ["modelEntry", modelEntry, true],
                          ["name", name, false],
                          ["length", length, true],
                          ["concept", concept, false],
                        ].map(([label, data, shouldCenterContent]) => {
                          const classes = shouldCenterContent ? `${label} centerCell` : label;
                          return (
                            <OverlayTrigger
                              key={label}
                              placement={OverlayTrigger.PLACEMENTS.TOP.CENTER}
                              delay={500}
                              overlay={<Tooltip>{data}</Tooltip>}
                            >
                              <div className={classes}>{data}</div>
                            </OverlayTrigger>
                          );
                        })}
                        {selectedMediaTypes.linear && (
                          <>
                            <div className="avail centerCell">{avail}</div>
                          </>
                        )}
                        {shouldEnableInsightsCategories && (
                          <div className="creativeTag">
                            {insightsCategories &&
                              insightsCategories.map(category => (
                                <span key={category} className="tagName">
                                  {category}
                                </span>
                              ))}
                          </div>
                        )}
                        {creativeOptions.categoryData.map(category => {
                          if (creativeTags && creativeTags[category.name]) {
                            return (
                              <div key={category.name} className="creativeTag">
                                {creativeTags[category.name].map(tag => (
                                  <span
                                    key={category.name.concat(category.options.length)}
                                    className="tagName"
                                  >
                                    {tag}
                                  </span>
                                ))}
                              </div>
                            );
                          } else {
                            return <div key={category.name} className="noTags"></div>;
                          }
                        })}
                        {selectedMediaTypes.streaming && (
                          <div className="live centerCell">{liveStreaming && <MdCheck />}</div>
                        )}
                        {selectedMediaTypes.linear && (
                          <div className="live centerCell">{liveLinear && <MdCheck />}</div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="thumb">
                          <Img title={isci} src={getCreativeThumbnail(company, isci)} />
                        </div>
                        <div className="nonImg">
                          <div className="metadata">
                            <div className="title" title={isci}>
                              {isci}
                            </div>
                            <div title={`${name} (${length}s)`}>
                              {name} <small>({length}s)</small>
                            </div>
                            <div title={`${language} (${avail})`}>
                              {selectedMediaTypes.linear ? ` (${avail}) ` : ""}
                              {language}
                            </div>
                            <div className="concept">
                              <span>Concept: </span>
                              <span>{concept ? concept : "No Concept Set"}</span>
                              <span>
                                {b2bCreatives && ` (${b2bCreatives.isci1} & ${b2bCreatives.isci2})`}
                              </span>
                            </div>
                          </div>
                          <div className="dateBlock">
                            <div className="title">Dates</div>
                            {(selectedMediaTypes.streaming ||
                              selectedMediaTypes.display ||
                              (media_types.includes("audio") && avail === "N")) && (
                              <div className="content">
                                <div className="contentLabel">
                                  {media_types.includes("display") ? "Display:" : "Streaming:"}
                                </div>
                                {streamingDateContent}
                              </div>
                            )}
                            {selectedMediaTypes.audio &&
                              media_types.includes("audio") &&
                              avail === "L" && (
                                <div className="content">
                                  <div className="contentLabel">{"Radio:"}</div>
                                  {linearDateContent}
                                </div>
                              )}
                            {selectedMediaTypes.linear && media_types.includes("linear") && (
                              <div className="content">
                                <div className="contentLabel">Linear:</div>
                                {linearDateContent}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
      {selectedISCI && data[selectedISCI] && (
        <ViewCreativeModal
          onClose={() => setSelectedISCI()}
          creative={data[selectedISCI]}
          selectedMediaTypes={selectedMediaTypes}
          preFillEditData={preFillEditData}
          companyMediaTypes={media_types}
          refetchData={async () => {
            setSelectedISCI();
            await refetchData();
          }}
          showRetired={showRetired}
          creativeOptions={creativeOptions}
        />
      )}
      {showBulkEditModal && (
        <BulkEditModal
          onClose={() => setShowBulkEditModal(false)}
          selectedISCIs={selectedBulkISCIs}
          setSelectedISCIs={setSelectedBulkISCIs}
          creativeData={data}
          refetchData={async () => {
            deselectedAllISCIsInView();
            cancelBulkEditMode();
            await refetchData();
          }}
          selectedMediaTypes={selectedMediaTypes}
          creativeAttributes={creativeOptions.categoryData}
        />
      )}
    </>
  ) : (
    <FullPageSpinner />
  );
};

export default Menu;

export const VendorCountries = [
  { value: "US", label: "United States of America" },
  { value: "CA", label: "Canada" },
  { value: "MX", label: "Mexico" },
  { value: "BR", label: "Brazil" },
  { value: "ES", label: "Spain" },
  { value: "GB", label: "United Kingdom" },
  { value: "FR", label: "France" },
  { value: "NL", label: "Netherlands" },
  { value: "DE", label: "Germany" },
  { value: "IT", label: "Italy" },
  { value: "SE", label: "Sweden" },
  { value: "PL", label: "Poland" },
  { value: "EG", label: "Egypt" },
  { value: "TR", label: "Turkey" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "IN", label: "India" },
  { value: "SG", label: "Singapore" },
  { value: "AU", label: "Australia" },
  { value: "JP", label: "Japan" },
];

export const SellerCountries = [
  { value: "US", label: "United States of America" },
  { value: "CA", label: "Canada" },
  { value: "MX", label: "Mexico" },
  { value: "BR", label: "Brazil" },
  { value: "ES", label: "Spain" },
  { value: "GB", label: "United Kingdom" },
  { value: "FR", label: "France" },
  { value: "NL", label: "Netherlands" },
  { value: "DE", label: "Germany" },
  { value: "IT", label: "Italy" },
  { value: "SE", label: "Sweden" },
  { value: "PL", label: "Poland" },
  { value: "EG", label: "Egypt" },
  { value: "TR", label: "Turkey" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "IN", label: "India" },
  { value: "SG", label: "Singapore" },
  { value: "AU", label: "Australia" },
  { value: "JP", label: "Japan" },
];

export const DummyCountries = [
  { label: "United States", value: "US" },
  { label: "United Kingdom", value: "UK" },
  { label: "United Kingdom", value: "GB" },
  { label: "Canada", value: "CA" },
  { label: "France", value: "FR" },
  { label: "Netherlands", value: "NL" },
  { label: "Germany", value: "DE" },
  { label: "India", value: "IN" },
  { label: "Italy", value: "IT" },
  { label: "Mexico", value: "MX" },
  { label: "Spain", value: "ES" },
  { label: "Australia", value: "AU" },
  { label: "China", value: "CN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Macau", value: "MO" },
  { label: "Japan", value: "JP" },
  { label: "Mongolia", value: "MN" },
  { label: "North Korea", value: "KP" },
  { label: "South Korea", value: "KR" },
  { label: "Taiwan", value: "TW" },
  { label: "Brunei", value: "BN" },
  { label: "Cambodia", value: "KH" },
  { label: "East Timor", value: "TL" },
  { label: "Indonesia", value: "ID" },
  { label: "Laos", value: "LA" },
  { label: "Malaysia", value: "MY" },
  { label: "Myanmar", value: "MM" },
  { label: "Philippines", value: "PH" },
  { label: "Singapore", value: "SG" },
  { label: "Thailand", value: "TH" },
  { label: "Vietnam", value: "VN" },
  { label: "Russia", value: "RU" },
  { label: "Brazil", value: "BR" },
  { label: "Sweden", value: "SE" },
  { label: "Poland", value: "PL" },
  { label: "Egypt", value: "EG" },
  { label: "Turkey", value: "TR" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "United Arab Emirates", value: "AE" },
];

export const SellingOptions = [
  { label: "Flat File Orders By Order Date Report", value: "allorders" },
  { label: "Sales and Traffic", value: "sales_and_traffic" },
  { label: "AFN Inventory", value: "afn_inventory" },
  { label: "FBA_MYI_UNSUPPRESSED INVENTORY", value: "fba_myi_unsuppressed_inventory" },
];

export const VendorOptions = [
  { label: "Sales Diagnostic", value: "vendor_sales_diagnostic" },
  { label: "Inventory Health and Planning", value: "vendor_inventory_health_and_planning" },
  { label: "Demand Forecast", value: "vendor_demand_forecast" },
  { label: "Promotion Performance", value: "promotion_performance" },
  { label: "search_terms", value: "search_terms" },
  { label: "Forecasting", value: "vendor_forecasting" },
  { label: "Sales Manufacturing", value: "vendor_sales_manufacturing" },
  { label: "Sales Sourcing", value: "vendor_sales_sourcing" },
  { label: "Traffic", value: "vendor_traffic" },
  { label: "Inventory Manufacturing", value: "vendor_inventory_manufacturing" },
  { label: "Inventory Sourcing", value: "vendor_inventory_sourcing" },
];

import React, { useMemo } from "react";
import "./MoreActionsMenu.scss";
import { Button, ButtonType } from "../../../Components";
import { ButtonFrameworkVariant } from "../../../Components/ButtonFramework";
import { MdDelete, MdEdit, MdList, MdOutlineMoreTime, MdRemoveRedEye } from "react-icons/md";
import { MetaBuyingTableRow } from "@blisspointmedia/bpm-types/dist/MetaBuying";

interface MoreActionsMenuProps {
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  selectedLevel: string;
  tab: "drafts" | "published";
}

const MoreActionsMenu = ({
  selectedRows,
  selectedLevel,
  tab,
}: MoreActionsMenuProps): JSX.Element => {
  const multipleSelected: boolean = useMemo(() => {
    return Object.keys(selectedRows[selectedLevel]).length > 1;
  }, [selectedLevel, selectedRows]);

  return (
    <div
      className="mamContainer"
      style={{
        height: selectedLevel === "ad" ? 190 : 156,
        width: selectedLevel === "ad" ? 163 : 134,
      }}
    >
      <Button
        className="mamButton"
        type={ButtonType.EMPTY}
        size="sm"
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<MdOutlineMoreTime />}
        disabled={multipleSelected}
      >
        Change Log
      </Button>
      <Button
        className="mamButton"
        type={ButtonType.EMPTY}
        size="sm"
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<MdEdit />}
        disabled={multipleSelected || tab === "published"}
      >
        Edit
      </Button>
      <div className="divider"></div>
      <Button
        className="mamButton"
        type={ButtonType.EMPTY}
        size="sm"
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<MdList />}
        disabled={multipleSelected}
      >
        View Details
      </Button>
      {selectedLevel === "ad" && (
        <Button
          className="mamButton"
          type={ButtonType.EMPTY}
          size="sm"
          variant={ButtonFrameworkVariant.LEADING_ICON}
          icon={<MdRemoveRedEye />}
          disabled={multipleSelected}
        >
          View Ad Preview
        </Button>
      )}
      <div className="divider"></div>
      <Button
        className="mamButton"
        type={ButtonType.EMPTY}
        size="sm"
        variant={ButtonFrameworkVariant.LEADING_ICON}
        icon={<MdDelete />}
        disabled={tab === "published"}
      >
        Delete
      </Button>
    </div>
  );
};

export default MoreActionsMenu;

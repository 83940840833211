import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Button, ButtonType } from "../Components";
import { RouteComponentProps } from "@reach/router";

const TAB_OPTIONS = ["Link File(s)", "Upload File(s)"];

const MetaBuyingUpload = ({ path }: RouteComponentProps): JSX.Element => {
  // const [fileType, setFileType] = useState<any>(null);
  // const [filePath, setFilePath] = useState<any>(null);
  const [fileLink, setFileLink] = useState<string>("");
  // const [selectedTab, setSelectedTab] = useState("Link File(s)");
  return (
    <div className="metaBuyingWidgetWrapper">
      <div className="metaBuyingWidget">
        <h2>Upload</h2>
        <div className="metaBuyingWidgetBody">
          <Button
            size="sm"
            className="metaBuyingButton upload"
            type={ButtonType.EMPTY}
            onClick={() => console.log("download template")}
          >
            Download Template
          </Button>
          <div className="metaBuyingUploadPrompt">
            <div className="metaBuyingTabs">
              {TAB_OPTIONS.map(tab => (
                <Button
                  type={ButtonType.EMPTY}
                  // className={selectedTab === tab ? "selected" : ""}
                  onClick={() => console.log(tab)}
                >
                  Link Files
                </Button>
              ))}
            </div>

            <Form.Group>
              <Form.Label>Link your file below and grant us view access.</Form.Label>
              <Form.Control value={fileLink} onChange={e => setFileLink(e.target.value)} />
            </Form.Group>
          </div>
        </div>
        <Button
          className="submitSelections"
          type={ButtonType.OUTLINED}
          onClick={() => console.log("upload!")}
        >
          Save as Draft
        </Button>
      </div>
    </div>
  );
};

export default MetaBuyingUpload;

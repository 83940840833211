import {
  CDN,
  CellData,
  ColumnMetaData,
  DimensionMap,
  getCreativeThumbnail,
  GlossaryItem,
  toPretty1000sInteger,
  toPrettyCPX,
  toPrettyNumber,
  toPrettyPercent,
  toPrettySpend,
} from "../MetricsTable/metricsTableUtils";
import { CreativeMap } from "../../redux/creative";
import { DerivedNetworkMap } from "../../redux/networks";
import { DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";

export const dimensionColumnMetaDataMap: Partial<
  Record<S.PerformanceDimensionColumnType, ColumnMetaData>
> = {
  "Network Name": {
    contentType: "text",
    dimensionVarName: "Network",
    displayName: "Network Name",
    iconStyle: "logo",
  },
  "Network Logo": {
    contentType: "logo",
    dimensionVarName: "Network",
    displayName: "Network Logo",
    iconStyle: "logo",
  },
  Description: {
    contentType: "text",
    dimensionVarName: "Network",
    displayName: "Description",
    iconStyle: "logo",
  },
  "Derived ID": {
    contentType: "text",
    dimensionVarName: "Network",
    displayName: "Derived ID",
    iconStyle: "logo",
  },
  Creative: {
    contentType: "text",
    dimensionVarName: "Creative",
    displayName: "Creative Name",
    iconStyle: "thumbnail",
  },
  "Creative Thumbnail": {
    contentType: "thumbnail",
    dimensionVarName: "Creative",
    displayName: "",
    iconStyle: "thumbnail",
  },
  Length: {
    contentType: "number",
    dimensionVarName: "Length",
    displayName: "Creative Length",
  },
  Size: {
    contentType: "number",
    dimensionVarName: "Size",
    displayName: "Size",
  },
  Device: {
    contentType: "text",
    dimensionVarName: "DeviceOS",
    displayName: "Device",
    iconStyle: "logo",
  },
  OS: {
    contentType: "text",
    dimensionVarName: "DeviceOS",
    displayName: "OS",
    iconStyle: "logo",
  },
  "Device Logo": {
    contentType: "logo",
    dimensionVarName: "DeviceOS",
    displayName: "Device Logo",
    iconStyle: "logo",
  },
  "OS Logo": {
    contentType: "logo",
    dimensionVarName: "DeviceOS",
    displayName: "OS Logo",
    iconStyle: "logo",
  },
  "Network Group Name": {
    contentType: "text",
    dimensionVarName: "Network Group",
    displayName: "Network Group Name",
    iconStyle: "logo",
  },
  "Network Group Logo": {
    contentType: "logo",
    dimensionVarName: "Network Group",
    displayName: "Network Group Logo",
    iconStyle: "logo",
  },
};

export const columnMetaDataMap: Partial<Record<S.ColumnType, ColumnMetaData>> = {
  avgFreq: {
    aggregator: agg =>
      agg.weightedAvgFreq && agg.imps
        ? (agg.weightedAvgFreq as number) / (agg.imps as number)
        : "--",
    displayName: "Avg. Freq.",
    decimals: 1,
    formatValue: toPrettyNumber,
    requiredTotalsColumns: [
      "imps",
      {
        dataVarName: "weightedAvgFreq",
        fetchGetter: fetch => R.defaultTo(0, fetch.imps) * R.defaultTo(0, fetch.avgFreq),
      },
    ],
  },
  completionsRate: {
    aggregator: agg =>
      agg.completions && agg.completionsImpressions
        ? (agg.completions as number) / (agg.completionsImpressions as number)
        : "--",
    displayName: "Completion Rate",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.completions && fetch.completionsImpressions
        ? (fetch.completions as number) / (fetch.completionsImpressions as number)
        : "--",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: ["completions", "completionsImpressions"],
  },
  cpm: {
    aggregator: agg =>
      agg.imps && agg.spend ? ((agg.spend as number) * 1000) / (agg.imps as number) : "--",
    displayName: "eCPM",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.imps && fetch.spend ? ((fetch.spend as number) * 1000) / (fetch.imps as number) : "--",
    formatValue: toPrettySpend,
    minIsBest: true,
    requiredTotalsColumns: ["imps", "spend"],
  },
  imps: {
    displayName: "Imp's ('000s)",
    formatValue: toPretty1000sInteger,
  },
  residImps: {
    displayName: "Resid. Imp's ('000s)",
    formatValue: toPretty1000sInteger,
  },
  respImps: {
    displayName: "Resp. Imp's",
    formatValue: toPrettyNumber,
  },
  respIndex: {
    aggregator: () => 1,
    formatValue: toPrettyNumber,
    displayName: "Resp. Index",
    decimals: 2,
    fetchGetter: (fetch, agg) =>
      fetch.respImps && agg.respImps && fetch.residImps && agg.residImps
        ? (fetch.respImps as number) /
          (agg.respImps as number) /
          ((fetch.residImps as number) / (agg.residImps as number))
        : "--",
    requiredTotalsColumns: ["respImps", "residImps"],
  },
  medianFreq: {
    aggregator: agg =>
      agg.weightedMedianFreq && agg.imps
        ? (agg.weightedMedianFreq as number) / (agg.imps as number)
        : "--",
    displayName: "Median Freq.",
    decimals: 2,
    formatValue: toPrettyNumber,
    requiredTotalsColumns: [
      "imps",
      {
        dataVarName: "weightedMedianFreq",
        fetchGetter: fetch => R.defaultTo(0, fetch.imps) * R.defaultTo(0, fetch.medianFreq),
      },
    ],
  },
  reachFreqPerIp: {
    aggregator: agg =>
      agg.weightedReachFreqPerIp && agg.imps
        ? (agg.weightedReachFreqPerIp as number) / (agg.imps as number)
        : "--",
    displayName: "Reach Frequency Per IP ('000s)",
    decimals: 2,
    formatValue: toPrettyNumber,
    requiredTotalsColumns: [
      {
        dataVarName: "weightedReachFreqPerIp",
        fetchGetter: fetch => R.defaultTo(0, fetch.imps) * R.defaultTo(0, fetch.reachFreqPerIp),
      },
    ],
  },
  residResps: {
    displayName: "Resid. Resp's",
    formatValue: toPrettyNumber,
  },
  resps: {
    displayName: "Resp's",
    formatValue: toPrettyNumber,
  },
  spend: {
    displayName: "Spend",
    formatValue: toPrettySpend,
  },
  stddevFreqPerIp: {
    aggregator: agg =>
      agg.weightedStddevFreqPerIp && agg.imps
        ? (agg.weightedReachFreqPerIp as number) / (agg.imps as number)
        : "--",
    displayName: "Standard Deviation of Frequency Per IP",
    decimals: 2,
    formatValue: toPrettyNumber,
    requiredTotalsColumns: [
      {
        dataVarName: "weightedStddevFreqPerIp",
        fetchGetter: fetch => R.defaultTo(0, fetch.imps) * R.defaultTo(0, fetch.stddevFreqPerIp),
      },
    ],
  },
  avgRevenueDirect: {
    displayName: "AOV Revenue",
    formatValue: toPrettySpend,
    aggregator: agg =>
      agg.revenueDirect && agg.volumeDirect
        ? (agg.revenueDirect as number) / (agg.volumeDirect as number)
        : "--",
    fetchGetter: fetch =>
      fetch.revenueDirect && fetch.volumeDirect
        ? (fetch.revenueDirect as number) / (fetch.volumeDirect as number)
        : "--",
    requiredTotalsColumns: ["revenueDirect", "volumeDirect"],
  },
  cpxDirect: {
    displayName: "CPX, Direct",
    formatValue: toPrettyCPX,
    aggregator: agg =>
      agg.volumeDirect && agg.spend ? (agg.spend as number) / (agg.volumeDirect as number) : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volumeDirect && fetch.spend
        ? (fetch.spend as number) / (fetch.volumeDirect as number)
        : "--",
    minIsBest: true,
    requiredTotalsColumns: ["volumeDirect", "spend"],
  },
  cpxDirectRaw: {
    displayName: "CPX, Direct Raw",
    formatValue: toPrettyCPX,
    aggregator: agg =>
      agg.volumeDirectRaw && agg.spend
        ? (agg.spend as number) / (agg.volumeDirectRaw as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volumeDirectRaw && fetch.spend
        ? (fetch.spend as number) / (fetch.volumeDirectRaw as number)
        : "--",
    minIsBest: true,
    requiredTotalsColumns: ["volumeDirectRaw", "spend"],
  },
  revenueDirect: {
    displayName: "Revenue, Direct",
    formatValue: toPrettySpend,
  },
  revenueDirectRaw: {
    displayName: "Revenue, Direct Raw",
    formatValue: toPrettySpend,
  },
  roasDirect: {
    displayName: "ROAS, Direct",
    formatValue: toPrettyNumber,
    aggregator: agg =>
      agg.revenueDirect && agg.spend ? (agg.revenueDirect as number) / (agg.spend as number) : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.revenueDirect && fetch.spend
        ? (fetch.revenueDirect as number) / (fetch.spend as number)
        : "--",
    requiredTotalsColumns: ["revenueDirect", "spend"],
  },
  roasDirectRaw: {
    displayName: "ROAS, Direct Raw",
    formatValue: toPrettyNumber,
    aggregator: agg =>
      agg.revenueDirectRaw && agg.spend
        ? (agg.revenueDirectRaw as number) / (agg.spend as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.revenueDirectRaw && fetch.spend
        ? (fetch.revenueDirectRaw as number) / (fetch.spend as number)
        : "--",
    requiredTotalsColumns: ["revenueDirectRaw", "spend"],
  },
  volumeDirect: {
    displayName: "KPI Volume, Direct",
    formatValue: toPrettyNumber,
  },
  volumeDirectRaw: {
    displayName: "KPI Volume, Direct Raw",
    formatValue: toPrettyNumber,
  },
  avgRevenueFractional: {
    displayName: "AOV, Fractional",
    formatValue: toPrettySpend,
    aggregator: agg =>
      agg.revenueFractional && agg.volumeFractional
        ? (agg.revenueFractional as number) / (agg.volumeFractional as number)
        : "--",
    fetchGetter: fetch =>
      fetch.revenueFractional && fetch.volumeFractional
        ? (fetch.revenueFractional as number) / (fetch.volumeFractional as number)
        : "--",
    requiredTotalsColumns: ["revenueFractional", "volumeFractional"],
  },
  avgRevenueHalo: {
    displayName: "AOV, Halo",
    formatValue: toPrettySpend,
    aggregator: agg =>
      agg.revenueHalo && agg.volumeHalo
        ? (agg.revenueHalo as number) / (agg.volumeHalo as number)
        : "--",
    fetchGetter: fetch =>
      fetch.revenueHalo && fetch.volumeHalo
        ? (fetch.revenueHalo as number) / (fetch.volumeHalo as number)
        : "--",
    requiredTotalsColumns: ["revenueHalo", "volumeHalo"],
  },
  cpxFractional: {
    displayName: "CPX, Fractional",
    formatValue: toPrettyCPX,
    aggregator: agg =>
      agg.volumeFractional && agg.spend
        ? (agg.spend as number) / (agg.volumeFractional as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volumeFractional && fetch.spend
        ? (fetch.spend as number) / (fetch.volumeFractional as number)
        : "--",
    minIsBest: true,
    requiredTotalsColumns: ["volumeFractional", "spend"],
  },
  cpxHalo: {
    displayName: "CPX, Halo",
    formatValue: toPrettyCPX,
    aggregator: agg =>
      agg.volumeHalo && agg.spend ? (agg.spend as number) / (agg.volumeHalo as number) : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volumeHalo && fetch.spend
        ? (fetch.spend as number) / (fetch.volumeHalo as number)
        : "--",
    minIsBest: true,
    requiredTotalsColumns: ["volumeHalo", "spend"],
  },
  cpxHaloRaw: {
    displayName: "CPX, Halo Raw",
    formatValue: toPrettyCPX,
    aggregator: agg =>
      agg.volumeHaloRaw && agg.spend ? (agg.spend as number) / (agg.volumeHaloRaw as number) : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volumeHaloRaw && fetch.spend
        ? (fetch.spend as number) / (fetch.volumeHaloRaw as number)
        : "--",
    minIsBest: true,
    requiredTotalsColumns: ["volumeHaloRaw", "spend"],
  },
  revenueFractional: {
    displayName: "Revenue, Fractional",
    formatValue: toPrettySpend,
  },
  revenueHalo: {
    displayName: "Revenue, Halo",
    formatValue: toPrettySpend,
  },
  revenueHaloRaw: {
    displayName: "Revenue, Halo Raw",
    formatValue: toPrettySpend,
  },
  roasFractional: {
    displayName: "ROAS, Fractional",
    formatValue: toPrettyNumber,
    aggregator: agg =>
      agg.revenueFractional && agg.spend
        ? (agg.revenueFractional as number) / (agg.spend as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.revenueFractional && fetch.spend
        ? (fetch.revenueFractional as number) / (fetch.spend as number)
        : "--",
    requiredTotalsColumns: ["revenueFractional", "spend"],
  },
  roasHalo: {
    displayName: "ROAS, Halo",
    formatValue: toPrettyNumber,
    aggregator: agg =>
      agg.revenueHalo && agg.spend ? (agg.revenueHalo as number) / (agg.spend as number) : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.revenueHalo && fetch.spend
        ? (fetch.revenueHalo as number) / (fetch.spend as number)
        : "--",
    requiredTotalsColumns: ["revenueHalo", "spend"],
  },
  roasHaloRaw: {
    displayName: "ROAS, Halo Raw",
    formatValue: toPrettyNumber,
    aggregator: agg =>
      agg.revenueHaloRaw && agg.spend
        ? (agg.revenueHaloRaw as number) / (agg.spend as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.revenueHaloRaw && fetch.spend
        ? (fetch.revenueHaloRaw as number) / (fetch.spend as number)
        : "--",
    requiredTotalsColumns: ["revenueHaloRaw", "spend"],
  },
  volumeFractional: {
    displayName: "KPI Volume, Fractional",
    formatValue: toPrettyNumber,
  },
  volumeHalo: {
    displayName: "KPI Volume, Halo",
    formatValue: toPrettyNumber,
  },
  volumeHaloRaw: {
    displayName: "KPI Volume, Halo Raw",
    formatValue: toPrettyNumber,
  },
  avgRevenueDirectIncremental: {
    displayName: "AOV, Direct Incremental",
    formatValue: toPrettySpend,
    aggregator: agg =>
      agg.revenueDirectIncremental && agg.volumeDirectIncremental
        ? (agg.revenueDirectIncremental as number) / (agg.volumeDirectIncremental as number)
        : "--",
    fetchGetter: fetch =>
      fetch.revenueDirectIncremental && fetch.volumeDirectIncremental
        ? (fetch.revenueDirectIncremental as number) / (fetch.volumeDirectIncremental as number)
        : "--",
    requiredTotalsColumns: ["revenueDirectIncremental", "volumeDirectIncremental"],
  },
  avgRevenueIncremental: {
    displayName: "AOV, Incremental",
    formatValue: toPrettySpend,
    aggregator: agg =>
      agg.revenueIncremental && agg.volumeIncremental
        ? (agg.revenueIncremental as number) / (agg.volumeIncremental as number)
        : "--",
    fetchGetter: fetch =>
      fetch.revenueIncremental && fetch.volumeIncremental
        ? (fetch.revenueIncremental as number) / (fetch.volumeIncremental as number)
        : "--",
    requiredTotalsColumns: ["revenueIncremental", "volumeIncremental"],
  },
  avgRevenueFullFunnel: {
    displayName: "AOV, Full Funnel",
    formatValue: toPrettySpend,
    aggregator: agg =>
      agg.revenueFullFunnel && agg.volumeFullFunnel
        ? (agg.revenueFullFunnel as number) / (agg.volumeFullFunnel as number)
        : "--",
    fetchGetter: fetch =>
      fetch.revenueFullFunnel && fetch.volumeFullFunnel
        ? (fetch.revenueFullFunnel as number) / (fetch.volumeFullFunnel as number)
        : "--",
    requiredTotalsColumns: ["revenueFullFunnel", "volumeFullFunnel"],
  },
  cpxDirectIncremental: {
    displayName: "CPX, Direct Incremental",
    formatValue: toPrettyCPX,
    aggregator: agg =>
      agg.volumeDirectIncremental && agg.spend
        ? (agg.spend as number) / (agg.volumeDirectIncremental as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volumeDirectIncremental && fetch.spend
        ? (fetch.spend as number) / (fetch.volumeDirectIncremental as number)
        : "--",
    minIsBest: true,
    requiredTotalsColumns: ["volumeDirectIncremental", "spend"],
  },
  cpxIncremental: {
    displayName: "CPX, Incremental",
    formatValue: toPrettyCPX,
    aggregator: agg =>
      agg.volumeIncremental && agg.spend
        ? (agg.spend as number) / (agg.volumeIncremental as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volumeIncremental && fetch.spend
        ? (fetch.spend as number) / (fetch.volumeIncremental as number)
        : "--",
    minIsBest: true,
    requiredTotalsColumns: ["volumeIncremental", "spend"],
  },
  cpxFullFunnel: {
    displayName: "CPX, Full Funnel",
    formatValue: toPrettyCPX,
    aggregator: agg =>
      agg.volumeFullFunnel && agg.spend
        ? (agg.spend as number) / (agg.volumeFullFunnel as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volumeFullFunnel && fetch.spend
        ? (fetch.spend as number) / (fetch.volumeFullFunnel as number)
        : "--",
    minIsBest: true,
    requiredTotalsColumns: ["volumeFullFunnel", "spend"],
  },
  cpxFullFunnelRaw: {
    displayName: "CPX, Full Funnel Raw",
    formatValue: toPrettySpend,
    aggregator: agg =>
      agg.volumeFullFunnelRaw && agg.spend
        ? (agg.spend as number) / (agg.volumeFullFunnelRaw as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volumeFullFunnelRaw && fetch.spend
        ? (fetch.spend as number) / (fetch.volumeFullFunnelRaw as number)
        : "--",
    minIsBest: true,
    requiredTotalsColumns: ["volumeFullFunnelRaw", "spend"],
  },
  revenueDirectIncremental: {
    displayName: "Revenue, Direct Incremental",
    formatValue: toPrettySpend,
  },
  revenueIncremental: {
    displayName: "Revenue, Incremental",
    formatValue: toPrettySpend,
  },
  revenueFullFunnel: {
    displayName: "Revenue, Full Funnel",
    formatValue: toPrettySpend,
  },
  revenueFullFunnelRaw: {
    displayName: "Revenue, Full Funnel Raw",
    formatValue: toPrettySpend,
  },
  roasDirectIncremental: {
    displayName: "ROAS, Direct Incremental",
    formatValue: toPrettyNumber,
    aggregator: agg =>
      agg.revenueDirectIncremental && agg.spend
        ? (agg.revenueDirectIncremental as number) / (agg.spend as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.revenueDirectIncremental && fetch.spend
        ? (fetch.revenueDirectIncremental as number) / (fetch.spend as number)
        : "--",
    requiredTotalsColumns: ["revenueDirectIncremental", "spend"],
  },
  roasIncremental: {
    displayName: "ROAS, Incremental",
    formatValue: toPrettyNumber,
    aggregator: agg =>
      agg.revenueIncremental && agg.spend
        ? (agg.revenueIncremental as number) / (agg.spend as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.revenueIncremental && fetch.spend
        ? (fetch.revenueIncremental as number) / (fetch.spend as number)
        : "--",
    requiredTotalsColumns: ["revenueIncremental", "spend"],
  },
  roasFullFunnel: {
    displayName: "ROAS, Full Funnel",
    formatValue: toPrettyNumber,
    aggregator: agg =>
      agg.revenueFullFunnel && agg.spend
        ? (agg.revenueFullFunnel as number) / (agg.spend as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.revenueFullFunnel && fetch.spend
        ? (fetch.revenueFullFunnel as number) / (fetch.spend as number)
        : "--",
    requiredTotalsColumns: ["revenueFullFunnel", "spend"],
  },
  roasFullFunnelRaw: {
    displayName: "ROAS, Full Funnel Raw",
    formatValue: toPrettyNumber,
    aggregator: agg =>
      agg.revenueFullFunnelRaw && agg.spend
        ? (agg.revenueFullFunnelRaw as number) / (agg.spend as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.revenueFullFunnelRaw && fetch.spend
        ? (fetch.revenueFullFunnelRaw as number) / (fetch.spend as number)
        : "--",
    requiredTotalsColumns: ["revenueFullFunnelRaw", "spend"],
  },
  volumeDirectIncremental: {
    displayName: "KPI Volume, Direct Incremental",
    formatValue: toPrettyNumber,
  },
  volumeIncremental: {
    displayName: "KPI Volume, Incremental",
    formatValue: toPrettyNumber,
  },
  volumeFullFunnel: {
    displayName: "KPI Volume, Full Funnel",
    formatValue: toPrettyNumber,
  },
  volumeFullFunnelRaw: {
    displayName: "KPI Volume, Full Funnel Raw",
    formatValue: toPrettyNumber,
  },
  percentDirectIncremental: {
    displayName: "% Inc, Direct",
    formatValue: toPrettyPercent,
    aggregator: agg =>
      agg.volumeDirectIncremental && agg.volumeDirect
        ? (agg.volumeDirectIncremental as number) / (agg.volumeDirect as number)
        : "--",
    fetchGetter: fetch =>
      fetch.volumeDirectIncremental && fetch.volumeDirect
        ? (fetch.volumeDirectIncremental as number) / (fetch.volumeDirect as number)
        : "--",
    requiredTotalsColumns: ["volumeDirectIncremental", "volumeDirect"],
  },
  percentIncremental: {
    displayName: "% Inc.",
    formatValue: toPrettyPercent,
    aggregator: agg =>
      agg.volumeIncremental && agg.volumeFullFunnel
        ? (agg.volumeIncremental as number) / (agg.volumeFullFunnel as number)
        : "--",
    fetchGetter: fetch =>
      fetch.volumeIncremental && fetch.volumeFullFunnel
        ? (fetch.volumeIncremental as number) / (fetch.volumeFullFunnel as number)
        : "--",
    requiredTotalsColumns: ["volumeIncremental", "volumeFullFunnel"],
  },
  impsConversionRateDirect: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.volumeDirectDirect
        ? ((agg.volumeDirect as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "Imps Conversion Rate Direct",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.volumeDirect
        ? ((fetch.volumeDirect as number) * 1.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "volumeDirect"],
  },
  impsConversionRateIncremental: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.volumeIncremental
        ? ((agg.volumeIncremental as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "Imps Conversion Rate Incremental",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.volumeIncremental
        ? ((fetch.volumeIncremental as number) * 1.0) /
          R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "volumeIncremental"],
  },
  impsConversionRateFullFunnel: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.volumeFullFunnel
        ? ((agg.volumeFullFunnel as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "Imps Conversion Rate Full Funnel",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.volumeFullFunnel
        ? ((fetch.volumeFullFunnel as number) * 1.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "volumeFullFunnel"],
  },
  impsConversionRateIncrementalRaw: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.volumeIncrementalRaw
        ? ((agg.volumeIncrementalRaw as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "Imps Conversion Rate Incremental Raw",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.volumeIncrementalRaw
        ? ((fetch.volumeIncrementalRaw as number) * 1.0) /
          R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "volumeIncrementalRaw"],
  },
  impsConversionRateFullFunnelRaw: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.volumeFullFunnelRaw
        ? ((agg.volumeFullFunnelRaw as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "Imps Conversion Rate Full Funnel Raw",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.volumeFullFunnelRaw
        ? ((fetch.volumeFullFunnelRaw as number) * 1.0) /
          R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "volumeFullFunnelRaw"],
  },
  impsConversionRateDirectRaw: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.volumeDirectRaw
        ? ((agg.volumeDirectRaw as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "Imps Conversion Rate Direct Raw",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.volumeDirectRaw
        ? ((fetch.volumeDirectRaw as number) * 1.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "volumeDirectRaw"],
  },
};

export const getDimensionCell = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn,
  creativeMap?: CreativeMap,
  derivedNetworkMap?: DerivedNetworkMap
): CellData => {
  const { dimensionTypeName, dimensionVarName, icon } = dimensionHeader;
  const resolvedDimensionData = dimensionData as Record<S.PerformanceDimension, string>;
  const resolvedDerivedNetworkMap = R.defaultTo({}, derivedNetworkMap);
  const resolvedCreativeMap = R.defaultTo({}, creativeMap);
  const dimensionValue = resolvedDimensionData[dimensionVarName];
  const defaultCell = {
    label: dimensionValue,
    value: dimensionValue,
  };
  if (dimensionVarName === "Network") {
    const derivedNetwork = resolvedDerivedNetworkMap[dimensionValue];
    if (derivedNetwork) {
      const overlayText =
        !R.isNil(icon) || dimensionTypeName === "Network Logo" ? derivedNetwork.network : undefined;
      const url =
        !R.isNil(icon) || dimensionTypeName === "Network Logo"
          ? `${CDN}/networks/${derivedNetwork.network}.png`
          : undefined;
      if (dimensionTypeName === "Network Logo") {
        return {
          label: derivedNetwork.network,
          overlayText,
          url,
          value: derivedNetwork.network,
        };
      } else if (dimensionTypeName === "Network Name") {
        return {
          label: derivedNetwork.network,
          overlayText,
          url,
          value: derivedNetwork.network,
        };
      } else if (dimensionTypeName === "Description") {
        return {
          label: derivedNetwork.description,
          overlayText,
          url,
          value: derivedNetwork.description,
        };
      } else if (dimensionTypeName === "Derived ID") {
        return {
          ...defaultCell,
          overlayText,
          url,
        };
      }
      return defaultCell;
    } else {
      return defaultCell;
    }
  } else if (dimensionVarName === "Network Group") {
    const url =
      !R.isNil(icon) || dimensionTypeName === "Network Group Logo"
        ? `${CDN}/networks/${dimensionValue.toUpperCase()}.png`
        : undefined;
    const overlayText =
      !R.isNil(icon) || dimensionTypeName === "Network Group Logo" ? dimensionValue : undefined;
    if (dimensionTypeName === "Network Group Logo") {
      return {
        ...defaultCell,
        overlayText,
        url,
      };
    } else if (dimensionTypeName === "Network Group Name") {
      return { ...defaultCell, overlayText: dimensionValue, url };
    }
    return defaultCell;
  } else if (dimensionVarName === "Creative") {
    const creative = resolvedCreativeMap[dimensionValue];
    if (creative) {
      const overlayText =
        !R.isNil(icon) || dimensionTypeName === "Creative Thumbnail" ? dimensionValue : undefined;
      const url =
        !R.isNil(icon) || dimensionTypeName === "Creative Thumbnail"
          ? getCreativeThumbnail(creative.company, creative)
          : undefined;
      if (dimensionTypeName === "Creative Thumbnail") {
        return {
          ...defaultCell,
          overlayText,
          url,
        };
      } else if (dimensionTypeName === "Creative") {
        return {
          label: creative.name,
          overlayText,
          url,
          value: creative.name,
        };
      }
    }
    return defaultCell;
  } else if (dimensionVarName === "Length") {
    return {
      label: `${dimensionValue} sec`,
      value: dimensionValue,
    };
  } else if (dimensionVarName === "DeviceOS") {
    let [device, os] = dimensionValue.toUpperCase().split("_");
    const isDevice = dimensionTypeName === "Device" || dimensionTypeName === "Device Logo";
    const isLogo = dimensionTypeName === "Device Logo" || dimensionTypeName === "OS Logo";
    const datum = ((isDevice ? device : os) || "").toUpperCase();
    if (!R.isNil(icon) || isLogo) {
      const url = `${CDN}/networks/${datum}.png`;
      return {
        ...defaultCell,
        overlayText: datum,
        url,
      };
    } else {
      return {
        label: datum,
        value: datum,
      };
    }
  }
  return defaultCell;
};

export const GLOSSARY_IP: GlossaryItem[] = [
  {
    term: "eCPM",
    definition:
      "Effective Cost Per Thousand Impressions. This is the total spend times 1,000 divided by the number of impressions delivered.",
  },
  {
    term: "Imp's ('000s)",
    definition:
      "Responding Impressions. The number of residential impressions that resulted in a direct KPI response.",
  },
  {
    term: "Resp. Imp's",
    definition:
      "Responding Impressions. The number of residential impressions that resulted in a direct KPI response.",
  },
  {
    term: "Resp. Index",
    definition:
      "Response Index. The ratio of a row's response rate to the overall campaign response rate.  It's (responding impressions) / (total responding impressions) / (matchable impressions) / (total matchable impressions).",
  },
  {
    term: "Volume",
    definition: "KPI Volume. The number of trued-up KPIs attributed to our impressions.",
  },
  {
    term: "ROAS",
    definition: "Return On Ad Spend. It's the total revenue divided by the total spend.",
  },
  {
    term: "Direct",
    definition:
      "Direct KPIs. These are people who saw our ad and directly converted without seeing media from other marketing channels.",
  },
  {
    term: "Halo",
    definition:
      "KPIs from people who saw our ad, then saw media from another marketing channel, then converted.",
  },
  {
    term: "Fractional",
    definition:
      "KPI attribution less permissive than Halo meant to model partial contributions from non-direct responses. For more detail, see this document: https://cdn.blisspointmedia.com/reference/Fractional%20Attribution%20BPM.pdf",
  },
  {
    term: "Response Between",
    definition:
      "The selected date range considers campaign performance based on when conversions occur.",
  },
  {
    term: "Weekly Frequency",
    definition:
      "Either the average or the median of the number of the number of times in a week an impression is delivered to the same IP address across all delivered IPs. If the same people are being delivered ads multiple times, these numbers increase.",
  },
  {
    term: "Res. Del. Rate",
    definition:
      "Residential Delivery Rate. The ratio of delivered residential impressions to total delivered impressions.",
  },
  {
    term: "Res. Resp. Rate",
    definition: "Residential Response Rate. The ratio of residential responses to total responses.",
  },
  {
    term: "Resid. Imp's",
    definition:
      "Residential Impressions. The number of impressions, in the thousands, served to residential IP addresses.",
  },
  {
    term: "Resid. Resp's",
    definition: "Residential Responses. The number of responses from residential IP addresses",
  },
  {
    term: "CPX",
    definition:
      'Cost per KPI. The average cost for a single KPI. The "X" is usually an abbreviation for the KPI, such as "CPV" for "visits" or "CPO" for "orders".',
  },
  {
    term: "Lag",
    definition:
      "The number of days after an impression a response can occur for it to still be attributable.",
  },
  {
    term: "Imp's Served Between",
    definition:
      "The selected date range considers campaign performance based on when impressions are served; responses occurring outside the selected date range are still counted if they are within the specified lag window.",
  },
  {
    term: "Response Between",
    definition:
      "The selected date range considers campaign performance based on when conversions occur.",
  },
  {
    term: "Weekly Frequency",
    definition:
      "Either the average or the median of the number of the number of times in a week an impression is delivered to the same IP address across all delivered IPs. If the same people are being delivered ads multiple times, these numbers increase.",
  },
];

export const GLOSSARY_GRAPH: GlossaryItem[] = [
  {
    term: "Full Funnel Volume",
    definition:
      "The volume of conversions with a deterministic link between ad exposure and conversion.",
  },
  {
    term: "% Incremental",
    definition:
      "The percentage of campaign conversions that are considered to be causal, i.e. the conversion occurred because of the ad exposure and would not have occurred in its absence. Incrementality Coefficient = (Treatment Response Rate - Control Response Rate) / (Treatment Response Rate)",
  },
  {
    term: "iVolume",
    definition:
      "Incremental KPI volume. The number of incremental conversions causally attributed to our campaign.",
  },
  {
    term: "iCPX",
    definition:
      'Cost per incremental conversion. The "X" is usually an abbreviation for the KPI, such as "CPV" for "visits" or "CPO" for "orders".',
  },
  {
    term: "Direct",
    definition:
      "Direct KPIs. This refers to the subset of conversions that take a high-intent response path (typically, direct or brand search) as opposed to clicking through from a paid marketing source (e.g. Facebook).",
  },
  {
    term: "Imp's ('000s)",
    definition: "Number of impressions, in thousands.",
  },
  {
    term: "Imp's Served Between",
    definition:
      "The selected date range considers campaign performance based on when impressions are served; responses occurring outside the selected date range are still counted if they are within the specified lag window.",
  },
  {
    term: "Lag",
    definition:
      "The maximum number of days following an impression that a conversion is eligible for attribution to that impression.",
  },
  {
    term: "iROAS",
    definition:
      "Incremental Return On Ad Spend. The revenue generated by a campaign divided by the investment in that campaign.",
  },
  {
    term: "Matchable Delivery Rate",
    definition:
      "The fraction of delivered impressions that are resolvable to an individual user/household.",
  },
  {
    term: "Matchable Response Rate",
    definition: "The fraction of conversions that are resolvable to an individual user/household.",
  },
  {
    term: "Matchable Imp's",
    definition:
      "Matchable Impressions. The number of impressions, in the thousands, served to resolvable users/households.",
  },
  {
    term: "Matchable Resp's",
    definition: "Matchable Responses. The number of responses from resolvable users/households.",
  },
  {
    term: "Resp. Imp's",
    definition:
      "Responding Impressions. The number of matchable impressions that resulted in a direct KPI response.",
  },
  {
    term: "Resp. Index",
    definition:
      "Response Index. The ratio of a row's response rate to the overall campaign response rate.  It's (responding impressions) / (total responding impressions) / (matchable impressions) / (total matchable impressions).",
  },
  {
    term: "Response Between",
    definition:
      "The selected date range considers campaign performance based on when conversions occur.",
  },
  {
    term: "Weekly Frequency",
    definition:
      "Either the average or the median number of times in a week an impression is delivered to the same user/household. If the same people are being delivered ads multiple times, these numbers increase.",
  },
  {
    term: "eCPM",
    definition:
      "Effective cost per thousand impressions. This is the total spend times 1,000 divided by the number of impressions delivered.",
  },
];

import React, { useState, useCallback } from "react";

import { Card, ImportModal } from "../../Components";
import { Button, Spinner } from "react-bootstrap";
import { StreamingUtilsLambdaFetch } from "../../utils/fetch-utils";
import { AdOpsToolProps } from "../AdOps";
import * as XLSX from "xlsx";
import { excelNumberToDate } from "../../BudgetIntakeTool/excelUtils";

const extractBPMText = (input: string): string => {
  const match = input.match(/BPM\S*/);
  return match ? match[0] : "";
};

interface NexxenExcelRow {
  data_date: number;
  creative_name: string;
  audience_data_cost: string;
  content_data_cost: string;
  turn_tpc: string;
  total_first_party_cost: string;
  traffic_acq_cost: string;
  cogs: string;
}

interface NexxenData {
  date: string;
  network: string;
  dsp_media_cost: number;
  dsp_tech_fee: number;
  cost: number;
}

const NexxenCostAdjuster: React.FC<AdOpsToolProps> = ({ utils }) => {
  const [activeAPICall, setActiveAPICall] = useState(false);
  const { setError } = utils;

  const [showBulkImportModal, setShowBulkImportModal] = useState(false);
  const [file, setFile] = useState<File | undefined>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [derivedIdList, setDerivedIdList] = useState<string[]>();
  const [excelDataMap, setExcelDataMap] = useState<{ [key: string]: NexxenData }>({});

  const adjustNexxenCosts = useCallback(async () => {
    try {
      setActiveAPICall(true);
      await StreamingUtilsLambdaFetch("/nexxenMonthlyCostAdjuster", {
        method: "POST",
        body: JSON.stringify({
          startDate,
          endDate,
          derivedIdList,
          excelDataMap,
        }),
      });
      setStartDate(undefined);
      setEndDate(undefined);
      setDerivedIdList(undefined);
      setExcelDataMap({});
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
    }
    setActiveAPICall(false);
  }, [startDate, endDate, derivedIdList, excelDataMap, setError]);

  const importNexxenData = useCallback(() => {
    try {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = event => {
          if (event.target) {
            const arrayBuffer = event.target.result as ArrayBuffer;
            const workbook = XLSX.read(arrayBuffer, { type: "array" });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const rows: NexxenExcelRow[] = XLSX.utils.sheet_to_json(worksheet);
            const initialData = {
              dataMap: {} as { [key: string]: NexxenData },
              minDate: null as string | null,
              maxDate: null as string | null,
              uniqueDerivedIds: new Set<string>(),
            };

            const { dataMap, minDate, maxDate, uniqueDerivedIds } = rows.reduce((acc, row) => {
              const date = excelNumberToDate(row.data_date);
              const network = extractBPMText(row.creative_name);
              acc.uniqueDerivedIds.add(network);
              const key = `${date}_${network}`;
              const mediaCostComponents = [
                parseFloat(row.audience_data_cost),
                parseFloat(row.content_data_cost),
                parseFloat(row.turn_tpc),
                parseFloat(row.total_first_party_cost),
                parseFloat(row.traffic_acq_cost),
              ];
              const totalMediaCost = mediaCostComponents.reduce((sum, cost) => sum + cost, 0);
              const dspTechFee = parseFloat(row.cogs) - totalMediaCost;
              const cost = dspTechFee + totalMediaCost;

              if (key in acc.dataMap) {
                const existing = acc.dataMap[key];
                existing.dsp_media_cost += totalMediaCost;
                existing.dsp_tech_fee += dspTechFee;
                existing.cost += cost;
              } else {
                acc.dataMap[key] = {
                  date,
                  network,
                  dsp_media_cost: totalMediaCost,
                  dsp_tech_fee: dspTechFee,
                  cost,
                };
              }
              if (!acc.minDate || date < acc.minDate) {
                acc.minDate = date;
              }
              if (!acc.maxDate || date > acc.maxDate) {
                acc.maxDate = date;
              }

              return acc;
            }, initialData);
            const startDate = minDate || undefined;
            const endDate = maxDate || undefined;
            setStartDate(startDate);
            setEndDate(endDate);
            setDerivedIdList(Array.from(uniqueDerivedIds));
            setExcelDataMap(dataMap);
          }
        };
        fileReader.readAsArrayBuffer(file);
      }
    } catch (e) {
      const reportError = e as Error;
      setError({
        message: `Failed to import: ${reportError.message}`,
        reportError,
      });
    }
  }, [file, setError]);

  return (
    <Card className="adOpsCard">
      <div className="adOpsCardTitle">Nexxen Cost Adjuster</div>
      <div className="adOpsElem">
        <Button onClick={() => setShowBulkImportModal(true)}>Import Nexxen File</Button>
      </div>
      <div className="adOpsElem">
        <Button
          onClick={adjustNexxenCosts}
          disabled={
            startDate === undefined ||
            endDate === undefined ||
            derivedIdList === undefined ||
            excelDataMap === undefined
          }
        >
          {activeAPICall ? <Spinner animation={"border"} /> : "Submit"}
        </Button>
      </div>
      {showBulkImportModal && (
        <ImportModal
          importFunction={importNexxenData}
          file={file}
          setFile={selectedFile => {
            if (!selectedFile || !selectedFile.name.endsWith(".xlsx")) {
              setError({
                message: "File must be a .xlsx",
                reportError: new Error("File must be a .xlsx"),
              });
            } else {
              setFile(selectedFile);
            }
          }}
          onClose={() => setShowBulkImportModal(false)}
        />
      )}
    </Card>
  );
};

export default NexxenCostAdjuster;

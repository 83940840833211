import React, { useContext } from "react";
import { FormContext } from "../MetaBuyingContext";
import { Button, ButtonType } from "../../Components";
import { Modal } from "react-bootstrap";
import { RouteComponentProps } from "@reach/router";
import { useSetError } from "../../redux/modals";
// import { MetaLambdaFetch } from "../../utils/fetch-utils";
import * as R from "ramda";
import "./MetaBuyingReviewModal.scss";

const CAMPAIGN_LABEL_MAP = [
  {
    label: "Segmentation ID",
    dataKey: "segmentation_id",
  },
  {
    label: "Campaign Objective",
    dataKey: "objective",
  },
  {
    label: "Special Ad Categories",
    dataKey: "special_ad_categories",
  },
  {
    label: "Budget",
    dataKey: "budget",
  },
  {
    label: "Bid Strategy",
    dataKey: "bid_strategy",
  },
];

const ADSET_LABEL_MAP = [
  {
    label: "Campaign",
    dataKey: "campaign",
  },
  {
    label: "Budget",
    dataKey: "budget",
  },
  {
    label: "Targeting",
    dataKey: "custom_audiences",
  },
  {
    label: "Geo",
    dataKey: "geo",
  },
  {
    label: "Pixel",
    dataKey: "pixel",
  },
  {
    label: "Conversion Event",
    dataKey: "conversion_event",
  },
  {
    label: "Optimization Goal",
    dataKey: "optimization_goal",
  },
  {
    label: "Bid Strategy",
    dataKey: "bid_strategy",
  },
  {
    label: "Bid Amount",
    dataKey: "bid_amount",
  },
  {
    label: "Attribution Window",
    dataKey: "attribution_window",
  },
];

const AD_LABEL_MAP = [
  {
    label: "Ad Set",
    dataKey: "ad_set",
  },
  {
    label: "Facebook Page",
    dataKey: "objective",
  },
  {
    label: "Instagram Page",
    dataKey: "special_ad_categories",
  },
  {
    label: "Creative Format",
    dataKey: "budget",
  },
  {
    label: "Asset",
    dataKey: "bid_strategy",
  },
  {
    label: "Link",
    dataKey: "ad_set",
  },
  {
    label: "Ad Copy",
    dataKey: "objective",
  },
  {
    label: "Ad Headline",
    dataKey: "special_ad_categories",
  },
  {
    label: "Link Description",
    dataKey: "cta_link_description",
  },
  {
    label: "CTA",
    dataKey: "cta_text",
  },
];

export interface CampaignPayload {
  client: string;
  business_name: string;
  ad_account_id: string;
  name: string;
  objective: string;
  special_ad_categories: string[];
  lifetime_budget?: number;
  daily_budget?: number;
  spend_cap?: number;
  bid_strategy?: string;
}

// const enum CreationTypes {
//   profiles = 'a',
//   locations = 'locations',
//   change_history = 'change_history'
// }

export interface MetaBuyingReviewModalProps {
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  client: string;
  show: boolean;
  closeModal: () => void;
  creationTabs: string[];
  namingConventions: { campaign: string[]; adset: string[]; ad: string[] };
  constructNameUsingConvention: (
    form: Record<string, string | number>,
    namingConvention: string[]
  ) => string;
}

export const MetaBuyingReviewModal = ({
  path,
  selectedAdAccount,
  client,
  show,
  closeModal,
  creationTabs,
  namingConventions,
  constructNameUsingConvention,
}: MetaBuyingReviewModalProps & RouteComponentProps): JSX.Element => {
  const { metaCampaignForm, metaAdSetForm, metaAdForm } = useContext(FormContext);

  const setError = useSetError();

  const renderReviewRows = (
    labelMap: { label: string; dataKey: string }[],
    form: Record<string, string | number>
  ): JSX.Element[] =>
    labelMap.map(({ label, dataKey }) => (
      <div className="metaBuyingReviewRow" key={label}>
        <div>{label}</div>
        <span>{R.isEmpty(form[dataKey]) ? "--" : form[dataKey]}</span>
      </div>
    ));

  interface ReviewModalColumnProps {
    type: string;
    labelMap: { label: string; dataKey: string }[];
    form: Record<string, string | number>;
    width: string | number;
  }

  const ReviewModalColumn = ({
    type,
    labelMap,
    form,
    width,
  }: ReviewModalColumnProps): JSX.Element => (
    <div className="metaBuyingReviewColumn" style={{ width }}>
      <div className="metaBuyingReviewColumnHeader">
        {type}
        <span>
          {constructNameUsingConvention(
            form,
            namingConventions[type.replace(" ", "").toLocaleLowerCase()]
          )}
        </span>
      </div>
      {renderReviewRows(labelMap, form)}
    </div>
  );

  const REVIEW_COLUMNS = {
    Campaign: {
      labelMap: CAMPAIGN_LABEL_MAP,
      form: metaCampaignForm,
    },
    "Ad Set": {
      labelMap: ADSET_LABEL_MAP,
      form: metaAdSetForm,
    },
    Ad: {
      labelMap: AD_LABEL_MAP,
      form: metaAdForm,
    },
  };

  const validateCampaignForm = () => {
    let payloadCampaign: CampaignPayload = {
      // business_name,
      // ad_account_id,
      // name,
      // objective,
      // special_ad_categories,
      // lifetime_budget,
      // daily_budget,
      // spend_cap,
      // bid_strategy,

      client,
      business_name: "",
      ad_account_id: "",
      name: "",
      objective: "",
      special_ad_categories: [],
    };

    namingConventions.campaign.forEach(element => {
      if (R.isEmpty(metaCampaignForm[element])) {
        throw new Error(`Missing required field '${element}'`);
      }
    });

    // let payloadAdSet = {};
    // let payloadAd = {};
    if (R.includes("Campaign", creationTabs)) {
      if (metaCampaignForm.budget_type === "CAMPAIGN_LIFETIME") {
        payloadCampaign.lifetime_budget = metaCampaignForm.budget;
        payloadCampaign.bid_strategy = metaCampaignForm.bid_strategy;
      } else if (metaCampaignForm.budget_type === "CAMPAIGN_DAILY") {
        payloadCampaign.daily_budget = metaCampaignForm.budget;
        payloadCampaign.bid_strategy = metaCampaignForm.bid_strategy;
      }
    }
  };

  const submitForms = (status: "paused" | "draft") => {
    if (R.includes("Campaign", creationTabs)) {
      try {
        console.log("submit campaign");
        const validatedCampaignForm = validateCampaignForm();
        console.log("validated campaign: ", validatedCampaignForm);
        // await MetaLambdaFetch("/createMetaCampaign", {
        //   method: "POST",
        //   body: {
        //     business_name: selectedAdAccount.business_manager.toLocaleUpperCase(),
        //     ad_account_id: selectedAdAccount.account_id,
        //     name: constructNameUsingConvention(metaCampaignForm, namingConventions.campaign),
        //     objective: metaCampaignForm.objective,
        //     special_ad_categories: metaCampaignForm.special_ad_categories,
        //   },
        // });
      } catch (e) {
        const error = e as Error;
        setError({
          message: `Failed to save draft. Error: ${error.message}`,
          reportError: error,
        });
      }
    }
    if (R.includes("Ad Set", creationTabs)) {
      try {
        console.log("submit ad set");
        // await MetaLambdaFetch("/createMetaAdSet", {
        //   method: "POST",
        //   body: {},
        // });
      } catch (e) {
        const error = e as Error;
        setError({
          message: `Failed to save draft. Error: ${error.message}`,
          reportError: error,
        });
      }
    }
    if (R.includes("Ad", creationTabs)) {
      try {
        console.log("submit ad");
        // await MetaLambdaFetch("/createMetaAd", {
        //   method: "POST",
        //   body: {},
        // });
      } catch (e) {
        const error = e as Error;
        setError({
          message: `Failed to save draft. Error: ${error.message}`,
          reportError: error,
        });
      }
    }
  };

  return (
    <Modal show={show} onHide={() => closeModal()} className="metaBuyingReviewModal">
      <Modal.Header closeButton>
        <Modal.Title>Review</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="metaBuyingReviewColumns">
          {creationTabs.map(tab => (
            <ReviewModalColumn
              key={tab}
              type={tab}
              labelMap={REVIEW_COLUMNS[tab].labelMap}
              form={REVIEW_COLUMNS[tab].form}
              width={`${100 / creationTabs.length}%`}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type={ButtonType.FILLED} onClick={() => submitForms("paused")}>
          Publish As Paused
        </Button>
        <Button type={ButtonType.FILLED} onClick={() => submitForms("draft")}>
          Save As Draft
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MetaBuyingReviewModal;

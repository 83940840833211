import "./SparkChart.scss";
import { Metric, Metrics, MetricTotalsByDate } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import { Brand0, Brand30, Brand60 } from "../../utils/colors";
import { DownloadDropdown, BPMTable, Dropdown, DropdownToggleType, IconToggleButton } from "..";
import { formatDateLabel, metricFormatter } from "../../TVADCrossChannel/homePageUtils";
import { LINE_STROKE_WIDTH } from "../../TVADCrossChannel/homePageConstants";
import { MdShowChart, MdOutlineTableRows, MdCallMade } from "react-icons/md";
import { METRIC_OPTIONS } from "../../CrossChannel/crossChannelConstants";
import { XAxis, YAxis, Tooltip, Line, Area, ComposedChart } from "recharts";
import * as R from "ramda";
import AutoSizer from "react-virtualized-auto-sizer";
import React, { useMemo, useState, useCallback } from "react";
import { makePrettyDateRange } from "../../CrossChannel/crossChannelUtils";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import ChartTooltipV2 from "../Charts/ChartTooltip/ChartTooltipV2";

const SHOW_GRAPH = "showGraph";
const SHOW_TABLE = "showTable";
const SHOW_TOTAL = "showTotal";
const SHOW_AVG = "showAvg";

const ONLY_SHOW_AVERAGE_METRICS = {
  [Metric.CPC]: true,
  [Metric.CPM]: true,
  [Metric.CPX]: true,
  [Metric.ROAS]: true,
};

interface JoinedData {
  date: string;
  date_other: string;
  metrics: Metrics;
  metrics_other: Metrics;
}

interface SparkChartProps {
  data: MetricTotalsByDate[]; // Primary date range
  otherData: MetricTotalsByDate[]; // Comparison date range
  defaultMetric?: `${Metric}`;
  defaultTotal?: boolean;
  metricOptions?: { label: string; value: `${Metric}` }[];
  pngFileIdentifiers?: string[];
  pngLabel?: string;
}

/**
 * Combine data and otherData into one array, even though they can be of differing lengths/date ranges.
 * Add "_other" suffix to the keys of the comparison data to avoid conflicts.
 */
const getJoinedData = (
  data: MetricTotalsByDate[],
  otherData: MetricTotalsByDate[]
): JoinedData[] => {
  const maxLength = Math.max(data.length, otherData.length);

  let joined: JoinedData[] = [];
  for (let i = 0; i < maxLength; i++) {
    const item = data[i] || {};
    // Rename keys of comparison data
    const otherItem = R.keys(otherData[i] || {}).reduce((acc, key) => {
      acc[`${key}_other`] = (otherData[i] || {})[key];
      return acc;
    }, {});

    joined.push({
      ...item,
      ...otherItem,
    } as JoinedData);
  }

  return joined;
};

const SparkChart: React.FC<SparkChartProps> = React.memo(
  ({
    data,
    otherData,
    defaultMetric,
    defaultTotal = false,
    metricOptions = METRIC_OPTIONS,
    pngFileIdentifiers = [],
    pngLabel,
  }) => {
    const [selectedMetric, setSelectedMetric] = useState<`${Metric}`>(
      defaultMetric || Metric.SPEND
    );
    const [view, setView] = useState<string>(SHOW_GRAPH);
    const [hover, setHover] = useState<boolean>(false);
    const [valueToggle, setValueToggle] = useState<string>(
      ONLY_SHOW_AVERAGE_METRICS[selectedMetric] && defaultTotal === false
        ? SHOW_AVG
        : defaultTotal
        ? SHOW_TOTAL
        : SHOW_AVG
    );

    const onlyShowAverage = useMemo(() => ONLY_SHOW_AVERAGE_METRICS[selectedMetric], [
      selectedMetric,
    ]);

    const { total, otherTotal, percentChange, average, otherAverage } = useMemo(() => {
      const total = data.reduce(
        (acc, curr) => acc + R.pathOr(0, ["metrics", selectedMetric], curr),
        0
      );
      const otherTotal = otherData.reduce(
        (acc, curr) => acc + R.pathOr(0, ["metrics", selectedMetric], curr),
        0
      );
      const percentChange = Math.round(((total - otherTotal) / otherTotal) * 100);

      const average =
        data.reduce((acc, curr) => acc + R.pathOr(0, ["metrics", selectedMetric], curr), 0) /
        data.length;
      const otherAverage =
        otherData.reduce((acc, curr) => acc + R.pathOr(0, ["metrics", selectedMetric], curr), 0) /
        otherData.length;

      return {
        total,
        otherTotal,
        percentChange: isFinite(percentChange) ? percentChange : "--",
        average,
        otherAverage,
      };
    }, [data, otherData, selectedMetric]);

    const metricsToPrettyNameMap = useMemo(() => {
      const prettyNameMap = {};
      for (let metric of metricOptions) {
        prettyNameMap[metric.value] = metric.label;
      }
      return prettyNameMap;
    }, [metricOptions]);

    const [headers, superHeaders] = useMemo(() => {
      let headers: any[] = [
        {
          label: "Date",
          name: "date",
          width: 60,
          nonInteractive: true,
          renderer: (item: JoinedData) => formatDateLabel(item.date, "", true) || "-",
        },
        {
          label: metricsToPrettyNameMap[selectedMetric],
          name: selectedMetric,
          nonInteractive: true,
          flex: 1,
          renderer: (item: JoinedData) =>
            metricFormatter(selectedMetric)(R.pathOr(0, ["metrics", selectedMetric], item)),
        },
        {
          label: "% ∆",
          name: "percentChange",
          nonInteractive: true,
          width: 35,
          renderer: (item: JoinedData) => {
            const metric = R.pathOr(0, ["metrics", selectedMetric], item);
            const otherMetric = R.pathOr(0, ["metrics_other", selectedMetric], item);
            const percentChange = Math.round(((metric - otherMetric) / otherMetric) * 100);
            const color = percentChange < 0 ? "red" : "green";
            return percentChange ? (
              <div style={{ color }}>{isFinite(percentChange) ? `${percentChange}%` : "--"}</div>
            ) : (
              "--"
            );
          },
        },
        {
          label: metricsToPrettyNameMap[selectedMetric],
          name: `${selectedMetric}_other`,
          nonInteractive: true,
          flex: 1,
          renderer: (item: JoinedData) =>
            metricFormatter(selectedMetric)(R.pathOr(0, ["metrics_other", selectedMetric], item)),
        },
        {
          label: "Date",
          name: "date_other",
          width: 60,
          nonInteractive: true,
          renderer: (item: JoinedData) => formatDateLabel(item.date_other, "", true) || "-",
        },
      ];
      const superHeaders: any[] = [
        { span: 2, data: "Primary Period" },
        { span: 1 },
        { span: 2, data: "Comparison Period" },
      ];

      return [headers, superHeaders];
    }, [metricsToPrettyNameMap, selectedMetric]);

    const joinedData = useMemo(() => getJoinedData(data, otherData), [data, otherData]);

    const totals = useMemo(() => {
      return {
        date: "",
        date_other: "",
        percentChange: "",
        [selectedMetric]:
          valueToggle === SHOW_TOTAL
            ? metricFormatter(selectedMetric)(total)
            : metricFormatter(selectedMetric)(average),
        [`${selectedMetric}_other`]:
          valueToggle === SHOW_TOTAL
            ? metricFormatter(selectedMetric)(otherTotal)
            : metricFormatter(selectedMetric)(otherAverage),
      };
    }, [average, otherAverage, otherTotal, selectedMetric, total, valueToggle]);

    const totalsRenderer = ({ data, style = {}, classes = [] as string[] }) => {
      return (
        <div style={style} className={[...classes, "grandTotalCell"].join(" ")}>
          {data}
        </div>
      );
    };

    const fileNameIdentifiers = useMemo(() => {
      const prettySelectedMetric =
        METRIC_OPTIONS.find(item => item.value === selectedMetric)?.label || selectedMetric;
      const subHeaderValue = !onlyShowAverage && valueToggle === SHOW_TOTAL ? "Total" : "DailyAvg";
      const primaryDateRange = data.length ? `${data[0].date}–${data[data.length - 1].date}` : "";
      const comparisonDateRange = otherData.length
        ? `vs_${otherData[0].date}–${otherData[otherData.length - 1].date}`
        : "";
      return [
        ...pngFileIdentifiers,
        prettySelectedMetric,
        subHeaderValue,
        primaryDateRange,
        comparisonDateRange,
      ];
    }, [data, otherData, pngFileIdentifiers, onlyShowAverage, valueToggle, selectedMetric]);

    const excelDownload = useCallback(() => {
      const resolvedFileName = fileNameIdentifiers.join("_");
      const unionData = [
        ...data.map(item => ({ date: item.date, ...item.metrics })),
        ...otherData.map(item => ({ date: item.date, ...item.metrics })),
      ];
      exportToExcel(unionData, resolvedFileName);
    }, [data, otherData, fileNameIdentifiers]);

    const pngDownload = useCallback(async () => {
      await downloadPNG(
        `.sparkChartContainer${defaultMetric ? `.${defaultMetric}` : ""}`,
        fileNameIdentifiers,
        pngLabel,
        [".sparkChartActions"]
      );
    }, [pngLabel, defaultMetric, fileNameIdentifiers]);

    return (
      <div
        className={`sparkChartContainer ${hover ? "hover" : ""} ${defaultMetric}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="sparkChartHeader">
          <div className="sparkChartTitle">
            <Dropdown
              type={DropdownToggleType.WIDGET_TITLE}
              value={selectedMetric}
              options={R.sort((a, b) => a.label.localeCompare(b.label), metricOptions)}
              onChange={option => setSelectedMetric(option)}
            />
          </div>
          <div className="sparkChartActions">
            {hover && (
              <>
                <IconToggleButton
                  size="sm"
                  options={[
                    { key: SHOW_TABLE, icon: <MdOutlineTableRows />, label: "table view" },
                    { key: SHOW_GRAPH, icon: <MdShowChart />, label: "graph view" },
                  ]}
                  selectedOption={view}
                  onChange={setView}
                />
                {data && (
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[excelDownload, pngDownload]}
                    disabledMenuOptions={
                      view === SHOW_GRAPH ? { XLSX: false, PNG: false } : { XLSX: false, PNG: true }
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="sparkChartSubHeader">
          {view === SHOW_GRAPH && (
            <div className="value">
              {metricFormatter(selectedMetric)(
                valueToggle === SHOW_TOTAL && !onlyShowAverage ? total : average
              )}
            </div>
          )}
          <div className="sparkChartToggleSelector">
            {(valueToggle === SHOW_TOTAL || hover) && !onlyShowAverage && (
              <div
                className={`sparkChartToggleItem ${
                  valueToggle === SHOW_TOTAL ? "selected" : "deselected"
                }`}
                onClick={() => setValueToggle(SHOW_TOTAL)}
              >
                TOTAL
              </div>
            )}
            {(valueToggle === SHOW_AVG || hover) && (
              <div
                className={`sparkChartToggleItem ${
                  valueToggle === SHOW_AVG ? "selected" : "deselected"
                }`}
                onClick={() => setValueToggle(SHOW_AVG)}
              >
                DAILY AVG
              </div>
            )}
          </div>
        </div>
        <div className={`sparkChartBody ${view === SHOW_GRAPH ? "graphView" : "tableView"}`}>
          {view === SHOW_GRAPH ? (
            <AutoSizer>
              {({ width, height }) => (
                <ComposedChart
                  width={width}
                  height={height}
                  data={joinedData}
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                >
                  <Tooltip
                    content={({ payload }) => {
                      const data = payload?.[0]?.payload || {};

                      const primaryHeaderLabel = formatDateLabel(data?.date || "", "Day", true);
                      const comparisonHeaderLabel = formatDateLabel(
                        data?.date_other || "",
                        "Day",
                        true
                      );

                      const primaryMetricValue = R.pathOr(0, ["metrics", selectedMetric], data);
                      const comparisonMetricValue = R.pathOr(
                        0,
                        ["metrics_other", selectedMetric],
                        data
                      );

                      const items = [
                        {
                          label: metricsToPrettyNameMap[selectedMetric],
                          value: metricFormatter(selectedMetric)(primaryMetricValue),
                          color: Brand60,
                        },
                      ];

                      const comparisonItems = [
                        {
                          label: metricsToPrettyNameMap[selectedMetric],
                          value: metricFormatter(selectedMetric)(comparisonMetricValue),
                          color: "#000000",
                        },
                      ];

                      return (
                        <ChartTooltipV2
                          headers={[primaryHeaderLabel, comparisonHeaderLabel]}
                          items={items}
                          comparisonItems={comparisonItems}
                        />
                      );
                    }}
                    allowEscapeViewBox={{ x: true }}
                  />
                  <XAxis
                    hide={true}
                    dataKey={R.length(data) > R.length(otherData) ? "date" : "date_other"}
                  />
                  <YAxis hide={true} />
                  <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="1%" stopColor={Brand30} />
                      <stop offset="95%" stopColor={Brand0} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Area
                    type="linear"
                    name={selectedMetric}
                    dataKey={`metrics.${selectedMetric}`}
                    stackId="1"
                    activeDot={false}
                    fillOpacity={1}
                    strokeWidth={3}
                    stroke={"#8254FF"}
                    isAnimationActive={false}
                    fill="url(#gradient)"
                  />
                  {hover && (
                    <Line
                      name={`${selectedMetric}_other`}
                      dataKey={`metrics_other.${selectedMetric}`}
                      type="linear"
                      strokeWidth={LINE_STROKE_WIDTH}
                      activeDot={false}
                      dot={false}
                      isAnimationActive={false}
                      stroke="#1F003F"
                      strokeDasharray="4"
                    />
                  )}
                </ComposedChart>
              )}
            </AutoSizer>
          ) : (
            <BPMTable
              data={joinedData}
              headers={headers}
              superHeaders={superHeaders}
              filterBar={false}
              totals={totals}
              totalsRenderer={totalsRenderer}
              headerHeight={30}
              superHeaderHeight={30}
              bottomRowHeight={30}
            />
          )}
        </div>
        {view === SHOW_GRAPH && (
          <div className="sparkChartFooter">
            {hover ? (
              <div className="comparison">
                <div className="range">
                  {`${makePrettyDateRange(
                    (otherData[0] || {}).date,
                    (otherData[otherData.length - 1] || {}).date
                  )} ${valueToggle === SHOW_AVG ? "Avg." : ""} ${
                    metricsToPrettyNameMap[selectedMetric]
                  }:`}
                </div>
                <div className="value">
                  {metricFormatter(selectedMetric)(
                    valueToggle === SHOW_TOTAL ? otherTotal : otherAverage
                  )}
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className={`percentChange ${(percentChange as number) < 0 ? "inverted" : ""}`}>
              <MdCallMade
                className={`percentArrow ${(percentChange as number) < 0 ? "inverted" : ""}`}
              />{" "}
              {`${percentChange}%`}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default SparkChart;

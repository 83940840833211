import React, { useContext } from "react";
import { FormContext, metaAdFormDefault } from "../MetaBuyingContext";
import { MetaBuyingNamePreview } from "./MetaBuyingNamePreview";
import { MetaBuyingOptionsNoteCard } from "./MetaBuyingOptionsNoteCard";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MetaBuyingFooterControls } from "./MetaBuyingFooterControls";
import { RouteComponentProps } from "@reach/router";

// for testing only
const DROPDOWN_TEST_PLACEHOLDER = [
  {
    label: "Option A",
    value: "test_a",
  },
  {
    label: "Option B",
    value: "test_b",
  },
  {
    label: "Option C",
    value: "test_c",
  },
];

const OPTIONS_NOTE_CARD_HEADER = "Ad Options to Populate in Ads Manager";
const OPTIONS_NOTE_CARD_OPTIONS = [
  "Catalog Ads",
  "Additional Text Options",
  "Promo Codes",
  "Advantage+ Creative",
  "Instant Experiences",
  "Facebook Events",
];

interface MetaBuyingAdProps {
  namingConvention: string[];
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
}

export const MetaBuyingAd = ({
  path,
  namingConvention,
  tabIndex,
  setTabIndex,
  showReviewModal,
}: MetaBuyingAdProps & RouteComponentProps): JSX.Element => {
  const { metaAdForm, setMetaAdForm } = useContext(FormContext);

  const updateFormField = (key: string, value: string) =>
    setMetaAdForm((current: any): typeof metaAdFormDefault => {
      return { ...current, [key]: value };
    });

  return (
    <div className="metaBuyingCreate">
      <div className="metaBuyingContainer">
        <div className="metaBuyingCard">
          <div className="metaBuyingHeader">
            <h2>Ad</h2>
            <div className="metaBuyingNameWrapper">
              Name:
              <MetaBuyingNamePreview data={metaAdForm} namingConvention={namingConvention} />
            </div>
          </div>

          <div className="metaBuyingFormBody">
            <div className="metaBuyingFormSection">
              <h3>Ad Name Construction</h3>
              <Form.Group>
                <Form.Label>Platform</Form.Label>
                <Form.Control
                  value={metaAdForm.platform}
                  onChange={e => updateFormField("platform", e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Asset Type</Form.Label>
                <DropdownButton
                  title={metaAdForm.asset_type}
                  onSelect={value => updateFormField("asset_type", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Tactic</Form.Label>
                <DropdownButton
                  title={metaAdForm.tactic}
                  onSelect={value => updateFormField("tactic", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Creative</Form.Label>
                <DropdownButton
                  title={metaAdForm.creative}
                  onSelect={value => updateFormField("creative", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Initiative</Form.Label>
                <DropdownButton
                  title={metaAdForm.initiative}
                  onSelect={value => updateFormField("initiative", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <h3>Ad Set</h3>
              <Form.Group>
                <Form.Label>Initiative</Form.Label>
                <DropdownButton
                  title={metaAdForm.ad_set}
                  onSelect={value => updateFormField("ad_set", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <h3>Ad Accounts</h3>
              <Form.Group>
                <Form.Label>Facebook Page</Form.Label>
                <DropdownButton
                  title={metaAdForm.facebook_page}
                  onSelect={value => updateFormField("facebook_page", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Instagram Page</Form.Label>
                <DropdownButton
                  title={metaAdForm.instagram_page}
                  onSelect={value => updateFormField("instagram_page", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <h3>Creative</h3>
              <Form.Group>
                <Form.Label>Creative Format</Form.Label>
                <DropdownButton
                  title={metaAdForm.creative_format}
                  onSelect={value => updateFormField("creative_format", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Creative Asset</Form.Label>
                <DropdownButton
                  title={metaAdForm.creative_asset}
                  onSelect={value => updateFormField("creative_asset", value || "")}
                >
                  {DROPDOWN_TEST_PLACEHOLDER.map(({ label, value }) => (
                    <Dropdown.Item key={value} eventKey={value}>
                      {label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
              <Form.Group>
                <Form.Label>Headline</Form.Label>
                <Form.Control
                  value={metaAdForm.headline}
                  onChange={e => updateFormField("headline", e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Copy</Form.Label>
                <Form.Control
                  value={metaAdForm.copy}
                  onChange={e => updateFormField("copy", e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>CTA Text</Form.Label>
                <Form.Control
                  value={metaAdForm.cta_text}
                  onChange={e => updateFormField("cta_text", e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>CTA Link</Form.Label>
                <Form.Control
                  value={metaAdForm.cta_link}
                  onChange={e => updateFormField("cta_link", e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>CTA Link Description</Form.Label>
                <Form.Control
                  value={metaAdForm.cta_link_description}
                  onChange={e => updateFormField("cta_link_description", e.target.value)}
                />
              </Form.Group>
            </div>
            <MetaBuyingFooterControls
              tabName={"Ad"}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              showReviewModal={showReviewModal}
            />
          </div>
        </div>

        <MetaBuyingOptionsNoteCard
          header={OPTIONS_NOTE_CARD_HEADER}
          options={OPTIONS_NOTE_CARD_OPTIONS}
        />
      </div>
    </div>
  );
};

export default MetaBuyingAd;

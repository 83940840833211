import React from "react";

interface MetaBuyingOptionsNoteCardProps {
  header: string;
  text?: string;
  options: string[];
}

export const MetaBuyingOptionsNoteCard = ({
  header,
  text,
  options,
}: MetaBuyingOptionsNoteCardProps): JSX.Element => {
  return (
    <div className="metaBuyingCard note">
      <h2>{header}</h2>
      {text}{" "}
      <ul>
        {options.map((option, index) => (
          <li key={index}>{option}</li>
        ))}
      </ul>
    </div>
  );
};

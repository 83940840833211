import { Spinner } from "./";
import React, { useRef, useEffect, useMemo, useState } from "react";

export const Audio = ({
  src,
  unloader = <span />,
  spinnerSize = 20,
  className = "",
  alt = "",
  ...props
}) => {
  const [data, setData] = useState(null);
  const [loadingState, setLoadingState] = useState("loading");
  const audioRef = useRef();
  const url = useMemo(() => (data ? URL.createObjectURL(data) : null), [data]);

  useEffect(() => {
    // Always fetch the data in the case that the src is a application/octet-stream
    const fetchData = async () => {
      setLoadingState("loading");
      const response = await fetch(src, {
        responseType: "blob",
      });
      const blob = await response.blob();
      setData(blob);
      setLoadingState("loaded");
    };
    fetchData();
  }, [src]);

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = document.createElement("audio");
    }
  }, [audioRef]);

  useEffect(() => {
    let stale = false;
    if (audioRef.current) {
      audioRef.current.src = url;
      audioRef.current.addEventListener("canplay", () => {
        if (!stale) {
          setLoadingState("loaded");
        }
      });
      audioRef.current.addEventListener("error", () => {
        if (!stale) {
          setLoadingState("error");
        }
      });
    }
    return () => {
      stale = true;
    };
  }, [audioRef, src, setLoadingState, url]);

  switch (loadingState) {
    case "loaded":
      return <audio {...props} alt={alt} src={url} className={className} />;
    case "loading":
      return <Spinner size={spinnerSize} />;
    case "error":
      return unloader;
    default:
      return <span />;
  }
};

export default Audio;

import * as R from "ramda";
import { capitalizeWords, currencyFormatter } from "../../MMM/MMMUtils";
import { getGroupingValue } from "../../TVADCrossChannel/homePageUtils";
import { DateGroupingKey } from "../../TVADCrossChannel/homePageConstants";

export const saturationDataFormatter = (data: any[]): { newD; medians } => {
  const d = R.groupBy(item => R.prop("Channel", item), data);
  const medianOfSpend = (data: any[]) => {
    const responses: number[] = R.map(item => {
      const response = R.prop("Spend", item);
      return response;
    }, data);

    const sortedResponses: number[] = R.sort((a, b) => a - b, responses);
    const median =
      sortedResponses.length % 2 === 0
        ? (sortedResponses[sortedResponses.length / 2 - 1] +
            sortedResponses[sortedResponses.length / 2]) /
          2
        : sortedResponses[Math.floor(sortedResponses.length / 2)];

    // Set datapoint to mark if median isn't a data point available
    const dataPoint =
      sortedResponses.length % 2 === 0 ? sortedResponses[sortedResponses.length / 2 - 1] : median;

    return { median, dataPoint };
  };

  //@ts-ignore
  const medians = R.map(medianOfSpend, d);
  const transformedData: { name: string; data: any[] }[] = R.pipe(
    R.toPairs,
    R.map(([name, data]) => {
      return {
        name: capitalizeWords(name)
          .replace("Paid Search Nb", "Paid Search - Non-brand")
          .replace("Paid Shopping Nb", "Paid Shopping - Non-brand")
          .replace("Tik Tok", "TikTok")
          .replace("Linear Television", "Linear TV"),
        data: data as any[],
      };
    })
  )(d);

  return { newD: transformedData, medians };
};

interface DataItem {
  Channel: string;
  Response: number;
}

interface MedianResult {
  median: number;
  dataPoint: number;
}

export const saturationDataFormatterTwo = (data: any[]): { newD; medians } => {
  const d = R.groupBy(item => R.prop("Channel", item), data);
  const medianOfSpend = (data: any[]) => {
    const responses: number[] = R.map(item => {
      const response = R.prop("Response", item);
      return response;
    }, data);

    const sortedResponses: number[] = R.sort((a, b) => a - b, responses);
    const median =
      sortedResponses.length % 2 === 0
        ? (sortedResponses[sortedResponses.length / 2 - 1] +
            sortedResponses[sortedResponses.length / 2]) /
          2
        : sortedResponses[Math.floor(sortedResponses.length / 2)];

    // Set datapoint to mark if median isn't a data point available
    const dataPoint =
      sortedResponses.length % 2 === 0 ? sortedResponses[sortedResponses.length / 2 - 1] : median;

    return { median, dataPoint };
  };

  const medians: { [key: string]: MedianResult } = R.mapObjIndexed(medianOfSpend, d);
  const transformedData: { name: string; data: DataItem[] }[] = R.pipe(
    R.toPairs,
    R.map(([name, data]) => {
      return {
        name: capitalizeWords(name)
          .replace("Paid Search Nb", "Paid Search - Non-brand")
          .replace("Paid Shopping Nb", "Paid Shopping - Non-brand")
          .replace("Tik Tok", "TikTok")
          .replace("Linear Television", "Linear TV"),
        data: data as any[],
      };
    })
  )(d);

  return { newD: transformedData, medians };
};

export const decayDataFormatterNew = (data: any[], yAxisDataKey: string): { newD; lines } => {
  const d = R.groupBy(item => R.prop("period", item), data);
  let newD = [] as any[];
  Object.keys(d).forEach((key, index) => {
    newD[index] = {};
    newD[index].Index = key;

    d[key].forEach((item: any) => {
      const edited = capitalizeWords(item.channel);
      if (newD[index][edited] === undefined) {
        newD[index][edited] = 0;
      }
      newD[index][edited] += item[yAxisDataKey] * 100;
    });
  });

  const keys = Object.keys(newD[0]).filter(key => key !== "Index");
  let lines: { name: string; dataKey: string }[] = [];
  keys.forEach((key, index) => {
    lines[index] = {
      name: capitalizeWords(key),
      dataKey: key,
    };
  });
  lines = R.sortBy(R.prop("name"), lines);
  return { newD, lines };
};

export const decayDataFormatter = (data: any[], yAxisDataKey: string): { newD; lines } => {
  const d = R.groupBy(item => R.prop("Period", item), data);
  let newD = [] as any[];
  Object.keys(d).forEach((key, index) => {
    newD[index] = {};
    newD[index].Index = key;

    d[key].forEach((item: any) => {
      const edited = capitalizeWords(item.name)
        .replace("Paid Search Nb", "Paid Search - Non-brand")
        .replace("Paid Shopping Nb", "Paid Shopping - Non-brand")
        .replace("Tik Tok", "TikTok")
        .replace("Linear Television", "Linear TV");
      newD[index][edited] = item[yAxisDataKey] * 100;
    });
  });

  const keys = Object.keys(newD[0]).filter(key => key !== "Index");
  let lines: { name: string; dataKey: string }[] = [];
  keys.forEach((key, index) => {
    lines[index] = {
      name: capitalizeWords(key),
      dataKey: key,
    };
  });
  lines = R.sortBy(R.prop("name"), lines);
  return { newD, lines };
};

export const decayDataFormatterTwo = (data: any[], yAxisDataKey: string): { newData; lines } => {
  const originalData = R.groupBy(item => R.prop("Period", item), data);
  let newData = [] as any[];
  Object.keys(originalData).forEach((key, index) => {
    newData[index] = {};
    newData[index].Index = key;

    originalData[key].forEach((item: any) => {
      const edited = capitalizeWords(item.Channel)
        .replace("Paid Search Nb", "Paid Search - Non-brand")
        .replace("Paid Shopping Nb", "Paid Shopping - Non-brand")
        .replace("Tik Tok", "TikTok")
        .replace("Linear Television", "Linear TV")
        .replace("Buzz P", "Buzz")
        .replace("Ad Awareness P", "Ad Awareness")
        .replace("Awareness P", "Aided Awareness")
        .replace("Consideration P", "Consideration");
      newData[index][edited] = item[yAxisDataKey] * 100;
    });
  });
  const keys = Object.keys(newData[0])
    .filter(key => key !== "Index")
    .filter(key => key !== "Affiliate")
    .filter(key => key !== "Linear TV")
    .filter(key => key !== "Meta")
    .filter(key => key !== "Paid Display")
    .filter(key => key !== "Paid Search - Non-brand")
    .filter(key => key !== "Paid Shopping - Non-brand")
    .filter(key => key !== "Streaming Video")
    .filter(key => key !== "TikTok");
  let lines: { name: string; dataKey: string }[] = [];
  keys.forEach((key, index) => {
    lines[index] = {
      name: capitalizeWords(key),
      dataKey: key,
    };
  });
  lines = R.sortBy(R.prop("name"), lines);
  return { newData, lines };
};

export const standardizeMetricName = (metric: string): string => {
  const formattedMetric = metric
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return formattedMetric === "Awareness" ? "Aided Awareness" : formattedMetric;
};

export const standardizeMetricNameOriginal = (metric: string): string => {
  const formattedMetric = metric
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return formattedMetric;
};

export const currFormat = (s: string | number, decimals: number): string => {
  let final = String(s).replace(/,/g, "");
  let finalNum = parseInt(final);
  return currencyFormatter.format(finalNum, decimals);
};

export const percFormat = (s: string | number, decimals = 2): string => {
  let final = String(s).replace(/,/g, "");
  let finalNum = parseFloat(final);
  const percentVal = `${finalNum.toFixed(decimals)}%`;
  return percentVal;
};

export const weekFormat = (s: string | number): string => {
  let final = String(s).replace(/,/g, "");
  let finalNum = parseFloat(final);
  const percentVal = `${finalNum.toFixed(0)}`;
  return percentVal;
};

export const snapShotDataSort = (data: any[], snapChartSortValue: string): any[] => {
  let snapData;

  switch (snapChartSortValue) {
    case "Alphabetical":
      snapData = R.sortBy(R.prop("name"), data);
      break;
    case "Highest to lowest":
    case "Spend: High to low":
      snapData = R.sortBy(R.compose(R.negate, R.prop("value")), data);
      break;
    case "Lowest to highest":
    case "Spend: Low to high":
      snapData = R.sortBy(R.prop("value"), data);
      break;
    case "Effect: High to low":
      snapData = R.sortBy(R.compose(R.negate, R.prop("value2")), data);
      break;
    case "Effect: Low to high":
      snapData = R.sortBy(R.prop("value2"), data);
      break;
    case "Spend to effect ratio":
      snapData = R.sortBy(R.compose(R.negate, R.prop("spendOverOutcome")), data);
      break;
  }

  return snapData;
};

export const groupDataByCadence = (data: any[], groupingKey: string): any[] => {
  const format = R.groupBy(item => R.prop("date", item), data);
  const totalsByDate = getTotalsByDate(groupingKey, format);
  const newD = getNewD({ totalsByDate: totalsByDate, relative: false });
  return newD;
};

export const getLineChartByDateGrouping = (data: any[], dateGrouping: string): any[] => {
  let totalsByDate: Record<string, Record<string, number>> = {};
  for (let item of data) {
    const { date, ad_awareness, awareness, buzz, consideration } = item;
    const dateToUse = getGroupingValue(date, dateGrouping as DateGroupingKey);
    totalsByDate = R.mergeDeepRight(totalsByDate, {
      [dateToUse]: {
        ad_awareness: R.pathOr(0, [dateToUse, "ad_awareness"], totalsByDate) + ad_awareness,
        awareness: R.pathOr(0, [dateToUse, "awareness"], totalsByDate) + awareness,
        buzz: R.pathOr(0, [dateToUse, "buzz"], totalsByDate) + buzz,
        consideration: R.pathOr(0, [dateToUse, "consideration"], totalsByDate) + consideration,
      },
    });
  }
  let rows: {
    date: string;
    ad_awareness: number;
    awareness: number;
    consideration: number;
    buzz: number;
  }[] = [];
  Object.keys(totalsByDate).forEach(item => {
    rows.push({
      date: item,
      ad_awareness: totalsByDate[item].ad_awareness / 7,
      awareness: totalsByDate[item].awareness / 7,
      consideration: totalsByDate[item].consideration / 7,
      buzz: totalsByDate[item].buzz / 7,
    });
  });

  return rows;
};

export const getWeeklySpendGrouping = (data: any[], dateGrouping: string): any[] => {
  let totalsByDate: Record<string, Record<string, number>> = {};
  for (let item of data) {
    const {
      Date,
      Affiliate,
      LinearTelevision,
      Meta,
      PaidDisplay,
      PaidSearchNb,
      PaidShoppingNb,
      StreamingVideo,
      TikTok,
    } = item;
    const dateToUse = getGroupingValue(Date, dateGrouping as DateGroupingKey);
    totalsByDate = R.mergeDeepRight(totalsByDate, {
      [dateToUse]: {
        Affiliate: R.pathOr(0, [dateToUse, "Affiliate"], totalsByDate) + parseFloat(Affiliate),
        LinearTelevision:
          R.pathOr(0, [dateToUse, "LinearTelevision"], totalsByDate) + parseFloat(LinearTelevision),
        Meta: R.pathOr(0, [dateToUse, "Meta"], totalsByDate) + parseFloat(Meta),
        PaidDisplay:
          R.pathOr(0, [dateToUse, "PaidDisplay"], totalsByDate) + parseFloat(PaidDisplay),
        PaidSearchNb:
          R.pathOr(0, [dateToUse, "PaidSearchNb"], totalsByDate) + parseFloat(PaidSearchNb),
        PaidShoppingNb:
          R.pathOr(0, [dateToUse, "PaidShoppingNb"], totalsByDate) + parseFloat(PaidShoppingNb),
        StreamingVideo:
          R.pathOr(0, [dateToUse, "StreamingVideo"], totalsByDate) + parseFloat(StreamingVideo),
        TikTok: R.pathOr(0, [dateToUse, "TikTok"], totalsByDate) + parseFloat(TikTok),
      },
    });
  }
  let rows: {
    Date: string;
    Affiliate: number;
    LinearTelevision: number;
    Meta: number;
    PaidDisplay: number;
    PaidSearchNb: number;
    PaidShoppingNb: number;
    StreamingVideo: number;
    TikTok: number;
  }[] = [];
  Object.keys(totalsByDate).forEach(item => {
    rows.push({
      Date: item,
      Affiliate: totalsByDate[item].Affiliate / 7,
      LinearTelevision: totalsByDate[item].LinearTelevision / 7,
      Meta: totalsByDate[item].Meta / 7,
      PaidDisplay: totalsByDate[item].PaidDisplay / 7,
      PaidSearchNb: totalsByDate[item].PaidSearchNb / 7,
      PaidShoppingNb: totalsByDate[item].PaidShoppingNb / 7,
      StreamingVideo: totalsByDate[item].StreamingVideo / 7,
      TikTok: totalsByDate[item].TikTok / 7,
    });
  });

  return rows;
};

const getTotalsByDate = (dateGrouping, groupedByDate) => {
  let totalsByDate = {};
  Object.keys(groupedByDate).forEach(key => {
    const dateToUse = getGroupingValue(key, dateGrouping as DateGroupingKey);

    const merged = R.mergeAll(groupedByDate[key]);
    Object.keys(merged).forEach(kpi => {
      if (kpi !== "date") {
        totalsByDate = R.mergeDeepRight(totalsByDate, {
          [dateToUse]: {
            [kpi]: R.pathOr(0, [dateToUse, kpi], totalsByDate) + merged[kpi],
          },
        });
      }
    });
  });
  return totalsByDate;
};

interface getNewDProps {
  totalsByDate: Record<string, Record<string, number>>;
  relative: boolean;
}
const getNewD = ({ totalsByDate, relative }: getNewDProps) => {
  let newD = [] as any[];
  Object.keys(totalsByDate).forEach((key, index) => {
    // if relative view for chart; divide each value by the total sum of the date
    if (relative) {
      const totalSum = R.sum(R.values(totalsByDate[key]));
      R.keys(totalsByDate[key]).forEach(item => {
        totalsByDate[key][item] = R.divide(totalsByDate[key][item], totalSum) * 100;
      });
    }
    newD[index] = {};
    newD[index].date = key;

    newD[index] = { ...newD[index], ...totalsByDate[key] };
  });
  return newD;
};

export const formatAreaChartData = (
  data: any[],
  data1Key: string,
  groupBy: string,
  dateGrouping: string
): any => {
  let groupedData;
  let channels;
  let newD = [] as any[];
  let totalsByDate: Record<string, Record<string, number>> = {};

  const groupByChannel = R.groupBy(item => R.prop(groupBy, item), data);
  channels = Object.keys(groupByChannel);
  groupedData = Object.keys(groupByChannel).map(key => {
    return R.groupBy(item => R.prop("date", item), groupByChannel[key]);
  });
  let channelsData = {} as any;
  groupedData.forEach((item, index) => {
    channelsData[channels[index]] = [] as any[];
    Object.keys(item).forEach(key => {
      const dKeyTotal = item[key].reduce((acc, obj) => {
        return acc + obj[data1Key];
      }, 0);
      channelsData[channels[index]].push({ date: key, [channels[index]]: dKeyTotal });
    });
  });

  const e = Object.keys(channelsData).map(key => {
    return channelsData[key];
  });

  const groupedByDate = R.groupBy(item => R.prop("date", item), e.flat());
  totalsByDate = getTotalsByDate(dateGrouping, groupedByDate);

  newD = getNewD({ totalsByDate, relative: false });

  return newD;
};

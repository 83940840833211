import "./StackedAreaChart.scss";
import React, { useCallback, useState, useMemo } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Area, AreaChart as AC, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
} from "../../Components/Charts/ChartConstants";
import { colorMap as colors, Neutral200 } from "../../utils/colors";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import ChartTooltip from "../../Components/Charts/ChartTooltip/ChartTooltip";
import { headerFormatter, useDynamicYAxisWidth } from "../../Components/Charts/ChartUtils";
import { MdOutlineWarningAmber } from "react-icons/md";

const INACTIVE_COLOR = Neutral200;

interface AreaChartProps {
  areas: {
    dataKey: string;
    incrementality: boolean;

    name: string;
  }[];
  chart1Title?: string;
  chart2Title?: string;
  data1: any[];
  data2: any[];
  dateGrouping?: string;
  groupedAreas?: any[];
  hardCodedColorName?: string;
  hardCodedColor?: string;
  hideLegend?: boolean;
  legendTitle?: string | null;
  tooltipFormatterTop?: (value: any) => string;
  tooltipFormatterBottom?: (value: any) => string;
  usePercentageYAxis?: boolean;
  xAxisDataKey: string;
  xAxisTickFormatter?: (value: any) => string;
  yAxisDataKey?: string;
  yAxisTickFormatter1?: (value: any) => string;
  yAxisTickFormatter2?: (value: any) => string;
}

const StackedAreaChart: React.FC<AreaChartProps> = ({
  areas,
  chart1Title,
  chart2Title,
  data1,
  data2,
  dateGrouping,
  groupedAreas,
  hardCodedColorName,
  hardCodedColor,
  hideLegend,
  legendTitle,
  tooltipFormatterTop,
  tooltipFormatterBottom,
  usePercentageYAxis = false,
  xAxisDataKey,
  xAxisTickFormatter,
  yAxisDataKey,
  yAxisTickFormatter1,
  yAxisTickFormatter2,
}) => {
  const [activeArea, setActiveArea] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const [hoveredLegend, setHoveredLegend] = useState(null);
  const [covariateSelected, setCovariateSelected] = useState(false);

  const colorMap = useMemo(() => {
    if (!R.isEmpty(areas)) {
      return colors(areas, hardCodedColorName, hardCodedColor);
    }
  }, [areas, hardCodedColor, hardCodedColorName]);

  const { xAxisHeight, yAxisWidth, setChartRef } = useDynamicYAxisWidth({
    yAxisWidthModifier: x => x + 7,
    data: data1,
  });
  const {
    xAxisHeight: xAxisHeight2,
    yAxisWidth: yAxisWidth2,
    setChartRef: setChartRef2,
  } = useDynamicYAxisWidth({
    yAxisWidthModifier: x => x + 7,
    data: data2,
  });

  const handleLegendClick = useCallback(
    (area, group?) => {
      if (activeArea === area.name) {
        setActiveArea(null);
        setActiveGroup(null);
      } else {
        setActiveArea(area.name);
        if (group) {
          setActiveGroup(group.channel);
        }
      }
    },
    [activeArea]
  );

  const handleLegendEnter = useCallback(
    line => {
      hoveredLegend === line.name ? setHoveredLegend(null) : setHoveredLegend(line.name);
    },
    [hoveredLegend]
  );

  const percentageYAxis = (
    <YAxis
      dataKey={yAxisDataKey}
      axisLine={false}
      domain={[0, 100]}
      orientation="left"
      tick={TICK_STYLE}
      ticks={[0, 25, 50, 75, 100]}
      type="number"
      yAxisId="0"
      unit="%"
      width={Math.max(yAxisWidth, yAxisWidth2)}
    />
  );

  const formatHeaderLabel = useCallback(
    (label: string) => {
      const formattedStartLabel = Dfns.format("MMMM dd, yyyy", new Date(`${label}T00:00:00`));

      let formattedLabel = headerFormatter(dateGrouping, formattedStartLabel);

      return formattedLabel;
    },
    [dateGrouping]
  );

  const buildLegend = useCallback(
    areas => {
      return areas.map(area => {
        const isActiveArea = activeArea === null ? true : activeArea === area.name;
        const isHoveredLegend = hoveredLegend === null ? true : hoveredLegend === area.name;
        const activeColor = isActiveArea ? colorMap[area.name] : INACTIVE_COLOR;
        const hoverColor = isHoveredLegend ? colorMap[area.name] : INACTIVE_COLOR;
        return (
          <div
            className="legendItem"
            key={area.name}
            onClick={() => {
              area.name === "Covariates" && isActiveArea
                ? setCovariateSelected(true)
                : setCovariateSelected(false);
              handleLegendClick(area);
            }}
            onMouseEnter={() => handleLegendEnter(area)}
            onMouseLeave={() => setHoveredLegend(null)}
          >
            <div className="legendValue">
              <div className={`${isActiveArea ? "" : "inactiveLegend"}`}>
                {(!area.grouping ||
                  !(
                    area.grouping.platform ||
                    area.grouping.tactic ||
                    area.grouping.brand_nonbrand
                  )) && (
                  <div style={{ display: "flex" }}>
                    <div
                      className="square"
                      style={{
                        backgroundColor: `${activeArea ? activeColor : hoverColor}`,
                      }}
                    />
                    <div className="legendHeader">{`${
                      area.grouping ? area.grouping.channel : area.name
                    }`}</div>
                    {area.incrementality && (
                      <MdOutlineWarningAmber
                        style={{
                          color: `${
                            isActiveArea || activeArea === null ? "orange" : INACTIVE_COLOR
                          }`,
                          marginLeft: "5px",
                        }}
                      />
                    )}
                  </div>
                )}
                {area.grouping &&
                  (area.grouping.platform ||
                    area.grouping.tactic ||
                    area.grouping.brand_nonbrand) && (
                    <>
                      <div className="legendHeaderWrapper">
                        <div className="legendHeader">{area.grouping.channel}</div>
                        {area.incrementality && (
                          <MdOutlineWarningAmber
                            style={{
                              color: `${
                                isActiveArea || activeArea === null ? "orange" : INACTIVE_COLOR
                              }`,
                              marginLeft: "5px",
                            }}
                          />
                        )}
                      </div>
                      <div className="subHeaderWrapper">
                        <div
                          className="square"
                          style={{
                            backgroundColor: `${activeArea ? activeColor : hoverColor}`,
                          }}
                        />
                        <div className="subHeader1">{area.grouping.platform}</div>
                        {area.grouping.platform &&
                          (area.grouping.tactic || area.grouping.brand_nonbrand) && (
                            <div style={{ whiteSpace: "pre-wrap" }}> – </div>
                          )}
                        <div className="subHeader2">{`${
                          area.grouping.tactic ? area.grouping.tactic : ""
                        }${area.grouping.tactic && area.grouping.brand_nonbrand ? ", " : ""}${
                          area.grouping.brand_nonbrand ? area.grouping.brand_nonbrand : ""
                        }`}</div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        );
      });
    },
    [activeArea, colorMap, handleLegendClick, handleLegendEnter, hoveredLegend]
  );

  const buildLegendByGroupedArea = useCallback(
    groupedAreas => {
      return groupedAreas.map(group => {
        return (
          <div className="legendItem">
            <div className="legendValue">
              <div>
                <div className="legendHeaderWrapper" style={{ cursor: "default" }}>
                  <div
                    className={`legendHeader ${
                      activeGroup === group.channel || activeGroup === null ? "" : "inactiveLegend"
                    }`}
                  >
                    {group.channel}
                  </div>
                </div>
                {group.areas.map(area => {
                  const isActiveArea = activeArea === null ? true : activeArea === area.name;
                  const isHoveredLegend =
                    hoveredLegend === null ? true : hoveredLegend === area.name;
                  const activeColor = isActiveArea ? colorMap[area.name] : INACTIVE_COLOR;
                  const hoverColor = isHoveredLegend ? colorMap[area.name] : INACTIVE_COLOR;
                  return (
                    <div
                      className="subHeaderWrapper"
                      onClick={() => {
                        area.name === "Covariates" && isActiveArea
                          ? setCovariateSelected(true)
                          : setCovariateSelected(false);
                        handleLegendClick(area, group);
                      }}
                      onMouseEnter={() => handleLegendEnter(area)}
                      onMouseLeave={() => setHoveredLegend(null)}
                    >
                      <div
                        className="square"
                        style={{
                          backgroundColor: `${activeArea ? activeColor : hoverColor}`,
                        }}
                      />
                      <div className="subHeaderTextWrap">
                        <div className={`subHeader1 ${isActiveArea ? "" : "inactiveLegend"}`}>
                          {area.grouping.platform}
                        </div>
                        {area.grouping.platform &&
                          (area.grouping.tactic || area.grouping.brand_nonbrand) && (
                            <div style={{ whiteSpace: "pre-wrap" }}> – </div>
                          )}
                        <div className={`subHeader2 ${isActiveArea ? "" : "inactiveLegend"}`}>{`${
                          area.grouping.tactic ? area.grouping.tactic : ""
                        }${area.grouping.tactic && area.grouping.brand_nonbrand ? ", " : ""}${
                          area.grouping.brand_nonbrand ? area.grouping.brand_nonbrand : ""
                        }`}</div>
                        {area.incrementality && (
                          <MdOutlineWarningAmber
                            style={{
                              color: `${
                                isActiveArea || activeArea === null ? "orange" : INACTIVE_COLOR
                              }`,
                              marginLeft: "5px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      });
    },
    [activeArea, activeGroup, colorMap, handleLegendClick, handleLegendEnter, hoveredLegend]
  );

  return (
    <div className="wrapper">
      <div className="areaChartsWrapper">
        <div className="areaChart stackedAreaChart1">
          <div className="chart1Title">{chart1Title}</div>
          <div style={{ display: "flex", flex: 1 }}>
            <AutoSizer>
              {({ width, height }) => (
                <AC data={data1} height={height} ref={setChartRef} width={width}>
                  <CartesianGrid
                    stroke={CARTESIAN_GRID_STROKE}
                    strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                    vertical={false}
                  />
                  <XAxis
                    axisLine={false}
                    dataKey={xAxisDataKey}
                    height={xAxisHeight}
                    tick={TICK_STYLE}
                    tickFormatter={xAxisTickFormatter}
                  />
                  {usePercentageYAxis && percentageYAxis}
                  {!usePercentageYAxis && (
                    <YAxis
                      axisLine={false}
                      dataKey={yAxisDataKey}
                      tick={TICK_STYLE}
                      tickFormatter={yAxisTickFormatter1}
                      tickLine={false}
                      width={Math.max(yAxisWidth, yAxisWidth2)}
                    />
                  )}
                  {areas.map(area => {
                    const isActiveArea = activeArea === null ? true : activeArea === area.name;
                    const isHoveredLegend =
                      hoveredLegend === null ? true : hoveredLegend === area.name;
                    const activeColor = isActiveArea ? colorMap[area.name] : INACTIVE_COLOR;
                    const hoverColor = isHoveredLegend ? colorMap[area.name] : INACTIVE_COLOR;
                    if (!isActiveArea && activeArea !== null && !covariateSelected) {
                      return null;
                    }
                    return (
                      <Area
                        activeDot={false}
                        dataKey={area.dataKey}
                        fill={activeArea ? activeColor : hoverColor}
                        fillOpacity={1}
                        isAnimationActive={false}
                        key={area.name}
                        name={area.name}
                        stackId="1"
                        stroke={activeArea ? activeColor : hoverColor}
                        strokeWidth={0}
                        type="monotone"
                      />
                    );
                  })}
                  <Tooltip
                    content={({ label, payload }) => {
                      if (!payload || !label) {
                        return null;
                      }

                      const formattedHeaderLabel = formatHeaderLabel(label);
                      let items = R.sortBy(
                        (item: any) => -Math.abs(item.value),
                        payload?.map(item => ({
                          label: item.name as string,
                          value: tooltipFormatterTop
                            ? tooltipFormatterTop(item.value)
                            : (item.value as number),
                          color: item.stroke,
                        })) || []
                      );

                      return <ChartTooltip headerLabel={formattedHeaderLabel} items={items} />;
                    }}
                    isAnimationActive={false}
                  />
                </AC>
              )}
            </AutoSizer>
          </div>
        </div>
        <div className="areaChart stackedAreaChart2">
          <div className="chart2Title">{chart2Title}</div>
          <div style={{ display: "flex", flex: 1 }}>
            <AutoSizer>
              {({ width, height }) => (
                <AC data={data2} height={height} ref={setChartRef2} width={width}>
                  <CartesianGrid
                    stroke={CARTESIAN_GRID_STROKE}
                    strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                    vertical={false}
                  />
                  <XAxis
                    axisLine={false}
                    dataKey={xAxisDataKey}
                    height={xAxisHeight2}
                    tick={TICK_STYLE}
                    tickFormatter={xAxisTickFormatter}
                  />
                  {usePercentageYAxis && percentageYAxis}
                  {!usePercentageYAxis && (
                    <YAxis
                      axisLine={false}
                      dataKey={yAxisDataKey}
                      tick={TICK_STYLE}
                      tickFormatter={yAxisTickFormatter2}
                      tickLine={false}
                      width={Math.max(yAxisWidth, yAxisWidth2)}
                    />
                  )}
                  {areas.map(area => {
                    const isActiveArea = activeArea === null ? true : activeArea === area.name;
                    const isHoveredLegend =
                      hoveredLegend === null ? true : hoveredLegend === area.name;
                    const activeColor = isActiveArea ? colorMap[area.name] : INACTIVE_COLOR;
                    const hoverColor = isHoveredLegend ? colorMap[area.name] : INACTIVE_COLOR;
                    if (!isActiveArea && activeArea !== null) {
                      return null;
                    }
                    return (
                      <Area
                        activeDot={false}
                        dataKey={area.dataKey}
                        fill={activeArea ? activeColor : hoverColor}
                        fillOpacity={1}
                        isAnimationActive={false}
                        key={area.name}
                        name={area.name}
                        stackId="1"
                        stroke={activeArea ? activeColor : hoverColor}
                        strokeWidth={0}
                        type="monotone"
                      />
                    );
                  })}
                  <Tooltip
                    content={({ label, payload }) => {
                      if (!payload || !label) {
                        return null;
                      }

                      const formattedHeaderLabel = formatHeaderLabel(label);
                      let items = R.sortBy(
                        (item: any) => -Math.abs(item.value),
                        payload?.map(item => ({
                          label: item.name as string,
                          value: tooltipFormatterBottom
                            ? tooltipFormatterBottom(item.value)
                            : (item.value as number),
                          color: item.stroke,
                        })) || []
                      );

                      return <ChartTooltip headerLabel={formattedHeaderLabel} items={items} />;
                    }}
                    isAnimationActive={false}
                  />
                </AC>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
      <div className={`rightOfChart ${hideLegend ? "hide" : ""}`}>
        <div className="legendTitle">{legendTitle}</div>
        <div className="legend">
          {(!groupedAreas || R.isEmpty(groupedAreas)) && buildLegend(areas)}
          {groupedAreas && buildLegendByGroupedArea(groupedAreas)}
        </div>
      </div>
    </div>
  );
};

export default StackedAreaChart;

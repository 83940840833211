import "./CrossChannel.scss";
import { Metric, KpiInfo } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import { computeResolvedDate } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { DatePicker, Dropdown, DropdownToggleType, FullPageSpinner, Page } from "../Components";
import { DateRange, StateSetter } from "../utils/types";
import {
  decodePrettyUrl,
  encodePrettyUrl,
  getBaseURL,
  STANDARD_GROUP_NAME,
} from "../Performance/performanceUtils";
import {
  DEFAULT_FILTER_PANE_STATE,
  FetchFilterBar,
  useFilterPaneState,
} from "../SingleChannel/FetchFilterBar/FetchFilterBar";
import { FilterPaneState } from "@blisspointmedia/bpm-types/dist/FilterPane";
import { FilterPreset } from "@blisspointmedia/bpm-types/dist/pgTables/FilterPreset";
import { getChannelSeriesColor, shouldUsePrimaryPalette } from "../utils/colors";
import { navigate, RouteComponentProps } from "@reach/router";
import { useCompanyInfo } from "../redux/company";
import { useSelector } from "react-redux";
import { useSetError } from "../redux/modals";
import * as CC from "@blisspointmedia/bpm-types/dist/CrossChannelPerformance";
import * as R from "ramda";
import * as UserRedux from "../redux/user";
import DeliveryAndPerformance from "./Tabs/DeliveryAndPerformance";
import EditPagePresetModal from "../SingleChannel/MetricsTableConfig/EditPagePresetModal";
import Metrics from "./Tabs/MetricsTableView";
import Overview from "./Tabs/Overview";
import PresetDropdown from "../SingleChannel/PresetDropdown";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import RenameModal from "../SingleChannel/RenameModal";
import useLocation from "../utils/hooks/useLocation";
import {
  awaitJSON,
  CrossChannelLambdaFetch,
  SingleChannelLambdaFetch,
  ToolsLambdaFetch,
} from "../utils/fetch-utils";
import {
  columnMetaDataMap,
  dimensionColumnMetaDataMap,
  getCrossChannelDimensionCell,
  calculateOverviewMetricsByDay,
  makeColumnMetaData,
  isSparcCompany,
} from "./crossChannelUtils";
import {
  DeletePresetParams as DeletePagePresetParams,
  GetPresetsParams as GetPagePresetsParams,
  MetricsPagePreset,
  SavePresetParams as SavePagePresetParams,
  SavePresetResponse as SavePagePresetResponse,
} from "@blisspointmedia/bpm-types/dist/MetricsPage";
import {
  GetPresetsParams as GetTablePresetsParams,
  PresetIDGroups as SimpleMetricsTablePreset,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";
import {
  computeDateFromPreset,
  computeDateFromComparisonPreset,
  computeCustomRelativeRange,
} from "@blisspointmedia/bpm-types/dist/RelativeDatePresets";
import { Mixpanel, MxE } from "../utils/mixpanelWrapper";

const enum TabKey {
  OVERVIEW = "overview",
  DELIVERY_AND_PERFORMANCE = "delivery",
  METRICS_TABLE = "metrics",
}

const NAVS = [
  { label: "Overview", key: TabKey.OVERVIEW },
  { label: "Delivery & Performance", key: TabKey.DELIVERY_AND_PERFORMANCE },
  { label: "Metrics Table", key: TabKey.METRICS_TABLE },
];

interface CrossChannelContextType {
  company: string;
  dates: DateRange;
  otherDates: DateRange;
  selectedChannels: Record<string, boolean>;
  deliveryAndPerformanceChannels: Record<string, boolean>;
  channelColors: Record<string, string>;
  kpi: string;
  crossChannelKPIs: string[];
  platformAsSourceOfData: boolean;
  // URL
  setURL: (pagePresetName: string, tablePresetName?: string) => void;
  // Page Preset
  pagePreset?: MetricsPagePreset;
  // Filter Preset
  filterData?: CC.GetFilterOptionsResponse;
  filterID?: number;
  filterSource?: "presetFilterID" | "currentFilterID" | "presetState" | "currentState";
  filterState?: FilterPaneState;
  setFilterData: StateSetter<CC.GetFilterOptionsResponse | undefined>;
  // Table Preset
  fetchingTablePresets: boolean;
  getTablePresets: () => void;
  setFetchingTablePresets: StateSetter<boolean>;
  tablePresetName?: string;
  tablePresets?: SimpleMetricsTablePreset[];
  // Metrics Table
  metricsTableDataMap: Record<string, any>;
  setMetricsTableDataMap: StateSetter<Record<string, any>>;
  metricsTableDataKey: string;
  customSegments: Record<string, boolean>;
}

export const CrossChannelContext = React.createContext<CrossChannelContextType>({
  company: "",
  dates: { start: "", end: "" },
  otherDates: { start: "", end: "" },
  selectedChannels: {},
  deliveryAndPerformanceChannels: {},
  channelColors: {},
  kpi: "",
  crossChannelKPIs: [],
  platformAsSourceOfData: false,
  // Metrics Table
  metricsTableDataMap: {},
  setMetricsTableDataMap: () => {},
  metricsTableDataKey: "",
  // Table Preset
  fetchingTablePresets: false,
  getTablePresets: () => {},
  setFetchingTablePresets: () => {},
  // Filters
  setFilterData: () => {},
  // URL
  setURL: () => {},
  customSegments: {},
});

interface CrossChannelProps extends RouteComponentProps {
  urlSettings: string;
}

const CrossChannel: React.FC<CrossChannelProps> = ({ location, urlSettings }) => {
  // Check if we're on the experimental branch
  const isExperimental =
    process.env.REACT_APP_DOCKER_LAMBDA_HOST === "https://api-staging-2.blisspointmedia.com" ||
    window.location.hostname === "localhost";
  const { company } = useLocation();
  const companyInfo = useCompanyInfo();
  const { defaultCrossChannelSource } = companyInfo;
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const pagePresetURLString = "_page-preset=";
  const prefix = "cross_channel";
  const setError = useSetError();
  const tablePresetURLString = "_table-preset=";

  // State Variables
  const [budgetsDataMap, setBudgetsDataMap] = useState<Record<string, any>>({});
  const [channelClassification, setChannelClassification] = useState<Record<string, string>>();
  const [customSegments, setCustomSegments] = useState<Record<string, any>>({});
  const [dates, setDates] = useState<DateRange | undefined>();
  const [fetchingPagePresets, setFetchingPagePresets] = useState(false);
  const [fetchingTablePresets, setFetchingTablePresets] = useState(false);
  const [filterData, setFilterData] = useState<CC.GetFilterOptionsResponse>();
  const [filterID, setFilterID] = useState<number | undefined>();
  const [filterPresets, setFilterPresets] = useState<FilterPreset[]>();
  const [filterSource, setFilterSource] = useState<
    "presetFilterID" | "currentFilterID" | "presetState" | "currentState"
  >();
  const [filterState, setFilterState, resetFilterState] = useFilterPaneState();
  const [kpi, setKpi] = useState<string | undefined>();
  const [kpiDataMap, setKpiDataMap] = useState<Record<string, KpiInfo>>({});
  const [sourceOptions, setSourceOptions] = useState<{ label: string; value: string }[]>([]);
  const [selectedSource, setSelectedSource] = useState<string>(defaultCrossChannelSource || "");
  const [marketingInputs, setMarketingInputs] = useState<any[]>();
  const [metricsDataMap, setMetricsDataMap] = useState<Record<string, any>>({});
  const [metricsTableDataMap, setMetricsTableDataMap] = useState<Record<string, any>>({});
  const [metricsTableFetchFilterBar, setMetricsTableFetchFilterBar] = useState<JSX.Element>();
  const [otherDates, setOtherDates] = useState<DateRange | undefined>();
  const [pagePresetChanges, setPagePresetChanges] = useState<MetricsPagePreset>({
    globalOptions: {},
  } as MetricsPagePreset);
  const [pagePresetName, setPagePresetName] = useState<string>();
  const [pagePresets, setPagePresets] = useState<MetricsPagePreset[]>();
  const [selectedChannels, setSelectedChannels] = useState<Record<string, boolean>>({});
  const [selectedTab, setSelectedTab] = useState<string>();
  const [showEditPagePresetModal, setShowEditPagePresetModal] = useState(false);
  const [showNewPagePresetModal, setShowNewPagePresetModal] = useState(false);
  const [showRenamePagePresetModal, setShowRenamePagePresetModal] = useState(false);
  const [tablePresetName, setTablePresetName] = useState<string>();
  const [tablePresets, setTablePresets] = useState<SimpleMetricsTablePreset[]>();
  const [platformAsSourceOfData, setPlatformAsSourceOfData] = useState<boolean>(
    defaultCrossChannelSource === "platform"
  );
  const [metricsDataLoading, setMetricsDataLoading] = useState<boolean>(false);
  const [metricsFilteredDataLoading, setMetricsFilteredDataLoading] = useState<boolean>(false);
  const [deliveryAndPerformanceChannels, setDeliveryAndPerformanceChannels] = useState<
    Record<string, boolean>
  >({});

  const baseUrl = useMemo(() => {
    return getBaseURL(urlSettings, location);
  }, [urlSettings, location]);

  const budgetsDataKey = useMemo(
    () =>
      dates && otherDates
        ? `${company}_${dates.start}_${dates.end}_${otherDates.start}_${otherDates.end}`
        : "",
    [company, dates, otherDates]
  );

  const kpiDataKey = useMemo(
    () => (dates ? `${company}_${dates.start}_${dates.end}_${selectedSource}` : ""),
    [company, dates, selectedSource]
  );

  const metricsTableDataKey = useMemo(
    () =>
      dates
        ? `${tablePresetName}_${filterID}_${JSON.stringify(filterState)}_${kpi}_${dates.start}_${
            dates.end
          }_${platformAsSourceOfData ? "platform" : "analytics"}`
        : "",
    [dates, filterID, filterState, kpi, tablePresetName, platformAsSourceOfData]
  );

  const metricsDataKey = useMemo(
    () =>
      dates && otherDates
        ? `${kpi}_${dates.start}_${dates.end}_${otherDates.start}_${otherDates.end}_${
            platformAsSourceOfData ? "platform" : "analytics"
          }`
        : "",
    [kpi, dates, otherDates, platformAsSourceOfData]
  );

  const [startingDateRange, startingOtherDateRange] = useMemo(() => {
    const localDateRangeString = localStorage.getItem(`${company}__crossChannelDateRange`);
    const localDateRange = localDateRangeString ? JSON.parse(localDateRangeString) : null;
    const localOtherDateRangeString = localStorage.getItem(`${company}__crossChannelDateRange`);
    const localOtherDateRange = localOtherDateRangeString
      ? JSON.parse(localOtherDateRangeString)
      : null;
    return [
      {
        start:
          localDateRange && localDateRange.start
            ? localDateRange.start
            : computeResolvedDate({
                adjustmentType: "month",
                adjustment: 1,
                pivotDate: "today",
              }),
        end:
          localDateRange && localDateRange.end
            ? localDateRange.end
            : computeResolvedDate({
                pivotDate: "today",
              }),
      },
      {
        start:
          localOtherDateRange && localOtherDateRange.start
            ? localOtherDateRange.start
            : computeResolvedDate({
                adjustmentType: "month",
                adjustment: 2,
                pivotDate: "yesterday",
              }),
        end:
          localOtherDateRange && localOtherDateRange.end
            ? localOtherDateRange.end
            : computeResolvedDate({
                adjustmentType: "month",
                adjustment: 1,
                pivotDate: "yesterday",
              }),
      },
    ];
  }, [company]);

  const metricsData = useMemo(() => metricsDataMap[metricsDataKey], [
    metricsDataMap,
    metricsDataKey,
  ]);
  const kpiData = useMemo(() => kpiDataMap[kpiDataKey], [kpiDataMap, kpiDataKey]);
  const crossChannelKPIs = useMemo(() => {
    const kpiMap: Record<string, boolean> = {};
    if (
      isExperimental &&
      companyInfo &&
      companyInfo.cid === "tinuititest" &&
      kpiData &&
      kpiData.kpiData
    ) {
      for (const row of kpiData.kpiData) {
        kpiMap[row.kpi] = true;
      }
      return R.keys(kpiMap);
    }
    return R.defaultTo([], companyInfo.cross_channel_kpis);
  }, [companyInfo, isExperimental, kpiData]);

  const budgetsData = useMemo(() => R.pathOr([], [budgetsDataKey], budgetsDataMap), [
    budgetsDataMap,
    budgetsDataKey,
  ]);

  // SPARC companies need performance metrics on Overview tab
  const addPerformanceMetrics = useMemo(() => isSparcCompany(company), [company]);

  const [metricTotalsByDate, otherMetricTotalsByDate] = useMemo(() => {
    return [
      calculateOverviewMetricsByDay({
        metricsData: R.pathOr([], ["primaryMetrics"], metricsData),
        kpiData: R.pathOr([], ["kpiData"], kpiData),
        selectedKpi: kpi,
        addPerformanceMetrics: addPerformanceMetrics,
      }),
      calculateOverviewMetricsByDay({
        metricsData: R.pathOr([], ["otherMetrics"], metricsData),
        kpiData: R.pathOr([], ["kpiData"], kpiData),
        selectedKpi: kpi,
        addPerformanceMetrics: addPerformanceMetrics,
      }),
    ];
  }, [addPerformanceMetrics, kpiData, metricsData, kpi]);

  const channelColors = useMemo(() => {
    const channels = R.keys(selectedChannels).sort();
    const usePrimaryPalette = shouldUsePrimaryPalette(channels.length);
    return channels.reduce((prev, curr, i) => {
      return { ...prev, [curr]: getChannelSeriesColor(i, usePrimaryPalette) };
    }, {});
  }, [selectedChannels]);

  // URL Callback
  const setURL = useCallback(
    (pagePresetName: string, tablePresetName?: string, selectedTabInput?: string) => {
      // To avoid infinite renders, only update the URL and page preset if it's different from
      // the current state
      const newURL = `${location?.origin}${baseUrl}/${R.defaultTo(
        selectedTab,
        selectedTabInput
      )}${pagePresetURLString}${encodePrettyUrl(pagePresetName)}${
        tablePresetName ? `${tablePresetURLString}${encodePrettyUrl(tablePresetName)}` : ""
      }`;
      const isLocationDifferent = location && location.href !== newURL;
      if (selectedTab && isLocationDifferent) {
        setPagePresetName(pagePresetName);
        setTablePresetName(tablePresetName);
        navigate(newURL);
      }
    },
    [location, baseUrl, selectedTab]
  );

  // Dates Callbacks
  const onDatePickerChange = useCallback(
    (dates: DateRange) => {
      localStorage.setItem(`${company}_crossChannelDateRange`, JSON.stringify(dates));
      setDates(dates);
    },
    [company, setDates]
  );

  const onOtherDatePickerChange = useCallback(
    (otherDates: DateRange) => {
      localStorage.setItem(`${company}_crossChannelDateRange`, JSON.stringify(otherDates));
      setOtherDates(otherDates);
    },
    [company, setOtherDates]
  );

  // Page Preset
  const pagePreset = useMemo(() => {
    if (pagePresets && tablePresets && !fetchingPagePresets && !fetchingTablePresets) {
      const pagePreset = R.find(
        (preset: MetricsPagePreset) => preset.name === pagePresetName,
        pagePresets
      );
      if (pagePreset) {
        if (pagePreset.dateInfo) {
          const {
            start,
            end,
            dateType,
            relative_date_info,
            comparison_start,
            comparison_end,
            comparison_dateType,
            comparison_relative_date_info,
          } = pagePreset.dateInfo;

          let resolvedPrimaryDates: DateRange | undefined;
          let resolvedComparisonDates: DateRange | undefined;
          if (R.isNil(dates)) {
            if (dateType === "relative" && relative_date_info && relative_date_info !== "Custom") {
              if (typeof relative_date_info === "string") {
                const newRange = computeDateFromPreset(relative_date_info);
                resolvedPrimaryDates = newRange;
              } else {
                const newRange = computeCustomRelativeRange(
                  relative_date_info.start,
                  relative_date_info.end
                );
                resolvedPrimaryDates = newRange;
              }
            } else if (start && end) {
              resolvedPrimaryDates = {
                start: start,
                end: end,
              };
            }

            if (resolvedPrimaryDates) {
              setDates(resolvedPrimaryDates);
            } else {
              setDates(startingDateRange);
            }
          }
          if (R.isNil(otherDates)) {
            if (
              comparison_dateType === "relative" &&
              comparison_relative_date_info &&
              comparison_relative_date_info !== "Custom" &&
              resolvedPrimaryDates
            ) {
              if (typeof comparison_relative_date_info === "string") {
                const newRange = computeDateFromComparisonPreset(
                  comparison_relative_date_info,
                  resolvedPrimaryDates.start,
                  resolvedPrimaryDates.end
                );
                resolvedComparisonDates = newRange;
              } else {
                const newRange = computeCustomRelativeRange(
                  comparison_relative_date_info.start,
                  comparison_relative_date_info.end
                );
                resolvedComparisonDates = newRange;
              }
            } else if (comparison_start && comparison_end) {
              resolvedComparisonDates = {
                start: comparison_start,
                end: comparison_end,
              };
            }

            if (!resolvedComparisonDates) {
              resolvedComparisonDates = startingOtherDateRange;
            }

            setOtherDates(resolvedComparisonDates);
          }
        } else {
          if (R.isNil(dates)) {
            setDates(startingDateRange);
          }
          if (R.isNil(otherDates)) {
            setOtherDates(startingOtherDateRange);
          }
        }
        // Set Filter Info
        if (pagePreset.filterInfo && R.isNil(filterSource)) {
          if (!R.isNil(pagePreset.filterInfo.filterID)) {
            setFilterSource("presetFilterID");
            setFilterID(pagePreset.filterInfo.filterID);
          } else if (pagePreset.filterInfo.filterState) {
            setFilterSource("presetState");
            setFilterState(pagePreset.filterInfo.filterState);
          } else {
            setFilterSource("currentState");
            resetFilterState();
          }
        }
        // Set Table Preset
        if (R.isNil(tablePresetName) && tablePresets && pagePreset.globalOptions) {
          const pageSourcedTableID = pagePreset.globalOptions.tableID;
          const pageSourcedTablePreset = R.find(
            preset => preset.id === pageSourcedTableID,
            tablePresets
          );
          if (pageSourcedTablePreset) {
            setURL(pagePreset.name, pageSourcedTablePreset.name);
          } else if (tablePresets && tablePresets.length && tablePresets[0]) {
            pagePreset.globalOptions.tableID = tablePresets[0].id;
            setURL(pagePreset.name, tablePresets[0].name);
          }
        }
        if (!kpi && pagePreset.globalOptions && pagePreset.globalOptions.kpi) {
          setKpi(pagePreset.globalOptions.kpi || "");
        } else if (!kpi && crossChannelKPIs) {
          setKpi(crossChannelKPIs[0] || "");
        }
        setPagePresetChanges(pagePreset);
        setPagePresetName(p => {
          if (p !== null && p !== pagePreset.name) {
            Mixpanel.track(MxE.PAGE_PRESET_CHANGE, {
              option_selected: pagePreset.name,
            });
          }
          return pagePreset.name;
        });
      }
      return pagePreset;
    }
  }, [
    pagePresets,
    tablePresets,
    fetchingPagePresets,
    fetchingTablePresets,
    pagePresetName,
    filterSource,
    tablePresetName,
    kpi,
    crossChannelKPIs,
    dates,
    otherDates,
    startingDateRange,
    startingOtherDateRange,
    setFilterState,
    resetFilterState,
    setURL,
  ]);

  const activeMetricsDataFilter = useMemo(() => {
    if (filterSource === "currentState" && !R.isNil(filterState)) {
      if (filterState.isAdvanced && R.isEmpty(filterState.advanced)) {
        return false;
      } else if (
        !filterState.isAdvanced &&
        R.isEmpty(filterState.basic.notMap) &&
        R.isEmpty(filterState.basic.selectedMap)
      ) {
        return false;
      } else {
        return true;
      }
    } else if (filterSource === "currentFilterID" && !R.isNil(filterID)) {
      return true;
    } else if (
      pagePreset &&
      filterSource === "presetFilterID" &&
      !R.isNil(pagePreset.filterInfo.filterID)
    ) {
      return true;
    } else if (pagePreset && filterSource === "presetState" && pagePreset.filterInfo.filterState) {
      const pageFilterState = pagePreset.filterInfo.filterState;
      if (pageFilterState.isAdvanced && R.isEmpty(pageFilterState.advanced)) {
        return false;
      } else if (
        !pageFilterState.isAdvanced &&
        R.isEmpty(pageFilterState.basic.notMap) &&
        R.isEmpty(pageFilterState.basic.selectedMap)
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }, [filterSource, filterState, filterID, pagePreset]);

  const filteredMetricsDataKey = useMemo(
    () =>
      dates && otherDates
        ? `filtered_${filterID}_${JSON.stringify(filterState)}_${kpi}_${dates.start}_${dates.end}_${
            otherDates.start
          }_${otherDates.end}_${platformAsSourceOfData ? "platform" : "analytics"}`
        : "",
    [kpi, dates, otherDates, platformAsSourceOfData, filterID, filterState]
  );

  const filteredMetricsData = useMemo(() => metricsDataMap[filteredMetricsDataKey], [
    metricsDataMap,
    filteredMetricsDataKey,
  ]);

  // Page Preset Callbacks
  const getPagePresets = useCallback(() => {
    (async () => {
      try {
        setFetchingPagePresets(true);
        const res = await SingleChannelLambdaFetch<GetPagePresetsParams>("/metrics_page_presets", {
          params: {
            company,
            mediatype: prefix,
          },
        });
        const parsedPresets = await awaitJSON(res);
        if (!parsedPresets.errorMessage) {
          setPagePresets(parsedPresets as MetricsPagePreset[]);
        }
      } catch (e) {
        const error = e as Error;
        setError({
          message: `Failed to fetch page presets. Error: ${error.message}`,
          reportError: error,
        });
      } finally {
        setFetchingPagePresets(false);
      }
    })();
  }, [company, prefix, setError]);

  const savePagePreset = useCallback(
    body => {
      return (async () => {
        let presetResponse;
        try {
          setFetchingPagePresets(true);
          const res = await SingleChannelLambdaFetch<SavePagePresetParams>("/metrics_page_preset", {
            method: "POST",
            body,
          });
          presetResponse = await awaitJSON<SavePagePresetResponse>(res);
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to save page preset. Error: ${error.message}`,
            reportError: error,
          });
          presetResponse = { message: `Failed to save page preset. Error: ${error.message}` };
        } finally {
          getPagePresets();
          return presetResponse;
        }
      })();
    },
    [getPagePresets, setError]
  );

  const deletePagePreset = useCallback(
    params => {
      return (async () => {
        try {
          setFetchingPagePresets(true);
          const res = await SingleChannelLambdaFetch<DeletePagePresetParams>(
            "/metrics_page_preset",
            {
              method: "DELETE",
              params,
            }
          );
          await awaitJSON(res);
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to delete page preset. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          getPagePresets();
        }
      })();
    },
    [getPagePresets, setError]
  );

  // Table Preset Callbacks
  const getTablePresets = useCallback(() => {
    (async () => {
      try {
        setFetchingTablePresets(true);
        const res = await SingleChannelLambdaFetch<GetTablePresetsParams>(
          "/metrics_table_presets",
          {
            params: {
              company,
              mediatype: prefix,
            },
          }
        );
        const parsedPresets = await awaitJSON(res);
        if (!parsedPresets.errorMessage) {
          setTablePresets(parsedPresets as SimpleMetricsTablePreset[]);
        }
      } catch (e) {
        const error = e as Error;
        setError({
          message: `Failed to fetch table presets. Error: ${error.message}`,
          reportError: error,
        });
      } finally {
        setFetchingTablePresets(false);
      }
    })();
  }, [company, prefix, setError, setTablePresets]);

  // useEffects
  useEffect(() => {
    if (metricsDataLoading || metricsFilteredDataLoading) {
      return;
    }
    (async () => {
      try {
        if (
          (!metricsDataMap[metricsDataKey] || !metricsDataMap[filteredMetricsDataKey]) &&
          dates &&
          otherDates &&
          (kpi || kpi === "") &&
          otherDates
        ) {
          const overviewParams: any = {
            company,
            start: dates.start,
            end: dates.end,
            otherStart: otherDates.start,
            otherEnd: otherDates.end,
            pageID: -10,
            kpi: kpi,
            lag: companyInfo.streaming_performance_default_lag,
            platformAsSource: JSON.stringify(platformAsSourceOfData),
          };
          const paramsWithFilters: any = { ...overviewParams };

          if (activeMetricsDataFilter) {
            if (filterSource === "currentState" && !R.isNil(filterState)) {
              let filterStateWithCustomSegments = {
                ...filterState,
                customSegments,
              };
              paramsWithFilters.filterState = JSON.stringify(filterStateWithCustomSegments);
            } else if (filterSource === "currentFilterID" && !R.isNil(filterID)) {
              paramsWithFilters.filterID = filterID;
            } else if (
              pagePreset &&
              filterSource === "presetFilterID" &&
              !R.isNil(pagePreset.filterInfo.filterID)
            ) {
              paramsWithFilters.filterID = pagePreset.filterInfo.filterID;
            } else if (
              pagePreset &&
              filterSource === "presetState" &&
              pagePreset.filterInfo.filterState
            ) {
              let filterStateWithCustomSegments = {
                ...pagePreset.filterInfo.filterState,
                customSegments,
              };
              paramsWithFilters.filterState = JSON.stringify(filterStateWithCustomSegments);
            }
          }

          if (
            activeMetricsDataFilter &&
            !metricsDataMap[metricsDataKey] &&
            !metricsDataMap[filteredMetricsDataKey]
          ) {
            setMetricsDataLoading(true);
            setMetricsFilteredDataLoading(true);
            CrossChannelLambdaFetch("/getCrossChannelMetrics", {
              params: overviewParams,
            })
              .then(res => awaitJSON(res))
              .then(data => {
                setMetricsDataMap(current => ({
                  ...current,
                  [metricsDataKey]: data,
                }));
                const availableChannels = data.channels.reduce(
                  (prev, curr) => ({ ...prev, [curr]: true }),
                  {}
                );
                setDeliveryAndPerformanceChannels(availableChannels);
                setSelectedChannels(c => ({ ...c, ...availableChannels }));
              })
              .catch(e => {
                const reportError = e as Error;
                setError({
                  message: `Failed to get cross channel metrics. ERROR: ${reportError.message}`,
                  reportError,
                });
              })
              .finally(() => setMetricsDataLoading(false));

            CrossChannelLambdaFetch("/getCrossChannelMetrics", {
              params: paramsWithFilters,
            })
              .then(res => awaitJSON(res))
              .then(data => {
                setMetricsDataMap(current => ({
                  ...current,
                  [filteredMetricsDataKey]: data,
                }));
                const availableChannels = data.channels.reduce(
                  (prev, curr) => ({ ...prev, [curr]: true }),
                  {}
                );
                setDeliveryAndPerformanceChannels(availableChannels);
                setSelectedChannels(c => ({ ...c, ...availableChannels }));
              })
              .catch(e => {
                const reportError = e as Error;
                setError({
                  message: `Failed to get filtered cross channel metrics. ERROR: ${reportError.message}`,
                  reportError,
                });
              })
              .finally(() => setMetricsFilteredDataLoading(false));
          } else if (!activeMetricsDataFilter && !metricsDataMap[metricsDataKey]) {
            setMetricsDataLoading(true);
            setMetricsFilteredDataLoading(true);
            const res = await CrossChannelLambdaFetch("/getCrossChannelMetrics", {
              params: overviewParams,
            });
            const data = await awaitJSON(res);
            setMetricsDataMap(current => ({
              ...current,
              [metricsDataKey]: data,
              [filteredMetricsDataKey]: data,
            }));
            const availableChannels = data.channels.reduce(
              (prev, curr) => ({ ...prev, [curr]: true }),
              {}
            );
            setDeliveryAndPerformanceChannels(availableChannels);
            setSelectedChannels(c => ({ ...c, ...availableChannels }));
            setMetricsDataLoading(false);
            setMetricsFilteredDataLoading(false);
          } else if (!metricsDataMap[filteredMetricsDataKey]) {
            setMetricsFilteredDataLoading(true);
            const res = await CrossChannelLambdaFetch("/getCrossChannelMetrics", {
              params: paramsWithFilters,
            });
            const data = await awaitJSON(res);
            setMetricsDataMap(current => ({
              ...current,
              [filteredMetricsDataKey]: data,
            }));
            const availableChannels = data.channels.reduce(
              (prev, curr) => ({ ...prev, [curr]: true }),
              {}
            );
            setDeliveryAndPerformanceChannels(availableChannels);
            setSelectedChannels(c => ({ ...c, ...availableChannels }));
            setMetricsFilteredDataLoading(false);
          }
        }
      } catch (e) {
        const reportError = e as Error;
        setMetricsDataLoading(false);
        setMetricsFilteredDataLoading(false);
        setError({
          message: `Failed to get cross channel metrics. ERROR: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [
    kpi,
    company,
    dates,
    otherDates,
    companyInfo.streaming_performance_default_lag,
    metricsDataMap,
    metricsDataKey,
    setError,
    platformAsSourceOfData,
    customSegments,
    filterID,
    filterState,
    filterSource,
    pagePreset,
    filteredMetricsDataKey,
    activeMetricsDataFilter,
    metricsDataLoading,
    metricsFilteredDataLoading,
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (R.isEmpty(sourceOptions)) {
          const res = await CrossChannelLambdaFetch("/getSourceOfTruthOptions", {
            params: {
              company,
            },
          });

          const data = await awaitJSON(res);

          setSourceOptions(data || []);
          if (!selectedSource) {
            setSelectedSource(data[0]?.value);
          }
        }
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to get cross channel Source of Truth options. ERROR: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [company, selectedSource, sourceOptions, setError]);

  useEffect(() => {
    (async () => {
      try {
        if (!kpiDataMap[kpiDataKey] && dates && selectedSource) {
          const res = await CrossChannelLambdaFetch("/getCrossChannelKpis", {
            params: {
              company,
              start: dates.start,
              end: dates.end,
              source: selectedSource,
            },
          });
          const data = await awaitJSON(res);

          const availableChannels = data.kpiData.reduce((acc, { channel }) => {
            if (channel === "") {
              return acc;
            }
            return { ...acc, [channel]: true };
          }, {});
          setKpiDataMap(current => ({ ...current, [kpiDataKey]: data }));
          setSelectedChannels(c => ({ ...c, ...availableChannels }));
        }
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to get cross channel KPIs. ERROR: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [
    kpi,
    company,
    dates,
    kpiDataMap,
    kpiDataKey,
    platformAsSourceOfData,
    selectedSource,
    setError,
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (!budgetsDataMap[budgetsDataKey] && dates && otherDates) {
          const res = await CrossChannelLambdaFetch("/getCrossChannelBudgets", {
            params: {
              company,
              start: dates.start,
              end: dates.end,
              otherStart: otherDates.start,
              otherEnd: otherDates.end,
            },
          });
          const data = await awaitJSON(res);
          setBudgetsDataMap(current => ({ ...current, [budgetsDataKey]: data }));
        }
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: `Failed to get cross channel budgets. ERROR: ${reportError.message}`,
          reportError,
        });
      }
    })();
  }, [kpi, company, dates, otherDates, budgetsDataKey, budgetsDataMap, setError]);

  useEffect(() => {
    (async () => {
      if (dates) {
        try {
          const res = await ToolsLambdaFetch("/getMarketingPortfolio", {
            params: { company, start: dates.start, end: dates.end, kpi: companyInfo.initial_kpi },
          });
          const { channelSpend, channelClassification } = await awaitJSON(res);
          setMarketingInputs(channelSpend);
          setChannelClassification(channelClassification);
        } catch (e) {
          const reportError = e as Error;
          setError({
            message: `Failed to get marketing inputs. ERROR: ${reportError.message}`,
            reportError,
          });
        }
      }
    })();
  }, [company, dates, companyInfo.initial_kpi, setError]);

  useEffect(() => {
    if (company && !filterData) {
      (async () => {
        try {
          const res = await CrossChannelLambdaFetch<CC.GetFilterOptionsParams>(
            "/getCrossChannelFilterOptions",
            { params: { company: company } }
          );
          const filterOptions = await awaitJSON<CC.GetFilterOptionsResponse>(res);

          // Get the custom segments from the filter options
          const customSegments = filterOptions
            .filter(option => option.isCustomSegment)
            .reduce(
              (curr, prev) => ({
                ...curr,
                [prev.label]: makeColumnMetaData(prev.label as any),
              }),
              {}
            );

          setFilterData(filterOptions);
          setCustomSegments(customSegments);
        } catch (e) {
          const error = e as Error;
          setError({
            message: error.message,
            reportError: error,
          });
        }
      })();
    }
  }, [company, filterData, prefix, setError, setFilterData]);

  // Combine the default cross-channel dimensions with the company-specific custom segments
  const allDimensions = useMemo(() => ({ ...dimensionColumnMetaDataMap, ...customSegments }), [
    customSegments,
  ]);

  useEffect(() => {
    if (company && !pagePresets) {
      getPagePresets();
    }
  }, [company, getPagePresets, pagePresets, setError]);

  useEffect(() => {
    if (company && !tablePresets) {
      getTablePresets();
    }
  }, [company, getTablePresets, setError, tablePresets]);

  useEffect(() => {
    if (R.isNil(selectedTab)) {
      if (urlSettings.startsWith(TabKey.DELIVERY_AND_PERFORMANCE)) {
        setSelectedTab(TabKey.DELIVERY_AND_PERFORMANCE);
      } else if (urlSettings.startsWith(TabKey.METRICS_TABLE)) {
        setSelectedTab(TabKey.METRICS_TABLE);
      } else if (urlSettings.startsWith(TabKey.OVERVIEW)) {
        setSelectedTab(TabKey.OVERVIEW);
      } else {
        setSelectedTab(TabKey.OVERVIEW);
      }
    }
  }, [selectedTab, urlSettings]);

  useEffect(() => {
    if (
      selectedTab &&
      pagePresets &&
      pagePresets.length &&
      tablePresets &&
      tablePresets.length &&
      !fetchingPagePresets
    ) {
      const decodedURLPagePresetName = decodePrettyUrl(
        urlSettings.substring(
          selectedTab.length + pagePresetURLString.length,
          urlSettings.indexOf(tablePresetURLString)
        )
      );
      const decodedURLTablePresetName = decodePrettyUrl(
        urlSettings.substring(
          urlSettings.indexOf(tablePresetURLString) + tablePresetURLString.length,
          urlSettings.length
        )
      );
      if (R.isNil(tablePresetName) && decodedURLTablePresetName) {
        setTablePresetName(decodedURLTablePresetName);
      }
      if (R.isNil(pagePresetName) && decodedURLPagePresetName) {
        setPagePresetName(decodedURLPagePresetName);
      }
      const urlPagePreset = R.find(preset => preset.name === decodedURLPagePresetName, pagePresets);
      const urlTablePreset = R.find(
        preset => preset.name === decodedURLTablePresetName,
        tablePresets
      );
      if (R.isNil(urlPagePreset) || (pagePreset && pagePreset.name !== urlPagePreset.name)) {
        if (urlPagePreset) {
          setURL(urlPagePreset.name, urlTablePreset?.name);
        } else if (pagePresets && pagePresets.length) {
          for (const preset of pagePresets) {
            if (!preset.temporary) {
              setURL(preset.name, urlTablePreset?.name);
              setPagePresetName(preset.name);
              break;
            }
          }
        }
      }
    }
  }, [
    fetchingPagePresets,
    pagePreset,
    pagePresetName,
    pagePresets,
    selectedTab,
    setURL,
    tablePresetName,
    tablePresets,
    urlSettings,
  ]);

  const [kpiNameMap, nameKpiMap, kpiOptions] = useMemo(() => {
    const kpiNameMap = {};
    const nameKpiMap = {};
    const kpiOptions: string[] = [];

    for (const kpi of crossChannelKPIs) {
      kpiNameMap[kpi] = {
        cpxName: `CP${kpi.slice(0).toUpperCase()}`,
        decimals: 0,
        id: kpi,
        kpi,
        name: kpi,
        revenueHeader: null,
      };
      nameKpiMap[kpi] = { id: kpi, name: kpi };
      kpiOptions.push(kpi);
    }

    return [kpiNameMap, nameKpiMap, kpiOptions];
  }, [crossChannelKPIs]);

  const pagePresetGroups = useMemo(() => {
    const groupsMap = {};
    if (pagePresets) {
      for (const preset of pagePresets) {
        if (preset.presetGroup && preset.presetGroup !== STANDARD_GROUP_NAME) {
          groupsMap[preset.presetGroup] = true;
        }
      }
    }
    return R.keys(groupsMap).sort();
  }, [pagePresets]);

  // Page Preset Modals
  const editPagePresetModal = (showEditPagePresetModal || showNewPagePresetModal) &&
    kpiData &&
    pagePresetChanges && (
      <EditPagePresetModal
        mediatype={"cross-channel"}
        menuTabs={[
          "Dates",
          "KPI, etc.",
          "Cross-Channel Overview",
          "Delivery & Performance",
          "Metrics Table",
          "Filters",
          "Preset Group",
        ]}
        filterPresets={filterPresets}
        funnelPresets={kpiData.funnelPresets}
        pagePresetChanges={pagePresetChanges}
        globalOptionsPickers={{
          kpi: {
            kpiNameMap,
            nameKpiMap,
            options: kpiOptions,
          },
        }}
        groups={pagePresetGroups}
        sparkChartOptions={[
          Metric.CLICKS_CONVERSION_RATE,
          Metric.IMPS_CONVERSION_RATE,
          Metric.CPM,
          Metric.CPX,
          Metric.CTR,
          Metric.IMPRESSIONS,
          Metric.REVENUE,
          Metric.ROAS,
          Metric.SPEND,
          Metric.VOLUME,
        ]}
        setPagePresetChanges={setPagePresetChanges}
        saveAndApplyOnClick={presetChanges => {
          (async () => {
            if (
              !R.isNil(presetChanges) &&
              !R.isNil(presetChanges.filterInfo) &&
              !R.isNil(presetChanges.filterInfo.filterState)
            ) {
              presetChanges.filterInfo.filterState = filterState;
            }
            const decodedURLTablePresetName = decodePrettyUrl(
              urlSettings.substring(
                urlSettings.indexOf(tablePresetURLString) + tablePresetURLString.length,
                urlSettings.length
              )
            );
            const presetResponse = await savePagePreset({
              company: company,
              id: R.defaultTo("new", presetChanges.id),
              mediatype: prefix,
              name: presetChanges.name,
              group: presetChanges.presetGroup,
              preset: presetChanges,
              temporary: presetChanges.temporary,
            });
            if (
              presetChanges.temporary ||
              (presetChanges.id as any) === "new" ||
              presetChanges.id <= 0 ||
              (pagePreset && presetChanges.id === pagePreset.id)
            ) {
              setKpi(undefined);
              setDates(undefined);
              setOtherDates(undefined);
              setFilterID(undefined);
              setURL(presetResponse.name, decodedURLTablePresetName);
            }
            setShowEditPagePresetModal(false);
            setShowNewPagePresetModal(false);
          })();
        }}
        tablePresets={R.filter(
          tablePreset => !tablePreset.temporary,
          R.defaultTo([], tablePresets)
        )}
        onHide={() => {
          setShowEditPagePresetModal(false);
          setShowNewPagePresetModal(false);
          if (pagePreset) {
            setPagePresetChanges(pagePreset);
          }
        }}
      />
    );

  const renamePagePresetModal = showRenamePagePresetModal && pagePresetChanges && (
    <RenameModal
      applyOnClick={newName => {
        (async () => {
          const decodedURLTablePresetName = decodePrettyUrl(
            urlSettings.substring(
              urlSettings.indexOf(tablePresetURLString) + tablePresetURLString.length,
              urlSettings.length
            )
          );
          await savePagePreset({
            company: company,
            id: pagePresetChanges.id,
            mediatype: prefix,
            name: newName,
            group: pagePresetChanges.presetGroup,
          });
          setShowRenamePagePresetModal(false);
          setURL(newName, decodedURLTablePresetName);
        })();
      }}
      applyText={"Save"}
      cancelText={"Discard"}
      onCancel={() => {
        setShowRenamePagePresetModal(false);
        if (pagePreset) {
          setPagePresetChanges(pagePreset);
        }
      }}
      onHide={() => {
        setShowRenamePagePresetModal(false);
        if (pagePreset) {
          setPagePresetChanges(pagePreset);
        }
      }}
      placeholder="Enter new preset name"
      subTitle={"New Page Preset Name"}
      title={`Rename ${pagePresetChanges && pagePresetChanges.name ? pagePresetChanges.name : ""}`}
    />
  );

  // Tab Render Item
  const renderItem: JSX.Element | undefined = useMemo(() => {
    if (!fetchingPagePresets && pagePresetChanges && !R.isNil(pagePresetChanges.id)) {
      const overview = (
        <Overview
          kpis={kpiData || { kpiData: [], funnelPresets: [], sources: [] }}
          data={metricTotalsByDate}
          otherData={otherMetricTotalsByDate}
          marketingInputs={marketingInputs || []}
          channelClassification={channelClassification || {}}
          defaultCompareMetrics={pagePresetChanges?.globalOptions?.compareMetrics}
          defaultCompareMetricsIncludeOtherDates={
            pagePresetChanges?.globalOptions?.compareMetricsIncludeOtherDates
          }
          defaultFunnelDynamicsPreset={pagePresetChanges?.globalOptions?.funnelDynamicsName}
          defaultSparkCharts={pagePresetChanges?.globalOptions?.sparkCharts}
          defaultTotal={pagePresetChanges?.globalOptions?.sparkChartTypes}
        />
      );
      switch (selectedTab) {
        case TabKey.OVERVIEW:
          return overview;
        case TabKey.DELIVERY_AND_PERFORMANCE:
          return (
            <DeliveryAndPerformance
              // getCrossChannelMetrics
              data={R.pathOr([], ["primaryMetrics"], filteredMetricsData)}
              otherData={R.pathOr([], ["otherMetrics"], filteredMetricsData)}
              // getCrossChannelBudgets
              budgetsData={budgetsData}
              defaultBudgetSnapshotBottom={pagePresetChanges?.globalOptions?.sbSnapshotBottom}
              defaultBudgetSnapshotTop={pagePresetChanges?.globalOptions?.sbSnapshotTop}
              defaultDeliveryIncludeOtherDates={
                pagePresetChanges?.globalOptions?.crossChannelDeliveryIncludeOtherDates
              }
              defaultDeliveryMetric={pagePresetChanges?.globalOptions?.crossChannelDeliveryMetric}
              defaultPerformanceBreakdownIncludeOtherDates={
                pagePresetChanges?.globalOptions?.crossChannelPerformanceIncludeOtherDates
              }
              defaultPerformanceBreakdownMetric={
                pagePresetChanges?.globalOptions?.crossChannelPerformanceMetric
              }
              defaultPerformanceSnapshotMetric={
                pagePresetChanges?.globalOptions?.crossChannelPerformanceSnapshotMetric
              }
            />
          );

        case TabKey.METRICS_TABLE:
          return (
            <Metrics
              columnMetaDataMap={columnMetaDataMap}
              dates={R.defaultTo(startingDateRange, dates)}
              dimensionColumnMetaDataMap={allDimensions}
              getDimensionCell={getCrossChannelDimensionCell}
              kpi={R.defaultTo("", kpi)}
              kpiMetaData={kpiNameMap}
              metricsTableFetchFilterBar={metricsTableFetchFilterBar}
              prefix={"cross_channel"}
              setMetricsTableFetchFilterBar={setMetricsTableFetchFilterBar}
            />
          );
        default:
          return overview;
      }
    }
  }, [
    fetchingPagePresets,
    pagePresetChanges,
    kpiData,
    metricTotalsByDate,
    otherMetricTotalsByDate,
    marketingInputs,
    channelClassification,
    selectedTab,
    filteredMetricsData,
    budgetsData,
    startingDateRange,
    dates,
    allDimensions,
    kpi,
    kpiNameMap,
    metricsTableFetchFilterBar,
  ]);

  return (
    <Page
      app2Redesign
      pageType="Cross Channel"
      title="Cross-Channel"
      navs={NAVS}
      selectedNav={selectedTab}
      onNav={nav => {
        setSelectedTab(nav);
        if (pagePresetName) {
          setURL(pagePresetName, tablePresetName, nav);
        }
      }}
      actions={
        <div className="ccPageActions">
          {isInternal && (
            <PresetDropdown
              addPresetOnClick={() => {
                setShowNewPagePresetModal(true);
                // In the case that we are in the standard group already, clear this out
                setPagePresetChanges(
                  preset =>
                    ({
                      ...preset,
                      id: "new",
                      name: "New Page Preset!",
                      presetGroup: "",
                      dateInfo: {
                        ...preset.dateInfo,
                        dateType: "relative",
                        comparison_dateType: "relative",
                        relative_date_info: "Last Month",
                        comparison_relative_date_info: "Default",
                      },
                    } as any)
                );
              }}
              addPresetText={"Add New Page Preset"}
              applyOnClick={preset => {
                setURL("");
                setURL(preset.name);
                setKpi(undefined);
                setDates(undefined);
                setOtherDates(undefined);
                setFilterSource(undefined);
              }}
              cancelOnClick={() => {}}
              className={`pagePresetPicker ${fetchingPagePresets ? "loading" : ""}`}
              defaultPresetNames={["Default"]}
              deleteItemOnClick={presetID => deletePagePreset({ id: presetID, mediatype: prefix })}
              duplicateItemOnClick={preset =>
                savePagePreset({
                  company,
                  id: "new",
                  mediatype: prefix,
                  preset: preset,
                  name: `${preset.name} (Copy)`,
                  group: preset.presetGroup,
                })
              }
              editItemOnClick={preset => {
                const presetCopy = { ...preset };
                if (
                  preset.dateInfo &&
                  preset.dateInfo.dateType === "relative" &&
                  !preset.dateInfo.relative_date_info
                ) {
                  presetCopy.dateInfo.dateType = "exact";
                }
                if (preset.dateInfo && !preset.dateInfo.comparison_relative_date_info) {
                  presetCopy.dateInfo.comparison_dateType = "exact";
                }
                setPagePresetChanges(presetCopy);
                setShowEditPagePresetModal(true);
              }}
              hasPresetGroups={true}
              hasTicks={true}
              label={
                pagePreset && !R.isNil(pagePreset.id)
                  ? `Page Preset: ${pagePreset.temporary ? "Temp" : pagePreset.name}`
                  : "Load Page Preset"
              }
              presets={R.filter(
                preset =>
                  !preset.temporary || (!R.isNil(pagePreset) && preset.id === pagePreset.id),
                R.defaultTo([], pagePresets)
              )}
              renameItemOnClick={() => setShowRenamePagePresetModal(true)}
              selectedPreset={pagePreset}
              subTitles={["Preset Group", "Preset"]}
            />
          )}
          {selectedTab === TabKey.OVERVIEW ? (
            <Dropdown
              background="dark"
              type={DropdownToggleType.OUTLINED}
              label="Source"
              value={selectedSource}
              options={sourceOptions}
              onChange={source => setSelectedSource(source)}
            />
          ) : (
            <Dropdown
              background="dark"
              type={DropdownToggleType.OUTLINED}
              label="Source"
              value={platformAsSourceOfData ? "Platform" : "Source of Truth"}
              options={["Source of Truth", "Platform"]}
              onChange={source => {
                const isPlatformAsSource = source === "Platform";
                Mixpanel.track(MxE.SOT_VS_PLATFORM_CHANGE, {
                  option_selected: isPlatformAsSource ? "Platform" : "Source of Truth",
                });
                setPlatformAsSourceOfData(isPlatformAsSource);
              }}
            />
          )}
          <Dropdown
            background="dark"
            label="KPI"
            type={DropdownToggleType.OUTLINED}
            value={R.defaultTo("", kpi)}
            options={crossChannelKPIs}
            onChange={option => {
              Mixpanel.track(MxE.PAGE_KPI_CHANGE, {
                option_selected: option,
              });
              setKpi(option);
            }}
          />
          <DatePicker
            range={dates}
            onChange={({ start, end }) => {
              onDatePickerChange({ start, end });
            }}
            comparison={true}
            comparisonRange={otherDates}
            comparisonOnChange={({ start, end }) => {
              onOtherDatePickerChange({ start, end });
            }}
            datePresets={true}
          />
        </div>
      }
      subHeader={
        <div className="ccPageSubHeader">
          {filterData && (
            <FetchFilterBar
              categories={filterData.filter(f => f.label !== "Surface")}
              company={company}
              filterID={filterID}
              filters={filterPresets}
              platform={prefix}
              disabled={selectedTab === TabKey.OVERVIEW}
              refreshData={() => {
                setMetricsDataMap(map => ({ ...map, [filteredMetricsDataKey]: undefined }));
                setMetricsTableDataMap(map => ({ ...map, [metricsTableDataKey]: undefined }));
              }}
              setFilterID={setFilterID}
              setFilters={setFilterPresets}
              setFilterSource={
                setFilterSource as StateSetter<
                  "presetFilterID" | "currentFilterID" | "presetState" | "currentState"
                >
              }
              setState={setFilterState}
              state={
                selectedTab === TabKey.OVERVIEW ? { ...DEFAULT_FILTER_PANE_STATE } : filterState
              }
            />
          )}
        </div>
      }
    >
      {metricsData &&
      kpiData &&
      metricTotalsByDate &&
      otherMetricTotalsByDate &&
      !fetchingPagePresets ? (
        <CrossChannelContext.Provider
          value={{
            company,
            dates: R.defaultTo(startingDateRange, dates),
            otherDates: R.defaultTo(startingOtherDateRange, otherDates),
            selectedChannels,
            deliveryAndPerformanceChannels,
            channelColors,
            kpi: R.defaultTo("", kpi),
            crossChannelKPIs,
            platformAsSourceOfData,
            // URL
            setURL,
            // Page Preset
            pagePreset,
            // Filter Presets
            filterData,
            filterID,
            filterSource,
            filterState,
            setFilterData,
            // Table Preset
            fetchingTablePresets,
            getTablePresets,
            setFetchingTablePresets,
            tablePresetName,
            tablePresets,
            // Metrics Table
            metricsTableDataMap,
            setMetricsTableDataMap,
            metricsTableDataKey,
            customSegments: R.keys(customSegments).reduce(
              (prev, curr) => ({ ...prev, [curr]: true }),
              {}
            ),
          }}
        >
          {editPagePresetModal}
          {renamePagePresetModal}
          <div className="ccPage">{renderItem}</div>
        </CrossChannelContext.Provider>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default CrossChannel;

export const CC_DIMENSIONS = ["Account ID", "Account Name", "Channel", "Platform"];

export const cartesianProduct = (arrays: any[][]): any[][] => {
  if (arrays.length === 0) {
    return [[]];
  }
  const [head, ...tail] = arrays;
  const tailCombinations = cartesianProduct(tail);
  return head.flatMap(headItem => tailCombinations.map(tailCombo => [headItem, ...tailCombo]));
};

import "./Geo.scss";
import React, { useCallback, useEffect, useState } from "react";
import * as R from "ramda";
import { useQuery } from "@apollo/client";
import { amcClient } from "../../Cache";
import { useCompanyInfo } from "../../../redux/company";
import { GET_GEO_REPORT } from "../../Queries";
import {
  formatDate,
  MonthPicker,
  startDateRange,
} from "../../../Components/MonthPicker/MonthPicker";
import { BPMTable, Dropdown, DropdownToggleType, FullPageSpinner, Page } from "../../../Components";
import GeoDataCards from "./GeoDataCards";
import { geoTableHeaders } from "./GeoConfig";
import { abbreviateNumberAMC } from "../../AMCFormatters";
import {
  GET_MAX_GEO,
  maxDPVRate,
  maxConversionRate,
  maxGeoNtbRepeatRate,
  maxPurchaseRate,
} from "../../ReactiveVariables";
import { StateMap } from "../../../Components/StateMap/StateMap";
import { RouteComponentProps } from "@reach/router";
import { AMC_TEST_INSTANCE_ID } from "../../Insights";

/*eslint-disable no-empty-pattern*/
const Geo = ({}: RouteComponentProps): JSX.Element => {
  let { cid, amc_instance_id: amcInstanceId } = useCompanyInfo();
  if (cid === "tinuititest") {
    amcInstanceId = AMC_TEST_INSTANCE_ID;
  }
  const [stateSelection, updateStateSelection] = useState("grandTotal");
  const [dataPointSelection, updateDataPointSelection] = useState("impressions");

  // Previous month reports not prepared until the 15th of current month
  const monthsBack = new Date().getDate() < 15 ? 2 : 1;
  const [startDateSelection, updateStartDateSelection] = useState(startDateRange(monthsBack + 2));
  const [endDateSelection, updateEndDateSelection] = useState(startDateRange(monthsBack));
  const [dateOptions, updateDateOptions] = useState([{ value: "" }]);
  const [dateRange, updateDateRange] = useState({
    endDate: formatDate(endDateSelection, "end"),
    startDate: formatDate(startDateSelection, "start"),
  });

  useQuery(GET_MAX_GEO, { client: amcClient });

  let { data, loading } = useQuery(GET_GEO_REPORT, {
    client: amcClient,
    variables: {
      amcInstanceId,
      dateRange,
    },
  });

  const bottomRenderer = ({ data, style, classes }): JSX.Element => {
    let newData;
    newData = typeof data === "string" ? data : abbreviateNumberAMC(parseFloat(data));

    return (
      <div className={classes.join(" ")} style={style}>
        {newData}
      </div>
    );
  };

  const formatTotalCells = data => {
    data.dpvRate = `${data.dpvRate}%`;
    data.conversionRate = `${data.conversionRate}%`;
    data.ntbRepeatRate = `${data.ntbRepeatRate}%`;
    data.ntbPurchaseRate = `${data.ntbPurchaseRate}%`;
  };

  // Cloned to make formatting changes for avgOrderSize and pruchaseRate
  const tableGrandTotal = R.clone(data?.amc.geoReport.tableGrandTotal);
  if (tableGrandTotal) {
    formatTotalCells(tableGrandTotal);
  }

  useEffect(() => {
    const filterData = (data, dataType) => {
      return data.amc.geoReport.table.map(row => row[dataType]);
    };
    if (data) {
      const dpvRate = filterData(data, "dpvRate");
      const conversionRate = filterData(data, "conversionRate");
      const geoNtbRepeatRate = filterData(data, "ntbRepeatRate");
      const ntbPurchaseRate = filterData(data, "ntbPurchaseRate");

      maxDPVRate(Math.max(...dpvRate));
      maxConversionRate(Math.max(...conversionRate));
      maxGeoNtbRepeatRate(Math.max(...geoNtbRepeatRate));
      maxPurchaseRate(Math.max(...ntbPurchaseRate));

      const months = data?.amc.geoReport.availableFilters.months;
      const options = months?.map(m => {
        return { value: m };
      });

      updateDateOptions(options);
    }
  }, [data]);

  const geoTableData = data?.amc?.geoReport.table;
  const geoGrandTotal = data?.amc?.geoReport.tableGrandTotal;
  const dataCardData =
    stateSelection === "grandTotal"
      ? geoGrandTotal
      : geoTableData.find(d => d.state === stateSelection);

  const stateMapTooltipFormatter = useCallback((dataPoint, dataPoints, d) => {
    return `${dataPoint
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/ntb/i, "NTB")
      .replace(/dpv/i, "DPV")}: ${abbreviateNumberAMC(dataPoints[d.properties.name])}`;
  }, []);

  return (
    <Page minHeight="600px" pageType="State Geo" title="Geo">
      {!data && <FullPageSpinner />}
      <div className="geo-page">
        <div className="geo-page-header">
          <div className="back-arrow"></div>
          <MonthPicker
            dateOptions={dateOptions}
            endDateSelection={endDateSelection}
            startDateSelection={startDateSelection}
            updateDateRange={updateDateRange}
            updateEndDateSelection={updateEndDateSelection}
            updateStartDateSelection={updateStartDateSelection}
          />
        </div>

        <div className="basic-card geo-map-and-data">
          <div className="map-section-content">
            <div className="dropdown-state-map-wrapper">
              <Dropdown
                type={DropdownToggleType.OUTLINED}
                design="secondary"
                onChange={option => updateDataPointSelection(option)}
                options={[
                  { label: "Impressions", value: "impressions" },
                  { label: "Reach", value: "reach" },
                  { label: "DPV", value: "dpv" },
                  { label: "Customers Converted", value: "customersConverted" },
                  { label: "Orders", value: "orders" },
                  { label: "Revenue", value: "revenue" },
                  { label: "NTB Customers", value: "ntbCustomers" },
                  { label: "DPV Rate", value: "dpvRate" },
                  { label: "Conversion Rate", value: "conversionRate" },
                  { label: "NTB Repeat Rate", value: "ntbRepeatRate" },
                ]}
                value={dataPointSelection}
              />
              {!loading && (
                <StateMap
                  data={geoTableData}
                  dataPoint={dataPointSelection}
                  resetValue="grandTotal"
                  tooltipFormatter={stateMapTooltipFormatter}
                  updateSelection={updateStateSelection}
                />
              )}
            </div>
            <GeoDataCards
              geoCardData={dataCardData}
              title={stateSelection === "grandTotal" ? "Grand Total" : stateSelection}
            />
          </div>
        </div>
        <div className="basic-card">
          <div className="table-wrapper">
            {data ? (
              <BPMTable
                data={geoTableData}
                filterBar={false}
                headers={geoTableHeaders}
                noRowsRenderer={() => <div className="noFailures">No data to show</div>}
                pagination={true}
                totals={tableGrandTotal}
                totalsRenderer={bottomRenderer}
              />
            ) : (
              <FullPageSpinner />
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Geo;

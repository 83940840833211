import React from "react";
import * as R from "ramda";

import { MdDelete, MdOutlineAvTimer, MdSave } from "react-icons/md";
import { StateSetter } from "../../utils/types";

import "./PendingChangesControls.scss";
import { ButtonFrameworkVariant } from "../ButtonFramework";
import { Button, ButtonType } from "../Button";
import { Spinner } from "../Spinner";

interface PendingChangesControlsProps {
  hasPendingChanges: boolean;
  setShowPendingChanges: StateSetter<boolean>;
  saveChanges: () => void;
  isSaving: boolean;
  clearAllChanges: () => void;
}

export const PendingChangesControls: React.FC<PendingChangesControlsProps> = React.memo(
  ({
    hasPendingChanges,
    setShowPendingChanges,
    saveChanges,
    isSaving,
    clearAllChanges,
  }: PendingChangesControlsProps): JSX.Element => {
    return (
      <div className="pendingChangesControls">
        <Button
          type={ButtonType.FILLED}
          design="secondary"
          variant={ButtonFrameworkVariant.LEADING_ICON}
          icon={<MdOutlineAvTimer />}
          onClick={() => {
            setShowPendingChanges(R.not);
          }}
          disabled={!hasPendingChanges}
        >
          View Pending Changes
        </Button>
        <Button
          type={ButtonType.OUTLINED}
          variant={ButtonFrameworkVariant.LEADING_ICON}
          onClick={clearAllChanges}
          disabled={!hasPendingChanges}
          icon={<MdDelete />}
        >
          Discard Changes
        </Button>
        <Button
          type={ButtonType.FILLED}
          variant={ButtonFrameworkVariant.LEADING_ICON}
          onClick={saveChanges}
          disabled={isSaving || !hasPendingChanges}
          icon={<MdSave />}
        >
          {isSaving ? <Spinner /> : "Save"}
        </Button>
      </div>
    );
  }
);

import React, { useCallback } from "react";
import PaidMediaImpact from "./PaidMediaImpact";
import ModelInsights from "./ModelInsights";
import InvestToIncrease from "./InvestToIncrease";
import { RouteComponentProps } from "@reach/router";
import { Dropdown, Img, Button, ButtonType, CheckBox } from "../../Components";
import Overview from "./Overview";
import "./BrandImpact.scss";
import { BrandImpactData } from "@blisspointmedia/bpm-types/dist/BrandEquity";
import { useSetAreYouSure, useSetError } from "../../redux/modals";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import { MetricsLambdaFetch } from "../../utils/fetch-utils";

interface BrandImpactProps {
  company: string;
  groupByMetric: string;
  groupByMetricOptions: Array<{
    label: string;
    value: string;
    s3Path: string;
    client_facing: boolean;
  }>;
  groupByLevel: string;
  brandImpactData: BrandImpactData;
  setGroupByMetric: any;
  isInternalSelector: any;
  modelRunDate: string;
  setGroupByOptionsBrandImpact: any;
  setGroupByOptionsBrandImpactAll: any;
}

const BrandImpact = ({
  company,
  groupByMetric,
  groupByMetricOptions,
  groupByLevel,
  brandImpactData,
  setGroupByMetric,
  isInternalSelector,
  modelRunDate,
  setGroupByOptionsBrandImpact,
  setGroupByOptionsBrandImpactAll,
}: BrandImpactProps & RouteComponentProps): JSX.Element => {
  const setAreYouSure = useSetAreYouSure(true);

  const {
    weeklyBrandHealthTimeSeries,
    modelOverview,
    weeklyBrandHealthAreaChartSpend,
    implicationsData,
    paidMediaAreaChart,
    paidMediaBarChart,
    spendAndEffectBarChart,
    nextBestDollarBarChart,
    saturationData,
    decayData,
  } = brandImpactData;
  const setError = useSetError();

  const toggleBrandEquityRun = useCallback(
    async (modelDate: string, checked: boolean, kpi: string) => {
      try {
        await MetricsLambdaFetch("/toggleBrandEquityRun", {
          method: "POST",
          body: {
            company,
            date: modelDate,
            client_facing: checked,
            kpi: kpi,
          },
        });
        const metric = kpi.charAt(0).toUpperCase() + kpi.slice(1).toLowerCase();
        setGroupByOptionsBrandImpact((prevOptions: any) =>
          prevOptions.map((item: any) => ({
            ...item,
            client_facing: item.label === metric ? !checked : checked,
          }))
        );
        setGroupByOptionsBrandImpactAll((prevOptions: any) => {
          const updatedOptions = { ...prevOptions };
          if (updatedOptions[modelDate]) {
            updatedOptions[modelDate] = updatedOptions[modelDate].map((item: any) =>
              item.label === metric ? { ...item, client_facing: !item.client_facing } : item
            );
          }
          return updatedOptions;
        });
      } catch (e) {
        const reportError = e as Error;
        setError({
          message: reportError.message,
          reportError,
        });
      }
    },
    [company, setError, setGroupByOptionsBrandImpact, setGroupByOptionsBrandImpactAll]
  );

  return (
    <div>
      <div className="brandImpactHeaderButtons">
        <Dropdown
          background="light"
          className="brandEquityGroupByDropdown"
          section={isInternalSelector ? ["Client-Facing", "Admin-Only"] : ["Client Facing"]}
          title="Brand Health Metric"
          onChange={setGroupByMetric}
          options={groupByMetricOptions.map(kpi => ({
            ...kpi,
            section: kpi.client_facing ? "Client-Facing" : "Admin-Only",
          }))}
          value={groupByMetric}
        />
        {groupByMetricOptions && (
          <Button
            className="archiveToggleButton"
            background="light"
            type={ButtonType.OUTLINED}
            variant={ButtonFrameworkVariant.TRAILING_ICON}
            onClick={() => {
              const matchedItem = groupByMetricOptions.find(item => item.value === groupByMetric);
              const visibility = matchedItem ? matchedItem.client_facing : false;
              setAreYouSure({
                title: visibility ? "Hide run from clients?" : "Show run to clients?",
                message: `You're about to make this run ${
                  visibility ? "hidden from" : "visible to"
                } clients. Are you sure you want to continue?`,
                cancelText: "Cancel",
                okayText: "Continue",
                onOkay: () => {
                  toggleBrandEquityRun(modelRunDate, visibility, groupByMetric.toLocaleLowerCase());
                },
              });
            }}
            icon={
              <CheckBox
                checked={
                  groupByMetricOptions.find(item => item.value === groupByMetric)?.client_facing ??
                  false
                }
                id="secondaryDesign"
              />
            }
          >
            Client-Facing
          </Button>
        )}
      </div>
      <div className="brandEquityHeader">
        <div className="brandEquityTextHeader">
          <div className="brandEquityTextHeaderTitle">
            A strong brand drives business outcomes and creates a tailwind for performance marketing
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Performance marketing generates results over days to weeks. Brand marketing operates
            over months to years, making unified assessment of the funnel challenging.
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Bliss Point Brand Health quantifies brand equity's impact on the business for a holistic
            understanding of ROAS.
          </div>
        </div>
        <div className="brandEquityHeaderImageContainer">
          <Img
            src="https://cdn.blisspointmedia.com/assets/img/BusinessImpactDemo/BrandEquity_Infographic.png"
            alt="brand health header"
          />
        </div>
      </div>
      {Object.keys(modelOverview).length > 0 &&
        weeklyBrandHealthTimeSeries.length > 0 &&
        Object.keys(weeklyBrandHealthAreaChartSpend).length > 0 && (
          <Overview
            company={company}
            groupByMetric={groupByMetric}
            groupByLevel={groupByLevel}
            weeklyBrandHealthTimeSeries={weeklyBrandHealthTimeSeries}
            weeklyBrandHealthAreaChartSpend={weeklyBrandHealthAreaChartSpend}
            modelOverviewAll={modelOverview}
          />
        )}
      {Object.keys(implicationsData).length > 0 && (
        <PaidMediaImpact
          company={company}
          groupByMetric={groupByMetric}
          paidMediaImpactAreaChart={paidMediaAreaChart}
          paidMediaImpactBarChart={paidMediaBarChart}
          implicationsData={implicationsData}
        />
      )}
      {Object.keys(nextBestDollarBarChart).length > 0 &&
        Object.keys(spendAndEffectBarChart).length > 0 && (
          <InvestToIncrease
            company={company}
            groupByMetric={groupByMetric}
            groupByMetricOptions={groupByMetricOptions}
            nextBestThousandDollarsAll={nextBestDollarBarChart}
            spendAndEffectAll={spendAndEffectBarChart}
          />
        )}
      {Object.keys(decayData).length > 0 && Object.keys(saturationData).length > 0 && (
        <ModelInsights
          company={company}
          groupByMetric={groupByMetric}
          saturationData={saturationData}
          decayData={decayData}
        />
      )}
    </div>
  );
};

export default BrandImpact;

import React, { useContext } from "react";
import { FormContext, metaCampaignFormDefault } from "../MetaBuyingContext";
import { Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MetaBuyingNamePreview } from "./MetaBuyingNamePreview";
import { MetaBuyingFooterControls } from "./MetaBuyingFooterControls";
import { MetaBuyingOptionsNoteCard } from "./MetaBuyingOptionsNoteCard";
import { RouteComponentProps } from "@reach/router";
import * as R from "ramda";

const BUDGET_TYPES = [
  {
    label: "Ad Set Budget",
    value: "AD_SET_BUDGET",
  },
  {
    label: "Campaign Daily Budget",
    value: "CAMPAIGN_DAILY_BUDGET",
  },
  {
    label: "Campaign Lifetime Budget",
    value: "CAMPAIGN_LIFETIME_BUDGET",
  },
];

const FUNNEL_OPTIONS = ["Upper", "Lower"];

const BID_STRATEGIES = [
  {
    label: "Lowest Cost w/out Cap",
    value: "LOWEST_COST_WITHOUT_CAP",
  },
  {
    label: "Lowest Cost w/ Bid Cap",
    value: "LOWEST_COST_WITH_BID_CAP",
  },
  {
    label: "Cost Cap",
    value: "COST_CAP",
  },
  {
    label: "Lowest Cost w/ Min. ROAS",
    value: "LOWEST_COST_WITH_MIN_ROAS",
  },
];

const OBJECTIVE_TYPES = [
  {
    label: "Outcome App Promotion",
    value: "OUTCOME_APP_PROMOTION",
  },
  {
    label: "Outcome Awareness",
    value: "OUTCOME_AWARENESS",
  },
  {
    label: "Outcome Engagement",
    value: "OUTCOME_ENGAGEMENT",
  },
  {
    label: "Outcome Leads",
    value: "OUTCOME_LEADS",
  },
  {
    label: "Outcome Sales",
    value: "OUTCOME_SALES",
  },
  {
    label: "Outcome Traffic",
    value: "OUTCOME_TRAFFIC",
  },
];

const SPECIAL_AD_CATEGORIES = [
  {
    label: "None",
    value: "NONE",
  },
  {
    label: "Employment",
    value: "EMPLOYMENT",
  },
  {
    label: "Housing",
    value: "HOUSING",
  },
  {
    label: "Credit",
    value: "CREDIT",
  },
  {
    label: "Issues/Elections/Politics",
    value: "ISSUES_ELECTIONS_POLITICS",
  },
  {
    label: "Online Gambling & Gaming",
    value: "ONLINE_GAMBLING_AND_GAMING",
  },
  {
    label: "Financial Products/Services",
    value: "FINANCIAL_PRODUCTS_SERVICES",
  },
];

const OPTIONS_NOTE_CARD_HEADER = "Campaign Options to Populate in Meta";
const OPTIONS_NOTE_CARD_TEXT =
  "A list of the objectives that people will not be able to set campaigns for in our tool";
const OPTIONS_NOTE_CARD_OPTIONS = ["App Promotion", "Engagements", "Leads"];

interface MetaBuyingCampaignProps {
  client: string;
  namingConvention: string[];
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
}

export const MetaBuyingCampaign = ({
  path,
  client,
  namingConvention,
  tabIndex,
  setTabIndex,
  showReviewModal,
}: MetaBuyingCampaignProps & RouteComponentProps): JSX.Element => {
  const { metaCampaignForm, setMetaCampaignForm } = useContext(FormContext);

  const updateFormField = (key: string, value: string) =>
    setMetaCampaignForm((current: any): typeof metaCampaignFormDefault => {
      return { ...current, [key]: value };
    });

  //@ts-ignore
  const mapLabelsByValues = R.pipe(R.indexBy(R.prop("value")), R.map(R.prop("label")));
  //@ts-ignore

  return (
    <div className="metaBuyingContainer">
      <div className="metaBuyingCard">
        <div className="metaBuyingHeader">
          <h2>Campaign</h2>
          <div className="metaBuyingNameWrapper">
            Name:
            <MetaBuyingNamePreview data={metaCampaignForm} namingConvention={namingConvention} />
          </div>
        </div>

        <div className="metaBuyingFormBody">
          <div className="metaBuyingFormSection">
            <h3>Campaign Name Construction</h3>
            <Form.Group>
              <Form.Label>Client</Form.Label>
              <Form.Control disabled={true} value={client} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Funnel</Form.Label>
              <DropdownButton
                title={metaCampaignForm.funnel}
                onSelect={value => updateFormField("funnel", value || "")}
              >
                {FUNNEL_OPTIONS.map(option => (
                  <Dropdown.Item key={option} eventKey={option}>
                    {option}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
            <Form.Group>
              <Form.Label>Tactic</Form.Label>
              <Form.Control
                value={metaCampaignForm.tactic}
                onChange={e => updateFormField("tactic", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Campaign Type</Form.Label>
              <Form.Control
                value={metaCampaignForm.campaign_type}
                onChange={e => updateFormField("campaign_type", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Geo</Form.Label>
              <Form.Control
                value={metaCampaignForm.geo}
                onChange={e => updateFormField("geo", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Initiative</Form.Label>
              <Form.Control
                value={metaCampaignForm.initiative}
                onChange={e => updateFormField("initiative", e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Segment ID</Form.Label>
              <Form.Control
                value={metaCampaignForm.segmentation_id}
                onChange={e => updateFormField("segmentation_id", e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="metaBuyingFormSection">
            <h3>Campaign Options</h3>
            <Form.Group>
              <Form.Label>Campaign Objective</Form.Label>
              <DropdownButton
                title={metaCampaignForm.objective}
                onSelect={value => updateFormField("objective", value || "")}
              >
                {OBJECTIVE_TYPES.map(({ label, value }) => (
                  <Dropdown.Item key={value} eventKey={value}>
                    {label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
            <Form.Group>
              <Form.Label>Special Ad Categories</Form.Label>
              <DropdownButton
                title={
                  <div className="title">
                    {metaCampaignForm.special_ad_categories.map(category => (
                      //@ts-ignore
                      <div key={category}>{mapLabelsByValues(SPECIAL_AD_CATEGORIES)[category]}</div>
                      //@ts-ignore
                    ))}
                  </div>
                }
                onSelect={value => {
                  let newMetaCampaignForm = R.clone(metaCampaignForm);
                  if (R.includes(value, metaCampaignForm.special_ad_categories)) {
                    newMetaCampaignForm.special_ad_categories = R.remove(
                      R.findIndex(elem => elem === value, metaCampaignForm.special_ad_categories),
                      1,
                      metaCampaignForm.special_ad_categories
                    );
                  } else {
                    newMetaCampaignForm.special_ad_categories.push(value);
                  }
                  setMetaCampaignForm(newMetaCampaignForm);
                }}
              >
                {SPECIAL_AD_CATEGORIES.map(({ label, value }) => (
                  <Dropdown.Item key={value} eventKey={value}>
                    <div
                      className={
                        R.includes(value, metaCampaignForm.special_ad_categories) ? "selected" : ""
                      }
                    >
                      {label}
                    </div>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
          </div>
          <div className="metaBuyingFormSection">
            <h3>Budget</h3>
            <Form.Group>
              <Form.Label>Budget Type</Form.Label>
              {BUDGET_TYPES.map(({ label, value }) => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={metaCampaignForm.budget_type === value}
                  id={value}
                  onChange={() => updateFormField("budget_type", value)}
                />
              ))}
            </Form.Group>
            {(metaCampaignForm.budget_type === "CAMPAIGN_DAILY_BUDGET" ||
              metaCampaignForm.budget_type === "CAMPAIGN_LIFETIME_BUDGET") && (
              <>
                <Form.Group>
                  <Form.Label>Bid Strategy</Form.Label>
                  <DropdownButton
                    //@ts-ignore
                    title={mapLabelsByValues(BID_STRATEGIES)[metaCampaignForm.bid_strategy]}
                    //@ts-ignore
                    onSelect={value => updateFormField("bid_strategy", value || "")}
                  >
                    {BID_STRATEGIES.map(({ label, value }) => (
                      <Dropdown.Item key={value} eventKey={value}>
                        {label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Budget</Form.Label>
                  <Form.Control
                    value={metaCampaignForm.budget}
                    onChange={e => updateFormField("budget", e.target.value)}
                    type="number"
                  />
                </Form.Group>
              </>
            )}
          </div>
          <MetaBuyingFooterControls
            tabName={"Campaign"}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            showReviewModal={showReviewModal}
          />
        </div>
      </div>

      <MetaBuyingOptionsNoteCard
        header={OPTIONS_NOTE_CARD_HEADER}
        text={OPTIONS_NOTE_CARD_TEXT}
        options={OPTIONS_NOTE_CARD_OPTIONS}
      />
    </div>
  );
};

export default MetaBuyingCampaign;

import React, { useState } from "react";
import { Button, Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";

import { BPMButton } from "../../Components";
import { FormattedRow } from "../linearBuyingUtils";
import { ORDERED_DEMO_GROUPINGS } from "../linearBuyingConstants";
import * as R from "ramda";

import { PlanRow, RowEdit } from "@blisspointmedia/bpm-types/dist/LinearBuying";

const MEASUREMENTS = ["Live+ Same Day AA", "Live+ 3 Days AA", "Live+ 7 Days AA"];

interface AddOrderedImpressionsModalProps {
  row: FormattedRow;
  editRow: (row: PlanRow, edits: RowEdit, key: string, isNewRow: boolean) => void;
  onClose: () => void;
}

const AddOrderedImpressionsModal: React.FC<AddOrderedImpressionsModalProps> = ({
  row,
  editRow,
  onClose,
}) => {
  const { key, isNewRow } = row;
  const [selectedDemo, setSelectedDemo] = useState<string>("");
  const [selectedMeasurement, setSelectedMeasurement] = useState<string>("");
  const [orderedImpressions, setOrderedImpressions] = useState<string>("");

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add/Update ordered impressions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Input Ordered Impressions</Form.Label>
          <Form.Control
            type="number"
            value={orderedImpressions}
            onChange={e => setOrderedImpressions(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="demoPicker">
          <Form.Label>Select Demo</Form.Label>
          {R.keys(ORDERED_DEMO_GROUPINGS).map(group => {
            const groupDemos = ORDERED_DEMO_GROUPINGS[group];
            return (
              <div className="demoGroup" key={group}>
                <strong>{group}:</strong>
                <div className="demoButtons">
                  {R.keys(groupDemos).map(prettyDemo => {
                    const demoValue = groupDemos[prettyDemo];
                    return (
                      <Button
                        className="demoButton"
                        key={prettyDemo}
                        size="sm"
                        variant={selectedDemo === demoValue ? "primary" : "outline-primary"}
                        onClick={() => setSelectedDemo(demoValue)}
                      >
                        {prettyDemo}
                      </Button>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Form.Group>
        <Form.Group className="measurementPicker">
          <Form.Label>Select Measurement</Form.Label>
          <DropdownButton
            className="measurementDropdown"
            title={`Measurement: ${selectedMeasurement}`}
            onSelect={value => {
              if (value) {
                setSelectedMeasurement(value);
              }
            }}
          >
            {MEASUREMENTS.map(measurementSelection => (
              <Dropdown.Item key={measurementSelection} eventKey={measurementSelection}>
                {measurementSelection}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <BPMButton variant="outline-primary" onClick={onClose}>
          Cancel
        </BPMButton>
        <BPMButton
          variant="primary"
          onClick={() => {
            const orderedImpressionsEdit = {
              orderedImpressionsDemo: selectedDemo,
              orderedImpressionsMeasurement: selectedMeasurement,
              orderedImpressions: parseInt(orderedImpressions),
            };
            editRow(row, orderedImpressionsEdit, key, !!isNewRow);
            onClose();
          }}
        >
          Add
        </BPMButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOrderedImpressionsModal;

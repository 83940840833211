import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as P from "@blisspointmedia/bpm-types/dist/Performance";

import { DateRange } from "../../utils/types";
import {
  ColumnMetaData,
  DimensionColumnMetaData,
  OverviewConfigItem,
  PercentageDisplayMode,
  toPretty1000sInteger,
  toPrettyNumber,
  toPrettySpend,
} from "../performanceUtils";
import { toPrettyPercent } from "../../SingleChannel/MetricsTable/metricsTableUtils";

export interface RowWithTabularData extends L.PerformanceDataRow {
  tabularRow: number[];
}

interface TotalsData {
  impressions: number;
  ratedAdRawEffect: number;
}
export type TotalsMap = Record<string, TotalsData>;

export const performanceRowToTableRow = (
  row: L.PerformanceDataRow,
  columns: P.Column[],
  totalsMap: TotalsMap,
  kpi: string,
  kpiMetaData: P.GetKpiMetaDataResponse,
  audience: string,
  isInternal: boolean
): number[] => {
  const getRowValue = (column: P.Column): number => {
    let columnKpi = column.kpi || kpi;
    let fetchKey = L.getFetchKey({ kpi: columnKpi, audience: column.audience || audience });
    let columnDataRow = row.fetches[fetchKey];
    if (!columnDataRow) {
      return 0;
    }
    if (
      ["spend", "ratedSpend", "spots", "impressions", "volume", "targetRatingPoints"].includes(
        column.type
      ) ||
      column.type.startsWith("volume") ||
      column.type.startsWith("revenue")
    ) {
      return columnDataRow[column.type] || 0;
    }
    if (column.type === "eCPM") {
      return L.calculateECPM(columnDataRow);
    }
    if (column.type === "responseIndex") {
      let totals = totalsMap[fetchKey];
      let responseRatioAvg = totals.ratedAdRawEffect / totals.impressions;
      let respIdx = L.calculateResponseIndex(columnDataRow, responseRatioAvg);
      return respIdx;
    }
    if (column.type.startsWith("cpv")) {
      let isFractional = column.type === "cpvFractional";
      let { maxCpxValue = 50 } = kpiMetaData[columnKpi] || {};
      return L.calculateCPV(columnDataRow, maxCpxValue, isFractional);
    }
    return 0;
  };

  return columns.reduce<number[]>((columns, column) => {
    if (!isInternal && column.adminOnly) {
      return columns;
    }
    return [...columns, getRowValue(column)];
  }, []);
};

export const makeFetchKey = (
  id: number | undefined,
  dates: DateRange,
  kpi: string,
  audience: L.Audience | ""
): string => `${id}_${dates.start}_${dates.end}_${kpi}_${audience}`;

// Constructs a map that holds impression and ratedAdRawEffect totals.
export const constructTotalsMap = (
  columns: P.Column[],
  rows: L.PerformanceDataRow[],
  kpi: string,
  audience: string
): TotalsMap => {
  let totalsMap: TotalsMap = {};
  for (let column of columns) {
    if (column.type === "responseIndex") {
      let fetchKey = L.getFetchKey({
        kpi: column.kpi || kpi,
        audience: column.audience || audience,
      });
      let totalImps = 0;
      let totalRatedAdRawEffect = 0;
      for (let row of rows) {
        totalImps += row.fetches[fetchKey]?.impressions || 0;
        totalRatedAdRawEffect += row.fetches[fetchKey]?.ratedAdRawEffect || 0;
      }
      totalsMap[fetchKey] = {
        impressions: totalImps,
        ratedAdRawEffect: totalRatedAdRawEffect,
      };
    }
  }
  return totalsMap;
};

export const DIMENSION_COLUMN_METADATA_MAP: Record<
  L.DimensionColumnType,
  DimensionColumnMetaData
> = {
  "Network Logo": {
    minWidth: 120,
    dimension: "Network",
    label: "\u00a0",
  },
  "Network Name": {
    minWidth: 100,
    dimension: "Network",
    label: "Network",
  },
  Creative: {
    dimension: "Creative",
    minWidth: 200,
  },
  "Creative Thumbnail": {
    dimension: "Creative",
    minWidth: 100,
    label: "\u00a0",
  },
  Length: {
    dimension: "Length",
    minWidth: 85,
  },
  Avail: {
    dimension: "Avail",
    minWidth: 85,
    label: "Avail",
  },
  Daypart: {
    dimension: "Daypart",
    minWidth: 100,
    label: "Daypart",
  },
  Campaign: {
    dimension: "Campaign",
    minWidth: 100,
    label: "Campaign",
  },
  "Media Classification": {
    dimension: "Media Classification",
    minWidth: 100,
    label: "Media Classification",
  },
};

export const COLUMN_METADATA_MAP: Record<L.ColumnType, ColumnMetaData> = {
  spend: {
    formatter: toPrettySpend,
    minWidth: 100,
    prettyName: "Spend",
    percentageDisplayMode: PercentageDisplayMode.SHOW,
  },
  ratedSpend: {
    formatter: toPrettySpend,
    minWidth: 100,
    prettyName: "Rated Spend",
    percentageDisplayMode: PercentageDisplayMode.HIDE,
  },
  spots: {
    formatter: toPrettyNumber,
    minWidth: 100,
    prettyName: "Spots",
    decimals: 0,
    percentageDisplayMode: PercentageDisplayMode.HIDE,
  },
  impressions: {
    formatter: toPretty1000sInteger,
    minWidth: 100,
    prettyName: "Imp's ('000s)",
    contentReplacement: {
      replacementString: "Unrated",
      threshold: 0,
    },
    percentageDisplayMode: PercentageDisplayMode.SHOW,
  },
  eCPM: {
    formatter: toPrettySpend,
    minWidth: 100,
    prettyName: "eCPM",
    decimals: 2,
    minIsBest: true,
    contentReplacement: {
      replacementString: "-",
      threshold: 0,
    },
    percentageDisplayMode: PercentageDisplayMode.DISABLE,
  },
  volume: {
    formatter: toPrettyNumber,
    minWidth: 100,
    prettyName: "Volume",
    decimals: 0,
    percentageDisplayMode: PercentageDisplayMode.SHOW,
  },
  cpvDirect: {
    formatter: toPrettySpend,
    minWidth: 100,
    prettyName: "CPX, Direct",
    defaultLabel: "CPX Direct",
    minIsBest: true,
    contentReplacement: {
      replacementString: "-",
      threshold: 0,
    },
    zerosSortHigh: true,
    percentageDisplayMode: PercentageDisplayMode.DISABLE,
  },
  cpvFractional: {
    formatter: toPrettySpend,
    minWidth: 100,
    prettyName: "CPX, +Frac",
    defaultLabel: "CPX +Frac",
    minIsBest: true,
    contentReplacement: {
      replacementString: "-",
      threshold: 0,
    },
    zerosSortHigh: true,
    percentageDisplayMode: PercentageDisplayMode.DISABLE,
  },
  responseIndex: {
    formatter: toPrettyNumber,
    minWidth: 100,
    prettyName: "Resp. Index",
    decimals: 2,
    contentReplacement: {
      replacementString: "-",
      threshold: 0,
    },
    percentageDisplayMode: PercentageDisplayMode.DISABLE,
  },
  targetRatingPoints: {
    formatter: toPrettyNumber,
    minWidth: 100,
    prettyName: "Target Rating Points",
    decimals: 2,
    contentReplacement: {
      replacementString: "-",
      threshold: 0,
    },
    percentageDisplayMode: PercentageDisplayMode.SHOW,
  },
  impsConversionRate: {
    formatter: toPrettyPercent,
    minWidth: 100,
    prettyName: "Imps Conversion Rate",
    decimals: 2,
    contentReplacement: {
      replacementString: "-",
      threshold: 0,
    },
    percentageDisplayMode: PercentageDisplayMode.DISABLE,
  },
} as const;

export const GLOSSARY: [string, React.ReactNode][] = [
  [
    "eCPM",
    "Effective Cost Per Thousand Impressions. This is the total spend times 1,000 divided by the number of impressions delivered.",
  ],
  ["Imp's ('000s)", "Number of impressions in the thousands."],
  [
    "Resp. Index",
    "Response Index. It's the measure of a row's response rate relative to the overall response rate.",
  ],
  ["Volume", "KPI Volume. The number of trued-up KPIs attributed to our impressions."],
  [
    "Direct",
    "Direct KPIs. These are people who saw our ad and directly converted without seeing media from other marketing channels.",
  ],
  [
    "CPX, +Fractional",
    "Cost per KPI based on matched users responding via all referring domains. This metric thus incorporates multi-touch marketing effects.",
  ],
  [
    "CPX",
    'Cost per KPI. The average cost for a single KPI. The "X" is usually an abbreviation for the KPI, such as "CPV" for "visits" or "CPO" for "orders".',
  ],
  [
    "Target Rating Point (TRP)",
    "Rating points are a metric used to identify the scale of a campaign. TRP measures the total exposure to a target audience.",
  ],
];

export const LINEAR_OVERVIEW_CONFIG: Record<keyof L.OverviewConfig, OverviewConfigItem> = {
  spendInfo: {
    displayName: "Spend Info",
  },
  conversions: {
    displayName: "Conversion / Imps ('000s)",
  },
  revenue: {
    displayName: "Revenue",
  },
  roas: {
    displayName: "ROAS",
  },
  cppChart: {
    displayName: "CPX Path",
  },
};

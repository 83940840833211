import { ClientInfo, Response, ClientInfoParams } from "@blisspointmedia/bpm-types/dist/Onboarding";
import { PlatformOptions } from "./PlatformChecklist/PlatformChecklistOptions";
import { ToolsLambdaFetch, awaitJSON } from "../utils/fetch-utils";

export const getClientInfo = async (company: string): Promise<ClientInfo> => {
  let res = await ToolsLambdaFetch("/getClientInfo", {
    params: {
      company,
    },
  });
  return await awaitJSON(res);
};

export const saveClientInfo = async (
  clientInfoParams: ClientInfoParams
): Promise<{ statusCode: number; body: string }> => {
  let res = await ToolsLambdaFetch("/createClientInfo", {
    method: "POST",
    body: JSON.stringify(clientInfoParams),
  });
  return await awaitJSON(res);
};

export const getCompletedPercentage = (
  responses: Response[],
  total: number,
  startsWith: string
): number => {
  if (!responses || responses.length === 0) {
    return 0;
  }
  const completedQuestions = responses.filter(response =>
    response.questionNumber.startsWith(startsWith)
  );
  return Math.round((completedQuestions.length / total) * 100);
};

export const getCompletedPlatformPercentage = (
  responses: Response[],
  platformOptions: PlatformOptions
): number => {
  const totalSections = Object.keys(platformOptions).length;
  if (!responses || responses.length === 0) {
    return 0;
  }

  const completedSections = Object.keys(platformOptions).filter(sectionKey => {
    return responses.some(response =>
      response.questionNumber.startsWith(platformOptions[sectionKey].simpleId)
    );
  }).length;

  return Math.round((completedSections / totalSections) * 100);
};

import "./PaidMediaImpact.scss";
import React, { useCallback, useState } from "react";
import WidgetContainer from "../../Components/WidgetContainer";
import ChartContainer from "../../Components/ChartContainer";
import AreaChart from "../../Components/Charts/AreaChart";
import * as R from "ramda";
import { exportToExcel, downloadPNG } from "../../utils/download-utils";
import * as Dfns from "date-fns/fp";
import { DownloadDropdown, TextToggleButton } from "../../Components";
import MoreInfo from "../../MMM/MoreInfo";
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis } from "recharts";
import { currFormat, percFormat, standardizeMetricName } from "./BrandImpactUtils";
import { MdArrowForward } from "react-icons/md";

interface PaidMediaImpactProps {
  company: string;
  groupByMetric: string;
  paidMediaImpactAreaChart: Record<string, PaidMediaAreaChart[]>;
  paidMediaImpactBarChart: Record<string, PaidMediaBarChart>;
  implicationsData: Record<string, ImplicationData>;
}

interface PaidMediaAreaChart {
  date: string;
  PaidMedia: number;
  OtherFactors: number;
}

interface PaidMediaBarChart {
  key: string;
  PaidMedia: number;
  OtherFactors: number;
}

interface ImplicationData {
  spendToIncreaseOnePercent: number;
}

type MetricOptionToolTipMap = {
  [key: string]: string;
};

const GROUP_BY_METRIC_MAP: MetricOptionToolTipMap = {
  Awareness:
    "Respondents are asked to select from a list of brands which they have seen or heard advertising for recently. If they select your brand, that counts as a positive response.",
  Buzz:
    "Respondents are asked to select from a list of brands which they have heard positive references to recently. If they select your brand, that counts as a positive response.",
  Consideration:
    "Respondents are asked to select from a list of brands which they would consider purchasing whenever they’re next in the market. If they select your brand, that counts as a positive response.",
  Intent:
    "Respondents are asked to select from a list of brands which they have ever heard of. If they select your brand, that counts as a positive response.",
};

const capitalizeWords = (s: string): string => {
  return s
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b./g, a => a.toUpperCase());
};

const renderCustomLabel = props => {
  const { x, y, width, value } = props;
  const percentage = `${(value * 100).toFixed(2)}%`;

  return (
    <text x={x + width / 2} y={y} fill="#000" textAnchor="middle" dy={-6}>
      {percentage}
    </text>
  );
};

export const PaidMediaImpact: React.FC<PaidMediaImpactProps> = ({
  groupByMetric,
  paidMediaImpactAreaChart,
  paidMediaImpactBarChart,
  implicationsData,
}) => {
  const [selectedToggleOptionWAA, setSelectedToggleOptionWAA] = useState<string>("Over time");
  const paidMediaImpactAreaChartFinal = paidMediaImpactAreaChart[groupByMetric];

  const excelDownloadWeeklyAdAwarenessAreaChart = useCallback(() => {
    exportToExcel(paidMediaImpactAreaChartFinal, `Weekly_${groupByMetric}_Area_Chart`);
  }, [groupByMetric, paidMediaImpactAreaChartFinal]);

  const pngDownloadWeeklyAdAwarenessAreaGraph = useCallback(async () => {
    await downloadPNG(".leftLeft .chartContainer .contents", `Weekly_${groupByMetric}_Area_Graph`);
  }, [groupByMetric]);

  const excelDownloadWeeklyAdAwarenessBarChart = useCallback(() => {
    exportToExcel([paidMediaImpactBarChart], `Weekly_${groupByMetric}_Bar_Chart`);
  }, [groupByMetric, paidMediaImpactBarChart]);

  const pngDownloadWeeklyAdAwarenessBarChart = useCallback(async () => {
    await downloadPNG(".leftLeft .chartContainer .contents", `Weekly_${groupByMetric}_Bar_Chart`);
  }, [groupByMetric]);

  return (
    <WidgetContainer
      collapsible
      header={
        <>
          How does our paid media impact{" "}
          <span style={{ fontWeight: 600 }}>{standardizeMetricName(groupByMetric)}</span>?
        </>
      }
      subHeader={
        <>
          {`Attribution of ${standardizeMetricName(
            groupByMetric
          )} to media investment based on model parameter estimates.`}
          <MoreInfo rightLabel="More info" size="sm">
            {`Calculated for each point in time as (${groupByMetric} at that point in time) - (level of media investments at that point in time) x (model parameter estimates for those media inputs)`}
          </MoreInfo>
        </>
      }
    >
      <div className="left">
        <div className="leftLeft">
          {!R.isEmpty(paidMediaImpactAreaChartFinal) && !R.isEmpty(paidMediaImpactBarChart) && (
            <ChartContainer
              enableHoverDesign
              rightActions={
                <div className="paidMediaImpactRightActions">
                  <TextToggleButton
                    design="secondary-light"
                    options={["Total", "Over time"]}
                    selectedOption={selectedToggleOptionWAA}
                    onChange={setSelectedToggleOptionWAA}
                  ></TextToggleButton>
                  <DownloadDropdown
                    size="sm"
                    onClickOptions={[
                      selectedToggleOptionWAA === "Over time"
                        ? excelDownloadWeeklyAdAwarenessAreaChart
                        : excelDownloadWeeklyAdAwarenessBarChart,
                      selectedToggleOptionWAA === "Over time"
                        ? pngDownloadWeeklyAdAwarenessAreaGraph
                        : pngDownloadWeeklyAdAwarenessBarChart,
                    ]}
                  />
                </div>
              }
              title={`Weekly ${groupByMetric}`}
              titleAfterDashText={`${Dfns.format(
                "M/dd/yy",
                new Date(paidMediaImpactAreaChartFinal[0].date)
              )} – ${Dfns.format(
                "M/dd/yy",
                new Date(
                  paidMediaImpactAreaChartFinal[paidMediaImpactAreaChartFinal.length - 1].date
                )
              )}`}
              beforeTooltipText="Positive Response"
              tooltipText="Positive response indicates the percentage of survey respondents expressing favorable perceptions or experiences related to each brand health metric."
            >
              {selectedToggleOptionWAA === "Over time" && (
                <AreaChart
                  data={paidMediaImpactAreaChartFinal}
                  xAxisDataKey="date"
                  xAxisTickFormatter={val => Dfns.format("M/dd/yy", new Date(`${val}`))}
                  dateGrouping="Week"
                  yAxisTickFormatter={value => `${value}%`}
                  yAxisWidth={150}
                  colorOverrides={{
                    [`${groupByMetric} From Paid Media`]: "#8254FF",
                    [`${groupByMetric} From Other Factors`]: "#E5E9F2",
                  }}
                  areas={[
                    {
                      name: `${groupByMetric} From Other Factors`,
                      dataKey: "OtherFactors",
                      toolTip: GROUP_BY_METRIC_MAP[groupByMetric],
                    },
                    {
                      name: `${groupByMetric} From Paid Media`,
                      dataKey: "PaidMedia",
                    },
                  ]}
                  tooltipFormatter={val => {
                    if (!val) {
                      return val;
                    }
                    return percFormat(val);
                  }}
                  reverseToolTipItems={true}
                  reverseLegend={true}
                ></AreaChart>
              )}
              {selectedToggleOptionWAA === "Total" &&
                Object.values(paidMediaImpactBarChart).map((item, index) => (
                  <div key={index}>
                    <ResponsiveContainer width="100%" height="100%" minWidth={100}>
                      <BarChart
                        width={500}
                        height={300}
                        data={[item]}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <XAxis
                          dataKey="key"
                          orientation="top"
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={capitalizeWords}
                          tick={
                            groupByMetric === item.key
                              ? {
                                  dy: -15,
                                  fill: "#1F003F",
                                  fontWeight: 600,
                                }
                              : {
                                  dy: -15,
                                  fill: "#1F003F",
                                  fontWeight: 400,
                                }
                          }
                        />
                        <Legend iconSize={0} layout="horizontal" />
                        <Bar
                          dataKey="PaidMedia"
                          fill={groupByMetric === item.key ? "#946DFF" : "#F1F3F9"}
                          name="Paid Media"
                          label={renderCustomLabel}
                        />
                        <Bar
                          dataKey="OtherFactors"
                          fill={groupByMetric === item.key ? "#A8A8A8" : "#F1F3F9"}
                          name="Other Factors"
                          label={renderCustomLabel}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                ))}
            </ChartContainer>
          )}
        </div>
        <div className="leftRight">
          <ChartContainer title={"Implications"} enableHoverDesign>
            <div className="brandEquityImplication">
              <div className="titleRowStyle">
                <div className="titleStyleLeft">Investment Increase</div>
                <div className="titleStyleRight">Brand Health Impact</div>
              </div>
              {Object.entries(implicationsData).map(([key, row]) => (
                <div key={key} className="rowStyle">
                  <div
                    className={
                      key === groupByMetric ? "spendText rowStyleColorSpendText" : "spendText"
                    }
                  >
                    {`+ ${currFormat(row.spendToIncreaseOnePercent, 0)}`}
                    <span className="cadenceText">{" per week"}</span>
                  </div>
                  <div
                    className={
                      key === groupByMetric ? "arrowIcon rowStyleColorArrowIcon" : "arrowIcon"
                    }
                  >
                    <MdArrowForward></MdArrowForward>
                  </div>
                  <div
                    className={
                      key === groupByMetric ? "metricText rowStyleColorMetricText" : "metricText"
                    }
                  >
                    {" "}
                    {`+1% ${key}`}
                  </div>
                </div>
              ))}
              <div className="endTextRow">
                <div className="endTextRowStyle">
                  The estimates of required investment — in each case to raise a brand health metric
                  by 1% — assume a continuation of the current investment composition.
                </div>
              </div>
            </div>
          </ChartContainer>
        </div>
      </div>
    </WidgetContainer>
  );
};

export default PaidMediaImpact;

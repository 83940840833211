import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Img } from "../../Components";
import "./BusinessImpact.scss";
import BusinessImpactOverview from "./BusinessImpactOverview";
import RevenueImpact from "./RevenueImpact";
import IncrementalRoas from "./IncrementalRoas";
import BusinessImpactModelInsights from "./BusinessImpactModelInsights";
import { BusinessImpactData } from "@blisspointmedia/bpm-types/dist/BrandEquity";

interface BusinessImpactProps {
  company: string;
  groupByMetric: string;
  groupByMetricOptions: Array<{ label: string; value: string; s3Path: string }>;
  businessImpactData: BusinessImpactData;
}

const BusinessImpact = ({
  company,
  groupByMetric,
  businessImpactData,
}: BusinessImpactProps & RouteComponentProps): JSX.Element => {
  const {
    weeklyBrandHealthTimeSeries,
    modelOverview,
    weeklyRevenueTimeSeries,
    totalRevenueDecomposition,
    weeklyRevenueDecomposition,
    revenueImpactTakeaways,
    weeklyIncrementalRoas,
    incrementalRoasTakeaways,
    saturationData,
    decayData,
  } = businessImpactData;
  return (
    <div>
      <div className="brandEquityHeader">
        <div className="brandEquityTextHeader">
          <div className="brandEquityTextHeaderTitle">
            A strong brand drives business outcomes and creates a tailwind for performance marketing
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Performance marketing generates results over days to weeks. Brand marketing operates
            over months to years, making unified assessment of the funnel challenging.
          </div>
          <div className="brandEquityTextHeaderParagraph">
            Bliss Point Brand Health quantifies brand equity's impact on the business for a holistic
            understanding of ROAS.
          </div>
        </div>
        <div className="brandEquityHeaderImageContainer">
          <Img
            src="https://cdn.blisspointmedia.com/assets/img/BusinessImpactDemo/BrandEquity_Infographic.png"
            alt="brand health header"
          />
        </div>
      </div>
      {weeklyBrandHealthTimeSeries.length > 0 &&
        weeklyRevenueTimeSeries.length > 0 &&
        Object.keys(modelOverview).length > 0 && (
          <BusinessImpactOverview
            company={company}
            groupByMetric={groupByMetric}
            weeklyBrandHealthTimeSeries={weeklyBrandHealthTimeSeries}
            weeklyRevenueTimeSeries={weeklyRevenueTimeSeries}
            modelOverviewAll={modelOverview}
          />
        )}
      {weeklyRevenueDecomposition.length > 0 &&
        totalRevenueDecomposition.length > 0 &&
        revenueImpactTakeaways.length && (
          <RevenueImpact
            company={company}
            weeklyRevenueDecomposition={weeklyRevenueDecomposition}
            totalRevenueDecomposition={totalRevenueDecomposition}
            revenueImpactTakeaways={revenueImpactTakeaways}
          />
        )}
      {incrementalRoasTakeaways && weeklyIncrementalRoas.length > 0 && (
        <IncrementalRoas
          company={company}
          weeklyIncrementalRoas={weeklyIncrementalRoas}
          incrementalRoasTakeaways={incrementalRoasTakeaways}
        />
      )}
      {Object.keys(decayData).length > 0 && Object.keys(saturationData).length > 0 && (
        <BusinessImpactModelInsights
          company={company}
          saturationDataNew={saturationData}
          decayDataNew={decayData}
        />
      )}
    </div>
  );
};

export default BusinessImpact;

import React from "react";
import "./SimpleTooltip.scss";
import { SimpleTooltipText } from "../SimpleTooltipText/SimpleTooltipText";

export interface SimpleTooltipArgs {
  text: string;
  direction: SimpleTooltipDirection;
  horizontalShift?: string; // Can be a percentage or exact amount in px, rem, or em.
  verticalShift: string; // Can be a percentage or exact amount in px, rem, or em.
  maxWidth?: string;
}

export enum SimpleTooltipDirection {
  TOP = "top",
  BOTTOM = "bottom",
}

interface SimpleTooltipProps {
  children?: React.ReactNode;
  text: string;
  direction?: SimpleTooltipDirection;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  showTooltip: boolean;
  horizontalShift?: string; // Can be a percentage or exact amount in px, rem, or em.
  verticalShift: string; // Can be a percentage or exact amount in px, rem, or em.
  maxWidth?: string;
  className?: string;
}

export const SimpleTooltip: React.FC<SimpleTooltipProps> = React.memo(
  ({
    children,
    text,
    direction = SimpleTooltipDirection.TOP,
    onMouseEnter = undefined,
    onMouseLeave = undefined,
    showTooltip,
    horizontalShift = "0px",
    verticalShift,
    maxWidth,
    className,
  }: SimpleTooltipProps): JSX.Element => {
    return (
      <div
        className="simpleTooltipContainer"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
        <SimpleTooltipText
          showTooltip={showTooltip}
          horizontalShift={horizontalShift}
          verticalShift={verticalShift}
          direction={direction}
          maxWidth={maxWidth}
          className={className}
        >
          {text}
        </SimpleTooltipText>
      </div>
    );
  }
);

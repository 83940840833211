export const CUSTOM_PRESET_INTERVAL = ["Days", "Weeks", "Months"];
export const CUSTOM_PRESET_REFERENCE = [
  "Today",
  "Yesterday",
  "Start of This Week",
  "End of This Week",
];
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;
export const DEFAULT_MIN_YEAR = 2017 as const;
export const DEFAULT_MAX_YEAR = new Date().getFullYear() + 2;

// Gotten from inspecting the date picker. Note: the height is the maximum height for when the
// calendar has 6 rows.
export const CALENDAR_WIDTH = 800;
export const CALENDAR_HEIGHT = 452;
export const CALENDAR_SPACE = 6;
export const PRESETS_WIDTH = 606;
export const GRAPH_INTERVALS_WIDTH = 494;
export const COMP_CALENDAR_HEIGHT = 858;
export const FOCUS_OPTIONS = ["START", "END"] as const;
export const DATE_INTERVALS = ["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"];
export const DEFAULT_IS_DAYBLOCKED = (): boolean => false;
export const YEAR_MONTH_FORMAT = "MM-yyyy";
export const PRETTY_DATE_FORMAT = "MM/dd/yyyy";
export const MAX_DATE = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

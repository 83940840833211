import { BiSolidBookOpen } from "react-icons/bi";
import { MdBuild, MdHeadphones, MdOutlinePalette, MdScience, MdInsertChart } from "react-icons/md";
import { ReactComponent as BrandHealthIcon } from "./Icons/Brand Health Icon.svg";
import { ReactComponent as CommerceIcon } from "./Icons/Commerce Icon.svg";
import { ReactComponent as CompetitiveIcon } from "./Icons/Competitive Icon.svg";
import { ReactComponent as CrossChannelIcon } from "./Icons/Cross Channel Icon.svg";
import { ReactComponent as DisplayIcon } from "./Icons/Display Icon.svg";
import { ReactComponent as LinearIcon } from "./Icons/Linear TV Icon.svg";
import { ReactComponent as SocialIcon } from "./Icons/Social Icon.svg";
import { ReactComponent as StreamingIcon } from "./Icons/Streaming TV Icon.svg";
import { ReactComponent as TVADCrossChannelIcon } from "./Icons/TVAD Cross Channel Icon.svg";
import { ReactComponent as YouTubeIcon } from "./Icons/YouTube Icon.svg";
import { ReactComponent as MMMIcon } from "./Icons/MMM_Icon.svg";
import { signOutAWS } from "../utils/auth";
import * as R from "ramda";

export const CHANNELS_MAP = {
  Audio: "audio",
  Commerce: "commerce",
  Display: "display",
  "Linear TV": "tv",
  Social: "social",
  "Streaming TV": "streaming",
  YouTube: "youtube",
  Custom: "custom",
};
export const CHANNELS_LINKS = [
  "Cross-Channel",
  "TVAD Cross-Channel",
  "Audio",
  "Commerce",
  "Display",
  "Linear TV",
  "Social",
  "Streaming TV",
  "YouTube",
  "Custom",
];
export const CHANNEL_ICONS = {
  "Cross-Channel": <CrossChannelIcon />,
  "TVAD Cross-Channel": <TVADCrossChannelIcon />,
  Audio: <MdHeadphones />,
  Commerce: <CommerceIcon />,
  Display: <DisplayIcon />,
  "Linear TV": <LinearIcon />,
  Social: <SocialIcon />,
  "Streaming TV": <StreamingIcon />,
  YouTube: <YouTubeIcon />,
  Custom: <MdInsertChart />,
};

export const ALL_EXTERNAL_TOOLS_LINKS = [
  "Creatives",
  "Competitive",
  "Experiments",
  "MMM",
  "Brand Health",
];
export const TOOLS_LINKS = ["Creatives", "Competitive", "MMM"];
export const TOOLS_ICONS = {
  Competitive: <CompetitiveIcon />,
  Creatives: <MdOutlinePalette />,
  MMM: <MMMIcon />,
};

export const LOCATIONS = {
  // Channels
  "Cross-Channel": { to: "cross-channel/overview", requiresClient: true },
  "TVAD Cross-Channel": { to: "TVAD-cross-channel", requiresClient: true },
  Audio: {
    subPages: {
      Performance: { to: "audio/performance", requiresClient: true, roleRequired: 10 },
      Delivery: { to: "audio/delivery", requiresClient: true, roleRequired: 10 },
      "Geo Response": { requiresClient: true, to: "audio/geo-response", roleRequired: 10 },
    },
  },
  Display: {
    subPages: {
      Performance: { to: "display/performance", requiresClient: true, roleRequired: 10 },
      Delivery: { to: "display/delivery", requiresClient: true, roleRequired: 10 },
    },
  },
  "Linear TV": {
    subPages: {
      Performance: { to: "linear/performance", requiresClient: true, roleRequired: 10 },
      Delivery: { to: "linear/delivery", requiresClient: true, roleRequired: 10 },
      "Spike Chart": { to: "linear/spike", requiresClient: true, roleRequired: 10 },
    },
    "Client Planning Tools": {
      "Linear Optimizations": { to: "linear/optimizations", requiresClient: true, roleRequired: 1 },
      "Linear ML Diagnostics": { to: "linear/diagnostics", requiresClient: true, roleRequired: 1 },
      "Linear Buying": { to: "linear/buying", requiresClient: true, roleRequired: 1 },
      "Linear Orders": { to: "linear/orders", requiresClient: true, roleRequired: 1 },
      "Linear Plans": { to: "linear/plans", requiresClient: true, roleRequired: 1 },
    },
    "Channel Buying": {
      "Linear Assumptions": { to: "linear/assumptions", roleRequired: 1 },
      "Linear CPMS": { to: "linear/cpms", roleRequired: 1 },
      "Linear Global Constraints": { to: "linear/constraints", roleRequired: 1 },
      "Linear Pacing": { to: "pacing/linear", roleRequired: 1 },
      "Linear Rates Comparisons": { to: "linear/rates/comparisons", roleRequired: 1 },
      "Linear Rates": { to: "linear/rates", roleRequired: 1 },
      "Radio Buying": {
        requiresClient: true,
        experiment: "enableRadioBuying",
        roleRequired: 1,
        to: "radio/buying",
      },
    },
    "Channel Operations": {
      "Process Post Logs": { to: "process", roleRequired: 1 },
      "Linear Networks": { to: "linear/networks", roleRequired: 1 },
      "Linear Buying Assignments": { to: "linear/assignments", roleRequired: 1 },
      "Linear Sales and Traffic Contacts": { to: "linear/contacts", roleRequired: 1 },
      "Pre Logs Tracker": { to: "logs/pre", roleRequired: 1 },
      "Post Logs Tracker": { to: "logs/post", roleRequired: 1 },
      "Linear AdOps": { to: "adops/linear", roleRequired: 1 },
      "Credit Memos": { to: "credit-memos", roleRequired: 1 },
    },
  },
  "Streaming TV": {
    subPages: {
      Performance: { to: "streaming/performance", requiresClient: true, roleRequired: 10 },
      Delivery: { to: "streaming/delivery", requiresClient: true, roleRequired: 10 },
      "Geo Response": { requiresClient: true, to: "streaming/geo-response", roleRequired: 10 },
    },
    "Client Planning Tools": {
      "Creative Allocations": { to: "streaming/creatives", requiresClient: true, roleRequired: 1 },
      "Geo Targeting": {
        experiment: "enableStreamingGeoTargeting",
        requiresClient: true,
        roleRequired: 1,
        to: "streaming/geo-targeting",
      },
      "Streaming Buying": { to: "streaming/buying", requiresClient: true, roleRequired: 1 },
      "Streaming ML Diagnostics": {
        to: "streaming/diagnostics",
        requiresClient: true,
        roleRequired: 1,
      },
      "Streaming Optimizations": {
        to: "streaming/optimizations",
        requiresClient: true,
        roleRequired: 1,
      },
      "Streaming Plans": { to: "streaming/plans", requiresClient: true, roleRequired: 1 },
    },
    "Channel Buying": {
      "Active Streaming Media (formerly Active OTT)": { to: "activeott", roleRequired: 1 },
      "Display Pacing": {
        experiment: "enableDisplayPacing",
        requiresClient: false,
        to: "pacing/display",
        roleRequired: 1,
      },
      "Order Status": { to: "streaming/status", requiresClient: true, roleRequired: 1 },
      "Streaming Global Constraints": { to: "streaming/constraints", roleRequired: 1 },
      "Streaming Pacing": { to: "pacing/streaming", requiresClient: false, roleRequired: 1 },
      "Streaming Plans Builder": { to: "streaming/planbuilder", roleRequired: 1 },
      Reports: { to: "streaming/report", requiresClient: true, roleRequired: 1 },
      Wizard: { to: "streaming/wizard", requiresClient: true, roleRequired: 1 },
    },
    "Channel Operations": {
      "Streaming AdOps": { to: "adops/streaming", roleRequired: 1 },
      "Streaming Discrepancies": { to: "pacing/discrepancies", roleRequired: 1 },
      "Streaming Networks": { to: "streaming/networks", roleRequired: 1 },
      "Streaming Monitoring": {
        experiment: "enableStreamingMonitoring",
        to: "streaming/monitoring",
        roleRequired: 1,
      },
    },
  },
  YouTube: {
    subPages: {
      Performance: {
        requiresClient: true,
        to: "youtube/performance",
        roleRequired: 10,
      },
      Delivery: {
        requiresClient: true,
        to: "youtube/delivery",
        roleRequired: 10,
      },
    },
  },
  Commerce: {
    subPages: {
      Overview: {
        requiresClient: true,
        to: "commerce/overview",
        roleRequired: 10,
      },
      "Metrics Table": {
        requiresClient: true,
        to: "commerce/metrics-table",
        roleRequired: 10,
      },
      Delivery: {
        requiresClient: true,
        to: "commerce/delivery",
        roleRequired: 10,
      },
    },
    "Channel Operations": {
      "AMC Insights": { requiresClient: true, to: "amc-insights", roleRequired: 10 },
    },
  },
  Social: {
    subPages: {
      Overview: {
        requiresClient: true,
        to: "social/overview",
        roleRequired: 10,
      },
      "Metrics Table": {
        requiresClient: true,
        to: "social/metrics-table",
        roleRequired: 10,
      },
      Delivery: {
        requiresClient: true,
        to: "social/delivery",
        roleRequired: 10,
      },
    },
    "Client Planning Tools": {
      "Meta Buying": {
        to: "social/meta-buying",
        requiresClient: true,
        roleRequired: 2,
        experiment: "enableMetaBuying",
      },
    },
    "Channel Operations": {
      "Web Traffic Optimization": { to: "wto", requiresClient: true, roleRequired: 2 },
      "Site Monitoring": { to: "sitemonitoring", roleRequired: 2 },
      "CRO Onboard": { to: "croonboard", requiresClient: true, roleRequired: 2 },
    },
  },
  Custom: { to: "customdashboard", requiresClient: true, roleRequired: 10 },
  Creatives: {
    "Creative Insights": {
      experiment: "enableCreativeInsights",
      requiresClient: true,
      to: "creative-insights",
    },
    Creatives: {
      to: "creatives",
      requiresClient: true,
      roleRequired: 10,
    },
  },
  Competitive: {
    "Competitive Metrics": {
      requiresClient: true,
      to: "competitive/metrics",
      roleRequired: 10,
    },
    "Spot Details": {
      requiresClient: true,
      to: "competitive/spots",
      roleRequired: 10,
    },
  },
  Experiments: { icon: <MdScience />, requiresClient: true, to: "streaming/experiments" },
  MMM: {
    experiment: "enableMMMNavbarGui",
    icon: <MMMIcon />,
    requiresClient: true,
    to: "mmm",
    roleRequired: 10,
  },
  "Brand Health": {
    experiment: "enableBrandHealthMetrics",
    icon: <BrandHealthIcon />,
    requiresClient: true,
    to: "brand-health-metrics",
  },
  "Internal-only": {
    "Internal Tools": { icon: <MdBuild /> },
    "Incrementality Playbook": { icon: <BiSolidBookOpen /> },
  },
  // Internal Tools for all channels
  "Internal Tools": {
    "Client Reporting": {
      "Creative Map": { to: "creativemap", requiresClient: true, roleRequired: 1 },
      "Expected Bookings": { to: "bookings", requiresClient: true, roleRequired: 1 },
      "Mobius Slides": {
        experiment: "enableMobiusSlidesCompany",
        requiresClient: true,
        to: "mobius-slides",
        roleRequired: 1,
      },
      Pacing: { to: "pacing", requiresClient: true, roleRequired: 1 },
      "Query Exporter": { to: "query-exporter", roleRequired: 1 },
      Reporting: { to: "reporting", requiresClient: true, roleRequired: 1 },
      Slides: { to: "slides", requiresClient: true, roleRequired: 1 },
    },
    Operations: {
      "Account Planning": { to: "accountplanning", roleRequired: 1 },
      "Add New Client": { to: "add-new-client", roleRequired: 1 },
      "Budget Intake Tool": {
        to: "budget-intake-tool",
        requiresClient: true,
        roleRequired: 1,
      },
      "Edit Client": { to: "edit-client", requiresClient: true, roleRequired: 1 },
      "User Admin": { to: "useradmin", roleRequired: 1 },
      Commissions: { to: "commissions", roleRequired: 1 },
      Invoicing: { to: "invoicing", roleRequired: 1 },
    },
    Media: {
      "Device Breakout": { to: "devicebreakout", roleRequired: 1 },
      "Internal Reports": { to: "reports", roleRequired: 1 },
      "Media Update Generator": { to: "media-update-generator", roleRequired: 1 },
      "Out of Home Buys": { to: "out-of-home-buys", roleRequired: 1 },
      Admin: { to: "admin", roleRequired: 1 },
      Calendar: { to: "calendar", roleRequired: 1 },
    },
    Dev: {
      "Batch Failures": { to: "batch-failures", roleRequired: 1 },
      "Segmentation Mapping": {
        roleRequired: 2,
        to: "segmentation-mapping",
        requiresClient: true,
      },
      "Dag Status": { to: "dag-status", roleRequired: 1 },
      "KPI Setup": { to: "kpi-setup", experiment: "enableKpiSetup", roleRequired: 1 },
      "KPI Status": { to: "kpi-status", roleRequired: 1 },

      Companies: { to: "companies", roleRequired: 1 },
      Properties: { to: "properties", requiresClient: true, roleRequired: 1 },
      "KPI Mapping": { to: "kpi-mapping", roleRequired: 1, requiresClient: true },
    },
  },
  "Incrementality Playbook": {
    to: "incrementality-playbook",
  },
  "Reference Docs": {
    to: "reference",
  },
  "Log Out": {
    to: "logout",
    onClick: signOutAWS,
  },
};

export const flattenRoutes = obj => {
  let flat = {};
  for (let i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      if (
        !R.isNil(obj[i].to) ||
        !R.isNil(obj[i].onClick) ||
        !R.isNil(obj[i].requiresClient) ||
        !R.isNil(obj[i].icon)
      ) {
        flat[i] = obj[i];
      } else if (typeof obj[i] === "object") {
        let flatObj = flattenRoutes(obj[i]);
        for (let x in flatObj) {
          if (Object.prototype.hasOwnProperty.call(flatObj, x)) {
            flat[`${i}/${x}`] = flatObj[x];
          }
        }
      }
    }
  }
  return flat;
};

const removeDisabledRoutesRecursive = (locations, experiments, role) => {
  const keys = R.keys(locations);
  if (
    keys.includes("to") ||
    keys.includes("onClick") ||
    keys.includes("experiment") ||
    keys.includes("requiresClient") ||
    keys.includes("icon") ||
    keys.includes("roleRequired")
  ) {
    if (!R.isNil(locations.experiment) && !experiments.includes(locations.experiment)) {
      return null;
    } else if (!R.isNil(locations.roleRequired) && locations.roleRequired < role) {
      return null;
    }

    return locations;
  } else {
    for (const key of keys) {
      locations[key] = removeDisabledRoutesRecursive(locations[key], experiments, role);
      if (R.isNil(locations[key])) {
        delete locations[key];
      }
    }
  }
  return locations;
};

const removeEmptyLocations = locations => {
  const keys = R.keys(locations);
  if (
    keys.includes("to") ||
    keys.includes("onClick") ||
    keys.includes("experiment") ||
    keys.includes("requiresClient") ||
    keys.includes("icon") ||
    keys.includes("roleRequired")
  ) {
    return locations;
  }
  if (!keys.length) {
    return null;
  } else {
    for (const key of keys) {
      locations[key] = removeEmptyLocations(locations[key]);
      if (R.isNil(locations[key])) {
        delete locations[key];
      }
    }
  }
  return locations;
};

export const removeDisabledRoutes = (experiments, role) => {
  const locationsCopy = R.clone(LOCATIONS);
  let locationsFiltered = removeDisabledRoutesRecursive(locationsCopy, experiments, role);
  const creativeKeys = R.keys(locationsFiltered.Creatives);
  if (creativeKeys.length === 1) {
    locationsFiltered.Creatives = locationsFiltered.Creatives[creativeKeys[0]];
  }
  const competitiveKeys = R.keys(locationsFiltered.Competitive);
  if (competitiveKeys.length === 1) {
    locationsFiltered.Competitive = locationsFiltered.Competitive[competitiveKeys[0]];
  }
  locationsFiltered = removeEmptyLocations(locationsFiltered);
  const internalToolsKeys = R.keys(locationsFiltered["Internal Tools"]);
  if (!internalToolsKeys.length) {
    delete locationsFiltered["Internal Only"];
  }
  return locationsFiltered;
};

export const getChannelTools = (LOCATIONS, channel, category) => {
  const clientTools = [];
  if (!R.keys(LOCATIONS).includes(channel)) {
    return clientTools;
  }
  const toolsMap = R.defaultTo(
    {},
    LOCATIONS[["Audio", "Display"].includes(channel) ? "Streaming TV" : channel][category]
  );
  if (category === "Channel Buying" && channel === "Display") {
    toolsMap["Display Pacing"] = {
      experiment: "enableDisplayPacing",
      requiresClient: false,
      to: "pacing/display",
    };
    delete toolsMap["Streaming Pacing"];
  } else if (category === "Channel Buying" && ["Audio", "Streaming TV"].includes(channel)) {
    toolsMap["Streaming Pacing"] = { to: "pacing/streaming", requiresClient: false };
    delete toolsMap["Display Pacing"];
  }
  R.keys(toolsMap).forEach(key => clientTools.push({ ...toolsMap[key], title: key }));
  return clientTools.sort((a, b) => a.title.localeCompare(b.title));
};

export const getInternalTools = (LOCATIONS, category) => {
  const internalTools = [];
  const internalToolsMap = R.defaultTo({}, LOCATIONS["Internal Tools"][category]);
  R.keys(internalToolsMap).forEach(key =>
    internalTools.push({ ...internalToolsMap[key], title: key })
  );
  return R.uniq(internalTools).sort((a, b) => a.title.localeCompare(b.title));
};

export const CLIENT_TOOLS_HEADERS = [
  "Client Planning Tools",
  "Channel Buying",
  "Channel Operations",
];
export const INTERNAL_TOOLS_HEADERS = ["Client Reporting", "Operations", "Media", "Dev"];

const currentNavBarItemHelper = (object, to, keys) => {
  if (object && to.startsWith(object.to)) {
    return keys;
  }
  if (typeof object === "object") {
    for (let i = 0; i < R.keys(object).length; i++) {
      if (typeof object[R.keys(object)[i]] === "object") {
        const o = currentNavBarItemHelper(object[R.keys(object)[i]], to, [
          ...keys,
          R.keys(object)[i],
        ]);
        if (o != null) {
          return o;
        }
      }
    }
  }
  return null;
};

export const currentNavBarItem = (object, to) => {
  const keys = currentNavBarItemHelper(object, to, []);
  if (keys && keys.length) {
    return keys;
  }
  return null;
};

import React from "react";
import { Button, ButtonType } from "../../Components";

interface MetaBuyingFooterControlsProps {
  tabName: string;
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  showReviewModal: () => void;
}

export const MetaBuyingFooterControls = ({
  tabName,
  tabIndex,
  setTabIndex,
  showReviewModal,
}: MetaBuyingFooterControlsProps): JSX.Element => {
  return (
    <div className="metaBuyingButtons">
      <Button type={ButtonType.OUTLINED} onClick={e => showReviewModal()}>
        Review and Finish
      </Button>
      <Button type={ButtonType.FILLED} onClick={() => setTabIndex(tabIndex + 1)}>
        Next (Create {tabName})
      </Button>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClientInfo, Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import useLocation from "../../utils/hooks/useLocation";
import * as UserRedux from "../../redux/user";
import { useSetError } from "../../redux/modals";
import OnboardingFormPage from "../OnboardingFormPage";
import QuestionOnlyForm from "../QuestionOnlyForm";
import { budgetDetails, budgetStrategy, budgets } from "./BudgetsBillingQuestions";
import { saveClientInfo, getCompletedPercentage } from "../OnboardingUtils";

enum BudgetsBillingTabs {
  BUDGET_STRATEGY = "budget-strategy",
  BUDGET_DETAILS = "budget-details",
  BUDGET_FILES = "budget-files",
}

interface BudgetsBillingProps {
  clientInfo: ClientInfo;
  onPercentageChange: (percentage: number) => void;
}

const BudgetsBilling: React.FC<BudgetsBillingProps> = ({ clientInfo, onPercentageChange }) => {
  const [initialResponses, setInitialResponses] = useState<Response[]>(clientInfo.responses || []);
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);
  const [saveSubmitting, setSaveSubmitting] = useState<boolean>(false);
  const setError = useSetError();
  const user = useSelector(UserRedux.fullNameSelector);
  const { company } = useLocation();
  useEffect(() => {
    setSaveSubmitting(initialResponses !== allResponses.responses);
  }, [initialResponses, allResponses]);

  const [budgetFilesCompleted, setBudgetFilesCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, budgets.length, "4A")
  );
  const [budgetStrategyCompleted, setBudgetStrategyCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, budgetStrategy.length, "4B")
  );
  const [budgetDetailsCompleted, setBudgetDetailsCompleted] = useState<number>(
    getCompletedPercentage(allResponses.responses, budgetDetails.length, "4C")
  );

  const handleBudgetChange = (updatedResponses: Response[]) => {
    const updatedClientInfo = {
      ...allResponses,
      responses: updatedResponses,
    };

    setAllResponses(updatedClientInfo);
  };

  useEffect(() => {
    const totalPercentage = getCompletedPercentage(
      allResponses.responses,
      budgetStrategy.length + budgetDetails.length + budgets.length,
      "4"
    );
    setBudgetFilesCompleted(
      getCompletedPercentage(allResponses.responses, budgets.length, "4A")
    );
    setBudgetStrategyCompleted(
      getCompletedPercentage(allResponses.responses, budgetStrategy.length, "4B")
    );
    setBudgetDetailsCompleted(
      getCompletedPercentage(allResponses.responses, budgetDetails.length, "4C")
    );
    onPercentageChange(totalPercentage);
  }, [allResponses, onPercentageChange]);

  const renderTabs = [
    {
      label: "Budget Files",
      headerLabel: "A. Budget Files",
      key: BudgetsBillingTabs.BUDGET_FILES,
      percentage: budgetFilesCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={budgets}
          onChange={handleBudgetChange}
        />
      ),
    },
    {
      label: "Budget Strategy",
      headerLabel: "B. Budget Strategy",
      key: BudgetsBillingTabs.BUDGET_STRATEGY,
      percentage: budgetStrategyCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={budgetStrategy}
          onChange={handleBudgetChange}
        />
      ),
    },
    {
      label: "Budget Details",
      headerLabel: "C. Budget Details",
      key: BudgetsBillingTabs.BUDGET_DETAILS,
      percentage: budgetDetailsCompleted,
      children: (
        <QuestionOnlyForm
          responses={allResponses.responses}
          questions={budgetDetails}
          onChange={handleBudgetChange}
        />
      ),
    },
  ];

  const handleSave = async () => {
    try {
      await saveClientInfo({
        company,
        responses: allResponses.responses,
        user,
      });
      setInitialResponses(allResponses.responses);
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <OnboardingFormPage
      renderTabs={renderTabs}
      defaultActiveTab={BudgetsBillingTabs.BUDGET_STRATEGY}
      tabs={Object.values(BudgetsBillingTabs)}
      saveSubmitting={saveSubmitting}
      onSave={handleSave}
    />
  );
};

export default BudgetsBilling;

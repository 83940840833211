import {
  CDN,
  CellData,
  ColumnMetaData,
  DimensionMap,
  toPretty1000sInteger,
  toPrettyCPX,
  toPrettyNumber,
  toPrettyPercent,
  toPrettySpend,
} from "../MetricsTable/metricsTableUtils";
import { CreativeMap } from "../../redux/creative";
import { DerivedNetworkMap } from "../../redux/networks";
import { DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/SocialPerformance";

export const getDimensionCell = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn,
  creativeMap?: CreativeMap,
  derivedNetworkMap?: DerivedNetworkMap
): CellData => {
  const { dimensionVarName, dimensionTypeName } = dimensionHeader;
  const resolvedDimensionData = dimensionData as Record<S.Dimension, string>;
  const dimensionValue = resolvedDimensionData[dimensionVarName];
  const defaultCell = {
    value: dimensionValue,
    label: dimensionValue,
  };
  if (dimensionTypeName === "Platform") {
    const parsedDimensionValue = dimensionValue.replace(/\s+/g, "");
    const url = `${CDN}/assets/img/platforms/${parsedDimensionValue}.png`;
    return {
      label: dimensionValue,
      overlayText: dimensionValue,
      value: dimensionValue,
      url,
    };
  }
  return defaultCell;
};

export const dimensionColumnMetaDataMap: Partial<Record<S.DimensionColumnType, ColumnMetaData>> = {
  Platform: {
    contentType: "text",
    dimensionVarName: "platform",
    displayName: "Platform",
    iconStyle: "logo",
  },
  Campaign: {
    contentType: "text",
    dimensionVarName: "campaign_name",
    displayName: "Campaign",
  },
  "Ad Group": {
    contentType: "text",
    dimensionVarName: "ad_group_name",
    displayName: "Ad Group Name",
  },
  Ad: {
    contentType: "text",
    dimensionVarName: "ad_name",
    displayName: "Ad Name",
  },
  Surface: {
    contentType: "text",
    dimensionVarName: "surface",
    displayName: "Surface",
  },
};

export const columnMetaDataMap: Partial<Record<S.ColumnType, ColumnMetaData>> = {
  clicks: {
    displayName: "Clicks",
    formatValue: toPrettyNumber,
  },
  spend: {
    displayName: "Spend",
    formatValue: toPrettySpend,
  },
  impressions: {
    displayName: "Impressions (000s)",
    formatValue: toPretty1000sInteger,
  },
  revenue: {
    displayName: "Revenue",
    formatValue: toPrettySpend,
  },
  roas: {
    aggregator: agg =>
      agg.spend && (agg.spend as number) > 0 && agg.revenue
        ? ((agg.revenue as number) * 1.0) / R.defaultTo(0.0, agg.spend as number)
        : "--",
    displayName: "ROAS",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.spend && (fetch.spend as number) > 0 && fetch.revenue
        ? ((fetch.revenue as number) * 1.0) / R.defaultTo(0.0, fetch.spend as number)
        : "--",
    formatValue: toPrettyNumber,
    requiredTotalsColumns: ["spend", "revenue"],
  },
  video_views: {
    displayName: "Video Views",
    formatValue: toPrettyNumber,
  },
  volume: {
    displayName: "KPI Volume",
    formatValue: toPrettyNumber,
  },
  cpm: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.spend
        ? ((agg.spend as number) * 1000.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    displayName: "CPM",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.spend
        ? ((fetch.spend as number) * 1000.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyCPX,
    requiredTotalsColumns: ["impressions", "spend"],
  },
  cpx: {
    aggregator: agg =>
      agg.volume && (agg.volume as number) > 0 && agg.spend
        ? (agg.spend as number) / R.defaultTo(0.0, agg.volume as number)
        : "--",
    displayName: "CPX",
    decimals: 2,
    fetchGetter: fetch =>
      fetch.volume && (fetch.volume as number) > 0 && fetch.spend
        ? (fetch.spend as number) / R.defaultTo(0.0, fetch.volume as number)
        : "--",
    formatValue: toPrettyCPX,
    requiredTotalsColumns: ["volume", "spend"],
  },
  clicksConversionRate: {
    aggregator: agg =>
      agg.clicks && (agg.clicks as number) > 0 && agg.volume
        ? ((agg.volume as number) * 1.0) / R.defaultTo(0.0, agg.clicks as number)
        : "--",
    decimals: 2,
    displayName: "Conversion Rate",
    fetchGetter: fetch =>
      fetch.clicks && (fetch.clicks as number) > 0 && fetch.volume
        ? ((fetch.volume as number) * 1.0) / R.defaultTo(0.0, fetch.clicks as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["clicks", "volume"],
  },
  impsConversionRate: {
    aggregator: agg =>
      agg.impressions && (agg.impressions as number) > 0 && agg.volume
        ? ((agg.volume as number) * 1.0) / R.defaultTo(0.0, agg.impressions as number)
        : "--",
    decimals: 2,
    displayName: "Imps Conversion Rate",
    fetchGetter: fetch =>
      fetch.impressions && (fetch.impressions as number) > 0 && fetch.volume
        ? ((fetch.volume as number) * 1.0) / R.defaultTo(0.0, fetch.impressions as number)
        : "--",
    formatValue: toPrettyPercent,
    requiredTotalsColumns: ["impressions", "volume"],
  },
};

import React from "react";
import { Dropdown, DropdownToggleType } from "../../Dropdown";
import { Button, ButtonType } from "../../Button";
import { Setter } from "../../../utils/types";
import { Form } from "react-bootstrap";
import {
  CUSTOM_PRESET_INTERVAL,
  CUSTOM_PRESET_REFERENCE,
} from "../DatePickerButton/DatePickerConstants";
import { CustomPresetState } from "@blisspointmedia/bpm-types/dist/RelativeDatePresets";

interface SinglePresetProps {
  label: "Start" | "End";
  customPresetState: CustomPresetState;
  setCustomPresetState: Setter<CustomPresetState | ((c: CustomPresetState) => CustomPresetState)>;
  rangeError: boolean;
  disabled?: boolean;
}

export const SingleCustomDatePreset: React.FC<SinglePresetProps> = ({
  customPresetState,
  setCustomPresetState,
  disabled,
  rangeError,
  label,
}) => {
  return (
    <>
      <div className="customDatePresetContainer">
        <div className="presetTitle">{label}</div>
        <div className="DPPresetsControlsContainer">
          <Button
            type={ButtonType.FILLED}
            background="light"
            design="secondary"
            size="sm"
            className="numberInputContainer"
            disabled={disabled}
          >
            <Form.Control
              size="sm"
              className="daysInput"
              type="number"
              placeholder="X"
              value={customPresetState.number === undefined ? "" : customPresetState.number}
              onChange={e => {
                e.persist();
                const value = e.target.value === "" ? undefined : Number(e.target.value);
                setCustomPresetState(c => ({ ...c, number: value }));
              }}
              disabled={disabled}
            />
          </Button>
          <Dropdown
            type={DropdownToggleType.FILLED}
            background="light"
            design="secondary"
            size="sm"
            value={customPresetState.interval}
            options={CUSTOM_PRESET_INTERVAL}
            onChange={value => setCustomPresetState(c => ({ ...c, interval: value }))}
            disabled={disabled}
          />
          <Dropdown
            type={DropdownToggleType.FILLED}
            value={customPresetState.before ? "Before" : "After"}
            options={["Before", "After"]}
            background="light"
            design="secondary"
            onChange={value => setCustomPresetState(c => ({ ...c, before: value === "Before" }))}
            size="sm"
            disabled={disabled}
          />
          <Dropdown
            type={DropdownToggleType.FILLED}
            value={customPresetState.referenceDate}
            options={CUSTOM_PRESET_REFERENCE}
            background="light"
            design="secondary"
            onChange={value => setCustomPresetState(c => ({ ...c, referenceDate: value }))}
            size="sm"
            disabled={disabled}
          />
        </div>
      </div>
      {!disabled && rangeError && <div className="rangeError">{"Outside date range"}</div>}
    </>
  );
};

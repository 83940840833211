import "./DemoDataGenerator.scss";
import { awaitJSON, CrossChannelLambdaFetch, DagLambdaFetch } from "../utils/fetch-utils";
import { BPMTable, Dropdown, Header, Page } from "../Components";
import { Button, Form } from "react-bootstrap";
import { cartesianProduct, CC_DIMENSIONS } from "./DemoDataGeneratorUtils";
import { useSelector } from "react-redux";
import { useSetError } from "../redux/modals";
import * as R from "ramda";
import * as UserRedux from "../redux/user";
import React, { useState, useCallback, useEffect, useMemo } from "react";

const MEDIATYPE_OPTIONS = [
  { value: "cross-channel", label: "Cross Channel" },
  { value: "audio", label: "Audio" }, // TODO: allow these when we have the lambda for it
  { value: "display", label: "Display" },
  { value: "linear", label: "Linear" },
  { value: "search", label: "Search" },
  { value: "social", label: "Social" },
  { value: "streaming", label: "Streaming" },
  { value: "youtube", label: "YouTube" },
];

interface GenerateTestDataBody {
  company: string;
  customSegments: string[];
  dimensionWeights: Record<
    string,
    Record<"clicks" | "impressions" | "revenue" | "spend" | "volume", number>
  >;
  // Daily Totals
  dailyClicks: number;
  dailyImpressions: number;
  dailyRevenue: number;
  dailySpend: number;
  dailyVolumeMap: Record<string, number>; // Use a map for multiple KPIs
  // Jitter
  impressionsJitter: number;
  clicksJitter: number;
  revenueJitter: number;
  spendJitter: number;
  volumeJitterMap: Record<string, number>;
  // Dates
  start: string;
  end: string;
}

const DemoDataGenerator: React.FC = () => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [clicksJitter, setClicksJitter] = useState<number>(0);
  const [company, setCompany] = useState<string>("");
  const [customSegmentMap, setCustomSegmentMap] = useState<Record<string, string>>({});
  const [dailyClicks, setDailyClicks] = useState<number>(0);
  const [dailyImpressions, setDailyImpressions] = useState<number>(0);
  const [dailyRevenue, setDailyRevenue] = useState<number>(0);
  const [dailySpend, setDailySpend] = useState<number>(0);
  const [dailyVolumeMap, setDailyVolumeMap] = useState<Record<string, number>>({});
  const [dimensionMap, setDimensionMap] = useState<Record<string, string>>({});
  const [dimensionWeights, setDimensionWeights] = useState<Record<string, Record<string, number>>>(
    {}
  );
  const [end, setEnd] = useState<string>("");
  const [impressionsJitter, setImpressionsJitter] = useState<number>(0);
  const [kpis, setKpis] = useState<string[]>([]);
  const [mediatype, setMediatype] = useState<string>("cross-channel");
  const [revenueJitter, setRevenueJitter] = useState<number>(0);
  const [spendJitter, setSpendJitter] = useState<number>(0);
  const [start, setStart] = useState<string>("");
  const [volumeJitterMap, setVolumeJitterMap] = useState<Record<string, number>>({});
  const userEmail = useSelector(UserRedux.emailSelector);
  const setError = useSetError();

  const mediatypeDropdown = (
    <Dropdown
      value={mediatype}
      options={MEDIATYPE_OPTIONS.slice(0, 1)}
      onChange={val => {
        setMediatype(val);
        setDimensionMap({});
      }}
    />
  );

  const generateBody: GenerateTestDataBody = useMemo(() => {
    if (!isDataLoaded && localStorage.getItem(`${mediatype}_demo_data`)) {
      const data = JSON.parse(R.defaultTo("", localStorage.getItem(`${mediatype}_demo_data`)));
      setCompany(data.company);
      setDimensionWeights(data.dimensionWeights);
      setDailyClicks(data.dailyClicks);
      setDailyImpressions(data.dailyImpressions);
      setDailyRevenue(data.dailyRevenue);
      setDailySpend(data.dailySpend);
      setDailyVolumeMap(data.dailyVolumeMap);
      setImpressionsJitter(data.impressionsJitter);
      setClicksJitter(data.clicksJitter);
      setRevenueJitter(data.revenueJitter);
      setSpendJitter(data.spendJitter);
      setVolumeJitterMap(data.volumeJitterMap);
      setStart(data.start);
      setEnd(data.end);
      setIsDataLoaded(true);
      if (data.volumeJitterMap && !R.isEmpty(data.volumeJitterMap)) {
        setKpis(R.keys(data.volumeJitterMap) as string[]);
      }
      if (data.dimensionMap && !R.isEmpty(data.dimensionMap)) {
        setDimensionMap(data.dimensionMap);
      }
      if (data.customSegmentMap && !R.isEmpty(data.customSegmentMap)) {
        setCustomSegmentMap(data.customSegmentMap);
      }
      return data;
    }
    const body = {
      company,
      customSegments: R.keys(customSegmentMap),
      dimensionWeights,
      dailyClicks,
      dailyImpressions,
      dailyRevenue,
      dailySpend,
      dailyVolumeMap,
      impressionsJitter,
      clicksJitter,
      revenueJitter,
      spendJitter,
      volumeJitterMap,
      start,
      end,
    };
    localStorage.setItem(
      `${mediatype}_demo_data`,
      JSON.stringify({ ...body, dimensionMap, kpis, customSegmentMap })
    );
    return body;
  }, [
    clicksJitter,
    company,
    customSegmentMap,
    dailyClicks,
    dailyImpressions,
    dailyRevenue,
    dailySpend,
    dailyVolumeMap,
    dimensionMap,
    dimensionWeights,
    end,
    impressionsJitter,
    isDataLoaded,
    kpis,
    mediatype,
    revenueJitter,
    spendJitter,
    start,
    volumeJitterMap,
  ]);

  const generateTestData = useCallback(async () => {
    try {
      const resp = await CrossChannelLambdaFetch("/generateTestData", {
        method: "POST",
        body: generateBody,
      });
      const data = await awaitJSON(resp);
      if (data.message) {
        if (data.message.startsWith(`Successfully generated test data for ${company}`)) {
          setError({
            title: "Success",
            variant: "success",
            message: `${data.message}
          Starting an ingestion job to copy the google sheets data to the database. You will receive an email when the job is complete.`,
          });
          await DagLambdaFetch("/start_single_job", {
            method: "POST",
            body: {
              dag: "blisspoint",
              task: "CC-DEMO-GEN",
              task_name: "CC-DEMO-GEN",
              override: true,
              extra: `${company}_${userEmail}`,
            },
          });
        }
      }
    } catch (e) {
      const reportError = e as Error;
      setError({ message: reportError.message, reportError });
    }
  }, [company, generateBody, setError, userEmail]);

  const tableHeaders = useMemo(() => {
    const headers: Header[] = [];
    for (const dimension of R.keys(dimensionMap)) {
      headers.push({
        label: dimension,
        field: dimension,
        type: "text",
        renderer: value => {
          return value[dimension];
        },
      });
    }
    for (const customSegment of R.keys(customSegmentMap)) {
      if (customSegment) {
        headers.push({
          label: customSegment,
          field: customSegment,
          type: "text",
          renderer: value => {
            return value[customSegment];
          },
        });
      }
    }
    for (const metric of ["Clicks", "Impressions", "Revenue", "Spend", "Volume"]) {
      headers.push({
        label: `${metric} Share(%)`,
        field: metric,
        type: "number",
        renderer: row => {
          const rowKey = [
            ...R.map(dim => row[dim], R.keys(dimensionMap)),
            ...R.map(segment => row[segment], R.keys(customSegmentMap)),
          ].join("|;|");
          return (
            <Form.Control
              type="number"
              value={
                dimensionWeights &&
                dimensionWeights[rowKey] &&
                dimensionWeights[rowKey][metric.toLowerCase()]
                  ? dimensionWeights[rowKey][metric.toLowerCase()]
                  : "0"
              }
              onChange={e => {
                const value = e && e.target && e.target.value ? e.target.value : 0;
                setDimensionWeights(prev => {
                  const newWeights = R.clone(prev);
                  newWeights[rowKey] = {
                    ...R.defaultTo({}, newWeights[rowKey]),
                    [metric.toLowerCase()]: parseInt(value as string),
                  };
                  return newWeights;
                });
              }}
            />
          );
        },
      });
      headers.push({
        label: `${metric} Share(%) Calculated`,
        field: metric,
        type: "number",
        renderer: row => {
          const rowKey = [
            ...R.map(dim => row[dim], R.keys(dimensionMap)),
            ...R.map(segment => row[segment], R.keys(customSegmentMap)),
          ].join("|;|");
          const weight =
            dimensionWeights[rowKey] && dimensionWeights[rowKey][metric.toLowerCase()]
              ? dimensionWeights[rowKey][metric.toLowerCase()]
              : 0;
          if (metric === "Clicks") {
            return (dailyClicks * weight) / 100;
          } else if (metric === "Impressions") {
            return (dailyImpressions * weight) / 100;
          } else if (metric === "Revenue") {
            return (dailyRevenue * weight) / 100;
          } else if (metric === "Spend") {
            return (dailySpend * weight) / 100;
          } else if (metric === "Volume") {
            return (dailySpend * weight) / 100;
          } else {
            return 0;
          }
        },
      });
    }
    return headers;
  }, [
    customSegmentMap,
    dailyClicks,
    dailyImpressions,
    dailyRevenue,
    dailySpend,
    dimensionMap,
    dimensionWeights,
  ]);

  const tableData = useMemo(() => {
    const tableData: Record<string, number | string>[] = [];
    // Create a table with every combination of dimension and custom segment
    // from each of their respective maps. In the case that we don't have any
    // custom segments, just go through dimensions
    const rows = cartesianProduct([
      ...R.values(dimensionMap).map(dimension => dimension.split(",")),
      ...R.values(customSegmentMap).map(segment => segment.split(",")),
    ]);
    for (const row of rows) {
      const rowObj = {};
      for (let i = 0; i < row.length; i++) {
        if (i < R.keys(dimensionMap).length) {
          rowObj[R.keys(dimensionMap)[i]] = row[i];
        } else {
          rowObj[R.keys(customSegmentMap)[i - R.keys(dimensionMap).length]] = row[i];
        }
      }
      tableData.push(rowObj);
    }
    return tableData;
  }, [customSegmentMap, dimensionMap]);

  const weightsTableTitle = <div className="demoGenTableTitle">Generated Row Settings</div>;
  const spreadEvenlyButton = (
    <Button
      onClick={() => {
        const newDimensionWeights = {};
        const numRows = R.defaultTo([], tableData).length;
        for (const row of tableData) {
          const key = [
            ...R.map(dim => row[dim], R.keys(dimensionMap)),
            ...R.map(segment => row[segment], R.keys(customSegmentMap)),
          ].join("|;|");
          newDimensionWeights[key] = {};
          for (const metric of ["clicks", "impressions", "revenue", "spend", "volume"]) {
            newDimensionWeights[key][metric] = Math.round(100 / numRows);
          }
        }
        setDimensionWeights(newDimensionWeights);
      }}
    >
      Spread Evenly
    </Button>
  );
  const weightsTable = (
    <div className="tableContainer">
      <BPMTable data={tableData} headers={tableHeaders} />
    </div>
  );

  const generateButton = (
    <Button onClick={generateTestData} size="sm">
      Generate
    </Button>
  );
  const generalInputs = useMemo(
    () => (
      <div className="demoGenInputs">
        <div className="title">General Settings</div>
        <div>Company (must either be "tinuititest" or start with "tinuitidemo")</div>
        <Form.Control
          type="text"
          value={company}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            setCompany(value);
          }}
        />
        <div>Start Date (YYYY-MM-DD)</div>
        <Form.Control
          type="text"
          value={start}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            setStart(value);
          }}
        />
        <div>End Date (YYYY-MM-DD)</div>
        <Form.Control
          type="text"
          value={end}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            setEnd(value);
          }}
        />
      </div>
    ),
    [company, end, start]
  );

  const dimensionInputs = useMemo(
    () => (
      <div className="demoGenInputs">
        <div className="title">Dimensions</div>
        {R.map(
          dimension => (
            <>
              <div>{dimension}</div>
              <Form.Control
                type="text"
                value={dimensionMap[dimension]}
                onChange={e => {
                  const value = e && e.target && e.target.value ? e.target.value : "";
                  setDimensionMap(prev => ({
                    ...prev,
                    [dimension]: value,
                  }));
                }}
              />
            </>
          ),
          R.keys(dimensionMap)
        )}
      </div>
    ),
    [dimensionMap]
  );

  const kpiInputs = useMemo(
    () => (
      <div className="demoGenInputs">
        <div className="title">KPIs</div>
        <>
          <div>
            KPIs (first kpis are at the top of funnel, lower kpis are the bottom of the funnel)
          </div>
          <Form.Control
            type="text"
            value={kpis.join(",")}
            onChange={e => {
              const value = e && e.target && e.target.value ? e.target.value : "";
              const kpis = R.map(val => val, value.split(","));
              setKpis(kpis);
            }}
          />
        </>
        {R.map(
          kpi =>
            kpi.length > 0 && (
              <>
                <div>{kpi} Daily Volume</div>
                <Form.Control
                  type="number"
                  value={dailyVolumeMap[kpi]}
                  onChange={e => {
                    const value = e && e.target && e.target.value ? e.target.value : "";
                    if (!isNaN(parseInt(value))) {
                      setDailyVolumeMap(prev => ({
                        ...prev,
                        [kpi]: parseInt(value),
                      }));
                    }
                  }}
                />
                <div>{kpi} Jitter (0.00 - 1.00)</div>
                <Form.Control
                  type="number"
                  value={volumeJitterMap[kpi]}
                  onChange={e => {
                    const value = e && e.target && e.target.value ? e.target.value : "";
                    if (!isNaN(parseFloat(value))) {
                      setVolumeJitterMap(prev => ({
                        ...prev,
                        [kpi]: parseFloat(value),
                      }));
                    }
                  }}
                />
              </>
            ),
          kpis
        )}
      </div>
    ),
    [dailyVolumeMap, kpis, volumeJitterMap]
  );

  const metricInputs = useMemo(
    () => (
      <div className="demoGenInputs">
        <div className="title">Metrics</div>
        <div>Daily Clicks</div>
        <Form.Control
          type="number"
          value={dailyClicks}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            if (!isNaN(parseInt(value))) {
              setDailyClicks(parseInt(value));
            }
          }}
        />
        <div>Clicks Jitter (0.00 - 1.00)</div>
        <Form.Control
          type="number"
          value={clicksJitter}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            if (!isNaN(parseFloat(value))) {
              setClicksJitter(parseFloat(value));
            }
          }}
        />
        <div>Daily Impressions</div>
        <Form.Control
          type="number"
          value={dailyImpressions}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            if (!isNaN(parseInt(value))) {
              setDailyImpressions(parseInt(value));
            }
          }}
        />
        <div>Impressions Jitter (0.00 - 1.00)</div>
        <Form.Control
          type="number"
          value={impressionsJitter}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            if (!isNaN(parseFloat(value))) {
              setImpressionsJitter(parseFloat(value));
            }
          }}
        />
        <div>Daily Revenue</div>
        <Form.Control
          type="number"
          value={dailyRevenue}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            if (!isNaN(parseInt(value))) {
              setDailyRevenue(parseInt(value));
            }
          }}
        />
        <div>Revenue Jitter (0.00 - 1.00)</div>
        <Form.Control
          type="number"
          value={revenueJitter}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            if (!isNaN(parseFloat(value))) {
              setRevenueJitter(parseFloat(value));
            }
          }}
        />
        <div>Daily Spend</div>
        <Form.Control
          type="number"
          value={dailySpend}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            if (!isNaN(parseInt(value))) {
              setDailySpend(parseInt(value));
            }
          }}
        />
        <div>Spend Jitter (0.00 - 1.00)</div>
        <Form.Control
          type="number"
          value={spendJitter}
          onChange={e => {
            const value = e && e.target && e.target.value ? e.target.value : "";
            if (!isNaN(parseFloat(value))) {
              setSpendJitter(parseFloat(value));
            }
          }}
        />
      </div>
    ),
    [
      clicksJitter,
      dailyClicks,
      dailyImpressions,
      dailyRevenue,
      dailySpend,
      impressionsJitter,
      revenueJitter,
      spendJitter,
    ]
  );

  const customSegmentInputs = useMemo(
    () => (
      <div className="demoGenInputs">
        <div className="title">Custom Segments</div>
        <>
          <div>Custom Segments</div>
          <Form.Control
            type="text"
            value={R.keys(customSegmentMap).join(",")}
            onChange={e => {
              const value = e && e.target && e.target.value ? e.target.value : "";
              const segments = value.split(",");
              setCustomSegmentMap(prev => {
                const newMap = {};
                for (const segment of segments) {
                  newMap[segment] = "";
                }
                for (const oldSegment of segments) {
                  if (prev[oldSegment]) {
                    newMap[oldSegment] = prev[oldSegment];
                  }
                }
                return newMap;
              });
            }}
          />
        </>
        {R.map(
          segment =>
            segment.length > 0 && (
              <>
                <div>{segment}</div>
                <Form.Control
                  type="text"
                  value={customSegmentMap[segment]}
                  onChange={e => {
                    const value = e && e.target && e.target.value ? e.target.value : "";
                    setCustomSegmentMap(prev => ({
                      ...prev,
                      [segment]: value,
                    }));
                  }}
                />
              </>
            ),
          R.keys(customSegmentMap)
        )}
      </div>
    ),
    [customSegmentMap]
  );

  useEffect(() => {
    if (mediatype === "cross-channel" && R.isEmpty(dimensionMap)) {
      const dimensionMap = {};
      for (const dimension of CC_DIMENSIONS) {
        dimensionMap[dimension] = "";
      }
      setDimensionMap(dimensionMap);
    }
  }, [dimensionMap, mediatype]);

  return (
    <Page
      actions={
        <div className="demoGenActions">
          {generateButton}
          {mediatypeDropdown}
        </div>
      }
      app2Redesign
      pageType={"Demo Data Generator"}
      title={"Demo Data Generator"}
    >
      {generalInputs}
      {dimensionInputs}
      {kpiInputs}
      {metricInputs}
      {customSegmentInputs}
      {weightsTableTitle}
      {spreadEvenlyButton}
      {weightsTable}
    </Page>
  );
};

export default DemoDataGenerator;
